import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import {
  requisitarDados,
  requisitarLimparDados,
} from "../../store/gridVendasUnidadesDiarias/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form, Input } from "reactstrap";
import {
  exportarExcel,
  formatarFooterColunaDoisValores,
  formatarFooterTitulo,
  Hoje,
  Mes,
  Ano,
  removerFocusNoBotao,
  primeiroDiaMes,
  toDateSQL,
  zeroEsquerda,
} from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import "@bryntum/grid/grid.stockholm.css";
import "../../App.scss";
import Header from "../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../components/filtroBrytum";
import Vazio from "../../components/vazio";
import FocarPaisagem from "../../components/forcarPaisagem";
import { retornarIdCliente } from "../../cliente/variaveis";
import Select from "react-select";

const ddlTipoPesquisa = [
  { label: "Líquido", value: "L" },
  { label: "Bruto", value: "B" }
];

const GridVendasUnidadesDiarias = (props) => {
  const idCliente = retornarIdCliente();
  const token = useSelector((state) => state.Login.user.tokenNOL);
  const [loading, setLoading] = useState(true);
  const { dados, carregando } = useSelector(
    (state) => state.GridVendasUnidadesDiarias
  );
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const grid = useRef(null);
  const dispatch = useDispatch();
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
  const [tipoPesquisa, setTipoPesquisa] = useState("L");
  const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);

  const dataInicialRef = useRef(null);
  dataInicialRef.current = { dataInicial, setDataInicial };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const atualizarDados = async () => {
    await dispatch(
      requisitarDados({
        data_inicial: toDateSQL(dataInicialRef.current.dataInicial),
        data_final: toDateSQL(dataFinalRef.current.dataFinal),
        retorno: "coluna",
        token,
      })
    );
    setMostrarFiltros(false);
  };
  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  };

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => dispatch(requisitarLimparDados());
  }, []);

  useEffect(() => {
    if (carregando) return;
    atualizarDadosDemanda();
  }, [carregando]);

  const atualizarDadosDemanda = () => {
    setMostrarFiltros(false);
    setmensagemDadosNaoEncontrados(false);
    setLoading(true);
    if (grid.current) {
      grid.current.instance.store.fetchOptions.body = JSON.stringify({
        data_inicial: toDateSQL(dataInicialRef.current.dataInicial),
        data_final: toDateSQL(dataFinalRef.current.dataFinal),
        retorno: "coluna",
        token,
      });
      grid.current.instance.store.removeAll();
      grid.current.instance.store.loadData();
      grid.current.instance.store
        .load()
        .then((resolve) => {
          document.getElementById(grid.current.instance.id).style.display =
            "flex";
          setLoading(false);
          if (!resolve.data.length) {
            document.getElementById(grid.current.instance.id).style.display =
              "none";
            setMostrarFiltros(true);
            setmensagemDadosNaoEncontrados(true);
          }
          dados.footer = resolve.json.ResultadoObjeto.footer;
          if (idCliente == "real" || idCliente == "volo") {
            if (grid.current.instance._columns.get(`faturamento_01`))
              grid.current.instance._columns.get(
                `faturamento_01`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_01`],
                    dados.footer[`cupons_01`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_01`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_02`))
              grid.current.instance._columns.get(
                `faturamento_02`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_02`],
                    dados.footer[`cupons_02`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_02`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_03`))
              grid.current.instance._columns.get(
                `faturamento_03`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_03`],
                    dados.footer[`cupons_03`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_03`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_04`))
              grid.current.instance._columns.get(
                `faturamento_04`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_04`],
                    dados.footer[`cupons_04`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_04`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_05`))
              grid.current.instance._columns.get(
                `faturamento_05`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_05`],
                    dados.footer[`cupons_05`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_05`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_06`))
              grid.current.instance._columns.get(
                `faturamento_06`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_06`],
                    dados.footer[`cupons_06`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_06`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_07`))
              grid.current.instance._columns.get(
                `faturamento_07`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_07`],
                    dados.footer[`cupons_07`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_07`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_08`))
              grid.current.instance._columns.get(
                `faturamento_08`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_08`],
                    dados.footer[`cupons_08`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_08`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_09`))
              grid.current.instance._columns.get(
                `faturamento_09`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_09`],
                    dados.footer[`cupons_09`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_09`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_10`))
              grid.current.instance._columns.get(
                `faturamento_10`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_10`],
                    dados.footer[`cupons_10`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_10`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_11`))
              grid.current.instance._columns.get(
                `faturamento_11`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_11`],
                    dados.footer[`cupons_11`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_11`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_12`))
              grid.current.instance._columns.get(
                `faturamento_12`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_12`],
                    dados.footer[`cupons_12`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_12`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_13`))
              grid.current.instance._columns.get(
                `faturamento_13`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_13`],
                    dados.footer[`cupons_13`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_13`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_14`))
              grid.current.instance._columns.get(
                `faturamento_14`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_14`],
                    dados.footer[`cupons_14`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_14`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_15`))
              grid.current.instance._columns.get(
                `faturamento_15`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_15`],
                    dados.footer[`cupons_15`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_15`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_16`))
              grid.current.instance._columns.get(
                `faturamento_16`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_16`],
                    dados.footer[`cupons_16`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_16`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_17`))
              grid.current.instance._columns.get(
                `faturamento_17`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_17`],
                    dados.footer[`cupons_17`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_17`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_18`))
              grid.current.instance._columns.get(
                `faturamento_18`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_18`],
                    dados.footer[`cupons_18`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_18`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_19`))
              grid.current.instance._columns.get(
                `faturamento_19`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_19`],
                    dados.footer[`cupons_19`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_19`]
                );
              };
            if (grid.current.instance._columns.get(`faturamento_20`))
              grid.current.instance._columns.get(
                `faturamento_20`
              ).summaryRenderer = () => {
                return formatarFooterTitulo(
                  formatarFooterColunaDoisValores(
                    dados.footer[`faturamento_20`],
                    dados.footer[`cupons_20`],
                    false,
                    2,
                    0
                  ),
                  resolve.json.ResultadoObjeto.header[`descricao_20`]
                );
              };
          }
          if (idCliente == "benassi") {
            for (var i = 1; i <= 20; i++) {
              const aux_faturamento = tipoPesquisa == 'L' ? `faturamento_liquido_${zeroEsquerda(i, 2)}` : `faturamento_${zeroEsquerda(i, 2)}`;
              const aux_faturamento_liquido = `faturamento_liquido_${zeroEsquerda(i, 2)}`;
              const aux_faturamento_bruto = `faturamento_${zeroEsquerda(i, 2)}`;
              const aux_quantidade = `quantidade_${zeroEsquerda(i, 2)}`;
              const aux_descricao = `descricao_${zeroEsquerda(i, 2)}`;
              const aux_visivel = `coluna_${zeroEsquerda(i, 2)}_visivel`;

              if (grid.current.instance._columns.get(aux_faturamento_liquido) && grid.current.instance._columns.get(aux_faturamento_bruto)) {
                let coluna_faturamento_liquido = grid.current.instance._columns.get(aux_faturamento_liquido);
                let coluna_futuramento_bruto = grid.current.instance._columns.get(aux_faturamento_bruto);
                let coluna = grid.current.instance._columns.get(aux_faturamento);

                coluna_faturamento_liquido.hidden = true;
                coluna_futuramento_bruto.hidden = true;
                coluna.hidden = !dados.header[aux_visivel];

                coluna.summaryRenderer = () => {
                  return formatarFooterTitulo(
                    formatarFooterColunaDoisValores(
                      dados.footer[aux_faturamento],
                      dados.footer[aux_quantidade],
                      true,
                      2,
                      1,
                      "KG"
                    ),
                    resolve.json.ResultadoObjeto.header[aux_descricao]
                  );
                };
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(dados, grid, token);
  }, [dados]);

  // if (!dados) return <PaginaLoader carregando={true} />

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={loading} />
      <>
        <Header
          parametros={{
            exportarExcel: () =>
              exportarExcel({
                data: grid.current.gridInstance.store.data,
                exportacao: dados.exportacao,
              }),
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros);
                },
                icone: "bx bx bx-filter-alt",
              },
            ],
          }}
          position={true}
          titulo="Mapa de negócios"
        />
        <FiltroBrytum aberto={mostrarFiltros}>
          <Form inline>
            <FormGroup style={{ width: 110 }}>
              <Flatpickr
                className="form-control d-block w-100"
                placeholder="dd M,yyyy"
                locale="pt"
                options={{
                  mode: "single",
                  locale: Portuguese,
                  dateFormat: "d/m/Y",
                  maxDate: `today`,
                  defaultDate: dataInicial,
                  disableMobile: "true",
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates.length > 0) {
                    setDataInicial(selectedDates[0]);
                  }
                }}
              />
            </FormGroup>
            <div className={"ml-1 mr-1"}>a</div>
            <FormGroup style={{ width: 110 }}>
              <Flatpickr
                className="form-control d-block w-100"
                placeholder="dd M,yyyy"
                locale="pt"
                options={{
                  mode: "single",
                  locale: Portuguese,
                  dateFormat: "d/m/Y",
                  maxDate: `today`,
                  defaultDate: "today",
                  disableMobile: "true",
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates.length > 0) {
                    setDataFinal(selectedDates[0]);
                  }
                }}
              />
            </FormGroup>
            <FormGroup className="select2-container ml-2">
              <div className="unidadesControler">
                <Select
                  placeholder="Tipo de pesquisa"
                  options={ddlTipoPesquisa}
                  classNamePrefix="select2-selection"
                  defaultValue={ddlTipoPesquisa[0]}
                  onChange={(Object) => {
                    setTipoPesquisa(Object?.value || "L");
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                color="light"
                onClick={(e) => {
                  atualizarDadosDemanda();
                  removerFocusNoBotao(e.currentTarget);
                }}
                className="ml-2"
              >
                <i className="fal fa-hashtag"></i>
                boralá!
              </Button>
            </FormGroup>
          </Form>
        </FiltroBrytum>
        {mensagemDadosNaoEncontrados && <Vazio />}
        {dados && (
          <BryntumGrid
            ref={grid}
            {...gridInfo}
            treeFeature={true}
            columnReordFeature={false}
            cellEditFeature={false}
            summaryFeature={true}
            sortFeature={true}
            key={carregando}
            filterBarFeature={{
              compactMode: habilitarFiltroColunas,
            }}
            cellMenuFeature={{
              disabled: true,
            }}
            headerMenuFeature={{
              items: {
                hideColumn: false,
              },
            }}
          />
        )}
      </>
    </React.Fragment>
  );
};
export default GridVendasUnidadesDiarias;
