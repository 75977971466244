import React from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// formulario
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser } from '../../store/actions';

// import images
import zib from "../../assets/images/profile-img.png";
import LogoCliente from './partes/logoCliente';

import FooterMsg from "./footerMsg";
import HeaderAuth from './partes/headerAutenticacao';
import { retornarIdCliente } from '../../cliente/variaveis';

const idCliente = retornarIdCliente();

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error, loading } = useSelector((state) => state.Login);

    let _label, _placeHolder, _input;

    if (idCliente == "volo") {
        _label = "Usuário";
        _placeHolder = "Seu login";
        _input = "input";
    }
    else {
        _label = "E-mail";
        _placeHolder = "Seu e-mail";
        _input = "email";
    }

    const handleSubmit = (event, values) => {
        try {
            dispatch(loginUser(values, navigate));
        } catch (error) {
            console.error("Erro ao enviar os dados de login:", error);
        }
    };

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <HeaderAuth
                                    titulo="Seja bem-vindo!"
                                    subtitulo="Entre no sistema com as suas informações de acesso"
                                    imagem={zib}
                                />
                                <CardBody className="pt-0">
                                    <LogoCliente />
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleSubmit}
                                        >
                                            {error && (
                                                <Alert color="danger">
                                                    {typeof error === 'string' ? error : 'Ocorreu um erro. Por favor, tente novamente.'}
                                                </Alert>
                                            )}

                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label={_label}
                                                    errorMessage="Campo obrigatório"
                                                    className="form-control"
                                                    placeholder={_placeHolder}
                                                    type={_input}
                                                    required
                                                />
                                            </div>

                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label="Senha"
                                                    errorMessage="Campo obrigatório"
                                                    type="password"
                                                    required
                                                    placeholder="Senha"
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    disabled={loading}
                                                    className="btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {loading ? (
                                                        <>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Carregando
                                                        </>
                                                    ) : (
                                                        "Entrar"
                                                    )}
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted">
                                                    Recuperar minha senha...
                                                </Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <FooterMsg />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Login;