import React, { useEffect, useState, useRef } from 'react';
import { exportarExcel, concatenaLojas, Hoje, primeiroDiaMes, toDateSQL, valorEmMoeda, valorEmInteiro, removerFocusNoBotao } from "../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../helpers/utils-amcharts"
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../store/graficoHoraLojas/actions";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import HeaderGrafico from '../../components/headerGrafico';
import Header from "../../components/HorizontalLayout/HeaderApp";
import { FormGroup, Button, Form, Container } from 'reactstrap';
import ToobarCard from '../toolBarCard';
import Grafico from "./partes/grafico";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { pegarCores } from '../../helpers/utils';
import Flatpickr from "react-flatpickr";
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import GraficoAltura from '../../components/graficoAltura';
import { Portuguese } from "../../helpers/locale/portuguese";
import FocarPaisagem from '../../components/forcarPaisagem';

const animatedComponents = makeAnimated();
const formatacaoAmCharts = retornarFormatacaoAmCharts();
const GraficoHoraLojas = () => {
  const [cor_selecionada, set_cor_selecionada] = useState("success");
  const [mostrarLoader, setMostrarLoader] = useState(true);
  const [tipoGrafico, setTipoGrafico] = useState("faturamento");
  const [cores, seTcores] = useState(null);
  const [cor, setCor] = useState("");
  const [nomeGrafico, setNomeGrafico] = useState("Faturamento");
  const [formatoNumeroGrafico, setFormatoNumeroGrafico] = useState(formatacaoAmCharts.formatoValores.decimal0Casas);
  const { dados, carregando } = useSelector(state => state.GraficoPorHoraLojas);
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [dataFinal, setDataFinal] = useState(Hoje());
  const [lojas, setLojas] = useState([]);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const token = useSelector(state => state.Login.user.tokenNOL);
  const dispatch = useDispatch();

  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const atualizarDados = async () => {
    if (!dataFinalRef.current.dataFinal) return

    await dispatch(requisitarDados({
      token,
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      data_inicial: toDateSQL(dataFinalRef.current.dataFinal),
      data_final: toDateSQL(dataFinalRef.current.dataFinal),
      agrupamento: "horario",
      visualizacao: "total",
      exportacao: "linha_hora"
    }
    ));
  }

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    seTcores(pegarCores());
    return () => dispatch(requisitarLimparDados())
  }, []);

  useEffect(() => {
    switch (tipoGrafico) {
      case "cupons":
        setCor(cores?.info)
        break;
      case "fatumento":
        setCor(cores?.success)
        break
      case "ticket_medio":
        setCor(cores?.warning)
        break
      default:
        setCor(cores?.success)
        break;
    }
  }, [cores, tipoGrafico])

  useEffect(() => {
    setMostrarTotais(!carregando && (dados.data && dados.data.length));
  }, [carregando, dados])

  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }

  const atualizarLojas = () => {
    atualizarDados();
  }

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />
      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados)
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroCasca>
              <>
                <Form inline>
                  <FormGroup className="select2-container">
                    <div className="unidadesControler">
                      <Select
                        placeholder="Todas as unidades"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={lojasDoUsuario}
                        isSearchable={false}
                        isMulti
                        classNamePrefix="select2-selection"
                        defaultValue={lojas || []}
                        onChange={Object => {
                          trocarLojas(Object || []);
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup style={{ width: 101 }} className="ml-2">
                    <Flatpickr
                      className="form-control d-block w-100"
                      placeholder="dd M,yyyy"
                      locale="pt"
                      options={{
                        mode: "single",
                        locale: Portuguese,
                        dateFormat: "d/m/Y",
                        maxDate: `today`,
                        defaultDate: "today"
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        if (selectedDates.length > 0) {
                          setDataFinal(selectedDates[0]);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="ml-2">
                    <Button color="light" onClick={(e) => { setMostrarTotais(false); atualizarLojas(); removerFocusNoBotao(e.currentTarget); setMostrarLoader(true); }} >
                      <i className="fal fa-hashtag"></i>
                      boralá!
                    </Button>
                  </FormGroup>
                </Form>
                <ToobarCard
                  botoes={
                    [
                      {
                        nome: "Ticket médio",
                        ativo: tipoGrafico === "ticket_medio",
                        funcao: () => {
                          setTipoGrafico("ticket_medio");
                          setNomeGrafico("Ticket Médio");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal2Casas);
                          set_cor_selecionada("warning");
                        }
                      },
                      {
                        nome: "Cupons",
                        ativo: tipoGrafico === "cupons",
                        funcao: () => {
                          setTipoGrafico("cupons");
                          setNomeGrafico("Cupons");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal0Casas);
                          set_cor_selecionada("info");

                        }
                      },
                      {
                        nome: "Faturamento",
                        ativo: tipoGrafico === "faturamento",
                        funcao: () => {
                          setTipoGrafico("faturamento");
                          setNomeGrafico("Faturamento");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal0Casas);
                          set_cor_selecionada("success");
                        }
                      }
                    ]
                  }
                >
                  <div className="d-flex flex-wrap ">
                  </div>
                </ToobarCard>

              </>
            </FiltroCasca>
            <Container className="fixWith" fluid>
              <HeaderGrafico mostrar={mostrarTotais} colunas={3} itens={[
                { nome: "Data", valor: dados?.header?.periodo_data },
                { nome: dados?.header?.ticket_medio, valor: valorEmMoeda(dados?.footer?.ticket_medio, true), corClass: "text-warning" },
                { nome: dados?.header?.cupons, valor: valorEmInteiro(dados?.footer?.cupons), corClass: "text-info" },
                { nome: dados?.header?.faturamento, valor: valorEmMoeda(dados?.footer?.faturamento, true), corClass: "text-success" }
              ]} />

            </Container>
          </div>

          <GraficoAltura>
            <h5 id="tituloGrafico" className={`text-center text-${cor_selecionada}`}>
              {
                nomeGrafico
              }
            </h5>
            <Grafico cor={cor?.trim()} formatoNumeroGrafico={formatoNumeroGrafico} tipoGrafico={tipoGrafico} dados={dados} data={dataFinalRef.current.dataFinal} />

          </GraficoAltura>
        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoHoraLojas;