import React, { useRef, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BryntumGrid } from '@bryntum/grid-react';
import { gridConfigBenassi } from "./gridModalPainelLojas";
import { requisitarDados, requisitarLimparDados } from "../../../store/painelReposicaoProdutos/actions"
import Header from "../../../components/HorizontalLayout/HeaderModal"
import ModalLoader from "../../../components/loader/modal"
import { motion, AnimatePresence } from "framer-motion"

const GridModal = (props) => {
  let propriedades = props?.propriedades;
  const dispatch = useDispatch();
  const gridModal = useRef(null);
  const { dados } = useSelector(state => state.PainelReposicaoProdutos);
  const [carregando, setCarregando] = useState(true)
  
  const atualizarDados = async () => {

    if (!propriedades?.filtro) return;

    await dispatch(requisitarDados({
      rede_id: "",
      data_inicial: propriedades?.filtro.data_inicial,
      data_final: propriedades?.filtro.data_inicial,
      visualizacao: "grupo",
      origem: "loja",
      inicializar: "0",
      tipo_cmv: propriedades?.filtro.tipo_cmv,
      token: propriedades?.filtro.token
    }));

  }
  const atualizarDadosDemanda = () => {

    if (gridModal.current) {
      gridModal.current.instance.store.fetchOptions.body = JSON.stringify({
        ...propriedades?.filtro
      });
      gridModal.current.instance.store.fetchOptions.headers = {
        Authorization: "Bearer " + propriedades?.filtro.token,
        "Content-Type": "application/json"
      };
      gridModal.current.instance.store.removeAll();
      gridModal.current.instance.store.loadData();
      //document.getElementById(gridModal.current.instance.id).style.display = "none";
      gridModal.current.instance.store.load()
        .then((resolve) => {
          setCarregando(false);
          document.getElementById(gridModal.current.instance.id).style.display = "flex";
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    atualizarDados();
    return () => dispatch(requisitarLimparDados())
  }, [])

  useEffect(() => {
    if (!dados) return;
    atualizarDadosDemanda();
  }, [dados]);

  const gridInfo = useMemo(() => {
    if (!dados) return

    return gridConfigBenassi(dados, propriedades?.filtro.token);
  }, [dados])

  if (!propriedades?.mostarModal) return null

  return (
    <AnimatePresence>
      {propriedades?.mostarModal &&
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          className="pos positon-absolute-br">
          <div id="containerB">
            <Header
              parametros={
                {
                  fechar: () => props.setParametrosModal({ ...propriedades, mostarModal: false })
                }
              }
              titulo={propriedades?.descricao || ""} />
            <div style={{ minHeight: "10em", flex: 1, position: "relative" }}>
              <ModalLoader carregando={carregando} />
              {
                !dados ? null
                  :
                  <BryntumGrid
                    ref={gridModal}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={true}
                    cellMenuFeature={
                      {
                        disabled: true,
                      }
                    }
                    headerMenuFeature={
                      {
                        items: {
                          hideColumn: false
                        }
                      }
                    }
                  />

              }

            </div>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  )
}

export default GridModal