import React from "react"
import logoSimplesnologiabia from "../../../assets/images/simplesnologia.png"
const LogoCliente = () =>{
    return(
        <div className="w-100 footer-login text-center">
            <img src={logoSimplesnologiabia} height={20} alt="logo :)BiZ"/>
        </div>

    )
}

export default LogoCliente