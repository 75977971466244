import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import { _handleError } from "../errorHandler"
import * as actions from "./actions"
import { API } from "../../config/index"
import cliente from "../../cliente"

const dadosApi = (token, atalho) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(atalho ? `${API}/api/menu/online/02` : `${API}/api/menu/online/01`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => console.log('error', error));
    })
}
function carregarDadosAPI(action, atalho) {
    return new Promise((resolve, reject) => {
        dadosApi(action.token, atalho)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* carregarMenu(action) {
    try {
        let dados;
        if (cliente.id != 'capitalRelacional') {
            dados = yield call(carregarDadosAPI, action.payload)
        }
        const dadosAtalho = yield call(carregarDadosAPI, action.payload, true)
        yield put(actions.requisicaoBemSucedidaAtalho(dadosAtalho))
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarMenu)
}


function* atualizarMenu(action) {
    const { nivel, indice, menu } = action.payload
    const novoMenu = [...menu];
    if (nivel === 0) {
        novoMenu[indice].filhoAberto = !novoMenu[indice].filhoAberto
        yield put(actions.requisicaoBemSucedida(novoMenu))
        return
    }
    switch (nivel.length) {
        case 1:
            novoMenu[nivel[0]].subMenu[indice].filhoAberto = !novoMenu[nivel[0]].subMenu[indice].filhoAberto
            break;
        case 2:
            novoMenu[nivel[0]].subMenu[nivel[1]].subMenu[indice].filhoAberto = !novoMenu[nivel[0]].subMenu[nivel[1]].subMenu[indice].filhoAberto
            break;
        case 3:
            novoMenu[nivel[0]].subMenu[nivel[1]].subMenu[indice].filhoAberto = !novoMenu[nivel[0]].subMenu[nivel[1]].subMenu[indice].filhoAberto
            break;
        case 4:
            break;
        default:
            return
    }
    yield put(actions.requisicaoBemSucedida(novoMenu))
}


function* assistindoAtualizarMenu() {
    yield takeEvery(types.SOLICITAR_ALTERAR_MENU, atualizarMenu)

}

function* listarMenu() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoAtualizarMenu)
        ]
    )
}

export default listarMenu