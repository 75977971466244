import "../../../helpers/locale/locale_BR";
import {
  valorEmMoeda,
  valorEmInteiro,
  showMedium,
  hideMedium,
  toMoney,
  formatarColunaPercentualValor,
  formatarFooterTitulo,
  retornarPercentual,
  formatarFooterColunaPercentualValor,
  formatarColunaDoisValores2,
} from "../../../helpers/utils";
import {
  bizGridResponsive,
  numeroFormatado,
} from "../../../helpers/biz-padrao-visual";
import { GridRowModel } from "@bryntum/grid/grid.umd.js";
import { API } from "../../../config/index";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      icr_descricao: 0,
    });
    this.set({
      icr_descricao: linha.icr_descricao,
    });
  }
}

const gridConfig = (dados, grid, token) => {
  const larguraColunaComputador = 150;
  const configResponsiveLevels = {
    "*": { width: larguraColunaComputador },
    small: { width: 100 },
  };
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      unidades: null,
      ano: null,
      mes: null,
      visualizacao: "produtos",
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/comercial/ws_produtos_pedidos_estagios`,
      fetchOptions: {
        method: "POST",
        redirect: "follow",
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: "responseMeta.data",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id
            ? this.getById(event.params.id).childLevel + 1
            : 0;
          if (nivel_atual == 0 && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.mes = request.mes;
            this.ano = request.ano;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(
                event.json.ResultadoObjeto.data[0]
              );
            }
          }
        },
        exception(response) {
          console.log(response);
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id
            ? this.getById(event.params.id).childLevel + 1
            : 0;
          if (event.params.id) {
            event.source.fetchOptions.body = JSON.stringify({
              ano: this.ano || 21,
              mes: this.mes || 10,
              referencia: this.getById(event.params.id).data.referencia,
              codigo: this.getById(event.params.id).data.codigo,
              visualizacao: "pedidos",
            });
          }
        },
      },
    },
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 220,
      },

      {
        align: "right",
        text: dados.header.florestaQtde,
        field: "florestaQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.florestaValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.florestaValor, 2),
            valorEmInteiro(dados.footer.florestaQtde),
            true,
            false,
            true
          );
        },
      },

      {
        align: "right",
        text: dados.header.taQuenteQtde,
        field: "taQuenteQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.taQuenteValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.taQuenteValor, 2),
            valorEmInteiro(dados.footer.taQuenteQtde),
            true,
            false,
            true
          );
        },
      },
      // analise
      {
        align: "right",
        text: dados.header.analiseQtde,
        field: "analiseQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.analiseValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.analiseValor, 2),
            valorEmInteiro(dados.footer.analiseQtde),
            true,
            false,
            true
          );
        },
      },
      // fechado
      {
        align: "right",
        text: dados.header.fechadoQtde,
        field: "fechadoQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.analiseValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.fechadoValor, 2),
            valorEmInteiro(dados.footer.fechadoQtde),
            true,
            false,
            true
          );
        },
      },

      // em separacao
      {
        align: "right",
        text: dados.header.separacaoQtde,
        field: "separacaoQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.separacaoValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.separacaoValor, 2),
            valorEmInteiro(dados.footer.separacaoQtde),
            true,
            false,
            true
          );
        },
      },

      // faturado
      {
        align: "right",
        text: dados.header.faturadoQtde,
        field: "faturadoQtde",
        responsiveLevels: configResponsiveLevels,
        filterable: false,
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          let coluna = cellElement.dataset.column.split("_")[1];
          cellElement.classList.add(dados.header[`classe_${coluna}`]);
          return formatarColunaDoisValores2(
            toMoney(record.faturadoValor, 2),
            valorEmInteiro(value)
          );
        },
        summaryRenderer: ({ sum }) => {
          return formatarColunaDoisValores2(
            toMoney(dados.footer.faturadoValor, 2),
            valorEmInteiro(dados.footer.faturadoQtde),
            true,
            false,
            true
          );
        },
      },
    ],
  };
};

export { gridConfig };
