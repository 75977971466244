import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/cinemaResultados/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { retornarTipoCustosBTFlex, exportarExcel, Hoje, Mes, Ano, removerFocusNoBotao, retornarAnos, retornarMeses, concatenarDropdown, retornarColunasExcel } from "../../../helpers/utils";
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions";
import { requisitarDdlFilmesCinema } from "../../../store/ddlFilmesCinema/actions";
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from 'react-select';
import GridModal from '../../../components/gridModal/gridModal';
import Vazio from '../../../components/vazio';
import FocarPaisagem from '../../../components/forcarPaisagem';
import { retornarIdCliente } from '../../../cliente/variaveis';
const anos = retornarAnos(6);
const meses = retornarMeses();
const idCliente = retornarIdCliente();
const custosDropdown = retornarTipoCustosBTFlex();
const CinemaResultadosBTFlex = () => {
    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.CinemaResultados);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [mesesSelecionados, setMesesSelecionados] = useState(meses.filter(a => a.value == Mes(Hoje())));
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const [ano, setAno] = useState(anos[0]);
    const mesesSelecionadosRef = useRef(null);
    const [parametrosModal, setParametrosModal] = useState(null);
    const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);
    const [custos, setCustos] = useState(custosDropdown[0]);

    mesesSelecionadosRef.current = { mesesSelecionados, setMesesSelecionados };

    const anoRef = useRef(null);
    anoRef.current = { ano, setAno };

    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            filme_id: "01",
            agrupador_id: "",
            ano: anoRef.current.ano.value,
            meses: mesesSelecionadosRef.current.mesesSelecionados.length ? concatenarDropdown(mesesSelecionadosRef.current.mesesSelecionados) : concatenarDropdown(meses),
            cf_nivel1_id: "",
            cf_nivel2_id: "",
            cf_nivel3_id: "",
            visualizacao: "agrupador",
            arvore: 3,
            nivelExpandido: 0,
            classeN1: "",
            sob_demanda: 1,
            nivel_atual: 0,
            token,
            tipo_cmv: custos.value
        }));
        setMostrarFiltros(false);
    }
    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token));
    }
    const atualizarDadosFilmesCinema = async () => {
        await dispatch(requisitarDdlFilmesCinema(token));
    }

    const abrirModal = (coluna, record) => {
        const filtro = {
            cf_nivel1_id: record.cf_nivel1_id,
            cf_nivel2_id: record.cf_nivel2_id,
            cf_nivel3_id: record.cf_nivel3_id,
            ca_id: dados.header[`ca_${coluna}`],
            visualizacao: "centro-alocacao",
            arvore: 2,
            token
        };
        adicionarParametrosModal({ ...record.originalData, filtro, mostarModal: true });
    }


    const adicionarParametrosModal = (parametros) => {

        parametros.filtro.ano = anoRef.current.ano.value;
        parametros.filtro.meses = mesesSelecionadosRef.current.mesesSelecionados.length ? concatenarDropdown(mesesSelecionadosRef.current.mesesSelecionados) : concatenarDropdown(meses);


        setParametrosModal(parametros);
    }

    useEffect(() => {
        if (!token) return
        atualizarDadosFilmesCinema();
        atualizarDadosAmbiente();
        atualizarDados();
        return () => dispatch(requisitarLimparDados())
    }, []);


    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const atualizarDadosDemanda = () => {
        setMostrarFiltros(false);
        setmensagemDadosNaoEncontrados(false);
        setLoading(true);
        if (grid.current) {
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                filme_id: "01",
                agrupador_id: "",
                ano: anoRef.current.ano.value,
                meses: mesesSelecionadosRef.current.mesesSelecionados.length ? concatenarDropdown(mesesSelecionadosRef.current.mesesSelecionados) : concatenarDropdown(meses),
                cf_nivel1_id: "",
                cf_nivel2_id: "",
                cf_nivel3_id: "",
                visualizacao: "agrupador",
                arvore: 3,
                nivelExpandido: 0,
                classeN1: "",
                sob_demanda: 1,
                nivel_atual: 0,
                token,
                tipo_cmv: custos.value
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    document.getElementById(grid.current.instance.id).style.display = "flex";
                    setLoading(false)

                    grid.current.instance.on('cellDblClick', (event) => {

                        if (event.record.possui_detalhes)
                            abrirModal(event.cellElement.dataset.column.split("_")[2], event.record);

                    }
                    );

                    grid.current.instance.on('cellMouseOver', (event) => {
                        document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
                          if (indice)
                            item.style.setProperty("background-color", "#12956c0d", "important");
            
                        });
                      });
            
            
                      grid.current.instance.on('cellMouseOut', (event) => {
                        document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
                          if (indice)
                            item.style.removeProperty("background-color");
                        });
                      });

                    if (!resolve.data.length) {
                        document.getElementById(grid.current.instance.id).style.display = "none";
                        setMostrarFiltros(true);
                        setmensagemDadosNaoEncontrados(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const trocarAno = (valor) => {
        if (valor) {
            setAno(valor)
        } else {
            setAno([])
        }
    }
    const trocarCustos = (valor) => {
        if (valor) {
            setCustos(valor)
        } else {
            setCustos([])
        }
    }
    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, grid, adicionarParametrosModal, token);
    }, [dados])

    const trocarMes = (valor) => {
        if (valor) {
            setMesesSelecionados(valor)
        } else {
            setMesesSelecionados([])
        }
    }
    return (
        <React.Fragment>
            <FocarPaisagem />
            {parametrosModal?.mostarModal && <GridModal propriedades={parametrosModal} setParametrosModal={setParametrosModal} />}


            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup>
                            <div className="unidadesControler anoControler">
                                <Select
                                    placeholder="Todas as unidades"
                                    className="basic-single"
                                    options={anos}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={anos[0] || []}
                                    onChange={Object => {
                                        trocarAno(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="select2-container ml-2"  >
                            <div className="unidadesControler">
                                <Select
                                    defaultValue={meses.filter(a => a.value == Mes(Hoje()))}
                                    isMulti
                                    name="meses"
                                    options={meses}
                                    placeholder="Todos os meses"
                                    closeMenuOnSelect={false}
                                    classNamePrefix="select2-selection"
                                    isSearchable={false}
                                    onChange={Object => {
                                        trocarMes(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={custosDropdown}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={custosDropdown[0] || []}
                                    onChange={Object => {
                                        trocarCustos(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {mensagemDadosNaoEncontrados && <Vazio />}
                {dados
                    &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={false}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />
                }
            </>
        </React.Fragment>
    );
}
export default CinemaResultadosBTFlex;