import "../../helpers/locale/locale_BR";
import { formatarFooterTituloLinhas, formatarFooterTituloLinhasInvertida, formatarFooterMultiplasLinhas, formatarFooterPercentualValorUnicaLinha, 
         formatarColunaMultiplasLinhas, formatarColunaPercentualValorUnicaLinha, showMedium, hideMedium, toMoney, toDate } from "../../helpers/utils";
import { bizGridResponsive } from "../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { urlApiPainelReposicaoLojas } from "../../helpers/api";
import { setasParaCimaOuParaBaixoComparacao, setasParaCimaOuParaBaixoComparacaoInvertida } from "../../helpers/biz-padrao-visual";

const larguraColuna = 130;

class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento_liquido: 0,
    });
    this.set({
      faturamento_liquido: linha.faturamento_liquido
    })
  }
}

function seta(perc, percDesafio) {
  if ((perc > 0) && (percDesafio > 0) && (perc != percDesafio)) {
    if (perc > percDesafio) {
      return 1;
    }
    else {
      return -1;
    }
  }
  else {
    return 0;
  }
}

const gridConfigBenassi = (dados, grid, token, setParametrosModal) => {
  const larguraColunaComputador = 110;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } };

  return {
    responsiveLevels: bizGridResponsive,
    store: {
      rede_id: null,
      data_inicial: null,
      data_final: null,
      visualizacao: null,
      tipo_cmv: null,
      tree: true,
      modelClass: GridLinha,
      readUrl: urlApiPainelReposicaoLojas(),
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.rede_id = request.rede_id;
            this.data_inicial = request.data_inicial;
            this.data_final = request.data_final;
            this.visualizacao = request.visualizacao;
            this.tipo_cmv = request.tipo_cmv;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data && event.json.ResultadoObjeto.data.length) {
              dados.header = event.json.ResultadoObjeto.header;
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          var visualizacao = event.params.id ? this.getById(event.params.id).data.visualizacao : "";
          var rede_id = event.params.id ? this.getById(event.params.id).data.rede_id : "";

          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              rede_id: rede_id,
              data_inicial: this.data_inicial,
              data_final: this.data_final,
              tipo_cmv: this.tipo_cmv,
              visualizacao: visualizacao
            })
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.rede_nome,
        field: "rede_nome",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 200,
        responsiveLevels: hideMedium,
        filterable: false
      },
      {
        text: dados.header.rede_nome,
        field: "rede_nome",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 200,
        locked: true,
        responsiveLevels: showMedium,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.faturamento_liquido,
        field: "faturamento_liquido",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(0, toMoney(dados.footer.faturamento_desafio), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(dados.footer.faturamento_desafio_perc, toMoney(dados.footer.faturamento_liquido), "", false, true, true, false)
              ], false),
            dados.header.faturamento_liquido,
            seta(dados.footer.faturamento_liquido, dados.footer.faturamento_desafio)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.faturamento_desafio > 0) && (record.faturamento_liquido > 0)) {
            setasParaCimaOuParaBaixoComparacao(
              record.faturamento_liquido,
              record.faturamento_desafio,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(0, toMoney(record.faturamento_desafio), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(record.faturamento_desafio_perc, toMoney(record.faturamento_liquido), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasInvertida(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(dados.footer.cmv_desafio_perc, toMoney(dados.footer.cmv_desafio), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(dados.footer.cmv_valor_perc, toMoney(dados.footer.cmv_valor), "", false, true, true, false)
              ], false),
            dados.header.cmv_valor,
            seta(dados.footer.cmv_valor_perc, dados.footer.cmv_desafio_perc)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.cmv_desafio_perc > 0) && (record.cmv_valor_perc > 0)) {
            setasParaCimaOuParaBaixoComparacaoInvertida(
              record.cmv_valor_perc,
              record.cmv_desafio_perc,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(record.cmv_desafio_perc, toMoney(record.cmv_desafio), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(record.cmv_valor_perc, toMoney(record.cmv_valor), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem,
        field: "margem",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(dados.footer.margem_desafio_perc, toMoney(dados.footer.margem_desafio), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(dados.footer.margem_perc, toMoney(dados.footer.margem), "", false, true, true, false)
              ], false),
            dados.header.margem,
            seta(dados.footer.margem_perc, dados.footer.margem_desafio_perc)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.margem_desafio_perc > 0) && (record.margem_perc > 0)) {
            setasParaCimaOuParaBaixoComparacao(
              record.margem_perc,
              record.margem_desafio_perc,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(record.margem_desafio_perc, toMoney(record.margem_desafio), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(record.margem_perc, toMoney(record.margem), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.valor_emissao_liquida,
        field: "valor_emissao_liquida",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha(dados.footer.faturamento_emissao_liquida_perc, toMoney(dados.footer.valor_emissao_liquida), "", false, true, true, false)
              ], false),
            dados.header.valor_emissao_liquida,
            seta(dados.footer.faturamento_liquido, dados.footer.valor_emissao_liquida)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.faturamento_liquido > 0) && (record.valor_emissao_liquida > 0)) {
            setasParaCimaOuParaBaixoComparacao(
              record.faturamento_liquido,
              record.valor_emissao_liquida,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha(record.faturamento_emissao_liquida_perc, toMoney(record.valor_emissao_liquida), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "center",
        text: dados.header.status_checkout,
        field: "status_checkout",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          return "";
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.status_checkout == 1) {
            return '<img src="https://happybiz-web3.s3.us-west-2.amazonaws.com/benassi/circulo-verde.png" height="30" />';
          }
          else {
            if (record.nivel_atual == 0) {
              return '<img src="https://happybiz-web3.s3.us-west-2.amazonaws.com/benassi/circulo-vermelho.png" height="30" />';
            }
            else {
              return '<div style="text-align: center;"><img src="https://happybiz-web3.s3.us-west-2.amazonaws.com/benassi/circulo-vermelho.png" height="30" /><br/>' + toDate(record.data_checkout) + '</div>';
            }
          }
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
    ]
  };
};

export { gridConfigBenassi };
