import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, CardBody, Input, FormGroup, Label } from "reactstrap";
import InputMask from 'react-input-mask';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from "react-router-dom"
import BtnLoader from '../../components/forms/btnLoader';
import { useDispatch } from 'react-redux';
import { requisitarDados } from "../../store/menus/actions"
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import gerarToken from '../../helpers/gerarToken';
import PaginaLoader from '../../components/loader/pagina';
import { useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { setUserAction } from "../../store/auth/user/actions"
import { Auth } from "aws-amplify"
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese"
import { salvarToStorage } from "../../helpers/amplify/storage"
import FileUploader from "../../components/fileUploader"
import moment from "moment"
import { replaceAll } from "../../helpers/utils";
const MeuPerfil = () => {
    const dispatch = useDispatch()
    const { carregando, atalhos, menuPrincipal } = useSelector(state => state.Menus)
    const [carregandoPerfil, setCarregandoPerfil] = useState(false)
    const [carregandoSenha, setCarregandoSenha] = useState(false)
    const [carregandoLogo, setCarregandoLogo] = useState(false)
    const [nascimento, setNascimento] = useState("")
    const user = useSelector((state) => state.User);
    const { usuario } = user;
    const [imagem, setImagem] = useState(usuario.attributes["custom:foto"])
    const atualizarDados = async (mes, ano) => {
        const token = await gerarToken();
        await dispatch(requisitarDadosAmbiente(token));
        await dispatch(requisitarDados(token, mes, ano));
    }
    const mudarImg = async (e) => {
        const file = e.target.files[0]
        setCarregandoLogo(true)
        try {
            const urlFile = await salvarToStorage(file)
            setCarregandoLogo(false)
            setImagem(urlFile)
        } catch (error) {
            setCarregandoLogo(false)
        }
    }

    const trocarSenha = async (errs, values) => {
        if (errs.length > 0) return
        if (values.nova_senha !== values.confirmar_senha)
            return toastr.error("Nova senha e sua confirmação não coincidem")

        if (values.nova_senha.length < 7) return toastr.error("Senha precisa ter pelo menos 8 digitos")
        setCarregandoSenha(true);
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, values.senha_atual, values.nova_senha);
            })
            .then(data => {
                toastr.success("Senha alterada com sucesso")

            })
            .catch(err => {
                console.log(err)
                toastr.error("Falha ao alterar senha")

            });
        setCarregandoSenha(false)
    }
    const atualizarState = async () => {
        const usuarioLogado = await Auth.currentAuthenticatedUser()
            .then(user => user);
        dispatch(setUserAction(usuarioLogado))
    }
    const salvarPerfil = async (errs, values) => {
        if (errs.length > 0) return
        setCarregandoPerfil(true);
        let telefone = replaceAll(values["custom:celular"], " ", "");
        telefone = replaceAll(telefone, "(", "");
        telefone = replaceAll(telefone, ")", "");
        telefone = replaceAll(telefone, "-", "");
        const usuarioLogado = await Auth.currentAuthenticatedUser();
        Auth.updateUserAttributes(usuarioLogado, {
            ...values,
            "custom:foto": imagem || " ",
            "custom:data_nascimento": nascimento || " ",
            "phone_number": `+55${telefone}`,
            "phone_number_verified": true
        })
            .then(result => {
                setCarregandoPerfil(false);
                atualizarState();
                toastr.success("Pefil atualizado com sucesso!");
            })
            .catch(error => {
                console.log(error);
                let mensagem = "";
                switch (error.message) {
                    case 'Invalid phone number format.':
                        mensagem = "O formato do número de telefone está incorreto ou vázio."
                        break;
                    default:
                        mensagem = error.mensagem;
                        break;
                }
                toastr.success(mensagem);
                setCarregandoPerfil(false);
            });

    }
    useEffect(() => {
        if (!((atalhos?.length > 0) && (menuPrincipal?.length > 0)))
            atualizarDados();
    }, [])

    const mostrarLoader = carregando && (atalhos?.length >= 0) && (menuPrincipal?.length <= 0);
    return (
        <React.Fragment>
            <PaginaLoader carregando={mostrarLoader} />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Meu perfil" links={[
                        { nome: "Página Principal", url: "/", ativa: false },
                        { nome: "Meu Perfil", url: "#", ativa: false },
                    ]} />
                    <Row className="mb-2">
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-4 h4 card-title">Perfil</div>
                                    <AvForm onSubmit={(event, errors, values) => { salvarPerfil(errors, values) }}>
                                        <Row>
                                            <Col sm={4}>
                                                <FileUploader salvarToStorage={mudarImg} url={imagem} textoBtn="Selecione o logo" carregandoFoto={carregandoLogo} />
                                            </Col>
                                            <Col sm={8}>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <AvField
                                                            value={usuario.attributes["custom:apelido"]}
                                                            name="custom:apelido"
                                                            label="Apelido"
                                                            type="text" />
                                                    </Col>
                                                    <Col xs={12} sm={12}>
                                                        <AvField
                                                            value={usuario.attributes["custom:nome_completo"]}
                                                            name="custom:nome_completo"
                                                            label="Nome completo"
                                                            type="text" />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>Data de nascimento</Label>
                                                    <Flatpickr
                                                        className="form-control d-block w-100"
                                                        placeholder="99/99/9999"
                                                        locale="pt"
                                                        options={{
                                                            mode: "single",
                                                            locale: Portuguese,
                                                            dateFormat: "d/m/Y",
                                                            maxDate: `today`,
                                                            defaultDate: usuario?.attributes["custom:data_nascimento"] ? moment(usuario.attributes["custom:data_nascimento"]).format("DD-MM-YYYY") : ""
                                                        }}
                                                        onChange={(selectedDates, dateStr, instance) => {
                                                            if (selectedDates.length > 0) {
                                                                setNascimento(selectedDates[0]);
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <AvField
                                                    value={usuario.attributes["custom:sexo"]}
                                                    name="custom:sexo"
                                                    label="Sexo"
                                                    type="select"  >
                                                    <option>Sexo</option>
                                                    <option>Masculino</option>
                                                    <option>Feminino</option>
                                                </AvField>
                                            </Col>
                                            <Col sm="4">
                                                <AvField
                                                    value={usuario.attributes["custom:celular"]}
                                                    name="custom:celular"
                                                    mask="(99) 99999-9999"
                                                    maskChar="-" tag={[Input, InputMask]}
                                                    label="Celular" type="text"
                                                    errorMessage="Campo obrigatório" validate={{
                                                        required: { value: true, errorMessage: 'Campo obrigatório' },
                                                    }}
                                                />
                                            </Col>
                                            <Col sm="8">
                                                <AvField
                                                    name="email"
                                                    value={usuario.attributes["email"]}
                                                    label="email" type="email" errorMessage="Campo obrigatório" validate={{
                                                        required: { value: true, errorMessage: 'Campo obrigatório' },
                                                    }} />
                                            </Col>
                                        </Row>
                                        <BtnLoader loading={carregandoPerfil} />
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-4 h4 card-title">Alterar senha</div>
                                    <AvForm onSubmit={(event, errors, values) => { trocarSenha(errors, values) }}>
                                        <Row>
                                            <Col sm="12">
                                                <AvField
                                                    name="senha_atual"
                                                    placeholder="****"
                                                    label="Senha atual" type="password" errorMessage="Campo obrigatório" validate={{
                                                        required: { value: true, errorMessage: 'Campo obrigatório' },
                                                    }} />
                                            </Col>
                                            <Col sm="12">
                                                <AvField
                                                    name="nova_senha"
                                                    placeholder="****"
                                                    label="Senha atual" type="password" errorMessage="Campo obrigatório" validate={{
                                                        required: { value: true, errorMessage: 'Campo obrigatório' },
                                                    }} />
                                            </Col>
                                            <Col sm="12">
                                                <AvField
                                                    name="confirmar_senha"
                                                    placeholder="****"
                                                    label="Confirmar senha" type="password" errorMessage="Campo obrigatório" validate={{
                                                        required: { value: true, errorMessage: 'Campo obrigatório' },
                                                    }} />
                                            </Col>
                                        </Row>
                                        <BtnLoader loading={carregandoSenha} />
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default MeuPerfil;