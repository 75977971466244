import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import {
  requisitarDados,
  requisitarLimparDados,
} from "../../../store/btflex/pedidosDeVendas/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form, Input } from "reactstrap";
import {
  Hoje,
  Mes,
  Ano,
  removerFocusNoBotao,
  primeiroDiaMes,
  toDateSQL,
  exportarExcel,
  retornarMeses,
  retornarAnos,
} from "../../../helpers/utils";

import "@bryntum/grid/grid.stockholm.css";
import "../../../App.scss";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from "react-select";

import FocarPaisagem from "../../../components/forcarPaisagem";

const meses = retornarMeses();
const anos = retornarAnos(4);

const PedidosDeVendas = () => {
  const [loading, setLoading] = useState(true);
  const { dados, carregando } = useSelector((state) => state.PedidosDeVendas);
  const [produto, setProduto] = useState("");
  const [mes, setMes] = useState(Mes(Hoje()));
  const [ano, setAno] = useState(anos[0]);
  const grid = useRef(null);
  const dispatch = useDispatch();
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
  const token = useSelector((state) => state.Login.user.tokenNOL);

  const atualizarDados = async () => {
    await dispatch(
      requisitarDados({
        token,
        ano: typeof ano === "object" ? parseInt(ano.value) : parseInt(ano),
        mes: typeof mes === "object" ? parseInt(mes.value) : parseInt(mes),
        produto: produto,
        classeN1: "xpto",
        referencia: "",
        codigo: "",
        visualizacao: "produtos",
      })
    );
    setMostrarFiltros(false);
  };

  useEffect(() => {
    atualizarDados();
    return () => {}; //dispatch(requisitarLimparDados());
  }, []);

  useEffect(() => {
    if (carregando) return;
    atualizarDadosDemanda();
  }, [carregando]);

  const atualizarDadosDemanda = () => {
    setMostrarFiltros(false);
    setLoading(true);
    if (grid.current) {
      grid.current.instance.store.fetchOptions.body = JSON.stringify({
        token,
        ano: typeof ano === "object" ? parseInt(ano.value) : parseInt(ano),
        mes: typeof mes === "object" ? parseInt(mes.value) : parseInt(mes),
        produto: produto,
        classeN1: "xpto",
        referencia: "",
        codigo: "",
        visualizacao: "produtos",
      });
      grid.current.instance.store.removeAll();
      grid.current.instance.store.loadData();
      grid.current.instance.store
        .load()
        .then((resolve) => {
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(dados, grid, token);
  }, [dados]);

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={loading} />
      <>
        <Header
          parametros={{
            exportarExcel: () =>
              exportarExcel({
                data: grid.current.gridInstance.store.data,
                exportacao: dados.exportacao,
              }),
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros);
                },
                icone: "bx bx bx-filter-alt",
              },
              {
                funcao: () => {
                  setHabilitarFiltroColunas(!habilitarFiltroColunas);
                },
                icone: "bx bx-filter",
              },
            ],
          }}
          position={true}
          titulo="Mapa de negócios"
        />
        <FiltroBrytum aberto={mostrarFiltros}>
          <Form inline>
            <FormGroup>
              <div className="unidadesControler anoControler">
                <Select
                  placeholder="Ano"
                  className="basic-single"
                  options={anos}
                  isSearchable={false}
                  classNamePrefix="select2-selection"
                  defaultValue={anos[0] || []}
                  onChange={(Object) => {
                    setAno(Object || []);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="select2-container anoControler ml-2">
              <div className="unidadesControler">
                <Select
                  defaultValue={meses.filter((a) => a.value == Mes(Hoje()))}
                  name="meses"
                  options={meses}
                  placeholder="Todos os meses"
                  closeMenuOnSelect={true}
                  classNamePrefix="select2-selection"
                  isSearchable={false}
                  onChange={(Object) => {
                    setMes(Object || []);
                  }}
                />
              </div>
            </FormGroup>

            <FormGroup className="ml-2">
              <Input
                placeholder="produto"
                onChange={(e) => setProduto(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Button
                color="light"
                onClick={(e) => {
                  atualizarDadosDemanda();
                  removerFocusNoBotao(e.currentTarget);
                }}
                className="ml-2"
              >
                <i className="fal fa-hashtag"></i>
                boralá!
              </Button>
            </FormGroup>
          </Form>
        </FiltroBrytum>
        {dados && (
          <BryntumGrid
            ref={grid}
            {...gridInfo}
            treeFeature={true}
            columnReordFeature={false}
            cellEditFeature={false}
            summaryFeature={true}
            sortFeature={true}
            key={carregando}
            filterBarFeature={{
              compactMode: habilitarFiltroColunas,
            }}
            cellMenuFeature={{
              disabled: true,
            }}
            headerMenuFeature={{
              items: {
                hideColumn: false,
              },
            }}
          />
        )}
      </>
    </React.Fragment>
  );
};
export default PedidosDeVendas;
