import {createStore, compose, applyMiddleware} from "redux"
import rootReducer from "./reducers"
import createSagaMiddleaware from "redux-saga";
import rootSaga from "./sagas"

const sagaMiddleare = createSagaMiddleaware();

const store = compose(
    applyMiddleware(sagaMiddleare),
)(createStore)(rootReducer);

sagaMiddleare.run(rootSaga)

export default store