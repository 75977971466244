import * as types from "./types";

const inicialState = {
  dados: null,
  carregando: false,
  falha: "",
};

const Relacao = function (state = inicialState, action) {
  switch (action.type) {
    case types.REQUISITAR_DADOS:
      return { ...state, carregando: true };
    case types.REQUISITAR_DADOS_SUCESSO:
      return { ...state, carregando: false, dados: action.payload };
    case types.REQUISITAR_DADOS_FALHOU:
      return { ...state, carregando: false, falha: action.payload };
    case types.LIMPAR_DADOS:
      return { ...state, dados: null, carregando: true }
    default:
      return state;
  }
};

export default Relacao;
