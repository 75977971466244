import React from "react"
import {FormGroup, Label, InputGroup} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Select from 'react-select'
import { Portuguese } from "../../../helpers/locale/portuguese"
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();


const FiltrosModal = ({parametros})=>{
    return(
        <div className="modal-body">
            <FormGroup className="select2-container">
                <Label>Unidades</Label>
                <Select 
                  placeholder="Selecione"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={parametros.lojasDoUsuario} 
                  isMulti
                  classNamePrefix="select2-selection"
                  defaultValue={parametros.lojas}
                  onChange={Object =>{
                    parametros.trocarLojas(Object);
                  }}
              />
          </FormGroup>
          <FormGroup className="mb-4">
                <Label>Período</Label>
                <InputGroup style={{minWidth: 300}}>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        locale="pt"
                        options={{
                        mode: "range",
                        locale: Portuguese,
                        dateFormat: "d/m/Y",
                        style: {width: 100},
                        defaultDate:[parametros.dataInicial, parametros.dataFinal]
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                        parametros.trocarPeriodo(selectedDates);
                        }}
                    />
                </InputGroup>
            </FormGroup>
        </div>

    )
}

export default FiltrosModal