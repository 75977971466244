import * as types from "./types"

export const requisitarDdlFilmesCinema = (token) => {
	return {
		type: types.REQUISITAR_DADOS,
		payload: { token }
	}
};

export const requisicaoBemSucedida = (dados) => ({
	type: types.REQUISITAR_DADOS_SUCESSO,
	payload: dados
});


export const requisicaoFalhou = (error) => ({
	type: types.REQUISITAR_DADOS_FALHOU,
	payload: error
});
