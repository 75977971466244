import { API } from "../config/index";
import cliente from "../cliente";

export default function (usuario = "zib") {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", usuario);
    urlencoded.append(
      "password",
      usuario === "zib" ? "hb@feliz" : cliente.senhaPadrao || "12@34"
    );
    urlencoded.append("casacomum", "0");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const token = fetch(`${API}/token`, requestOptions)
      .then((response) => response.json())
      .then((result) => result.access_token)
      .catch((error) => {
        console.log("error", error);
        reject(JSON.stringify(error));
      });
    return resolve(token);
  });
}
