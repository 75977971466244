import "../../../helpers/locale/locale_BR";
import React from 'react';
import { DataGenerator } from "@bryntum/grid/grid.umd.js";
import { toDate, zeroEsquerda, formatarColunaClassCss, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import { bizGridResponsive } from "../../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { API } from "../../../config/index";
import { ThirdPartyDraggable } from "@fullcalendar/interaction";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      valorLiquidado: 0,
    });
    this.set({
      valorLiquidado: linha.valorLiquidado
    })
  }
}
const gridConfigBTFlex = (dados, token) => {
  const larguraColunaComputador = 120;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  let visualizacao = "";
  return {
    modelClass: GridLinha,
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      ano: "",
      meses: "",
      ca_id: "",
      arvore: "",
      cf_nivel1_id: "",
      cf_nivel2_id: "",
      cf_nivel3_id: "",
      visualizacao: "",
      projecao_resultados: 0,
      arvore: "",
      cuidador_id: "",
      readUrl: `${API}/api/varejo/ws_cinema_resultado_detalhes`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.ca_id = request.ca_id;
            this.ano = request.ano;
            this.meses = request.meses;
            this.cf_nivel1_id = request.cf_nivel1_id;
            this.cf_nivel2_id = request.cf_nivel2_id;
            this.cf_nivel3_id = request.cf_nivel3_id;
            this.visualizacao = request.visualizacao;
            this.arvore = request.arvore;
            this.cuidador_id = request?.cuidador_id || "";
            this.projecao_resultados = request.projecao_resultados;
          }
          if (event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.visualizacao = request.visualizacao;
          }
        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          var ca_dimensao1_id = event.params.id ? this.getById(event.params.id).data.ca_dimensao1_id : "";
          var ca_dimensao2_id = event.params.id ? this.getById(event.params.id).data.ca_dimensao2_id : "";
          var ca_dimensao3_id = event.params.id ? this.getById(event.params.id).data.ca_dimensao3_id : "";
          var ca_dimensao4_id = event.params.id ? this.getById(event.params.id).data.ca_dimensao4_id : "";
          var ca_dimensao5_id = event.params.id ? this.getById(event.params.id).data.ca_dimensao5_id : "";
          var ca_id = event.params.id ? this.getById(event.params.id).data.ca_id : "";

          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              visualizacao: "titulos",
              meses: this.meses,
              ano: this.ano,
              cf_nivel1_id: this.cf_nivel1_id,
              cf_nivel2_id: this.cf_nivel2_id,
              cf_nivel3_id: this.cf_nivel3_id,
              projecao_resultados: this.projecao_resultados,
              ca_dimensao1_id,
              ca_dimensao2_id,
              ca_dimensao3_id,
              ca_dimensao4_id,
              ca_dimensao5_id,
              ca_id,
              arvore: this.arvore,
              nivel_atual,
              sob_demanda: 1,
              cuidador_id: this.cuidador_id
            })
          }
        }
      }
    },

    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        flex: 1,
        type: "tree",
        minWidth: 120,
        responsiveLevels: hideMedium,
        sum: true,
        summaryRenderer: () => {
          return "Total";
        },
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        minWidth: 120,
        type: "tree",
        locked: true,
        responsiveLevels: showMedium,
        summaryRenderer: () => {
          return "Total";
        },
      },
      {
        text: dados.header.descricaoTitulo,
        field: "descricaoTitulo",
        flex: 1,
        minWidth: 120,
        renderer: ({ value, cellElement, record }) => {
          return <div dangerouslySetInnerHTML={{ __html: value ? value : "-" }}
          ></div>;
        }
      },
      {
        text: dados.header.documento,
        field: "documento",
        responsiveLevels: configResponsiveLevels,
        align: "center",
        renderer: ({ value, cellElement, record }) => {
          return value ? value : "-";
        }
      },
      {
        text: dados.header.dataVencimento,
        field: "dataVencimento",
        renderer: ({ value, cellElement, record }) => {
          return value ? `${toDate(value)} ${record.parcela}` : "-";
        },
        responsiveLevels: configResponsiveLevels,
        align: "center"
      },
      // {
      //   text: dados.header.dataLiquidacao,
      //   field: "dataLiquidacao",
      //   renderer: ({ value, cellElement, record }) => {
      //     return value ? `${toDate(value)}` : "-";
      //   },
      //   responsiveLevels: configResponsiveLevels,
      //   align: "center"
      // },
      {
        text: dados.header.valorTitulo,
        field: "valorTitulo",
        type: 'number',
        sum: 'sum',
        htmlEncode: false,
        align: "right",
        renderer: ({ value, cellElement, record }) => {
          return toMoney(value, 2);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.valorTitulo), dados.header.valorTitulo);
        },
        responsiveLevels: configResponsiveLevels
      },
      // {
      //   text: dados.header.valorJurosDesconto,
      //   field: "valorJurosDesconto",
      //   type: 'number',
      //   sum: 'sum',
      //   htmlEncode: false,
      //   align: "right",
      //   renderer: ({ value, cellElement, record }) => {
      //     return formatarColunaPercentualValor(0, value > 0 ? toMoney(value, 2) : 0);
      //   },
      //   summaryRenderer: ({ sum }) => {
      //     if (!dados.footer) return "";
      //     return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.valorJurosDesconto), dados.header.valorJurosDesconto);
      //   },
      //   responsiveLevels: configResponsiveLevels
      // },
      // {
      //   text: dados.header.valorLiquidado,
      //   field: "valorLiquidado",
      //   type: 'number',
      //   sum: 'sum',
      //   align: "right",
      //   htmlEncode: false,
      //   renderer: ({ value, cellElement, record }) => {
      //     return formatarColunaPercentualValor(0, toMoney(value, 2));
      //   },
      //   summaryRenderer: ({ sum }) => {
      //     if (!dados.footer) return "";
      //     return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.valorLiquidado), dados.header.valorLiquidado);
      //   },
      //   responsiveLevels: configResponsiveLevels
      // }
    ]
  };
};
export { gridConfigBTFlex };
