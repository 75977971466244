import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import PaginaLoader from "../loader/pagina";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

const Layout = (props) => {
  const location = useLocation();
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    if (props.isPreloader === true) {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";

      setTimeout(function () {
        document.getElementById('preloader').style.display = "none";
        document.getElementById('status').style.display = "none";
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = capitalizeFirstLetter(location.pathname);

    document.title = currentage + " | :)BiZ";

    if (props.leftSideBarTheme) {
      props.changeSidebarTheme(props.leftSideBarTheme);
    }

    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    }

    if (props.leftSideBarType) {
      props.changeSidebarType(props.leftSideBarType);
    }

    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    }
  }, [
    props.isPreloader,
    props.leftSideBarTheme,
    props.layoutWidth,
    props.leftSideBarType,
    props.topbarTheme,
    location.pathname,
    props.changeSidebarTheme,
    props.changeLayoutWidth,
    props.changeSidebarType,
    props.changeTopbarTheme
  ]);

  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <PaginaLoader carregando={true} />
        </div>
      </div>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={props.leftSideBarTheme}
          type={props.leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {props.children}
        </div>
        <Footer />
      </div>
      {props.showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
};

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
})(Layout);