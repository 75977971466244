import React, { useLayoutEffect, useRef,memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";
import { insertIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;

const Grafico = ({ dados, cores, index }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        let corFaturamento = cores.success.trim();
        let corCupons = cores.info.trim();
        let corCMV = cores.danger.trim();
        let corMargem = cores.secondary.trim();
        let maxValores = {
            faturamento: 0,
            cupons: 0,
            cmv: 0,
            margem: 0
        }
        if (dadosGrafico.length > 0) {
            const data = dadosGrafico.map(arr => {
                maxValores.faturamento = arr.faturamento > maxValores.faturamento ? arr.faturamento : maxValores.faturamento
                maxValores.cupons = arr.cupons > maxValores.cupons ? arr.cupons : maxValores.cupons
                maxValores.cmv_percentual = arr.cmv_percentual > maxValores.cmv_percentual ? arr.cmv_percentual : maxValores.cmv_percentual
                maxValores.margem_contribuicao_percentual = arr.margem_contribuicao_percentual > maxValores.margem_contribuicao_percentual ? arr.margem_contribuicao_percentual : maxValores.margem_contribuicao_percentual
                return { ...arr, date: toDateJS(arr.ano, arr.mes) }
            });

            var interfaceColors = new am4core.InterfaceColorSet();
            var chart = am4core.create(index, am4charts.XYChart);
            
            definirLocalizacaoAmCharts(chart);
            chart.data = data;
            chart.bottomAxesContainer.layout = "horizontal";
            chart.bottomAxesContainer.reverseOrder = true;


            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "u_nome";
            categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
            categoryAxis.renderer.grid.template.strokeOpacity = 1;
            categoryAxis.renderer.grid.template.location = 1;
            categoryAxis.renderer.minGridDistance = 20;
            categoryAxis.renderer.inversed = true;

            var valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis1.tooltip.disabled = true;
            valueAxis1.renderer.baseGrid.disabled = true;
            valueAxis1.marginRight = 30;
            valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis1.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis1.renderer.grid.template.strokeOpacity = 1;
            valueAxis1.title.text = dados?.header?.cupons || "";
            valueAxis1.renderer.labels.template.disabled = true;
            valueAxis1.title.dy = 10;

            var series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.categoryY = "u_nome";
            series1.dataFields.valueX = "cupons";
            series1.xAxis = valueAxis1;
            series1.name = dados?.header?.cupons || "";
            series1.stroke = am4core.color(corCupons);
            series1.fill = am4core.color(corCupons);
            series1.clustered = false;

            var columnTemplate1 = series1.columns.template;
            columnTemplate1.strokeOpacity = 0;
            columnTemplate1.propertyFields.fill = "color";

            var label1 = columnTemplate1.createChild(am4core.Label);
            label1.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            label1.align = "left";
            label1.valign = "middle";
            label1.dx = 5;
            label1.fontSize = 15;
            label1.fill = am4core.color("#fff")
            label1.background.fill = am4core.color("gray");
            label1.background.fillOpacity = 0.5;
            label1.paddingRight = 5;
            label1.paddingLeft = 5;
            label1.paddingTop = 3;
            label1.truncate = false;
            label1.hideOversized = false;

            var valueAxis2 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis2.tooltip.disabled = true;
            valueAxis2.renderer.baseGrid.disabled = true;
            valueAxis2.marginRight = 30;
            valueAxis2.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis2.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis2.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis2.renderer.grid.template.strokeOpacity = 1;
            valueAxis2.title.text = dados?.header?.faturamento || "";
            valueAxis2.renderer.labels.template.disabled = true;
            valueAxis2.title.dy = 10;


            var series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.categoryY = "u_nome";
            series2.dataFields.valueX = "faturamento";
            series2.xAxis = valueAxis2;
            series2.name = dados?.header?.faturamento || "";;
            series2.stroke = am4core.color(corFaturamento);
            series2.fill = am4core.color(corFaturamento);
            series2.clustered = false;
            series2.opacity = .5;

            var columnTemplate2 = series2.columns.template;
            columnTemplate2.strokeOpacity = 0;
            columnTemplate2.propertyFields.fill = "color";

            var label2 = columnTemplate2.createChild(am4core.Label);
            label2.text = `R$ {valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            label2.align = "left";
            label2.valign = "middle";
            label2.dx = 5;
            label2.fontSize = 15;
            label2.fill = am4core.color("#fff")
            label2.background.fill = am4core.color("gray");
            label2.background.fillOpacity = 0.5;
            label2.paddingRight = 5;
            label2.paddingLeft = 5;
            label2.paddingTop = 3;
            label2.truncate = false;
            label2.hideOversized = false;

            var valueAxis3 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis3.tooltip.disabled = true;
            valueAxis3.renderer.baseGrid.disabled = true;
            valueAxis3.marginRight = 30;
            valueAxis3.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis3.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis3.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis3.renderer.grid.template.strokeOpacity = 1;
            valueAxis3.renderer.grid.template.align = "left";
            valueAxis3.renderer.labels.template.disabled = true;

            valueAxis3.title.text = dados?.header?.cmv_valor || "";
            valueAxis3.max = 100;
            valueAxis3.title.dy = 10;
            //valueAxis3.renderer.opposite = true;

            // valueAxis3.numberFormatter = new am4core.NumberFormatter();
            // valueAxis3.numberFormatter.numberFormat = "0'%'";
            // valueAxis3.adjustLabelPrecision = true;

            var series3 = chart.series.push(new am4charts.ColumnSeries());
            series3.dataFields.categoryY = "u_nome";
            series3.dataFields.valueX = "cmv_percentual";
            series3.xAxis = valueAxis3;
            series3.name = dados?.header?.cmv_percentual || "";
            series3.stroke = am4core.color(corCMV);
            series3.fill = am4core.color(corCMV);
            series3.clustered = false;

            var columnTemplate3 = series3.columns.template;
            columnTemplate3.strokeOpacity = 0;
            columnTemplate3.propertyFields.fill = "color";

            var label3 = columnTemplate3.createChild(am4core.Label);
            label3.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            label3.align = "left";
            label3.valign = "middle";
            label3.dx = 5;
            label3.fontSize = 15;
            label3.fill = am4core.color("#fff")
            label3.background.fill = am4core.color("gray");
            label3.background.fillOpacity = 0.5;
            label3.paddingRight = 5;
            label3.paddingLeft = 5;
            label3.paddingTop = 3;
            label3.truncate = false;
            label3.hideOversized = false;


            // var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
            // bullet3.fillOpacity = 0;
            // bullet3.strokeOpacity = 0;

            // let valueLabel3 = series3.bullets.push(new am4charts.LabelBullet());
            // valueLabel3.label.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            // valueLabel3.label.fontSize = 15;
            // valueLabel3.label.fill = am4core.color("#495057")
            // valueLabel3.label.background.fill = am4core.color("gray");
            // valueLabel3.label.background.fillOpacity = 0.3;
            // valueLabel3.label.paddingRight = 5;
            // valueLabel3.label.paddingLeft = 5;
            // valueLabel3.label.paddingTop = 3;
            // valueLabel3.label.dx = 30;
            // valueLabel3.label.truncate = false;
            // valueLabel3.label.hideOversized = false;


            var valueAxis4 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis4.tooltip.disabled = true;
            valueAxis4.renderer.baseGrid.disabled = true;
            valueAxis4.marginRight = 30;
            valueAxis4.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis4.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis4.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis4.renderer.grid.template.strokeOpacity = 1;
            valueAxis4.title.text = dados?.header?.margem_contribuicao_valor || "";
            valueAxis4.renderer.labels.template.disabled = true;
            valueAxis4.title.dy = 10;
            valueAxis4.min = -100;
            valueAxis4.max = 100;

            // valueAxis4.numberFormatter = new am4core.NumberFormatter();
            // valueAxis4.numberFormatter.numberFormat = "0'%'";
            // valueAxis4.adjustLabelPrecision = true;


            var series4 = chart.series.push(new am4charts.ColumnSeries());
            series4.dataFields.categoryY = "u_nome";
            series4.dataFields.valueX = "margem_contribuicao_percentual";
            series4.xAxis = valueAxis4;
            series4.name = dados?.header?.margem_contribuicao_percentual || "";
            series4.stroke = am4core.color(corMargem);
            series4.fill = am4core.color(corMargem);
            series4.clustered = false;

            var columnTemplate4 = series4.columns.template;
            columnTemplate4.strokeOpacity = 0;
            columnTemplate4.propertyFields.fill = "color";

            var label4 = columnTemplate4.createChild(am4core.Label);
            label4.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            label4.align = "left";
            label4.valign = "middle";
            label4.dx = 5;
            label4.fontSize = 15;
            label4.fill = am4core.color("#fff")
            label4.background.fill = am4core.color("gray");
            label4.background.fillOpacity = 0.5;
            label4.paddingRight = 5;
            label4.paddingLeft = 5;
            label4.paddingTop = 3;
            label4.truncate = false;
            label4.hideOversized = false;

            // var bullet4 = series4.bullets.push(new am4charts.CircleBullet());
            // bullet4.fillOpacity = 0;
            // bullet4.strokeOpacity = 0;

            // let valueLabel4 = series4.bullets.push(new am4charts.LabelBullet());
            // valueLabel4.label.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            // valueLabel4.label.fontSize = 15;
            // valueLabel4.label.fill = am4core.color("#495057")
            // valueLabel4.label.background.fill = am4core.color("gray");
            // valueLabel4.label.background.fillOpacity = 0.3;
            // valueLabel4.label.paddingRight = 5;
            // valueLabel4.label.paddingLeft = 5;
            // valueLabel4.label.paddingTop = 3;
            // valueLabel4.label.dx = 30;
            // valueLabel4.label.truncate = false;
            // valueLabel4.label.hideOversized = false;
            chart.responsive.useDefault = true;
            chart.responsive.enabled = true;

            return () => {
                chart.dispose();
            };
        }

    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        if (alturaGrafico < 300) {
            alturaGrafico = 350
        }
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
    }
    return (
        <div id={index} style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(Grafico)