import React from "react";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Form, FormGroup, Button, Container } from 'reactstrap';
import DropdownOpcoesCamadas from "../DropdownOpcoesCamadas";
const ModalOpcoesCamadas = ({ aberto, setAberto, idParceiro, idCliente, relacoes, token, filtroCamadasOpcoes, setFiltroCamadasOpcoes, executarConsulta, submitHabilitado }) => {
    return (
        <div style={{ zIndex: 999 }}>
            <Drawer
                id={"drawerComponente"}
                open={aberto}
                direction='right'
                size={400}
                style={{ paddingTop: "90px", overflowY: "auto" }}>
                {<Container>
                    <Form>
                        {
                            relacoes?.map((relacao, indice) => {
                                return (
                                    <DropdownOpcoesCamadas key={`formOpcaoCamada_${indice}`} filtroCamadasOpcoes={filtroCamadasOpcoes} setFiltroCamadasOpcoes={setFiltroCamadasOpcoes} token={token} idParceiro={idParceiro} idCliente={idCliente} relacao={relacao}></DropdownOpcoesCamadas>
                                )
                            })
                        }
                        <FormGroup className="text-right">
                            <Button
                                color="light"
                                disabled={!submitHabilitado}
                                onClick={(e) => {
                                    executarConsulta();
                                    setAberto(false);
                                }}
                                className="ml-2"
                            > aplicar filtro de camadas
                            </Button>
                        </FormGroup>
                    </Form>
                </Container>}
            </Drawer >
        </div>
    )
}
export default ModalOpcoesCamadas