import "../../../helpers/locale/locale_BR";
import React from 'react';
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import {
  bizGridResponsive
} from "../../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { urlApiFinanceiro } from "../../../helpers/api";



class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      valor_liquidado: 0,
    });
    this.set({
      valor_liquidado: linha.valor_liquidado
    })
  }
}


const gridConfig = (dados, grid, token, setParametrosModal) => {
  let visualizacao = "familia";
  let cf_nivel1_ids = "";
  let cf_nivel2_ids = "";
  let cf_nivel3_ids = "";
  let classeN1 = "";

  const larguraColunaComputador = 110;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      unidades: null,
      u_unidades_negocio_id: null,
      ano_inicial: null,
      mes_inicial: null,
      ano_final: null,
      mes_final: null,
      tree: true,
      modelClass: GridLinha,
      readUrl: urlApiFinanceiro(),
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.u_unidades_negocio_id = request.u_unidades_negocio_id;
            this.ano_inicial = request.ano_inicial;
            this.mes_inicial = request.mes_inicial;
            this.ano_final = request.ano_final;
            this.mes_final = request.mes_final;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data && event.json.ResultadoObjeto.data.length) {
              dados.header = event.json.ResultadoObjeto.header;
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          switch (nivel_atual) {
            case 1:
              visualizacao = "gaveta";
              cf_nivel1_ids = this.getById(event.params.id).data.cf_nivel1_id;
              cf_nivel2_ids = "";
              cf_nivel3_ids = "";
              classeN1 = "";
              break;
            case 2:
              visualizacao = "pasta";
              cf_nivel1_ids = this.getById(event.params.id).data.cf_nivel1_id;
              cf_nivel2_ids = this.getById(event.params.id).data.cf_nivel2_id;
              cf_nivel3_ids = "";
              classeN1 = "";
              break;
          }
          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              ano_inicial: this.ano_inicial,
              mes_inicial: this.mes_inicial,
              ano_final: this.ano_final,
              mes_final: this.mes_final,
              arvore: 3,
              visualizacao,
              agrupamento: "mensal",
              classeN1,
              u_unidades_negocio_id: this.u_unidades_negocio_id,
              cf_nivel1_ids,
              cf_nivel2_ids,
              cf_nivel3_ids,
              sob_demanda: 1,
              nivel_atual,
              resultado: "despesas",
              retorno: "coluna",
              titulos: "pagar",
            })
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 120,
        responsiveLevels: hideMedium,
        filterable: false
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 120,
        locked: true,
        responsiveLevels: showMedium,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_01,
        field: "valor_liquidado_01",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_01, dados.footer.valor_liquidado_01), dados.header.coluna_01);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_01 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "01|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_01, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_02,
        field: "valor_liquidado_02",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_02, dados.footer.valor_liquidado_02), dados.header.coluna_02);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_02 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "02|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_02, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_03,
        field: "valor_liquidado_03",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_03, dados.footer.valor_liquidado_03), dados.header.coluna_03);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_03 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                arvore: 2,
                meses: "03|;",
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_03, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_04,
        field: "valor_liquidado_04",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_04, dados.footer.valor_liquidado_04), dados.header.coluna_04);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_04 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "04|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_04, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_05,
        field: "valor_liquidado_05",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_05, dados.footer.valor_liquidado_05), dados.header.coluna_05);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_05 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "05|;",
                visualizacao: "centro-alocacao",
                arvore: 2,
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_05, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_06,
        field: "valor_liquidado_06",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_06, dados.footer.valor_liquidado_06), dados.header.coluna_06);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_06 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "06|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_06, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_07,
        field: "valor_liquidado_07",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_07, dados.footer.valor_liquidado_07), dados.header.coluna_07);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_07 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "07|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_07, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_08,
        field: "valor_liquidado_08",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_08, dados.footer.valor_liquidado_08), dados.header.coluna_08);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_08 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "08|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_08, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_09,
        field: "valor_liquidado_09",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_09, dados.footer.valor_liquidado_09), dados.header.coluna_09);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_09 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "09|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_09, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_10,
        field: "valor_liquidado_10",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_10, dados.footer.valor_liquidado_10), dados.header.coluna_10);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_10 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "10|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_10, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_11,
        field: "valor_liquidado_11",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_11, dados.footer.valor_liquidado_11), dados.header.coluna_11);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_11 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {
              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "11|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_11, toMoney(value)) }}
          ></div>;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_12,
        field: "valor_liquidado_12",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao_12, dados.footer.valor_liquidado_12), dados.header.coluna_12);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.participacao_12 == 0) return "-";

          return <div style={{ cursor: "pointer" }}
            tabIndex="0"
            onDoubleClick={() => {

              const filtro = {
                cf_nivel1_id: record.cf_nivel1_id,
                cf_nivel2_id: record.cf_nivel2_id,
                cf_nivel3_id: record.cf_nivel3_id,
                ca_id: record.ca_id,
                meses: "12|;",
                arvore: 2,
                visualizacao: "centro-alocacao",
                token
              };
              setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
            }}
            dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao_12, toMoney(value)) }}
          ></div>;

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna,
        field: "valor_liquidado",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.participacao, dados.footer.valor_liquidado), dados.header.coluna);
        },
        renderer: ({ value, cellElement, record }) => {
          // if (record.participacao == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {

          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "01|;02|;03|;04|;05|;06|;07|;08|;09|;10|;11|;12|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValor(record.participacao, toMoney(value)) }}
          // ></div>;
          return formatarColunaPercentualValor(record.participacao, toMoney(value));

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
      /*
      {
        align: "right",
        text: dados.header.valor_saldo,
        field: "valor_saldo",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.valor_saldo), dados.header.valor_saldo);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 0))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
      */
    ]
  };
};

export { gridConfig };
