import "../../../helpers/locale/locale_BR";
import {
  toDateJS,
  retornarDiaSemanaAbreviada,
  toDate,
  valorEmMoeda,
  valorEmInteiro,
  showMedium,
  hideMedium,
  toMoney,
  formatarColunaPercentualValor,
  formatarFooterTitulo,
  formatarFooterColunaPercentualValor,
} from "../../../helpers/utils";
import { bizGridResponsive } from "../../../helpers/biz-padrao-visual";

// valores padroes para campos de moedas
const valoreDeMoeda = {
  width: 120,
  sum: true,
  align: "right",
  summaryRenderer: ({ sum }) => {
    return valorEmMoeda(sum, true);
  },
  renderer: ({ value }) => {
    return valorEmMoeda(value, true);
  },
};

const gridConfig = (dados, grid, colunasVisiveis) => {
  const larguraColunaComputador = 100;
  const configResponsiveLevels = {
    "*": { width: larguraColunaComputador },
    small: { width: 100 },
  };
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      data: dados.data,
    },
    columns: [
      {
        text: dados.header.fornecedor,
        field: "fornecedor",
        minWidth: 350,
        flex: 1,
        responsiveLevels: hideMedium,
        sum: true,
        summaryRenderer: () => {
          return "Total";
        },
      },
      {
        text: dados.header.dataEmissao,
        field: "dataEmissao",
        width: 120,
        filterable: false,
        renderer: ({ value }) => {
          return toDate(value);
        },
      },
      {
        text: dados.header.dataEntrada,
        field: "dataEntrada",
        width: 120,
        filterable: false,
        renderer: ({ value }) => {
          return toDate(value);
        },
      },
      {
        text: dados.header.notaFiscal,
        field: "notaFiscal",
        width: 120,
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorCOFINS,
        field: "valorCOFINS",
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorFCP,
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorFCP_ST,
      },

      {
        ...valoreDeMoeda,
        text: dados.header.valorICMS,
        field: "valorICMS",
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorICMS_ST,
        field: "valorICMS_ST",
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorPIS,
        field: "valorPIS",
      },
      {
        ...valoreDeMoeda,
        text: dados.header.valorTotal,
        field: "valorTotal",
      },
    ],
  };
};

export { gridConfig };
