import "../../../helpers/locale/locale_BR";
import {
  bizGridResponsive,
  tamanhoDafonte,
  setarBgColor,
} from "../../../helpers/biz-padrao-visual";
import { replaceAll } from "../../../helpers/utils";
const larguraSuplica = 110;
//Largura da coluna da visualização no computador

const gridConfig = (dados, redirecionarBoaConversa) => {
  const formatarHeader = (valor) => {

    if (valor == "Conquiste a minha confiança!")
      return "CONQUISTE<BR />A MINHA<BR />CONFIANÇA!";

    return valor.replace(" ", "<br />");
  }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      data: dados,
    },
    columns: [
      {
        text: "RESPONDENTES",
        field: "icr_nome_respondente",
        flex: 1,
        minWidth: 300,
        autoHeight: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          const valores = (record.icr_nome_agente_holograma + "|" + record.icr_camadas.trim()).split("|");
          return `
          <div class="mb-2 mt-2 ml-1">
            <div class="font-size-16 text-bold">
              ${record.icr_nome_respondente}
            </div>
            <div class="font-size-12">
              ${record.icr_empresa_respondente || "-"}
            </div>
            <div>
            ${valores
              .map((valor, i) => {
                if (!valor) return "";
                return `
                <span class="badge bg-light mr-2 p-1">
                    ${valor}
                  </span>`;
              })
              .join(" ")}
            </div>
            
          </div>
          
          `;
        },
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_1 : "",
        field: "suplica_retorno_1",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_1);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_2 : "",
        field: "suplica_retorno_2",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_2);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_3 : "",
        field: "suplica_retorno_3",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_3);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_4 : "",
        field: "suplica_retorno_4",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_4);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_5 : "",
        field: "suplica_retorno_5",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_5);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_6 : "",
        field: "suplica_retorno_6",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_6);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_7 : "",
        field: "suplica_retorno_7",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_7);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? dados[0].nome_suplica_8 : "",
        field: "suplica_retorno_8",
        width: larguraSuplica + 10,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_8);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: 'A boa conversa',
        width: 150,
        type: 'widget',
        align: "center",
        widgets: [{
          type: 'button',
          content: '<div>Ponto de <br />atenção</div>',
          cls: 'b-blue b-raised btn-boa-conversa py-1 w-100',
          onAction: ({ source: btn }) => {
            redirecionarBoaConversa(btn.cellInfo.record.data);
          }
        }]
      }
    ],
  };
};
export { gridConfig };
