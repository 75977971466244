import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { retornarIdCliente } from "../../cliente/variaveis";

const idCliente = retornarIdCliente();

function retornarValores(val) {
  return new URLSearchParams(val.search);
}

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { abrirFiltro, imprimirPdf, exportarExcel, botoesExtras, forcaExportaExcel } = props.parametros;

  const valores = retornarValores(location);
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig({
      icone: localStorage.getItem("iconeBizAtual"),
      titulo: localStorage.getItem("tituloBizAtual"),
    });
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="bg-primary"
        style={{ position: "initial" }}
      >
        <div
          className="navbar-header"
          style={{
            maxWidth: "100%",
            paddingRight: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <div className="d-flex titulopage">
            <div
              className="brand-bryntum text-truncate"
              style={{ textAlign: "left" }}
            >
              <Link to="/" className="logo">
                <span className="">
                  <h5 className="text-white text-truncate text-uppercase mb-0 pb-0">
                    <i
                      className={`${props.icone || config.icone || valores.get("icone")
                        } mr-2 mb-0`}
                    ></i>
                    {props.tituloPagina || config.titulo || valores.get("nome")}
                  </h5>
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex text-white">
            {abrirFiltro && (
              <div className="dropdown ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    abrirFiltro();
                  }}
                >
                  <i className="bx bx bx-filter-alt text-white"></i>
                </button>
              </div>
            )}

            {botoesExtras &&
              botoesExtras.map((botao) => {
                return (
                  <div className="dropdown ml-1" key={Math.random()}>
                    <button
                      type="button"
                      className="btn header-item noti-icon waves-effect"
                      onClick={() => {
                        botao.funcao();
                      }}
                      data-toggle="fullscreen"
                    >
                      <i className={`${botao.icone} text-white`}></i>
                    </button>
                  </div>
                );
              })}

            {imprimirPdf && (
              <div className="dropdown ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    imprimirPdf();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-printer text-white"></i>
                </button>
              </div>
            )}

            {exportarExcel && (forcaExportaExcel || ((idCliente != "benassi") && (idCliente != "btflex") && (idCliente != "volo"))) && (
              <div className="dropdown ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    exportarExcel();
                  }}
                >
                  <i className="fas fa-file-excel text-white"></i>
                </button>
              </div>
            )}

            <div className="dropdown ml-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-expand text-white"></i>
              </button>
            </div>

            <div className="dropdown ml-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect pr-0"
                onClick={() => {
                  if (props.url) {
                    navigate(props.url);
                  } else if (props.funcaoFechar) {
                    props.funcaoFechar();
                  } else {
                    navigate("/");
                  }
                }}
              >
                <i className="fas fa-times text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;