import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { API } from "../../config/index"

export const dadosApi = (token, lojas, tipo_cmv) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                lojas: lojas,
                tipo_cmv
            }),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/vw_bizoiada`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                console.log(error);
                reject(error)
            });
    })
}
function loadProgramasAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApi(action.token, action.lojas, action.tipo_cmv)
            .then(result => {
                let { data, header, footer, exportacao } = result.ResultadoObjeto;

                return resolve({ dados: data, data, header, footer, exportacao });
            })
            .catch(e => reject(e))
    })
}

function* carregarDadosRaioXLoja(action) {
    try {
        const dados = yield call(loadProgramasAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}
function* limparDados() {
    yield put(actions.limparDados())
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosRaioXLoja)
}
function* assistindoLimparDados() {
    yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}
function* assistindoRequisicaoSilencioso() {
    yield takeEvery(types.REQUISITAR_DADOS_SELENCIOSO, carregarDadosRaioXLoja)
}

function* listarRaioXLoja() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoRequisicaoSilencioso),
            fork(assistindoLimparDados)
        ]
    )
}
export default listarRaioXLoja