import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { valorEmMoeda } from "../../helpers/utils"
import {Link} from "react-router-dom"
const IndicadoresIcones = ({ estagio, icone, pedidos, valor, status, ano, mes }) => {

    return (
        <Card>
            <CardBody>
                <Link to={`/indicadores-de-vendas/listagemPedidosEstagios/${mes}/${ano}/${status}/${estagio}`}>
                    <h4 className="text-truncate text-left">{estagio}</h4>
                    <div className="d-flex">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-gray align-self-center" style={{overflow: "hidden", width: "3rem"}}>
                            <span className="avatar-title" style={{width: "3rem"}}>
                                <i className={`${icone} font-size-24`}></i>
                            </span>
                        </div>
                        <div className="ml-1" style={{flex:1}}>
                            <p className="text-muted mb-0 mt-1"> {`${pedidos} pedidos `}</p>
                            <h5 className="text-muted text-truncate mb-2">
                                <div className="mt-0">
                                    <strong>
                                        {`R$ ${valorEmMoeda(valor)}`}
                                    </strong>
                                </div>
                            </h5>
                        </div>
                    </div>


                </Link>
            </CardBody>
        </Card>
    )
}
export default IndicadoresIcones