import React, { useState } from 'react';
import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

import { Auth } from "aws-amplify";

// import images
import profile from "../../assets/images/profile-img.png";

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import FooterMsg from "./footerMsg";
import HeaderAuth from './partes/headerAutenticacao';
import LogoCliente from './partes/logoCliente';
import FooterLogin from "./partes/footer";

const AlterPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.Login.user);
    const [loading, setLoading] = useState(false);

    const passwordChanced = (data) => {
        toastr.success("Sua senha foi alterada", "Senha alterada");
        navigate('/login');
    };

    const passwordChancedFailed = (e) => {
        console.log(e);
        toastr.error("Erro ao alterar senha");
    };

    async function changePassword(event, values) {
        setLoading(true);
        console.log(event, values);

        if (values.newPassword.length < 6) {
            setLoading(false);
            toastr.error("Senha deve conter mais de seis digitos");
            return;
        }

        try {
            const data = await Auth.completeNewPassword(user, values.newPassword);
            passwordChanced(data);
        } catch (err) {
            passwordChancedFailed(err);
        }
        
        setLoading(false);
    }

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <HeaderAuth 
                                    titulo="Trocar senha!" 
                                    subtitulo="Antes de prosseguir altere sua senha." 
                                    imagem={profile} 
                                />
                                <CardBody className="pt-0">
                                    <LogoCliente />
                                    <div className="p-2">
                                        <AvForm 
                                            className="form-horizontal" 
                                            onValidSubmit={(e, v) => { changePassword(e, v) }}
                                        >
                                            <AvGroup className="mb-3">
                                                <Label for="example">nova senha</Label>
                                                <AvInput 
                                                    name="newPassword" 
                                                    label="*******" 
                                                    errorMessage="Campo obrigatório" 
                                                    type="password" 
                                                    required 
                                                    placeholder="Nova senha" 
                                                />
                                            </AvGroup>
                                            <div className="mt-3">
                                                <button 
                                                    disabled={loading} 
                                                    className="btn btn-primary btn-block waves-effect waves-light" 
                                                    type="submit"
                                                >
                                                    {loading ? (
                                                        <>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Carregando
                                                        </>
                                                    ) : (
                                                        "Trocar senha"
                                                    )}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <FooterMsg />
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterLogin />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { error } = state.Login;
    return { error };
};

const mapDispatchToProps = {
    loginUser,
    apiError
};

export default connect(mapStateToProps, mapDispatchToProps)(AlterPassword);