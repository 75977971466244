import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { pegarCores } from '../../../../helpers/utils';
import { definirLocalizacaoAmCharts5 } from "../../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../../helpers/utils-amcharts";

am5.addLicense("AM5C303720496");

const formatacaoAmCharts = retornarFormatacaoAmCharts();
//am4core.options.commercialLicense = true;

const Grafico = ({ dados }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados || [];


    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {

            document.querySelector(`#chartdiv`).innerHTML = "";

            var root = am5.Root.new("chartdiv");


            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
                paddingTop: 40,
            }));



            // Add legend
            // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
            // var legend = chart.children.push(am5.Legend.new(root, {
            //     // centerX: am5.p50,
            //     // x: am5.p50,
            //     layout: root.horizontalLayout
            // }));

            let legend = chart.children.unshift(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                y: 15
            }));


            var data = [];
            dadosGrafico.map(dado => {
                for (let i = 1; i <= 8; i++) {
                    data.push({
                        suplica: `[fontFamily: Poppins]${dado[`suplica_${i}_nome`]}[/]`,
                        nao_atendidas: dado[`suplica_${i}_naoatendidas`],
                        tamanho_nao_atendidas: dado[`suplica_${i}_naoatendidas`] == 0 ? "0px" : "14px",
                        desatendidas: dado[`suplica_${i}_desatendidas`],
                        tamanho_desatendidas: dado[`suplica_${i}_desatendidas`] == 0 ? "0px" : "14px",
                        atendidas: dado[`suplica_${i}_atendidas`],
                        tamanho_atendidas: dado[`suplica_${i}_atendidas`] == 0 ? "0px" : "14px",
                    });
                }
            });

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

            var xRenderer = am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            });
            xRenderer.grid.template.set("strokeOpacity", 0);

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "suplica",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.data.setAll(data);


            var yRenderer = am5xy.AxisRendererY.new(root, {});
            yRenderer.labels.template.setAll({ visible: false });

            yRenderer.grid.template.set("strokeOpacity", 0);

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: yRenderer
            }));







            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            function makeSeries(name, fieldName, stacked, fill, fillStyle) {
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    stacked: stacked,
                    name: `[fontFamily: Poppins]${name}[/]`,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    categoryXField: "suplica",
                    fill: am5.color(fill)
                }));

                // series.columns.template.setAll({
                //     tooltipText: "{name}, {categoryX}:{valueY}",
                //     width: am5.percent(90),
                //     tooltipY: am5.percent(10)
                // });
                series.data.setAll(data);

                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                series.appear();

                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationY: 0.5,
                        sprite: am5.Label.new(root, {
                            text: `[fontFamily: Poppins fontSize:{tamanho_${fieldName}}]{valueY}[/]`,
                            fill: am5.color("#fff"),
                            centerY: am5.percent(50),
                            centerX: am5.percent(50),
                            populateText: true
                        })
                    });
                });

                legend.data.push(series);
            }

            makeSeries("Não atendidas", "nao_atendidas", false, "#f1b44c", "#fff");
            makeSeries("Desatendidas", "desatendidas", true, "#f46a6a", "#fff");
            makeSeries("Atendidas", "atendidas", false, "#34c38f", "#fff");


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);


        }
    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 46}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(Grafico)