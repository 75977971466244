import * as types from "./types";

export const requisitarDadosGerais = (token, tipo) => {
  return {
    type: types.REQUISITAR_DADOS,
    payload: { token, tipo },
  };
};

export const requisicaoBemSucedida = (dados) => ({
  type: types.REQUISITAR_DADOS_SUCESSO,
  payload: dados,
});

export const requisicaoFalhou = (error) => ({
  type: types.REQUISITAR_DADOS_FALHOU,
  payload: error,
});

export const requisitarAtualizarListaClientes = (token, idEmpresa) => {
  return {
    type: types.ATUALIZAR_LISTA_CLIENTES,
    payload: { token, idEmpresa },
  };
};

export const requisitarAtualizarListaClientesSucesso = (dados) => ({
  type: types.ATUALIZAR_LISTA_CLIENTES_SUCCESS,
  payload: dados,
});

export const requisitarAtualizarListaPerido = (
  token,
  idParceiro,
  idCliente
) => {
  return {
    type: types.ATUALIZAR_LISTA_PERIODO,
    payload: { token, idParceiro, idCliente },
  };
};

export const requisitarAtualizarListaPeridoSucesso = (dados) => ({
  type: types.ATUALIZAR_LISTA_PERIODO_SUCCESS,
  payload: dados,
});

export const requisitarAtualizarListaAgentes = (
  token,
  idParceiro,
  idCliente
) => {
  return {
    type: types.ATUALIZAR_LISTA_AGENTES,
    payload: { token, idParceiro, idCliente },
  };
};

export const requisitarAtualizarListaAgentesSucesso = (dados) => ({
  type: types.ATUALIZAR_LISTA_AGENTES_SUCCESS,
  payload: dados,
});

export const requisitarAtualizarListaCamadas = (
  token,
  idParceiro,
  idCliente,
  idAgenteHolograma
) => {
  return {
    type: types.ATUALIZAR_LISTA_CAMADAS,
    payload: { token, idParceiro, idCliente, idAgenteHolograma },
  };
};

export const requisitarAtualizarListaCamadasSucesso = (dados) => ({
  type: types.ATUALIZAR_LISTA_CAMADAS_SUCCESS,
  payload: dados,
});
