import React, {useEffect, useRef, useState} from 'react';
import lottie from "lottie-web"
import rolling from "../../assets/animacoes/semdados.json"
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

function Vazio() {
    const container = useRef(null)
    const [esconder, setEsconder] = useState(false)
    useEffect(()=>{
        lottie.loadAnimation({
          container: container.current,
          renderer: "svg",
          autoplay: true,
          animationData: rolling
        })
        const timer = setTimeout(() => {
            setEsconder(true)
          }, 5000);
          return () => clearTimeout(timer);
      }, [])
      
    if(esconder) return null

    return (
        <Container fluid className="fixWith">
            <Row>
                <Col>
                    <Row className="justify-content-center mt-5 pt-2">
                        <Col sm={4} md={3} style={{maxWidth: 180}}>
                            <div ref={container}></div>
                            <p className="text-center text-muted">Xiii, não encontrei nenhum registro.</p>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>

    )
}

export default Vazio;