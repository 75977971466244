import React from 'react';
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
  return (
   <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={12}>
                            <div className="text-sm-right d-none d-sm-block">
                                <img src={require("../../assets/images/simplesnologia.png")} alt="Logo Hapybiz" height="20" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
  );
}

export default Footer;