import React, {useEffect, useCallback, useState, useRef} from 'react';

import PaginaLoader from '../../components/loader/pagina';
import gerarToken from '../../helpers/gerarToken'
import { useDispatch, useSelector } from 'react-redux'
import {requisitarDados} from "../../store/mapaNegociosUnidades/actions"
import { gridConfig } from './AppConfig'; 
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { Card, CardBody, Button, Form, FormGroup, Collapse } from 'reactstrap';
import {AvField, AvForm} from "availity-reactstrap-validation"
import {
    BryntumGrid,
    BryntumToolbar
} from '@bryntum/grid-react';
import zipcelx from 'zipcelx';

import makeAnimated from 'react-select/animated';
import Select from 'react-select'
const animatedComponents = makeAnimated();

const headerTpl = ({ currentPage, totalPages }) => `
    <div class="demo-export-header">
        <img src="resources/logo.png"/>
        <dl>
            <dt>Date:</dt>
            <dd></dd>
        </dl>
    </div>`;

const footerTpl = () => '<div class="demo-export-footer"><h3>© 2020 Bryntum AB</h3></div>';

const MapaNegociosUnidades  = (props) => {
    //store

    const {dados, carregando} = useSelector(state => state.MapaNegociosUnidades);
    const grid = useRef(null);
    const [mes, setMes] = useState(8);
    const [ano, setAno] = useState(2021);

    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const [lojas, setLojas] = useState([]);
    const [mostrarFiltros, setMostrarFiltros] = useState(true)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const atualizarDados = async(mes, ano)=>{
        const token = await gerarToken();
        await dispatch(requisitarDados(token, mes, ano));
    }
    useEffect(()=>{
        atualizarDados(mes, ano);
    }, [])

    const trocarLojas = (valor) => {
        if (valor) {
          setLojas(valor)
        } else {
          setLojas([])
        }
    }
    const salvarFormulario = (values) =>{
        atualizarDados(values.mes, values.ano);
    }

    const exportarExcel = () => {
        grid.current.instance.features.excelExporter.export({
            filename : 'Mapa de Negócio',
            exporterConfig : {
                columns : [
                    { text : 'Nome', field: "nome", width : 90},
                    { text : 'TM', field : 'ticketMedio' },
                    { text : 'Cupons', field : 'cupons' },
                    { text : 'Faturamento', field : 'faturamento' },

                ]
            }
        })
    };
    const exportarPdf = ()=>{
        grid.current.instance.features.pdfExport.showExportDialog();
        return
        grid.current.instance.features.pdfExport.showExportDialog({
          // Required, set list of column ids to export
          
          columns : grid.current.instance.columns.map(c => c.id),
          exporterType: "multipage",
          fileName: "biz",
          rowsRange: "all"
  
        }).then(result => {
            // Response instance and response content in JSON
            let { response } = result;
        });
  
      }
    if(!dados) return <PaginaLoader carregando={true} />

    const gridInfo = gridConfig(dados)
    return (
            <React.Fragment>
                <PaginaLoader carregando={carregando} />
                <>
                    <Header 
                        parametros={ {
                            exportarExcel: ()=> exportarExcel(),
                            imprimirPdf: ()=> exportarPdf(),
                            botoesExtras: [
                                {
                                    funcao:()=>{
                                        setMostrarFiltros(!mostrarFiltros)
                                    },
                                    icone: "bx bx-menu"
                                }
                            ]
                        }
                        } position={true} titulo="Mapa de negócios"/>
                    <Collapse isOpen={mostrarFiltros}>
                        <Card className="mb-0">
                            <CardBody className="altoZIndex barBrytum">
                                <AvForm inline onSubmit={(event, errors, values) => { salvarFormulario( values) }}> 
                                    <FormGroup className="select2-container altoZIndex"  >
                                        <div style={{ minWidth: 200 }}>
                                            <Select
                                            placeholder="Todas as unidades"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={lojasDoUsuario}
                                            isSearchable={false}
                                            isMulti
                                            classNamePrefix="select2-selection"
                                            defaultValue={lojas || []}
                                            onChange={Object => {
                                                trocarLojas(Object || []);
                                            }}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <AvField value={2021} className="ml-2" type="select" name="ano">
                                            <option>2021</option>
                                            <option>2020</option>
                                            <option>2019</option>
                                            <option>2018</option>

                                        </AvField>
                                    </FormGroup>
                                    <FormGroup>
                                        <AvField value={8} className="ml-2" type="select" name="mes">
                                            <option value={1}>Janeiro</option>
                                            <option value={2}>Fevereiro</option>
                                            <option value={3}>Março</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Maio</option>
                                            <option value={6}>Junho</option>
                                            <option value={7}>Julho</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Setembro</option>
                                            <option value={10}>Outubro</option> 
                                            <option value={11}>Novembro</option> 
                                            <option value={12}>Dezembro</option>
                                        </AvField>
                                    </FormGroup>
                                    
                                    <FormGroup>
                                    <Button type="submit" color="light" className="ml-2">
                                        <i className={`fal fa-rabbit-fast mr-1`}></i>
                                        vai...
                                    </Button>
                                    </FormGroup>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Collapse>
                    <div className="w-100" style={{minWidth: "100%", flex: 1}}>
                        <BryntumGrid
                            ref={grid}
                            key={carregando}
                            {...gridInfo} 
                            treeFeature={true}
                            columnReorderFeature={false}
                            cellEditFeature={false}
                            summaryFeature={true}
                            sortFeature={false}
                            cellMenuFeature={{
                                disabled: true
                            }}
                            
                            headerMenuFeature={{
                                items : {
                                    hideColumn : false
                                }
                            }}
                            excelExporterFeature={{
                                zipcelx
                            }}
                            pdfExportFeature={{
                                exportServer: 'https://bryntum-pdf.t9oqilelq5tj0.us-east-1.cs.amazonlightsail.com',
                                translateURLsToAbsolute: true,
                            }}
                            />
                    </div>

                </>
            </React.Fragment>
          );
    }
        
export default MapaNegociosUnidades;