import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { pegarCores } from '../../../../helpers/utils';
import { definirLocalizacaoAmCharts5 } from "../../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../../helpers/utils-amcharts";

am5.addLicense("AM5C303720496");

const formatacaoAmCharts = retornarFormatacaoAmCharts();
//am4core.options.commercialLicense = true;

const Grafico = ({ dados }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados || [];

    const formatarLegenda = (suplica) => {
        if (suplica == "Conquiste a minha confiança!")
            return `  Conquiste a \nminha confiança!`;

        if (suplica == "Facilite a minha vida!")
            return ` Facilite a \nminha vida!`;

        return suplica;
    }
    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {

            document.querySelector(`#chartdiv`).innerHTML = "";


            /**
 * ---------------------------------------
 * This demo was created using amCharts 5.
 * 
 * For more information visit:
 * https://www.amcharts.com/
 * 
 * Documentation is available at:
 * https://www.amcharts.com/docs/v5/
 * ---------------------------------------
 */

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            var root = am5.Root.new("chartdiv");


            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout
            }));


            // Create axes and their renderers
            var yRenderer = am5xy.AxisRendererY.new(root, {
                visible: false,
                minGridDistance: 20,
                inversed: true
            });

            yRenderer.grid.template.set("visible", false);

            var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                renderer: yRenderer,
                categoryField: "icr"
            }));

            var xRenderer = am5xy.AxisRendererX.new(root, {
                visible: false,
                minGridDistance: 30,
                opposite: true
            });

            xRenderer.grid.template.set("visible", false);

            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                renderer: xRenderer,
                categoryField: "suplica"
            }));


            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                calculateAggregates: true,
                stroke: am5.color(0xffffff),
                clustered: false,
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: "suplica",
                categoryYField: "icr",
                valueField: "value"
            }));

            // series.labels.template.setAll({
            //     fontFamily: "Poppins",
            //     fill: am5.color(0X9092a1)
            // });


            series.columns.template.setAll({
                // tooltipText: "{value}",
                strokeOpacity: 1,
                strokeWidth: 2,
                width: am5.percent(100),
                height: am5.percent(100)
            });

            series.columns.template.events.on("pointerover", function (event) {
                var di = event.target.dataItem;
                if (di) {
                    heatLegend.showValue(di.get("value", 0), " ");
                }
            });

            series.events.on("datavalidated", function () {
                heatLegend.set("startValue", series.getPrivate("valueHigh"));
                heatLegend.set("endValue", series.getPrivate("valueLow"));
            });


            // Set up heat rules
            // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
            series.set("heatRules", [{
                target: series.columns.template,
                min: am5.color(0xf7d0d0),
                max: am5.color(0xf46a6a),
                // min: am5.color(0xd7f1e9),
                // max: am5.color(0x12956c),
                dataField: "value",
                key: "fill"
            }]);



            // Add heat legend
            // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
            var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
                orientation: "horizontal",
                endColor: am5.color(0xf7d0d0),
                startColor: am5.color(0xf46a6a)
            }));

            heatLegend.startLabel.setAll({
                opacity: 0
            });

            heatLegend.endLabel.setAll({
                opacity: 0
            });


            // Set data
            // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
            var data = [];
            dadosGrafico.map(dado => {
                for (let i = 1; i <= 8; i++) {
                    data.push({
                        icr: `[fontFamily: Poppins]${dado.icr_descricao}[/]`,
                        suplica: `[fontFamily: Poppins]${formatarLegenda(dado[`nome_suplica_${i}`])}[/]`,
                        value: dado[`suplica_retorno_${i}`],
                    });
                }
            });

            series.data.setAll(data);

            let icrs = [...new Set(dadosGrafico.map(item => item.icr_descricao))];

            // let ucrs_utilizados = icrs.sort((a, b) => {
            //     return b.localeCompare(a);
            // }).map(item => {
            //     return { icr: item }
            // })

            let ucrs_utilizados = icrs.map(item => {
                return { icr: `[fontFamily: Poppins]${item}[/]` }
            })


            yAxis.data.setAll(ucrs_utilizados);

            let rotulosSuplicas = [];

            for (let i = 1; i <= 8; i++) {
                rotulosSuplicas.push({ suplica: `[fontFamily: Poppins]${formatarLegenda(dadosGrafico[0][`nome_suplica_${i}`])}[/]` })
            }


            //ucrs_utilizados.reverse();
            xAxis.data.setAll(rotulosSuplicas);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
            chart.appear(1000, 100);

        }
    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(Grafico)