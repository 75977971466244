import {takeEvery, fork, all, call, put} from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { API } from "../../config/index"

const dadosApi = (token, lojas, dataInicial, dataFinal) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                lojas: lojas,
                dataInicial: dataInicial,
                dataFinal: dataFinal
            }),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/rankingProdutos`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}
function loadProgramasAPI(action){
    return new Promise((resolve, reject)=>{
        dadosApi(action.token, action.lojas, action.dataInicial, action.dataFinal)
        .then(result => {
            let { data, header, footer } = result.ResultadoObjeto;
           
            return resolve({ dados: data, header, footer });
    })
    .catch(e => reject(e))
})
}

function* carregarDadosRankingProdutos(action){
    try{
        const dados = yield call( loadProgramasAPI , action.payload)
        yield put( actions.requisicaoBemSucedida(dados) )
    }catch (e){
        yield put (actions.requisicaoFalhou(e))
    }
}

function* limparDados(action){
    yield put( actions.limparDados() )

}

function* assistindoRequisicao(){
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosRankingProdutos)
}

function* destruirDados(){
    yield takeEvery(types.REQUISITAR_DADOS_LIMPAR_REQ, limparDados)
}



function* listarRankingProdutos(){
    yield all(
        [
            fork(assistindoRequisicao),
            fork(destruirDados)
        ]
    )
}

export default listarRankingProdutos