import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/fluxoCaixa/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { exportarExcel, concatenaLojas, retornarTipoFluxoCaixa, Hoje, removerFocusNoBotao, toDateSQL, retornarColunasExcel } from "../../helpers/utils";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import FocarPaisagem from '../../components/forcarPaisagem';
import zipcelx from 'zipcelx';
const animatedComponents = makeAnimated();
const tiposFluxoCaixa = retornarTipoFluxoCaixa();

const FluxoCaixa = () => {
    const token = useSelector(state => state.Login.user.tokenNOL);
    const { dados, carregando } = useSelector(state => state.FluxoCaixa);
    const [dataFinal, setDataFinal] = useState(Hoje());
    const grid = useRef(null);
    const dispatch = useDispatch();
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [lojas, setLojas] = useState([]);
    const [fluxoCaixa, setFluxoCaixa] = useState(tiposFluxoCaixa[0]);

    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };

    const dataFinalRef = useRef(null);
    dataFinalRef.current = { dataFinal, setDataFinal };

    const fluxoCaixaRef = useRef(null);
    fluxoCaixaRef.current = { fluxoCaixa, setFluxoCaixa };

    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            u_unidades_id: concatenaLojas(lojasRef.current.lojas),
            tipo: fluxoCaixaRef.current.fluxoCaixa.value,
            data_final: toDateSQL(dataFinalRef.current.dataFinal),
            token
        }));
        setMostrarFiltros(false);
    }

    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token, "financeiras"));
    }
    const destruirDados = () => {
        dispatch(requisitarLimparDados())
    }
    useEffect(() => {
        atualizarDados();
        atualizarDadosAmbiente();
        return () => {
            destruirDados()
        }
    }, []);



    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }

    const trocarFluxoCaixa = (valor) => {
        if (valor) {
            setFluxoCaixa(valor)
        } else {
            setFluxoCaixa([])
        }
    }

    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, grid, fluxoCaixaRef.current.fluxoCaixa.value == "D");
    }, [dados, grid])



    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={carregando} />

            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="select2-container"  >
                            <div style={{ minWidth: 200 }}>
                                <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder="Todas as unidades"
                                    className="basic-single"
                                    options={tiposFluxoCaixa}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposFluxoCaixa[0] || []}
                                    onChange={Object => {
                                        trocarFluxoCaixa(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup style={{ width: 110 }} className="ml-2">
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: "today"
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataFinal(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} >
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {dados &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={false}
                        sortFeature={false}
                        key={carregando}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />
                }
            </>
        </React.Fragment>
    );
}
export default FluxoCaixa;