import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais, retornarTipoCustos, exportarExcel, concatenaLojas, Hoje, Mes, Ano, valorEmMoeda, valorEmInteiro, removerFocusNoBotao, RetornaPeriodoAnterior, toMoney, retornarTipoCustosBTFlex } from "../../../helpers/utils"
import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts"
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarDadosTotais, requisitarLimparDados } from "../../../store/diario/actions"
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions"
import HeaderGrafico from '../../../components/headerGrafico';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { FormGroup, Button, Form, Container } from 'reactstrap';
import ToobarCard from '../../toolBarCard';
import GraficoLojasAoVivo from "./partes/grafico"
import Select from 'react-select'
import GraficoAltura from '../../../components/graficoAltura';
import makeAnimated from 'react-select/animated';
import { pegarCores } from '../../../helpers/utils';
import FocarPaisagem from '../../../components/forcarPaisagem';
import FiltroBrytum from "../../../components/filtroBrytum";
import { retornarIdCliente } from '../../../cliente/variaveis';
import { retornaExportacaoExcel } from './partes/variaveis';

const animatedComponents = makeAnimated();
const formatacaoAmCharts = retornarFormatacaoAmCharts();
const mesAnterior = RetornaPeriodoAnterior(Mes(Hoje()), Ano(Hoje()));
const mesAtual = { mes: Mes(Hoje()), ano: Ano(Hoje()) };
const tiposCusto = retornarTipoCustosBTFlex();
const idCliente = retornarIdCliente();

const DiarioBTFlex = () => {

  const token = useSelector(state => state.Login.user.tokenNOL);
  const [propTipoCusto, setPropTipoCusto] = useState(tiposCusto[1].value == "02" ? "02_" : "");
  const [tipoGrafico, setTipoGrafico] = useState("faturamento");
  const [cores, seTcores] = useState(null);
  const [cor, setCor] = useState("");
  const [sinalGraficoDepoisValor, setSinalGraficoDepoisValor] = useState("R$");
  const [nomeGrafico, setNomeGrafico] = useState("Faturamento");
  const [formatoNumeroGrafico, setFormatoNumeroGrafico] = useState(formatacaoAmCharts.formatoValores.decimal0Casas);
  const { dados, carregando, totais } = useSelector(state => state.Diario);
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const [lojasFiltradas, setLojasFiltradas] = useState([]);
  const [cor_selecionada, set_cor_selecionada] = useState("success");
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);

  const dispatch = useDispatch();
  const lojasRef = useRef(null);

  lojasRef.current = { lojas, setLojas };

  const lojasFiltradasRef = useRef(null);
  lojasFiltradasRef.current = { lojasFiltradas, setLojasFiltradas };

  const atualizarDados = async () => {
    await dispatch(requisitarDados({
      token,
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      mes_final: mesAnterior.mes,
      ano_final: mesAnterior.ano,
      agrupamento: "mensal",
      visualizacao: "total",
      retornaMC3: "",
      exportacao: retornaExportacaoExcel(tipoCusto.value),
      retorna_desafio: "",
      tipo_cmv: tipoCusto.value,
      base_calculo_percentual: "bruto"
    }));
    if (dados) {
      setMostrarTotais(true);
    }
  }

  const atualizarDadosMesAtual = async () => {
    await dispatch(requisitarDadosTotais({
      token,
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      mes_inicial: mesAtual.mes,
      ano_inicial: mesAtual.ano,
      mes_final: mesAtual.mes,
      ano_final: mesAtual.ano,
      agrupamentos: "total",
      visualizacao: "total",
      retornaMC3: "",
      retorna_desafio: "",
      tipo_cmv: tipoCusto.value,
      base_calculo_percentual: "bruto"
    }));

  }


  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    atualizarDadosMesAtual();
    seTcores(pegarCores());
    const timer = setInterval(() => {
      if (!carregando) {
        atualizarDadosMesAtual();
      }
    }, 2000)
    return () => {
      clearInterval(timer)
      dispatch(requisitarLimparDados())
    }
  }, []);

  useEffect(() => {
    if (dados) {
      setPropTipoCusto(tipoCusto.value == "02" ? "02_" : "")
    }
  }, [dados]);

  useEffect(() => {
    switch (tipoGrafico) {
      case "cupons":
        setCor(cores?.info)
        break;
      case "fatumento_liquido":
        setCor(cores?.success)
        break
      case "ticket_medio":
        setCor(cores?.warning)
        break
      case "cmv_percentual":
        setCor(cores?.danger)
        break
      case "margem_contribuicao":
        setCor(cores?.dark)
        break
      case "nao_vendas_percentual":
        setCor(cores?.danger)
        break
      case "margem_ii_percentual":
        setCor(cores?.dark)
        break
      case "despesas_unidade_percentual":
        setCor(cores?.danger)
        break
      case "margem_iii_percentual":
        setCor(cores?.dark)
        break
      case "margens":
        setCor(cores?.dark)
        break
      default:
        setCor(cores?.success)
        break;
    }
  }, [cores, tipoGrafico])

  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])

  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }


  const atualizarLojas = () => {
    atualizarDados();
    setLojasFiltradas(lojasRef.current.lojas);
    atualizarDadosMesAtual();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }

  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }
  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />
      <>
        <Header
          parametros={
            {
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ],
              exportarExcel: () => exportarExcel(dados),
              titulo: "Batida do tambor",
              icone: "fad fa-drum"
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="ml-2">
                  <div className="unidadesControler">
                    <Select
                      placeholder=""
                      className="basic-single"
                      options={tiposCusto}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      defaultValue={tiposCusto[0] || []}
                      onChange={Object => {
                        trocarTipoCusto(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button color="light" onClick={(e) => { setMostrarFiltros(!mostrarFiltros); atualizarLojas(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
                <ToobarCard
                  botoes={
                    [
                      // {
                      //   nome: dados?.header?.ticket_medio,
                      //   color: "warning",
                      //   ativo: tipoGrafico === "ticket_medio",
                      //   abr: "TM",
                      //   funcao: () => {
                      //     setTipoGrafico("ticket_medio");
                      //     setNomeGrafico("Ticket Médio");
                      //     setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal2Casas);
                      //     setSinalGraficoDepoisValor("R$");
                      //     set_cor_selecionada("warning");
                      //   }
                      // },
                      // {
                      //   nome: dados?.header?.cupons,
                      //   color: "info",
                      //   ativo: tipoGrafico === "cupons",

                      //   funcao: () => {
                      //     setTipoGrafico("cupons");
                      //     setNomeGrafico("Cupons");
                      //     setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal0Casas);
                      //     setSinalGraficoDepoisValor("");
                      //     set_cor_selecionada("info");

                      //   }
                      // },
                      {
                        nome: dados?.header?.faturamento,
                        color: "success",
                        ativo: tipoGrafico === "faturamento",
                        funcao: () => {
                          setTipoGrafico("faturamento");
                          setNomeGrafico("Faturamento");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal0Casas);
                          setSinalGraficoDepoisValor("R$");
                          set_cor_selecionada("success");
                        }
                      },
                      {
                        nome: "Custos",
                        color: "danger",
                        ativo: tipoGrafico === "custos",
                        funcao: () => {
                          setTipoGrafico("custos");
                          setNomeGrafico("Custos");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal1Casa);
                          setSinalGraficoDepoisValor("%");
                          set_cor_selecionada("danger");
                        }
                      },
                      // {
                      //   nome: "Margens",
                      //   color: "dark",
                      //   ativo: tipoGrafico === "margens",
                      //   funcao: () => {
                      //     setTipoGrafico("margens");
                      //     setNomeGrafico("Margens");
                      //     setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal1Casa);
                      //     setSinalGraficoDepoisValor("%");
                      //     set_cor_selecionada("dark");
                      //   }
                      // },
                      {
                        nome: dados?.header?.margem_contribuicao_valor,
                        color: "dark",
                        ativo: tipoGrafico === "margem_contribuicao",
                        funcao: () => {
                          setTipoGrafico("margem_contribuicao");
                          setNomeGrafico("Margem de contribuição");
                          setFormatoNumeroGrafico(formatacaoAmCharts.formatoValores.decimal0Casas);
                          setSinalGraficoDepoisValor("R$");
                          set_cor_selecionada("success");

                        }
                      },


                    ]
                  }
                  tipoGrafico={tipoGrafico}
                  setTipoGrafico={setTipoGrafico}
                  setNomeGrafico={setNomeGrafico}
                  setFormatoNumeroGrafico={setFormatoNumeroGrafico} >


                </ToobarCard>
              </Form>
            </FiltroBrytum>
            <Container className="fixWith">
              <div className={"headers mt-4"}>
                <HeaderGrafico mostrar={mostrarTotais} colunas={2} itens={[
                  { nome: "Mês atual", valor: totais?.header?.periodo_ano, },
                  {
                    nome: totais?.header?.faturamento,
                    valor: `R$ ${toMoney(totais?.footer?.faturamento, 0)}`,
                    corClass: "text-success"
                  },
                  {
                    nome: totais?.header?.impostos_valor,
                    valor: `R$ ${toMoney(totais?.footer?.impostos_valor, 0)}`,
                    corClass: "text-warning"
                  },
                  {
                    nome: totais?.header?.devolucao_total_valor,
                    valor: "R$ " + toMoney(totais?.footer?.devolucao_total_valor, 0),
                    corClass: "text-info"
                  },
                  {
                    nome: totais?.header?.cmv_valor,
                    valor: `${toMoney(totais?.footer?.cmv_percentual, 1)}%`,
                    segundoValor: `${toMoney(totais?.footer?.cmv_valor, 0)}`,
                    corClass: "text-danger"
                  },
                  {
                    nome: totais?.header?.margem_contribuicao_valor,
                    valor: `${toMoney(totais?.footer?.margem_contribuicao_percentual, 1)}%`,
                    segundoValor: `${toMoney(totais?.footer?.margem_contribuicao_valor, 0)}`,
                    corClass: "text-dark"
                  }
                ]} />
              </div>
            </Container>
          </div>


          <GraficoAltura>
            <GraficoLojasAoVivo sinalGraficoDepoisValor={sinalGraficoDepoisValor} cor={cor?.trim()} nomeGrafico={nomeGrafico} tipoGrafico={tipoGrafico} formatoNumeroGrafico={formatoNumeroGrafico} dados={dados} />
          </GraficoAltura>

        </div>
      </>
    </React.Fragment>
  );
}

export default DiarioBTFlex;