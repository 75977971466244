import * as types from "./types";

export const requisitarDadosParceiros = (
  token,
  idEmpresa,
  idParceiro,
  idCliente,
  idPeriodoResposta,
  idAgenteHolograma,
  idCamadaOpcao
) => {
  return {
    type: types.REQUISITAR_DADOS,
    payload: {
      token,
      idEmpresa,
      idParceiro,
      idCliente,
      idPeriodoResposta,
      idAgenteHolograma,
      idCamadaOpcao,
    },
  };
};

export const requisicaoBemSucedida = (dados) => ({
  type: types.REQUISITAR_DADOS_SUCESSO,
  payload: dados,
});

export const requisicaoFalhou = (error) => ({
  type: types.REQUISITAR_DADOS_FALHOU,
  payload: error,
});
