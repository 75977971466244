import "../../helpers/locale/locale_BR";
import {
  formatarColunaTresValores,
  toMoney,
  valorEmMoeda,
  valorEmInteiro,
} from "../../helpers/utils";
import {
  setasParaCimaOuParaBaixoComparacao,
  bizGridResponsive,
  setaSumarioComparacao,
} from "../../helpers/biz-padrao-visual";
import { dadosApi } from "../../store/raioXLoja/saga";
import { GridRowModel } from "@bryntum/grid/grid.umd.js";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      nome: linha.nome,
      abreviatura: linha.abreviatura,
      cuponsHoje: linha.cuponsHoje,
      cuponsSemana: linha.cuponsSemana,
      cuponsOntem: linha.cuponsOntem,
      ticketMedioHoje: linha.ticketMedioHoje,
      ticketMedioSemana: linha.ticketMedioSemana,
      ticketMedioOntem: linha.ticketMedioOntem,
      faturamentoHoje: linha.faturamentoHoje,
      faturamentoSemana: linha.faturamentoSemana,
      faturamentoOntem: linha.faturamentoOntem,
    });
  }
}
//Largura da coluna da visualização no computador
const larguraColunaComputador = 300;
//Largura de todas as colunas
const configResponsiveLevels = {
  "*": { width: larguraColunaComputador },
  small: { width: 65 },
};
const configResponsiveLevelsComparacao = {
  "*": { width: larguraColunaComputador },
  small: { width: 75 },
};
//Colunas faturamento
const configResponsiveLevelsFaturamento = {
  "*": { width: larguraColunaComputador },
  small: { width: 63 },
};
const configResponsiveLevelsFaturamentoComparacao = {
  "*": { width: larguraColunaComputador },
  small: { width: 100 },
};
const showMedium = {
    large: { hidden: true },
    "*": {
      hidden: false,
    },
  },
  hideMedium = {
    large: { hidden: false },
    "*": {
      hidden: true,
    },
  };
const gridConfig = (dados, token) => {
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      modelClass: GridLinha,
      tree: true,
      data: dados.dados,
      autoLoad: true,
      updateInterval: 10000,
      updatePriceIntervalId: null,
      listeners: {
        refresh({ action, records }) {
          if (action === "dataset") {
            this.startAutoUpdate();
          }
        },
      },
      startAutoUpdate() {
        this.updatePriceIntervalId = setInterval(async () => {
          const novosDados = await dadosApi(token, "", true);
          if (novosDados.ResultadoObjeto && novosDados.ResultadoObjeto.data) {
            dados.footer = novosDados.ResultadoObjeto.footer;
            novosDados.ResultadoObjeto.data.forEach((linha, indice) => {
              this.getById(linha.id).updateLinha(linha);
              if (linha.children) {
                linha.children.forEach((filho, indice) => {
                  this.getById(filho.id).updateLinha(filho);
                });
              }
            });
          }
        }, this.updateInterval);
      },
      stopAutoUpdate() {
        clearInterval(this.updatePriceIntervalId);
      },
    },
    columns: [
      {
        text: "UNIDADES",
        field: "nome",
        sum: () => {
          return "Total";
        },
        type: "tree",
        flex: 1,
        minWidth: 300,
        responsiveLevels: hideMedium,
      },
      {
        text: "UNIDADES",
        field: "abreviatura",
        sum: () => {
          return "Total";
        },
        locked: true,
        width: 163,
        minWidth: 163,
        responsiveLevels: showMedium,
        type: "tree",
      },
      {
        align: "right",
        text: dados.header.tituloHoje,
        field: "faturamentoHoje",
        type: "number",
        htmlEncode: false,
        autoHeight: true,
        sum: true,
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(dados.footer.faturamentoHoje, 0),
            toMoney(dados.footer.cuponsHoje, 0),
            toMoney(dados.footer.ticketMedioHoje, 2)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(record.faturamentoHoje, 0),
            toMoney(record.cuponsHoje, 0),
            toMoney(record.ticketMedioHoje, 2)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
      {
        align: "right",
        text: dados.header.tituloOntem,
        field: "faturamentoOntem",
        type: "number",
        htmlEncode: false,
        // sum: () => {
        //   return {
        //     faturamentoHoje: dados.footer.faturamentoHoje,
        //     faturamentoSemana: dados.footer.faturamentoSemana
        //   };
        // },
        // summaryRenderer: ({ sum }) => {
        //   return `${valorEmInteiro(sum.faturamentoHoje)} ${setaSumarioComparacao(sum.faturamentoHoje, sum.faturamentoSemana)}`;
        // },
        sum: true,
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(dados.footer.faturamentoOntem, 0),
            toMoney(dados.footer.cuponsOntem, 0),
            toMoney(dados.footer.ticketMedioOntem, 2)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(record.faturamentoOntem, 0),
            toMoney(record.cuponsOntem, 0),
            toMoney(record.ticketMedioOntem, 2)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
      {
        align: "right",
        text: dados.header.tituloSemana,
        field: "faturamentoSemana",
        type: "number",
        htmlEncode: false,
        sum: true,
        // sum: () => {
        //   return {
        //     faturamentoHoje: dados.footer.faturamentoHoje,
        //     faturamentoSemana: dados.footer.faturamentoSemana
        //   };
        // },
        // summaryRenderer: ({ sum }) => {
        //   return `${valorEmInteiro(sum.faturamentoHoje)} ${setaSumarioComparacao(sum.faturamentoHoje, sum.faturamentoSemana)}`;
        // },
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(dados.footer.faturamentoSemana, 0),
            toMoney(dados.footer.cuponsSemana, 0),
            toMoney(dados.footer.ticketMedioSemana, 2)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValores(
            "R$ " + toMoney(record.faturamentoSemana, 0),
            toMoney(record.cuponsSemana, 0),
            toMoney(record.ticketMedioSemana, 2)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
    ],
  };
};
export { gridConfig };
