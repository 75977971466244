import * as types from "./types"

export const requisitarDados = (token) => {
	return{
		type: types.REQUISITAR_DADOS,
		payload: {token}
	}
};

export const requisicaoBemSucedida = (dados) => ({
	type:  types.REQUISITAR_DADOS_SUCESSO,
	payload: dados
});

export const requisicaoBemSucedidaAtalho = (dados) => ({
	type:  types.REQUISITAR_DADOS_SUCESSO_ATALHO,
	payload: dados
});


export const requisicaoFalhou = (error) => ({
	type: types.REQUISITAR_DADOS_FALHOU,
	payload: error
});


export const requisitarAtualizarMenuPrincipal = (nivel, indice, menu) =>({
	type: types.SOLICITAR_ALTERAR_MENU,
	payload: {nivel, indice, menu}
})

export const atualizarMenu = (novoMenu) =>({
	type: types.SOLICITAR_ALTERAR_MENU,
	payload: novoMenu
})