import React from "react"

const ModalTitulo = ({toggleModalFiltro, titulo}) =>{
    return (
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="exampleModalFullscreenLabel"
          >
              {titulo}
              </h5>
          <button
            onClick={() => {
              toggleModalFiltro()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    )
}

export default ModalTitulo