import React from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

const App = (props) => {
  // Função para determinar o layout baseado nas props
  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  return (
    <React.Fragment>
        <Routes>
          {/* Rotas de autenticação */}
          {authRoutes?.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <NonAuthLayout>
                  <route.component />
                </NonAuthLayout>
              }
            />
          ))}

          {/* Rotas de usuário */}
          {userRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <Authmiddleware
                  path={route.path}
                  layout={route.layout === "nenhum" ? NonAuthLayout : Layout}
                  component={route.component}
                />
              }
            />
          ))}

          {/* Rota padrão - redireciona para dashboard se nenhuma rota corresponder */}
          <Route
            path="*"
            element={<Navigate to="/dashboard" replace />}
          />
        </Routes>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);