import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import NegociosUnidades from "./mapaNegociosUnidades/saga";
import RankingProdutos from "./rankingProdutos/saga";
import Menus from "./menus/saga";
import Ambiente from "./ambiente/saga";
import RaioXLoja from "./raioXLoja/saga";
import LojasAoVivo from "./lojasAoVivo/saga";
import Diario from "./diario/saga";
import ReaLina from "./reaLina/saga";
import GraficoUnidades from "./graficoUnidades/saga";
import GraficoPorHoraLojas from "./graficoHoraLojas/saga";
import User from "./auth/user/saga";
import IndicadoresVendaEstagios from "./btflex/indicadoresVendaEstagios/saga";
import ListagemPedidosEstagios from "./btflex/listagemPedidosEstagios/saga";
import Vendedores from "./btflex/vendedores/saga";
import DesempenhoVendedor from "./btflex/desempenhoVendedor/saga";
import TabuleiroNegocios from "./tabuleiroNegocios/saga";
import TopSujeitos from "./topSujeitos/saga";
import TopProdutos from "./topProdutos/saga";
import TopFamilias from "./topFamilias/saga";
import CoposContribuicao from "./coposContribuicao/saga";
import GraficoCachoeira from "./graficoCachoeira/saga";
import GraficoArvore from "./graficoArvore/saga";
import GraficoEvolucaoDespesas from "./graficoEvolucaoDespesas/saga";
import Xpto from "./xpto/saga";
import PainelReposicaoLojas from "./painelReposicaoLojas/saga";
import PainelReposicaoProdutos from "./painelReposicaoProdutos/saga";
import CinemaResultados from "./cinemaResultados/saga";
import ProjecaoResultados from "./projecaoResultados/saga";
import CinemaResultadosDetalhes from "./cinemaResultadosDetalhes/saga";
import ddlFilmesCinema from "./ddlFilmesCinema/saga";
import SaldosContas from "./saldosContas/saga";
import GridVendasUnidadesDiarias from "./gridVendasUnidadesDiarias/saga";
import FluxoCaixa from "./fluxoCaixa/saga";
import GridMargemFilial from "./benassi/gridMargemFilial/saga";
import GridMargemProduto from "./benassi/gridMargemProduto/saga";
import GridMargemNegocio from "./benassi/gridMargemNegocio/saga";
import Filial from "./benassi/filial/saga";
import Negocio from "./benassi/negocio/saga";
import Rede from "./benassi/rede/saga";
import Loja from "./benassi/loja/saga";
import Comprador from "./benassi/comprador/saga";
import GrupoProduto from "./benassi/grupoProduto/saga";
import FamiliaProduto from "./benassi/familiaProduto/saga";
import Vendedor from "./benassi/vendedor/saga";
import Parceiros from "./capitalRelacional/parceiros/saga";
import Relacao from "./capitalRelacional/relacao/saga";

import Gerais from "./capitalRelacional/gerais/saga";
// Btflex
import PedidosDeVendas from "./btflex/pedidosDeVendas/saga";
import CreditoDeImposto from "./btflex/creditoDeImposto/saga";
import SaldoDePedidosDeCompras from "./btflex/saldoDePedidosDeCompras/saga";

//Capital relacional
import GraficoReviravoltaAia from "./capitalRelacional/graficoReviravoltaAia/saga";

import NuvemPalavras from "./capitalRelacional/nuvemPalavras/saga";
import IcrRelacoesCamadas from "./capitalRelacional/icrRelacoesCamadas/saga";

import Respondentes from "./capitalRelacional/respondentes/saga";
import Agora from "./capitalRelacional/agora/saga";
import EvolucaoRelacoes from "./capitalRelacional/evolucaoRelacoes/saga";
import EvolucaoSuplicas from "./capitalRelacional/evolucaoSuplicas/saga";
import IcrVisaoSuplicas from "./capitalRelacional/icrVisaoSuplicas/saga";


export default function* rootSaga() {
  yield all([
    LayoutSaga(),
    User(),
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    NegociosUnidades(),
    RankingProdutos(),
    Menus(),
    Ambiente(),
    RaioXLoja(),
    LojasAoVivo(),
    Diario(),
    GraficoPorHoraLojas(),
    ReaLina(),
    IndicadoresVendaEstagios(),
    ListagemPedidosEstagios(),
    Vendedores(),
    DesempenhoVendedor(),
    GraficoUnidades(),
    TabuleiroNegocios(),
    TopSujeitos(),
    TopProdutos(),
    TopFamilias(),
    CoposContribuicao(),
    GraficoCachoeira(),
    GraficoArvore(),
    GraficoEvolucaoDespesas(),
    Xpto(),
    PainelReposicaoLojas(),
    PainelReposicaoProdutos(),
    CinemaResultados(),
    ProjecaoResultados(),
    CinemaResultadosDetalhes(),
    ddlFilmesCinema(),
    SaldosContas(),
    GridVendasUnidadesDiarias(),
    FluxoCaixa(),
    GridMargemFilial(),
    GridMargemProduto(),
    GridMargemNegocio(),
    Filial(),
    Negocio(),
    Rede(),
    Loja(),
    Comprador(),
    GrupoProduto(),
    FamiliaProduto(),
    Vendedor(),
    Parceiros(),
    Relacao(),
    Gerais(),
    PedidosDeVendas(),
    CreditoDeImposto(),
    SaldoDePedidosDeCompras(),
    GraficoReviravoltaAia(),
    NuvemPalavras(),
    IcrRelacoesCamadas(),
    Respondentes(),
    Agora(),
    EvolucaoRelacoes(),
    EvolucaoSuplicas(),
    IcrVisaoSuplicas()
  ]);
}
