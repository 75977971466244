import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import MapaNegociosUnidades from "./mapaNegociosUnidades/reducer";
import RankingProdutos from "./rankingProdutos/reducer";
import Menus from "./menus/reducer";
import Ambiente from "./ambiente/reducer";
import RaioXLoja from "./raioXLoja/reducer";
import ReaLina from "./reaLina/reducer";
import Diario from "./diario/reducer";
import GraficoPorHoraLojas from "./graficoHoraLojas/reducer";
import GraficoUnidades from "./graficoUnidades/reducer";
import User from "./auth/user/reducer";
import IndicadoresVendaEstagios from "./btflex/indicadoresVendaEstagios/reducer";
import ListagemPedidosEstagios from "./btflex/listagemPedidosEstagios/reducer";
import Vendedores from "./btflex/vendedores/reducer";
import desempenhoVendedor from "./btflex/desempenhoVendedor/reducer";
import TabuleiroNegocios from "./tabuleiroNegocios/reducer";
import TopSujeitos from "./topSujeitos/reducer";
import TopProdutos from "./topProdutos/reducer";
import TopFamilias from "./topFamilias/reducer";
import LojasAoVivo from "./lojasAoVivo/reducer";
import CoposContribuicao from "./coposContribuicao/reducer";
import GraficoCachoeira from "./graficoCachoeira/reducer";
import GraficoArvore from "./graficoArvore/reducer";
import GraficoEvolucaoDespesas from "./graficoEvolucaoDespesas/reducer";
import Xpto from "./xpto/reducer";
import PainelReposicaoLojas from "./painelReposicaoLojas/reducer";
import PainelReposicaoProdutos from "./painelReposicaoProdutos/reducer";
import CinemaResultados from "./cinemaResultados/reducer";
import ProjecaoResultados from "./projecaoResultados/reducer";
import CinemaResultadosDetalhes from "./cinemaResultadosDetalhes/reducer";
import DdlFilmesCinema from "./ddlFilmesCinema/reducer";
import SaldosContas from "./saldosContas/reducer";
import GridVendasUnidadesDiarias from "./gridVendasUnidadesDiarias/reducer";
import FluxoCaixa from "./fluxoCaixa/reducer";
import GridMargemFilial from "./benassi/gridMargemFilial/reducer";
import GridMargemProduto from "./benassi/gridMargemProduto/reducer";
import GridMargemNegocio from "./benassi/gridMargemNegocio/reducer";
import Filial from "./benassi/filial/reducer";
import Negocio from "./benassi/negocio/reducer";
import Rede from "./benassi/rede/reducer";
import Loja from "./benassi/loja/reducer";
import Comprador from "./benassi/comprador/reducer";
import GrupoProduto from "./benassi/grupoProduto/reducer";
import FamiliaProduto from "./benassi/familiaProduto/reducer";
import Vendedor from "./benassi/vendedor/reducer";
import Parceiros from "./capitalRelacional/parceiros/reducer";
import Relacao from "./capitalRelacional/relacao/reducer";
import Gerais from "./capitalRelacional/gerais/reducer";
import PedidosDeVendas from "./btflex/pedidosDeVendas/reducer";
import CreditoDeImposto from "./btflex/creditoDeImposto/reducer";
import SaldoDePedidosDeCompras from "./btflex/saldoDePedidosDeCompras/reducer";
import NuvemPalavras from "./capitalRelacional/nuvemPalavras/reducer";
import IcrRelacoesCamadas from "./capitalRelacional/icrRelacoesCamadas/reducer";

//Capital Relacional
import GraficoReviravoltaAia from "./capitalRelacional/graficoReviravoltaAia/reducer";

import Respondentes from "./capitalRelacional/respondentes/reducer";
import Agora from "./capitalRelacional/agora/reducer";
import EvolucaoRelacoes from "./capitalRelacional/evolucaoRelacoes/reducer";
import EvolucaoSuplicas from "./capitalRelacional/evolucaoSuplicas/reducer";
import IcrVisaoSuplicas from "./capitalRelacional/icrVisaoSuplicas/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  User,
  ForgetPassword,
  Profile,
  MapaNegociosUnidades,
  RankingProdutos,
  Menus,
  Ambiente,
  RaioXLoja,
  LojasAoVivo,
  Diario,
  GraficoPorHoraLojas,
  ReaLina,
  IndicadoresVendaEstagios,
  ListagemPedidosEstagios,
  Vendedores,
  desempenhoVendedor,
  GraficoUnidades,
  TabuleiroNegocios,
  TopSujeitos,
  TopFamilias,
  TopProdutos,
  CoposContribuicao,
  GraficoCachoeira,
  GraficoArvore,
  GraficoEvolucaoDespesas,
  Xpto,
  PainelReposicaoLojas,
  PainelReposicaoProdutos,
  CinemaResultados,
  ProjecaoResultados,
  CinemaResultadosDetalhes,
  DdlFilmesCinema,
  SaldosContas,
  GridVendasUnidadesDiarias,
  FluxoCaixa,
  GridMargemFilial,
  GridMargemProduto,
  GridMargemNegocio,
  Filial,
  Negocio,
  Rede,
  Loja,
  Comprador,
  GrupoProduto,
  FamiliaProduto,
  Vendedor,
  Parceiros,
  Relacao,
  Gerais,
  PedidosDeVendas,
  CreditoDeImposto,
  SaldoDePedidosDeCompras,
  GraficoReviravoltaAia,
  NuvemPalavras,
  IcrRelacoesCamadas,
  Respondentes,
  Agora,
  EvolucaoRelacoes,
  EvolucaoSuplicas,
  IcrVisaoSuplicas
});

export default rootReducer;
