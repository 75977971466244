import "../../helpers/locale/locale_BR";
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual } from "../../helpers/utils";
import {
  bizGridResponsive
} from "../../helpers/biz-padrao-visual";


const gridConfig = (dados, grid) => {
  let totalFaturamento = 0;
  const larguraColunaComputador = 200;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  const minLarguraDescricoes = 180;
  const minLarguraValores = 100;
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      data: dados.data,
    },
    columns: [
      {
        text: "#",
        type: "rownumber",
        filterable: false,
        responsiveLevels: hideMedium
      },
      {
        text: "#",
        type: "rownumber",
        filterable: false,
        locked: true,
        responsiveLevels: showMedium
      },
      {
        text: dados.header.p_ean,
        field: "p_ean",
        sum: () => {
          return "Total"
        },
        minWidth: 120
      },
      {
        text: dados.header.descricao,
        field: "p_descricao",
        flex: 2,
        minWidth: 300,
        responsiveLevels: hideMedium
      },
      {
        text: dados.header.descricao,
        field: "p_descricao",
        sum: true,
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 220,
        locked: true,
        responsiveLevels: showMedium
      },
      {
        text: dados.header.p_grade_v01_descricao,
        field: "p_grade_v01_descricao",
        flex: 1,
        minWidth: minLarguraDescricoes
      },
      {
        text: dados.header.p_grade_01_descricao,
        field: "p_grade_01_descricao",
        flex: 1,
        minWidth: minLarguraDescricoes
      },
      {
        text: dados.header.p_grade_02_descricao,
        field: "p_grade_02_descricao",
        flex: 1,
        minWidth: minLarguraDescricoes
      },
      {
        text: dados.header.p_grade_03_descricao,
        field: "p_grade_03_descricao",
        flex: 1,
        minWidth: minLarguraDescricoes
      },
      {
        align: "right",
        text: dados.header.quantidade,
        field: "quantidade",
        type: "number",
        sum: true,
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(valorEmInteiro(sum), dados.header.quantidade);
        },
        renderer: ({ value, cellElement, record }) => {
          return `${valorEmInteiro(value)} ${record.unidade_medida_id}` 
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.faturamento,
        field: "faturamento",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          totalFaturamento = sum;
          return formatarFooterTitulo(sum == 0 ? "-" : toMoney(sum, 2), dados.header.faturamento);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.participacao, toMoney(value, 2))
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.impostos_valor,
        field: "impostos_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarColunaPercentualValor(retornarPercentual(totalFaturamento, sum), sum), dados.header.impostos_valor);

        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.impostos_percentual, value);
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.taxas_financeiras_valor,
        field: "taxas_financeiras_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.taxas_financeiras_percentual, value);
        },
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarColunaPercentualValor(retornarPercentual(totalFaturamento, sum), sum), dados.header.taxas_financeiras_valor);
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.cmv_percentual, value);
        },
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarColunaPercentualValor(retornarPercentual(totalFaturamento, sum), sum), dados.header.cmv_valor);
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem_contribuicao_valor,
        field: "margem_contribuicao_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.margem_contribuicao_percentual, value);
        },
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarColunaPercentualValor(retornarPercentual(totalFaturamento, sum), sum), dados.header.margem_contribuicao_valor);
        },
        flex: 1,
        minWidth: minLarguraValores,
        filterable: false
      }
    ]
  };
};

export { gridConfig };
