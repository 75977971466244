import React, { useLayoutEffect, useRef } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../../helpers/utils-amcharts"
import { toMoney, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../../helpers/utils-amcharts";
import { insertIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;

const Grafico = ({ dados, index }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            var opacidade_padrao = 1
            var vermelho = "#f67e7d";

            am4core.useTheme(am4themes_animated);

            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chartReferencia.current = chart;

            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
            definirLocalizacaoAmCharts(chart);
            chart.data = [{
                category: "Faturamento bruto",
                value: dados.footer.faturamento,
                open: 0,
                stepValue: dados.footer.faturamento,
                color: "#93e1d8",
                displayValue: dados.footer.faturamento,
                percentual: "",
                opacidade: opacidade_padrao
            }, {
                category: dados.header.impostos_valor,
                value: dados.footer.faturamento - dados.footer.impostos_valor,
                open: dados.footer.faturamento,
                stepValue: dados.footer.faturamento - dados.footer.impostos_valor,
                color: vermelho,
                displayValue: dados.footer.impostos_valor,
                percentual: toMoney(dados.footer.impostos_percentual, 1) + "%",
                opacidade: opacidade_padrao
            }, {
                category: dados.header.devolucao_total_valor,
                value: dados.footer.faturamento - dados.footer.impostos_valor - dados.footer.devolucao_total_valor,
                open: dados.footer.faturamento - dados.footer.impostos_valor,
                stepValue: dados.footer.faturamento - dados.footer.impostos_valor - dados.footer.devolucao_total_valor,
                color: vermelho,
                displayValue: dados.footer.devolucao_total_valor,
                percentual: toMoney(dados.footer.devolucao_total_percentual, 1) + "%",
                opacidade: opacidade_padrao

            },
            {
                category: dados.header.faturamento_liquido,
                value: dados.footer.faturamento_liquido,
                open: 0,
                stepValue: dados.footer.faturamento_liquido,
                color: "#9fe6dd",
                displayValue: dados.footer.faturamento_liquido,
                percentual: toMoney(dados.footer.faturamento_liquido_percentual, 1) + "%",
                opacidade: opacidade_padrao
            },
            {
                category: dados.header.cmv_valor,
                value: dados.footer.faturamento - dados.footer.impostos_valor - dados.footer.taxas_financeiras_valor - dados.footer.cmv_valor,
                open: dados.footer.faturamento - dados.footer.impostos_valor - dados.footer.taxas_financeiras_valor,
                stepValue: dados.footer.faturamento - dados.footer.impostos_valor - dados.footer.taxas_financeiras_valor - dados.footer.cmv_valor,
                color: vermelho,
                displayValue: dados.footer.cmv_valor,
                percentual: toMoney(dados.footer.cmv_percentual, 1) + "%",
                opacidade: opacidade_padrao

            },
            {
                category: dados.header.margem_contribuicao_valor,
                value: dados.footer.margem_contribuicao_valor,
                open: 0,
                stepValue: dados.footer.margem_contribuicao_valor,
                color: "#acebe2",
                displayValue: dados.footer.margem_contribuicao_valor,
                percentual: toMoney(dados.footer.margem_contribuicao_percentual, 1) + "%",
                opacidade: opacidade_padrao

            },
            // {
            //     category: dados.header.nao_vendas,
            //     value: dados.footer.margem_contribuicao_valor - dados.footer.nao_vendas,
            //     open: dados.footer.margem_contribuicao_valor,
            //     stepValue: dados.footer.margem_contribuicao_valor - dados.footer.nao_vendas,
            //     color: vermelho,
            //     displayValue: dados.footer.nao_vendas,
            //     percentual: toMoney(dados.footer.nao_vendas_percentual, 1) + "%",
            //     opacidade: opacidade_padrao

            // },
            // {
            //     category: dados.header.margem_ii,
            //     value: dados.footer.margem_ii,
            //     open: 0,
            //     stepValue: dados.footer.margem_ii,
            //     color: "#b8f0e8",
            //     displayValue: dados.footer.margem_ii,
            //     percentual: toMoney(dados.footer.margem_ii_percentual, 1) + "%",
            //     opacidade: opacidade_padrao

            // },
            // {
            //     category: dados.header.despesas_unidade,
            //     value: dados.footer.margem_ii - dados.footer.despesas_unidade,
            //     open: dados.footer.margem_ii,
            //     stepValue: dados.footer.margem_ii - dados.footer.despesas_unidade,
            //     color: vermelho,
            //     displayValue: dados.footer.despesas_unidade,
            //     percentual: toMoney(dados.footer.despesas_unidade_percentual, 1) + "%",
            //     opacidade: opacidade_padrao

            // },
            // {
            //     category: dados.header.margem_iii,
            //     value: dados.footer.margem_iii,
            //     open: 0,
            //     stepValue: dados.footer.margem_iii,
            //     color: "#c4f5ed",
            //     displayValue: dados.footer.margem_iii,
            //     percentual: toMoney(dados.footer.margem_iii_percentual, 1) + "%",
            //     opacidade: opacidade_padrao

            // }
            ];

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.grid.template.stroke = am4core.color("gray");

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.renderer.grid.template.stroke = am4core.color("gray");
            valueAxis.strictMinMax = true;


            var columnSeries = chart.series.push(new am4charts.ColumnSeries());
            columnSeries.dataFields.categoryX = "category";
            columnSeries.dataFields.valueY = "value";
            columnSeries.dataFields.openValueY = "open";
            columnSeries.sequencedInterpolation = true;
            columnSeries.interpolationDuration = 1500;
            columnSeries.columns.template.column.cornerRadiusTopLeft = 10;
            columnSeries.columns.template.column.cornerRadiusTopRight = 10;

            var columnTemplate = columnSeries.columns.template;
            columnTemplate.strokeOpacity = 0;
            columnTemplate.propertyFields.fill = "color";
            columnSeries.columns.template.column.propertyFields.fillOpacity = "opacidade";

            var label = columnTemplate.createChild(am4core.Label);
            label.text = `[bold][font-size: 20px]{percentual}[/][/] \n R$ {displayValue.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            label.align = "center";
            label.valign = "top";
            label.dy = 15;
            label.fontSize = 15;
            label.fill = am4core.color("#fff");
            label.background.fill = am4core.color("gray");
            label.background.fillOpacity = 0.5;
            label.paddingRight = 5;
            label.paddingLeft = 5;
            label.paddingTop = 3;
            label.truncate = false;
            label.hideOversized = false;


            var stepSeries = chart.series.push(new am4charts.StepLineSeries());
            stepSeries.dataFields.categoryX = "category";
            stepSeries.dataFields.valueY = "stepValue";
            stepSeries.noRisers = true;
            stepSeries.stroke = am4core.color("gray").lighten(.3);

            stepSeries.strokeWidth = 0.5;
            stepSeries.interpolationDuration = 2000;
            stepSeries.sequencedInterpolation = true;

            stepSeries.startLocation = 0.1;
            stepSeries.endLocation = 1.1;

            return () => {
                chart.dispose();
            };
        }

    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id={index} style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default Grafico