import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { API } from "../../config/index"

const dadosApi = (token, mes, ano, lojas = "") => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                mes: mes,
                ano: ano,
                lojas: lojas
            }),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/indicadoresDoMes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}
function loadProgramasAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApi(action.token, action.mes, action.ano, action.lojas)
            .then(result => {
                let { data, header, footer } = result.ResultadoObjeto;
                const newArray = data.map((item) => {
                    let newItem = { ...item }
                    const { children } = item;
                    const newChildren = children.map((child) => {
                        let n = { ...child }
                        return n
                    })
                    newItem.children = newChildren;
                    newItem.cls = "pavel";
                    newItem.expanded = true;
                    return newItem;
                })
                return resolve({ dados: newArray, header, footer });
            })
            .catch(e => reject(e))
    })
}

function* carregarDadosMapaDeNegocios(action) {
    try {
        const dados = yield call(loadProgramasAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosMapaDeNegocios)
}


function* listarMapaDeNegocios() {
    yield all(
        [
            fork(assistindoRequisicao)
        ]
    )
}

export default listarMapaDeNegocios