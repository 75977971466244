import React, { useLayoutEffect, useRef, useState } from 'react';

//Import Breadcrumb

import PaginaLoader from '../../components/loader/pagina';
import Header from "../../components/HorizontalLayout/HeaderApp";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ModalFiltro from '../../components/filtroModal/modalFiltro'

am4core.useTheme(am4themes_animated);

const Amcharts  = () => {
    const chart = useRef(null);

    const [modalFiltro, setModalFiltro] = useState(false);
    const toggleModalFiltro = ()=> setModalFiltro(!modalFiltro);

    useLayoutEffect(() => {
      let x = am4core.create("chartdiv", am4charts.XYChart);
      x.paddingRight = 20;
      let data = [];
      let visits = 10;
  
      for (let i = 1; i < 366; i++) {
        visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
      }
  
      x.data = data;
  
      let dateAxis = x.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
  
      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
  
      let series = x.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      x.cursor = new am4charts.XYCursor();
  
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      x.scrollbarX = scrollbarX;
      chart.current = x;
      return () => {
        x.dispose();
      };
    }, []);
    return (
            <React.Fragment>
                <PaginaLoader carregando={false} />
                <ModalFiltro aberto={modalFiltro} toggleModal={toggleModalFiltro} />
                <Header position={true} titulo="amCharts" abrirModal={toggleModalFiltro} />
                <div id="chartdiv" style={{ width: "100%", height: "calc(100vh)" }}></div>
            </React.Fragment>
          );
    }
        
export default Amcharts;