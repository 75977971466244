import "../../../helpers/locale/locale_BR";
import { formatarFooterTituloLinhas, formatarFooterTituloLinhasInvertida, formatarFooterMultiplasLinhas, formatarFooterPercentualValorUnicaLinha,
         formatarColunaMultiplasLinhas, formatarColunaPercentualValorUnicaLinha, showMedium, hideMedium, toMoney } from "../../../helpers/utils";
import { bizGridResponsive } from "../../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { urlApiPainelReposicaoProdutos } from "../../../helpers/api";
import { setasParaCimaOuParaBaixoComparacao } from "../../../helpers/biz-padrao-visual";

const larguraColuna = 130;

class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento_liquido: 0,
    });
    this.set({
      faturamento_liquido: linha.faturamento_liquido
    })
  }
}

function seta(perc, percDesafio) {
  if ((perc > 0) && (percDesafio > 0) && (perc != percDesafio)) {
    if (perc > percDesafio) {
      return 1;
    }
    else {
      return -1;
    }
  }
  else {
    return 0;
  }
}

const gridConfigBenassi = (dados, token) => {
  const larguraColunaComputador = 110;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } };

  return {
    modelClass: GridLinha,
    responsiveLevels: bizGridResponsive,
    store: {
      rede_id: null,
      lojas_id: null,
      grupos_id: null,
      familias_id: null,
      data_inicial: null,
      data_final: null,
      visualizacao: null,
      origem: null,
      tipo_cmv: null,
      tree: true,
      readUrl: urlApiPainelReposicaoProdutos(),
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.rede_id = request.rede_id;
            this.lojas_id = request.lojas_id;
            this.grupos_id = request.grupos_id;
            this.familias_id = request.familias_id;
            this.data_inicial = request.data_inicial;
            this.data_final = request.data_final;
            this.visualizacao = request.visualizacao;
            this.origem = request.origem;
            this.tipo_cmv = request.tipo_cmv;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data && event.json.ResultadoObjeto.data.length) {
              dados.header = event.json.ResultadoObjeto.header;
              dados.footer = event.json.ResultadoObjeto.footer;
              //this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          var visualizacao = event.params.id ? this.getById(event.params.id).data.visualizacao : "";
          var filtro_produto = event.params.id ? this.getById(event.params.id).data.filtro_produto : "";

          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              rede_id: this.rede_id,
              lojas_id: this.lojas_id,
              grupos_id: this.grupos_id,
              familias_id: this.familias_id,
              data_inicial: this.data_inicial,
              data_final: this.data_final,
              tipo_cmv: this.tipo_cmv,
              visualizacao: visualizacao,
              origem: this.origem,
              inicializar: "0",
              filtro_produto: filtro_produto
            })
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 200,
        responsiveLevels: hideMedium,
        filterable: false
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 200,
        locked: true,
        responsiveLevels: showMedium,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.faturamento_liquido,
        field: "faturamento_liquido",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(0, toMoney(dados.footer.valor_emissao), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(dados.footer.faturamento_emissao_perc, toMoney(dados.footer.faturamento_liquido), "", false, true, true, false)
              ], false),
            dados.header.faturamento_liquido,
            seta(dados.footer.faturamento_liquido, dados.footer.valor_emissao)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.valor_emissao > 0) && (record.faturamento_liquido > 0)) {
            setasParaCimaOuParaBaixoComparacao(
              record.faturamento_liquido,
              record.valor_emissao,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(0, toMoney(record.valor_emissao), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(record.faturamento_emissao_perc, toMoney(record.faturamento_liquido), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasInvertida(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha(dados.footer.cmv_valor_perc, toMoney(dados.footer.cmv_valor), "", false, true, true, false)
              ], false),
            dados.header.cmv_valor,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha(record.cmv_valor_perc, toMoney(record.cmv_valor), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem,
        field: "margem",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha(dados.footer.margem_perc, toMoney(dados.footer.margem), "", false, true, true, false)
              ], false),
            dados.header.margem,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha(record.margem_perc, toMoney(record.margem), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.quantidade,
        field: "quantidade",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(0, toMoney(dados.footer.quantidade_emissao), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(dados.footer.quantidade_perc, toMoney(dados.footer.quantidade), "", false, true, true, false)
              ], false),
            dados.header.quantidade,
            seta(dados.footer.quantidade, dados.footer.quantidade_emissao)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          if ((record.quantidade_emissao > 0) && (record.quantidade > 0)) {
            setasParaCimaOuParaBaixoComparacao(
              record.quantidade,
              record.quantidade_emissao,
              cellElement
            );
          }
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(0, toMoney(record.quantidade_emissao), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(record.quantidade_perc, toMoney(record.quantidade), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.entradas,
        field: "entradas",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha("", toMoney(dados.footer.entradas, 2), "", false, true, true, false)
              ], false),
            dados.header.entradas,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha("", toMoney(record.entradas, 2), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.saidas,
        field: "saidas",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha("", toMoney(dados.footer.saidas, 2), "", false, true, true, false)
              ], false),
            dados.header.saidas,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha("", toMoney(record.saidas, 2), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.estoque,
        field: "estoque",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha("", toMoney(dados.footer.estoque, 2), "", false, true, true, false)
              ], false),
            dados.header.estoque,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha("", toMoney(record.estoque, 2), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.custo_estoque,
        field: "custo_estoque",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                "",
                formatarFooterPercentualValorUnicaLinha("", toMoney(dados.footer.custo_estoque, 2), "", false, true, true, false)
              ], false),
            dados.header.custo_estoque,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaMultiplasLinhas(
            [
              "",
              formatarColunaPercentualValorUnicaLinha("", toMoney(record.custo_estoque, 2), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
    ]
  };
};

export { gridConfigBenassi };
