import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/respondentes/actions";
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form, Input } from "reactstrap";
import "@bryntum/grid/grid.stockholm.css";
import "../../../App.scss";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from "react-select";
import FocarPaisagem from "../../../components/forcarPaisagem";
import BoaConversa from "../boaConversa";

const GridAgentesHolograma = (props) => {
  //Funcionalidades da tela
  const dispatch = useDispatch();
  const { history, match } = props;
  const grid = useRef(null);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);

  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [agente, setAgente] = useState(null);

  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);

  const token = useSelector((state) => state.Login.user.tokenNOL);

  //Filtros
  const { dados, carregando } = useSelector((state) => state.Respondentes);

  const [carregandoPagina, setCarregandoPagina] = useState(false);
  const tela = useSelector((state) => state.Gerais);
  const [respondenteSelecionando, setRespondenteSelecionado] = useState(null);
  const [submitHabilitado, setSubmitHabilitado] = useState(false);

  const atualizarDados = async (
    idEmpresa,
    idParceiro,
    idCliente,
    idPeriodoResposta,
    idsAgentesHolograma,
    idCamadaOpcao
  ) => {
    setCarregandoPagina(true);
    await dispatch(
      requisitarDados(
        {
          token,
          idEmpresa,
          idParceiro,
          idCliente,
          idPeriodoResposta,
          idsAgentesHolograma,
          idCamadaOpcao
        }
      )
    );
    setMostrarFiltros(false);
    setTimeout(() => {
      setCarregandoPagina(false);
      setSubmitHabilitado(true);
    }, 3000)

  };


  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);
    if (!agente) setAgente(tela.agentes[0]);
    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length && tela.agentes?.length) {
      setSubmitHabilitado(true);
    }
  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
    dispatch(
      requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value)
    );
  }, [cliente]);

  useEffect(() => {
    if (!agente || agente.length <= 0) return;
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        agente.value
      )
    );
  }, [agente]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setAgente([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);
      setCarregandoAgente(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente.value));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setPeriodo([]);
    setAgente([]);
    setCarregandoPeriodo(true);
    setCarregandoAgente(true);

    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        idCliente,
        agente.value
      )
    );
  };

  useEffect(() => {
    atualizarDadosAmbiente();

    return () => { dispatch(requisitarLimparDados()); }
  }, []);

  const redirecionarBoaConversa = (respondente) => {
    setRespondenteSelecionado(respondente);
  }

  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(dados, redirecionarBoaConversa);
  }, [dados]);

  return (
    <React.Fragment>
      <FocarPaisagem />
      {!respondenteSelecionando &&
        <div className={"capitalRelacional"}>
          <PaginaLoader carregando={tela.carregando || carregandoPagina} />
          <>
            <Header
              parametros={{
                botoesExtras: [
                  {
                    funcao: () => {
                      setMostrarFiltros(!mostrarFiltros);
                    },
                    icone: "bx bx bx-filter-alt",
                  },
                ],
              }}
              position={true}
              titulo="Mapa de negócios"
            />
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Período"
                      className="basic-single"
                      isLoading={carregandoPeriodo}
                      value={periodo}
                      options={tela.periodos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setPeriodo(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Todas as relações"
                      className="basic-single"
                      isLoading={carregandoAgente}
                      options={tela.agentes}
                      isMulti
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setAgente(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      setSubmitHabilitado(false);
                      const agentesFiltro =
                        agente.length > 0
                          ? agente.map((item) => item.value).join("|;") + "|;"
                          : "";

                      atualizarDados(
                        "001",
                        parceiro.value,
                        cliente.value,
                        periodo.value,
                        agentesFiltro,
                        ""
                      );
                    }}
                    className="ml-2"
                    disabled={!submitHabilitado}
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>
            {dados && (
              <BryntumGrid
                ref={grid}
                {...gridInfo}
                treeFeature={false}
                columnReorderFeature={false}
                cellEditFeature={false}
                sortFeature={false}
                key={carregando}
                cellMenuFeature={{
                  disabled: true,
                }}
                filterBarFeature={{
                  compactMode: true,
                }}
                headerMenuFeature={{
                  items: {
                    hideColumn: false,
                  },
                }}
              />
            )}
          </>
        </div>
      }
      {respondenteSelecionando &&
        <BoaConversa respondente={respondenteSelecionando} setRespondenteSelecionado={setRespondenteSelecionado} />
      }
    </React.Fragment >
  );
};
export default GridAgentesHolograma;
