import React from "react";
import "../../../helpers/locale/locale_BR";
import { GridRowModel, StringHelper } from "@bryntum/grid/grid.umd.js";
import { API } from "../../../config/index";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { retornarGraficoCapitalRelacional } from "../../../helpers/utils-amcharts";
import ReactEcharts from "echarts-for-react";

import {
  formatarColunaTresValores,
  toMoney,
  valorEmMoeda,
  valorEmInteiro,
} from "../../../helpers/utils";
import {
  setasParaCimaOuParaBaixoComparacao,
  bizGridResponsive,
  setaSumarioComparacao,
  tamanhoDafonte,
  setarBgColor,
  setarFontColor,
  setarFontBold,
  retornarIndicadorRedondo,
  tamanhoDafontePixel
} from "../../../helpers/biz-padrao-visual";
import { dadosApi } from "../../../store/raioXLoja/saga";
const larguraSuplica = 130;
//Largura da coluna da visualização no computador
const larguraColunaComputador = 300;
//Largura de todas as colunas
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      descricao: 0,
    });
    this.set({
      descricao: linha.descricao,
    });
  }
}
const getOption = (valor) => {
  return {

    toolbox: {
      feature: {
        //restore: {},
        //saveAsImage: {},
      },
    },
    series: [
      {
        type: "gauge",
        legendHoverLink: false,
        min: -100,
        max: 100,
        detail: false,
        axisLabel: false,
        splitLine: false,
        radius: "100%",
        silent: true,
        axisLine: {
          lineStyle: {
            color: [
              [0.25, "#f46a6a"],
              [0.5, "#f6d32b"],
              [0.75, "#f1b44c"],
              [1, "#34c38f"],
            ],
            width: 10,
            type: 'solid'
          },
        },
        data: [{ value: valor, name: "" }],
      },
    ],
  }
}
const gerarGrafico = (id, valor) => {

  // Create root element
  // https://www.amcharts.com/docs/v5/getting-started/#Root_element
  var root = am5.Root.new(id);

  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([
    am5themes_Animated.new(root)
  ]);

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/radar-chart/
  var chart = root.container.children.push(
    am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      startAngle: 180,
      endAngle: 360
    })
  );

  // Create axis and its renderer
  // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
  var axisRenderer = am5radar.AxisRendererCircular.new(root, {
    innerRadius: -1,
    strokeOpacity: 1,
    strokeWidth: 5,
    strokeGradient: am5.LinearGradient.new(root, {
      rotation: 0,
      stops: [
        { color: am5.color(0xfb7116) },
        { color: am5.color(0xf6d32b) },
        { color: am5.color(0xf4fb16) },
        { color: am5.color(0x19d228) }

      ]
    })
  });

  axisRenderer.labels.template.setAll({
    visible: false
  });

  var xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: -100,
      max: 100,
      strictMinMax: true,
      renderer: axisRenderer
    })
  );

  // Add clock hand
  // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
  var axisDataItem = xAxis.makeDataItem({});
  axisDataItem.set("value", 0);


  var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
    sprite: am5radar.ClockHand.new(root, {
      radius: am5.percent(99),
      pinRadius: am5.percent(4),
      bottomWidth: 2
    })
  }));

  xAxis.createAxisRange(axisDataItem);

  axisDataItem.get("grid").set("visible", false);

  axisDataItem.animate({
    key: "value",
    to: valor,
    duration: 800,
    easing: am5.ease.out(am5.ease.cubic)
  });

  // Make stuff animate on load
  chart.appear(1000, 100);
}



const gridConfig = (dados, grid, token) => {
  const formatarHeader = (valor) => {

    if (valor == "Conquiste a minha confiança!")
      return "CONQUISTE<BR />A MINHA<BR />CONFIANÇA!";

    return valor.replace(" ", "<br />");
  }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      idEmpresa: "001",
      idParceiro: null,
      idCliente: null,
      idPeriodoResposta: null,
      visualizacao: "relacao",
      tree: false,
      modelClass: GridLinha,
      readUrl: `${API}/api/pesquisas/retornarICRRelacoesCamadas`,
      fetchOptions: {
        method: "POST",
        redirect: "follow",
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: "responseMeta.data",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      listeners: {
        metaChange: function (store, meta) {

          console.log(store, meta);
        },
        change() {

          // Will catch change event from the text field
          // const celulasGrafico = document.querySelectorAll(".grafico_suplica");
          // celulasGrafico.forEach((elemento) => {
          //   if (!elemento.innerHTML) {
          //     elemento.style.height = "80px";
          //     elemento.style.width = "100%";
          //     gerarGrafico(elemento.id, elemento.getAttribute("valor"));
          //   }
          // });
        },
        afterRequest(event) {
          // var nivel_atual = event.params.id
          //   ? this.getById(event.params.id).childLevel + 1
          //   : 0;
          // if (nivel_atual == 0 && !event.json.Message) {
          //   var request = JSON.parse(event.source.fetchOptions.body);
          //   this.mes = request.mes;
          //   this.ano = request.ano;
          //   this.idEmpresa = request.idEmpresa;
          //   this.idParceiro = request.idParceiro;
          //   this.idCliente = request.idCliente;
          //   this.idPeriodoResposta = request.idPeriodoResposta;
          //   this.visualizacao = "relacao";
          // }
        },
        exception(response) {
          console.log(response);
        },

        beforeRequest(event) {
          var nivel_atual = event.params.id
            ? this.getById(event.params.id).childLevel + 1
            : 0;
          if (event.params.id) {
            const data = this.getById(event.params.id).data;
            if (nivel_atual === 1) {
              event.source.fetchOptions.body = JSON.stringify({
                idParceiro: this.idParceiro,
                idCliente: this.idCliente,
                idPeriodoResposta: this.idPeriodoResposta,
                idAgenteHolograma: data.icr_id_agente_holograma,
                visualizacao: "camada"
              });
            } else if (nivel_atual === 2) {
              event.source.fetchOptions.body = JSON.stringify({
                idParceiro: this.idParceiro,
                idCliente: this.idCliente,
                idPeriodoResposta: this.idPeriodoResposta,
                idAgenteHolograma: data.icr_id_agente_holograma,
                idCamada: data.icr_id_camada,
                visualizacao: "opcao"
              });
            }
          }
        },
      },
    },
    columns: [
      {
        text: dados.header.icr_descricao,
        field: "icr_descricao",
        flex: 1,
        minWidth: 120,
        minHeight: 100,
        autoHeight: true,
        cls: "coluna1",
        // type: "tree",
      },

      {
        text: "ICR",
        field: "relacao_icr",
        width: 100,
        autoHeight: true,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          cellElement.classList.add(`p-0`)
          return `
                  <div class="w-100">
                    <div class="w-100 text-center font-weight-bolder p-0 text-white bg-${record.relacao_cor}" style="font-size:45px;height:70px;">${value}</div>
                  </div>`;
        }
      },
      {
        text: "Engajamento",
        field: "pesquisas_feitas",
        width: 110,
        autoHeight: true,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          const cor =
            record.pesquisas_feitas_indicador === "vermelho"
              ? "text-danger"
              : record.pesquisas_feitas_indicador === "verde"
                ? "text-success"
                : "text-warning";

          return `
          <div>
            <div class="w-100 text-center font-weight-bolder p-0 ${cor}" style="font-size:30px;height:40px;"><i class="fad fa-circle"></i></div>
            <div class="w-100 text-center">${record.pesquisas_feitas}</div>
            <div class="w-100 text-center" style="font-size:10px;margin-top: -7px;opacity: 0.6;">${record.pesquisas_feitas > 1 ? "repondentes" : "respondente"}</div>
          </div>`;
        }
      },
      {
        text: dados.header.suplica_1_nome,
        field: "suplica_1_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_2_nome,
        field: "suplica_2_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_2_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_3_nome,
        field: "suplica_3_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_3_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_4_nome,
        field: "suplica_4_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_4_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_5_nome,
        field: "suplica_5_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_5_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_6_nome,
        field: "suplica_6_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_6_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_7_nome,
        field: "suplica_7_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_7_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.header.suplica_8_nome,
        field: "suplica_8_resultado",
        width: larguraSuplica,
        align: "center",
        autoHeight: true,

        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          retornarGraficoCapitalRelacional(value, cellElement, record);
          //return `<div id="${record.id}_suplica_8_resultado" valor="${value}" class="grafico_suplica"></div>`;
          // return <ReactEcharts style={{ height: "100px", width: "100%", marginTop: "15px" }} option={getOption(value)} />;

        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      }





      // {
      //   text: dados.header.suplica_2_nome,
      //   field: "suplica_2_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   autoHeight: true,

      //   htmlEncode: false,
      //   filterable: false,
      //   // renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
      // {
      //   text: dados.header.suplica_3_nome,
      //   field: "suplica_3_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   autoHeight: true,

      //   htmlEncode: false,
      //   filterable: false,
      //   renderer({ value, cellElement, record }) {
      //     // setarBgColor(cellElement, record.suplica_2_cor);
      //     // cellElement.id = record.id;
      //     return ``;
      //   }
      // },
      // {
      //   text: dados.header.suplica_4_nome,
      //   field: "suplica_4_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   autoHeight: true,
      //   htmlEncode: false,
      //   filterable: false,
      //   // renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
      // {
      //   text: dados.header.suplica_5_nome,
      //   field: "suplica_5_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   autoHeight: true,
      //   htmlEncode: false,
      //   filterable: false,
      //   // renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
      // {
      //   text: dados.header.suplica_6_nome,
      //   field: "suplica_6_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   htmlEncode: false,
      //   autoHeight: true,
      //   filterable: false,
      //   //renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
      // {
      //   text: dados.header.suplica_7_nome,
      //   field: "suplica_7_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   htmlEncode: false,
      //   autoHeight: true,
      //   filterable: false,
      //   // renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
      // {
      //   text: dados.header.suplica_8_nome,
      //   field: "suplica_8_resultado",
      //   width: larguraSuplica,
      //   align: "center",
      //   htmlEncode: false,
      //   filterable: false,
      //   // renderer({ value, cellElement, record }) {
      //   //   // setarBgColor(cellElement, record.suplica_2_cor);
      //   //   // cellElement.id = record.id;
      //   //   return ``;
      //   // }
      // },
    ],
  };
};
export { gridConfig };
