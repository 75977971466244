
import React from "react"
import { Container, Row, CardBody, Col, Card } from "reactstrap"

const GraficoAltura = ({ children }) => {
    return (
        <Container fluid className="fixWith graficoprincipal">
            <Row>
                <Col>
                    <Card className="mb-0">
                        <CardBody className="cardbodygrafico pw-0 pb-0">
                            {children}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default GraficoAltura

