import "../../../helpers/locale/locale_BR";
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import {
  bizGridResponsive
} from "../../../helpers/biz-padrao-visual";
import { API } from "../../../config/index";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      saldo_final: 0,
    });
    this.set({
      saldo_final: linha.saldo_final
    })
  }
}

const gridConfig = (dados, grid, token) => {
  let visualizacao = "";
  let classeN1 = "";
  let u_filial_id = "";

  const larguraColunaComputador = 200;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      ano_inicial: "",
      mes_inicial: "",
      ano_final: "",
      mes_final: "",
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/varejo/ws_movimentacao_contas`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.ano_inicial = request.ano_inicial;
            this.mes_inicial = request.mes_inicial;
            this.ano_final = request.ano_final;
            this.mes_final = request.mes_final;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
              dados.footer = event.json.ResultadoObjeto.footer;
              if (event.json.ResultadoObjeto.data[0])
                this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }

        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          var tipo_conta_id = event.params.id ? this.getById(event.params.id).data.tipo_conta_id : "";
          var banco_id = event.params.id ? this.getById(event.params.id).data.banco_id : "";
          switch (nivel_atual) {
            case 1:
              visualizacao = "banco";
              break;
            case 2:
              visualizacao = "conta";
              break;
          }
          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              ano_inicial: this.ano_inicial,
              mes_inicial: this.mes_inicial,
              ano_final: this.ano_final,
              mes_final: this.mes_final,
              tipo_conta_id,
              visualizacao,
              agrupamento: "mensal",
              arvore: 3,
              nivelExpandido: 0,
              classeN1: "",
              sob_demanda: 1,
              nivel_atual,
              banco_id
            })
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 120,
        responsiveLevels: hideMedium,
        filterable: false
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 120,
        locked: true,
        responsiveLevels: showMedium,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.saldo_inicial,
        field: "saldo_inicial",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.saldo_inicial, false, 2), dados.header.saldo_inicial);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.entradas,
        field: "entradas",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.entradas, false, 2), dados.header.entradas);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.saidas,
        field: "saidas",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.saidas, false, 2), dados.header.saidas);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.resultado,
        field: "resultado",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.resultado, false, 2), dados.header.resultado);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.saldo_final,
        field: "saldo_final",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.saldo_final, false, 2), dados.header.saldo_final);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
    ]
  };
};

export { gridConfig };
