import React, { useEffect, useState } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';  // Alterada esta linha
import { setUserAction } from "../../store/auth/login/actions";
import { useDispatch } from "react-redux";
import gerarToken from "../../helpers/gerarToken";

const Authmiddleware = ({ component: Component, layout: Layout, path }) => {
  const [userLogIn, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  const authUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();  // Alterada esta linha também
      if (!user) {
        setUser(false);
        setLoading(false);
        return;
      }

      const userAttributes = user.attributes || user.signInUserSession?.idToken?.payload;
      const email = userAttributes?.email;
      
      const tokenNOL = await gerarToken(email);

      setUser(user);
      dispatch(setUserAction({ ...user, tokenNOL }));
    } catch (error) {
      console.error("Auth error:", error);
      setUser(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    authUser();
  }, []);

  if (loading) return null;

  if (!userLogIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default Authmiddleware;