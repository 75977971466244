import * as types from "./types"

export const requisitarDados = (token, lojas, dataInicial, dataFinal) => {
	return{
		type: types.REQUISITAR_DADOS,
		payload: {token, lojas, dataInicial, dataFinal}
	}
};

export const requisicaoBemSucedida = (dados) => ({
	type:  types.REQUISITAR_DADOS_SUCESSO,
	payload: dados
});


export const requisicaoFalhou = (error) => ({
	type: types.REQUISITAR_DADOS_FALHOU,
	payload: error
});

export const limparDados = () =>({
	type: types.REQUISITAR_DADOS_LIMPAR,
	payload: null
})

export const limparDadosReq = () =>({
	type: types.REQUISITAR_DADOS_LIMPAR_REQ,
	payload: null
})