import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import * as types from "./types";
import { _handleError } from "../../errorHandler";
import * as actions from "./actions";
import { API } from "../../../config/index";

const dadosApi = (token, params) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let obj = { ...params };
    delete obj.apiUrl;
    myHeaders.append("Authorization", "Bearer " + token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
      redirect: "follow",
    };
    fetch(`${API}/api/${params.apiUrl}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result.ResultadoObjeto);
      })
      .catch((error) => reject(error));
  });
};

function* carregar(action) {
  try {
    const parceiros = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      apiUrl: "vw_parceiros_json",
    });
    const clientes = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: parceiros[0].value,
      apiUrl: "vw_clientes_json",
    });
    const periodos = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: parceiros[0].value,
      idCliente: clientes[0].value,
      apiUrl: "vw_periodos_respostas_json",
    });

    const agentes = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: parceiros[0].value,
      idCliente: clientes[0].value,
      apiUrl: "vw_agentes_holograma_cliente_json",
    });
    const camadas = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: parceiros[0].value,
      idCliente: clientes[0].value,
      idAgenteHolograma: agentes[0].value,
      apiUrl: "vw_camadas_json",
    });
    yield put(
      actions.requisicaoBemSucedida({
        parceiros,
        clientes,
        periodos,
        agentes,
        camadas,
      })
    );
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* atualizarCamadas(action) {
  try {
    const camadas = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: action.payload.idParceiro,
      idCliente: action.payload.idCliente,
      idAgenteHolograma: action.payload.idAgenteHolograma,
      apiUrl: "vw_camadas_json",
    });
    yield put(
      actions.requisitarAtualizarListaCamadasSucesso({
        camadas,
      })
    );
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* atualizarPeridos(action) {
  try {
    const periodos = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: action.payload.idParceiro,
      idCliente: action.payload.idCliente,
      apiUrl: "vw_periodos_respostas_json",
    });
    yield put(
      actions.requisitarAtualizarListaPeridoSucesso({
        periodos,
      })
    );
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* atualizarAgentes(action) {
  try {
    const agentes = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: action.payload.idParceiro,
      idCliente: action.payload.idCliente,
      apiUrl: "vw_agentes_holograma_cliente_json",
    });

    yield put(
      actions.requisitarAtualizarListaAgentesSucesso({
        agentes,
      })
    );
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* atualizarListaCliente(action) {
  try {
    const clientes = yield call(dadosApi, action.payload.token, {
      idEmpresa: "001",
      idParceiro: action.payload.idEmpresa,
      apiUrl: "vw_clientes_json",
    });

    yield put(
      actions.requisitarAtualizarListaClientesSucesso({
        clientes,
      })
    );
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* assistindoRequisicao() {
  yield takeEvery(types.REQUISITAR_DADOS, carregar);
}

function* assistindoRequisicaoAtualizarClientes() {
  yield takeEvery(types.ATUALIZAR_LISTA_CLIENTES, atualizarListaCliente);
}

function* assistirAtualizarPeriodos() {
  yield takeEvery(types.ATUALIZAR_LISTA_PERIODO, atualizarPeridos);
}

function* assistirAtualizarAgentes() {
  yield takeEvery(types.ATUALIZAR_LISTA_AGENTES, atualizarAgentes);
}

function* asistirAtualizarCamadas() {
  yield takeEvery(types.ATUALIZAR_LISTA_CAMADAS, atualizarCamadas);
}

function* assistindoCarregamento() {
  yield all([
    fork(assistindoRequisicao),
    fork(assistindoRequisicaoAtualizarClientes),
    fork(assistirAtualizarPeriodos),
    fork(assistirAtualizarAgentes),
    fork(asistirAtualizarCamadas),
  ]);
}

export default assistindoCarregamento;
