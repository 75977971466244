import React from "react"
import { Col, Row } from "reactstrap"
const HeaderAuth = ({titulo, subtitulo, imagem}) =>{
    return(
        <div className="bg-primary bg-soft">
        <Row>
            <Col className="col-7 col-sm-7">
                <div className="text-primary p-3 pb-4 pb-md-4 p-md-4">
                    <h5 className="text-primary">{titulo}</h5>
                    <p>{subtitulo}</p>
                </div>
            </Col>
            <Col className="col-5 col-sm-5 align-self-end">
                <img src={imagem} alt="" className="img-fluid" />
            </Col>
        </Row>
    </div>
    )
}

export default HeaderAuth