import React, { useEffect, useCallback, useState, useRef } from 'react';
import { concatenaLojas, Hoje, toMoney, toDate, primeiroDiaMes, toDateSQL, Mes, Ano, valorEmMoeda, valorEmInteiro, removerFocusNoBotao } from "../../helpers/utils"
import PaginaLoader from '../../components/loader/pagina';
import gerarToken from '../../helpers/gerarToken'
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/graficoArvore/actions"
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Header from "../../components/HorizontalLayout/HeaderApp"
import { Card, CardBody, FormGroup, Button, Form, Container, Row, Col } from 'reactstrap';
import Grafico from "./partes/grafico"
import makeAnimated from 'react-select/animated';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import { pegarCores } from '../../helpers/utils';
import HeaderGrafico from '../../components/headerGrafico';
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import GraficoAltura from '../../components/graficoAltura';
import Select from 'react-select'
const animatedComponents = makeAnimated();


const GraficoArvore = (props) => {
  const carregandoMesAtual = useSelector(state => state.MapaNegociosUnidades.carregando);
  const { dados, carregando } = useSelector(state => state.GraficoArvore);
  const [cores, seTcores] = useState(pegarCores());
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const dispatch = useDispatch();
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);


  const dataInicialRef = useRef(null);
  dataInicialRef.current = { dataInicial, setDataInicial };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };


  const atualizarDados = async () => {
    if (!dataInicialRef.current.dataInicial || !dataFinalRef.current.dataFinal) return

    const token = await gerarToken();

    await dispatch(requisitarDados({
      data_inicial: toDateSQL(dataInicialRef.current.dataInicial),
      data_final: toDateSQL(dataFinalRef.current.dataFinal),
      arvore: 3,
      visualizacao: "familia",
      agrupamento: "total",
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      token
    }
    ));
  }

  const atualizarDadosAmbiente = async () => {
    const token = await gerarToken();
    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  useEffect(() => {
    setMostrarTotais(!carregando && (dados && dados.data && dados.data.length));
  }, [carregando, dados])

  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }

  return (
    <React.Fragment>
      <PaginaLoader carregando={carregando || carregandoMesAtual} />
      <>
        <Header
          parametros={
            {
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroCasca>
              <Form inline>
                <FormGroup className="select2-container" >
                  <div className="unidadesControler">
                    <Select
                      placeholder="Todas as unidades"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      options={lojasDoUsuario}
                      isSearchable={false}
                      isMulti
                      classNamePrefix="select2-selection"
                      defaultValue={lojas || []}
                      onChange={Object => {
                        trocarLojas(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup style={{ width: 110 }} className="ml-2">
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: dataInicial,
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataInicial(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <div className={"ml-1 mr-1"} >a</div>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: "today",
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataFinal(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-rabbit-fast mr-1"></i>
                    vai...
                  </Button>
                </FormGroup>
              </Form>
            </FiltroCasca>
            {/* <Container className="fixWith" fluid>
                  {
                  <HeaderGrafico mostrar={mostrarTotais} colunas={3} itens={[
                  { nome: dados?.header?.cupons, 
                    valor: valorEmInteiro(dados?.footer?.cupons), 
                    marginRight: 30, 
                    corClass: "text-info" 
                  },
                  { nome: dados?.header?.faturamento, 
                    valor: `R$ ${toMoney(dados?.footer?.faturamento, 0)}`,
                    marginRight: 30, 
                    corClass: "text-success" 
                  },
                  {
                    nome: dados?.header?.cmv_valor, 
                    valor: `${toMoney(dados?.footer?.cmv_percentual, 1)}%`, 
                    segundoValor: `${valorEmInteiro(dados?.footer?.cmv_valor, false)}`,
                    corClass: "text-danger" 
                  },
                  { 
                    nome: dados?.header?.margem_contribuicao_valor, 
                    valor: `${toMoney(dados?.footer?.margem_contribuicao_percentual, 1)}%`, 
                    segundoValor: `${valorEmInteiro(dados?.footer?.margem_contribuicao_valor, false)}`,
                    corClass:"text-dark" },
                ]} />}
                </Container> */}
          </div>



          <GraficoAltura>
            <Grafico index={"chartdiv"} dados={dados} cores={cores} />
          </GraficoAltura>


        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoArvore;