import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/painelReposicaoLojas/actions"
import { gridConfigBenassi } from './AppConfigBenassi';
import { FormGroup, Button, Form } from 'reactstrap';
import { toDateSQL, Ontem, Mes, removerFocusNoBotao, primeiroDiaMes, ArrayToMultiSelect, retornarTipoCustos } from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import Select from 'react-select';
import GridModal from './modal/gridModal';
import Vazio from '../../components/vazio';
import FocarPaisagem from '../../components/forcarPaisagem';
import { requisitarDadosRede } from "../../store/benassi/rede/actions";
const tiposCusto = retornarTipoCustos();

const PainelReposicaoLojas = (props) => {
    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.PainelReposicaoLojas);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Ontem());
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[1])
    const redes = useSelector(state => state.Rede.dados);

    const [rede, setRede] = useState("");
    const [redeSelecionada, setRedeSelecionada] = useState([]);

    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);
    const [parametrosModal, setParametrosModal] = useState(null);
    const token = useSelector(state => state.Login.user.tokenNOL);

    const atualizarDados = async () => {
        await dispatch(requisitarDadosRede(token, { u_unidades_id: "", u_negocios_id: "05|;" }));

        await dispatch(requisitarDados({
            rede_id: rede,
            data_inicial: toDateSQL(dataInicial),
            data_final: toDateSQL(dataFinal),
            visualizacao: "rede",
            tipo_cmv: tipoCusto.value,
            token
        }));

        setMostrarFiltros(false);
    }

    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token, "gerenciais"));
    }

    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();

        return () => dispatch(requisitarLimparDados())
    }, []);

    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const ocultarModal = () => {
        setParametrosModal({ ...parametrosModal, mostarModal: false })
    }

    const atualizarDadosDemanda = () => {
        setmensagemDadosNaoEncontrados(false);
        setMostrarFiltros(false);
        setLoading(true);

        if (grid.current) {
            document.getElementById(grid.current.instance.id).style.display = "flex";
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                rede_id: rede,
                data_inicial: toDateSQL(dataInicial),
                data_final: toDateSQL(dataFinal),
                visualizacao: "rede",
                tipo_cmv: tipoCusto.value
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    setLoading(false);
                    document.getElementById(grid.current.instance.id).style.display = "flex";

                    if (!resolve.data.length) {
                        document.getElementById(grid.current.instance.id).style.display = "none";
                        setMostrarFiltros(true);
                        setmensagemDadosNaoEncontrados(true);
                    } else {
                        grid.current.instance.on('cellDblClick', (event) => {

                            if (event.record.possui_detalhes) {
                                const filtro = {
                                    rede_id: event.record.rede_id,
                                    data_inicial: toDateSQL(dataInicial),
                                    data_final: toDateSQL(dataFinal),
                                    lojas_id: event.record.loja_id + "|;",
                                    visualizacao: "grupo",
                                    origem: "loja",
                                    inicializar: "1",
                                    tipo_cmv: tipoCusto.value,
                                    token
                                };
                                setParametrosModal({ ...event.record.originalData, filtro, mostarModal: true });
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const trocarTipoCustos = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto("")
        }
    }

    const trocarRedes = (valor) => {
        if (valor) {
            setRedeSelecionada(valor || []);
            setRede(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
        } else {
            setRedeSelecionada([]);
            setRede("");
        }
    }

    const adicionarParametrosModal = (parametros) => {
        let unidades = [];

        setParametrosModal(parametros);
    }

    const gridInfo = useMemo(() => {
        if (!dados) {
            return;
        }
        else {
            return gridConfigBenassi(dados, grid, token, adicionarParametrosModal);
        }
    }, [dados])

    return (
        <React.Fragment>
            <FocarPaisagem />
            {parametrosModal?.mostarModal && <GridModal propriedades={parametrosModal} setParametrosModal={setParametrosModal} />}

            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    }
                    position={true}
                    titulo="Painel de reposição das lojas"
                />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup>
                            <div className="unidadesControler">
                                <Select
                                    placeholder="Todas as redes"
                                    className="basic-single"
                                    isClearable={true}
                                    options={redes}
                                    classNamePrefix="select2-selection"
                                    isSearchable={true}
                                    value={redeSelecionada || null}
                                    onChange={Object => {
                                        trocarRedes(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2" style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: dataInicial,
                                    disableMobile: "true",
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataInicial(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <div className={"ml-1 mr-1"}>a</div>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: dataFinal,
                                    disableMobile: "true",
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataFinal(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={tiposCusto}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposCusto[1] || []}
                                    onChange={Object => {
                                        trocarTipoCustos(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {mensagemDadosNaoEncontrados && <Vazio />}
                {dados &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={true}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />}

            </>
        </React.Fragment >
    );
}
export default PainelReposicaoLojas;