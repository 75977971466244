import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais, retornarTipoCustos, exportarExcel, concatenaLojas, Hoje, primeiroDiaMes, toDateSQL, Mes, valorEmMoeda, valorEmInteiro, removerFocusNoBotao, toMoney } from "../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../helpers/utils-amcharts";
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/reaLina/actions";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import HeaderGrafico from '../../components/headerGrafico';
import HeaderGraficoBenassi from '../../components/headerGraficoBenassi';
import Header from "../../components/HorizontalLayout/HeaderApp";
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import Grafico from "./partes/grafico";
import GraficoBenassi from "./partes/grafico_benassi";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import FocarPaisagem from '../../components/forcarPaisagem';
import cliente from '../../cliente';
import { retornarVisualizacao, retornaExportacaoExcel } from './partes/variaveis';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FiltroBrytum from "../../components/filtroBrytum";
import { retornarIdCliente } from '../../cliente/variaveis';

const formatacaoAmCharts = retornarFormatacaoAmCharts();
const idCliente = retornarIdCliente();
const tipoGrafico = "faturamento";
const formatoNumeroGrafico = formatacaoAmCharts.formatoValores.decimal0Casas;
const visualizacao = retornarVisualizacao();
const tiposCusto = retornarTipoCustos();
const ReaLina = () => {
  const [cor, setCor] = useState("");
  const [simboloGrafico, setSimboloGrafico] = useState(" R$");
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const { dados, carregando } = useSelector(state => state.ReaLina);
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const [lojasFiltradas, setLojasFiltradas] = useState([]);
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[1]);
  const [propTipoCusto, setPropTipoCusto] = useState(tiposCusto[1].value == "02" ? "02_" : "");
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const lojasRef = useRef(null);
  
  lojasRef.current = { lojas, setLojas };

  const lojasFiltradasRef = useRef(null);
  lojasFiltradasRef.current = { lojasFiltradas, setLojasFiltradas };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const atualizarDados = async () => {
    if (!dataFinalRef.current.dataFinal) return
    
    await dispatch(requisitarDados({
      data_inicial: toDateSQL(dataInicial),
      data_final: toDateSQL(dataFinal),
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      arvore: idCliente == "benassi" ? 3 : 2,
      visualizacao,
      agrupamento: "total",
      exportacao: retornaExportacaoExcel(tipoCusto.value),
      token,
      retornaMC3: idCliente == "benassi" ? 1 : "",
      retorna_desafio: idCliente == "benassi" ? 1 : "",
      base_calculo_percentual: idCliente == "benassi" ? "bruto" : "",
      tipo_cmv: idCliente == "benassi" ? tipoCusto.value == "01" ? "medio" : "gerencial" : null,
      base_calculo_percentual:  idCliente == "benassi" ? "fixa" : ""
    }
    ));
    setMostrarFiltros(false);
  }

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  useEffect(() => {
    if (dados) {
      setPropTipoCusto(tipoCusto.value == "02" ? "02_" : "")
    }
  }, [dados]);

  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }

  const atualizarLojas = () => {
    atualizarDados();
    setLojasFiltradas(lojasRef.current.lojas);
  }

  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }

  useLayoutEffect(() => {
    var objeto = idCliente == "benassi" ? document.querySelector("#chartdiv_realizado") : document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />

      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />

        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
              <FormGroup className="select2-container"  >
                  <div className="unidadesControler">
                    <Select
                      placeholder="Todas as unidades"
                      className="basic-single"
                      options={lojasDoUsuario}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      isMulti
                      defaultValue={lojas || []}
                      onChange={Object => {
                        trocarLojas(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="ml-2" style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: dataInicial,
                      disableMobile: "true"

                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataInicial(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <div className={"ml-1 mr-1"} >a</div>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: "today",
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataFinal(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                {idCliente == "benassi" &&
                  <FormGroup className="ml-2">
                    <div className="unidadesControler">
                      <Select
                        placeholder=""
                        className="basic-single"
                        options={tiposCusto}
                        isSearchable={false}
                        classNamePrefix="select2-selection"
                        defaultValue={tiposCusto[1] || []}
                        onChange={Object => {
                          trocarTipoCusto(Object || []);
                        }}
                      />
                    </div>
                  </FormGroup>
                }
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarLojas(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>

            <Container fluid className="fixWith">
              {(idCliente == "real" || idCliente == "volo") &&
                <div className={"headers mt-4"}>
                  <HeaderGrafico mostrar={mostrarTotais} colunas={2} itens={[
                    {
                      nome: "Período",
                      valor: dados?.header?.periodo_data,
                      corClass: ""
                    },
                    {
                      nome: dados?.header?.ticket_medio,
                      valor: "R$ " + toMoney(dados?.footer?.ticket_medio, 2),
                      corClass: "text-warning"
                    },
                    {
                      nome: dados?.header?.cupons,
                      valor: valorEmInteiro(dados?.footer?.cupons),
                      corClass: "text-info"
                    },
                    {
                      nome: dados?.header?.faturamento,
                      valor: `R$ ${valorEmInteiro(dados?.footer?.faturamento, true)}`,
                      corClass: "text-success"

                    },
                    {
                      nome: dados?.header?.cmv_valor,
                      valor: `${toMoney(dados?.footer?.cmv_percentual, 1)}%`,
                      segundoValor: `${valorEmInteiro(dados?.footer?.cmv_valor, false)}`,
                      corClass: "text-danger"
                    },
                    {
                      nome: dados?.header?.margem_contribuicao_valor,
                      valor: `${toMoney(dados?.footer?.margem_contribuicao_percentual, 1)}%`,
                      segundoValor: `${valorEmInteiro(dados?.footer?.margem_contribuicao_valor, false)}`,
                      corClass: "text-darker"
                    },
                  ]} />

                </div>
              }

              {idCliente == "benassi" &&
                <div className={"headers mt-4"}>

                  <HeaderGraficoBenassi mostrar={mostrarTotais} colunas={2} itens={[
                    {
                      nome: "Período",
                      valor: dados?.header?.periodo_data,
                      periodo: true,
                      corClass: ""
                    },
                    // {
                    //   valor: dados?.footer.faturamento,
                    //   nome: dados?.header?.faturamento,
                    //   realizado_percentual: dados?.footer.faturamento_percentual == 0 ? `-` : `${toMoney(dados?.footer.faturamento_percentual, 1)}%`,
                    //   necessario_percentual: dados?.footer?.desafio_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_percentual, 1)}%`,
                    //   realizado_valor: dados?.footer?.faturamento == 0 ? `-` : `${valorEmInteiro(dados?.footer.faturamento, false)}`,
                    //   necessario_valor: dados?.footer?.desafio_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_valor, 0)}`,
                    //   variacao: dados?.footer?.desafio_variacao_percentual,
                    //   corClass: "text-info",
                    //   periodo: false,
                    //   faturamento: false
                    // },
                    {
                      valor: dados?.footer.faturamento_liquido,
                      nome: dados?.header?.faturamento_liquido,
                      realizado_percentual: dados?.footer.faturamento_liquido_percentual == 0 ? `-` : `${toMoney(dados?.footer.faturamento_liquido_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.faturamento_liquido == 0 ? `-` : `${valorEmInteiro(dados?.footer.faturamento_liquido, false)}`,
                      necessario_valor: dados?.footer?.desafio_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_valor, 0)}`,
                      variacao: dados?.footer?.desafio_variacao_percentual,
                      corClass: "text-info",
                      periodo: false,
                      faturamento: false
                    },
                    {
                      valor: dados?.footer.cmv_valor,
                      nome: dados?.header?.cmv_valor,
                      realizado_percentual: dados?.footer.cmv_percentual == 0 ? `-` : `${toMoney(dados?.footer.cmv_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_cmv_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_cmv_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.cmv_valor == 0 ? `-` : `${valorEmInteiro(dados?.footer.cmv_valor, false)}`,
                      necessario_valor: dados?.footer?.desafio_cmv_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_cmv_valor, 0)}`,
                      variacao: dados?.footer?.desafio_cmv_variacao_percentual,
                      corClass: "text-danger",
                      periodo: false,
                      faturamento: false
                    },
                    {
                      valor: dados?.footer.margem_contribuicao_valor,
                      nome: dados?.header?.margem_contribuicao_valor,
                      realizado_percentual: dados?.footer.margem_contribuicao_percentual == 0 ? `-` : `${toMoney(dados?.footer.margem_contribuicao_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_margem_i_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_margem_i_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.margem_contribuicao_valor == 0 ? `-` : `${valorEmInteiro(dados?.footer.margem_contribuicao_valor, false)}`,
                      necessario_valor: dados?.footer?.desafio_margem_i_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_margem_i_valor, 0)}`,
                      variacao: dados?.footer?.desafio_margem_i_variacao_percentual,
                      corClass: "text-success",
                      periodo: false,
                      faturamento: false
                    },
                    {
                      valor: dados?.footer.despesas_unidade,
                      nome: dados?.header?.despesas_unidade,
                      realizado_percentual: dados?.footer.despesas_unidade_percentual == 0 ? `-` : `${toMoney(dados?.footer.despesas_unidade_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_despesa_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_despesa_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.despesas_unidade == 0 ? `-` : `${valorEmInteiro(dados?.footer.despesas_unidade, false)}`,
                      necessario_valor: dados?.footer?.desafio_despesa_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_despesa_valor, 0)}`,
                      variacao: dados?.footer?.desafio_despesa_variacao_percentual,
                      corClass: "text-danger",
                      periodo: false,
                      faturamento: false
                    },
                    {
                      valor: dados?.footer.nao_vendas,
                      nome: dados?.header?.nao_vendas,
                      realizado_percentual: dados?.footer.nao_vendas_percentual == 0 ? `-` : `${toMoney(dados?.footer.nao_vendas_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_nao_vendas_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_nao_vendas_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.nao_vendas == 0 ? `-` : `${valorEmInteiro(dados?.footer.nao_vendas, false)}`,
                      necessario_valor: dados?.footer?.desafio_nao_vendas_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_nao_vendas_valor, 0)}`,
                      variacao: dados?.footer?.desafio_nao_vendas_variacao_percentual,
                      corClass: "text-danger",
                      periodo: false,
                      faturamento: false
                    },
                    {
                      valor: dados?.footer.margem_ii,
                      nome: dados?.header?.margem_ii,
                      realizado_percentual: dados?.footer.margem_ii_percentual == 0 ? `-` : `${toMoney(dados?.footer.margem_ii_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_margem_ii_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_margem_ii_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.margem_ii == 0 ? `-` : `${valorEmInteiro(dados?.footer.margem_ii, false)}`,
                      necessario_valor: dados?.footer?.desafio_margem_ii_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_margem_ii_valor, 0)}`,
                      variacao: dados?.footer?.desafio_margem_ii_variacao_percentual,
                      corClass: "text-success",
                      periodo: false,
                      faturamento: false
                    }
                  ]} />

                </div>
              }

            </Container>
          </div>
          <Container fluid className="fixWith graficoprincipal">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <Row>
                      {/* <Col sm={6} className="d-md-flex justify-content-md-end">
                        {idCliente == "benassi" && <GraficoBenassi tipoGrafico={"desafio_valor"} tipoGraficoPercentual={"desafio_percentual"} dados={dados} comparativo={"Necessário"} id={"chartdiv_necessario"} formatoNumeroGrafico={formatoNumeroGrafico} tipoCusto={tipoCusto.value} mostrarFiltros={mostrarFiltros} />}
                      </Col> */}
                      <Col>
                        {(idCliente == "real" || idCliente == "volo") && <Grafico cor={cor?.trim()} formatoNumeroGrafico={formatoNumeroGrafico} tipoGrafico={tipoGrafico} dados={dados} simbolo={simboloGrafico} id={"chartdiv"} />}

                        {idCliente == "benassi" && <GraficoBenassi tipoGrafico={"faturamento_liquido"} tipoGraficoPercentual={"participacao_liquido"} dados={dados} comparativo={"Realizado"} id={"chartdiv_realizado"} formatoNumeroGrafico={formatoNumeroGrafico} tipoCusto={tipoCusto.value} />}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>

    </React.Fragment>
  );
}
export default ReaLina;