import { API } from "../config/index"
export const dadosApi = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_vendas_unidades`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiProdutos = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_vendas_produtos`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiFluxoCaixa = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_fluxo_caixa`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiMovimentacaoContas = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_movimentacao_contas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}


export const dadosApiCinemaResultados = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_cinema_resultado`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiProjecaoResultados = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_projecao_resultados`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiCinemaResultadosDetalhes = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_cinema_resultado_detalhes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiFinanceiro = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_financeiro`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiPainelReposicaoLojas = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_painel_reposicao_lojas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiPainelReposicaoProdutos = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_painel_reposicao_produtos`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiListaRespondentes = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarListaRespondentes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiListaAgora = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarDadosAgora`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiListaEvolucaoRelacoes = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarICREvolucaoRelacoes`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiListaEvolucaoSuplicas = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarICREvolucaoSuplicas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiNuvemPalavras = (parametros) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarDadosNuvem`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiIcrRelacoesCamadas = (parametros) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarICRRelacoesCamadas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiIcrVisaoSuplicas = (parametros) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/pesquisas/retornarICRVisaoSuplicas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const vw_camadas_negocio_json = (parametros) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/vw_camadas_negocio_json`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result.ResultadoObjeto);
            })
            .catch(error => reject(error));
    })
}


export const consultarDdlCuidadores = (parametros) => {

    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ddl_cuidadores`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result.ResultadoObjeto);
            })
            .catch(error => reject(error));
    })
}


export const dadosApiDdlGrade01 = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ddl_grade_01`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

export const dadosApiEstruturaCusto = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ws_estrutura_custo`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}


export const urlApiFinanceiro = () => {
    return `${API}/api/varejo/ws_financeiro`;
}

export const urlApiPainelReposicaoLojas = () => {
    return `${API}/api/varejo/ws_painel_reposicao_lojas`;
}

export const urlApiPainelReposicaoProdutos = () => {
    return `${API}/api/varejo/ws_painel_reposicao_produtos`;
}
