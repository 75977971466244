import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { toMoney, replaceAll } from "../../helpers/utils"

const HeaderGraficoBenassi = ({ colunas, itens, marginLeft = 0, marginRight = 0, mostrar, }) => {
    return (
        <>
            <div style={{ marginLeft: marginLeft, marginRight: marginRight }}>
                <Row className="totalizadores">

                    {itens.map((item, i) => {
                        return (
                            // <Col sm={6} md={4} lg={colunas} key={`headerMaps_${i}`}>
                            <Col key={`headerMaps_${i}`}>
                                <Card>
                                    {item.periodo && <CardBody>
                                        <p className="text-muted mb-2 mb-lg-3 text-truncate">
                                            <i className={`mdi mdi-circle font-size-10 mr-1 ${item.corClass || "text-light"} align-middle mb-0 me-3`}></i>
                                            {item.nome ? item.nome : " "}
                                        </p>

                                        <div>
                                            <div className="row justify-content-space-between">
                                                <div className={`col-12 text-left pr-0`}>
                                                    <p className="mb-1 font-size-16 pt-2 text-truncate">
                                                        <strong>{item.valor || ""}</strong>
                                                    </p>
                                                    <p className="font-size-14 mb-1 mt-1 text-truncate text-white"><strong><strong>{item.valor || ""}</strong></strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>}
                                    {!item.periodo && <CardBody>
                                        <p className="text-muted mb-2 mb-lg-3 text-truncate">
                                            <i className={`mdi mdi-circle font-size-10 mr-1 ${item.corClass || "text-light"} align-middle mb-0 me-3`}></i>
                                            {item.nome ? item.nome : " "}
                                        </p>

                                        <div className={"d-flex align-items-center"}>
                                            <div className={`mr-2`} style={{ fontWeight: 900, fontSize: "20px", width: "30px" }}>
                                                {/* {
                                                    item.variacao == 0 &&
                                                    <div className={"fas fa-arrows-h text-gray"}></div>
                                                } */}
                                                {
                                                    item.variacao > 0 &&
                                                    <i className={"fas fa-arrow-up text-success"}></i>
                                                }
                                                {
                                                    item.variacao < 0 &&
                                                    <div className={"fas fa-arrow-down text-warning"}></div>
                                                }
                                            </div>
                                            <div className="w-100">
                                                <div className="row justify-content-space-between">
                                                    {!item.faturamento && <div className="col-8 text-left pr-0">
                                                        <p className="font-size-14 mb-1 pt-1 text-truncate" style={{ color: "#999a9b" }}>{item.necessario_valor}</p>
                                                        <p className="mb-1 font-size-16 pt-1 text-truncate" style={{ color: "#000" }}>
                                                            <strong>{item.realizado_valor}</strong>
                                                            {/* {!item.faturamento && <span className={`ml-2 mt-0 badge badge-soft-${item.variacao > 0 ? "success" : item.variacao < 0 ? "danger" : "warning"} font-size-12`}>
                                                                {item.variacao}
                                                            </span>} */}
                                                        </p>
                                                    </div>}
                                                    <div className={`${item.faturamento ? "col-12" : "col-4"} text-left pr-0`} >
                                                        <div>
                                                            <p className="font-size-14 mb-1 pt-1 text-truncate" style={{ color: "#999a9b" }}>{item.necessario_percentual}</p>
                                                            <p className="mb-1 font-size-16 pt-1 text-truncate" style={{ color: "#000" }}><strong>{item.realizado_percentual}</strong>
                                                                {/* {item.faturamento && item.variacao && <span className={`ml-2 align-middle badge badge-soft-${item.variacao > 0 ? "success" : item.variacao < 0 ? "danger" : "warning"} font-size-12`}>
                                                                    {item.variacao}
                                                                </span>} */}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </CardBody>}
                                </Card>
                            </Col>
                        )

                    })}
                </Row>
            </div>
        </>
    )
}
export default HeaderGraficoBenassi