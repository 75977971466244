import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../../store/tabuleiroNegocios/actions";
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { retornarTipoCustosBTFlex, Hoje, Mes, removerFocusNoBotao, primeiroDiaMes, toDateSQL, exportarExcel } from "../../../helpers/utils";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../../components/filtroBrytum";
import FocarPaisagem from '../../../components/forcarPaisagem';
import { retornarIdCliente } from '../../../cliente/variaveis';
import Select from 'react-select';

const idCliente = retornarIdCliente();

const TabuleiroNegociosBTFlex = (props) => {
    const tiposCusto = retornarTipoCustosBTFlex();
    const { dados, carregando } = useSelector(state => state.TabuleiroNegocios);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
    const token = useSelector(state => state.Login.user.tokenNOL);

    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            data_inicial: toDateSQL(dataInicial),
            data_final: toDateSQL(dataFinal),
            arvore: 3,
            nivelExpandido: 1,
            classeN1: "pavel",
            visualizacao: "zoom",
            agrupamento: "total",
            tipo_cmv: tipoCusto.value,
            token
        }));
        setMostrarFiltros(false);
    }
    const destruirDados = () => {
        dispatch(requisitarLimparDados())
    }
    useEffect(() => {
        atualizarDados();
        return () => {
            destruirDados()
        }
    }, []);

    const gridInfo = useMemo(() => {
        if (!dados) return

        return gridConfig(dados, grid);


    }, [dados, grid])

    //if (!dados) return <PaginaLoader carregando={true} />
    const trocarTipoCusto = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto([])
        }
    }
    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={carregando} />

            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel(dados),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: dataInicial
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataInicial(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <div className={"ml-1 mr-1"} >a</div>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: "today"
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataFinal(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={tiposCusto}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposCusto[0] || []}
                                    onChange={Object => {
                                        trocarTipoCusto(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} >
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    key={carregando}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />
            </>
        </React.Fragment>
    );
}
export default TabuleiroNegociosBTFlex;