import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withTranslation } from 'react-i18next';
import brasil from "../../../assets/images/flags/brasil.jpg";

const LanguageDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [flag, setFlag] = useState(brasil);
  const [lng, setLng] = useState("");

  function changeLanguageAction(lng) {
    //set language as i18n
    i18n.changeLanguage(lng);

    if(lng === "sp") {
      setFlag(brasil); 
      setLng('Spanish');
    }    
  }
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          tag="button"
        >
          <img
            src={flag}
            alt="Skote"
            height="16"
            className="mr-1"
          />
          <span className="align-middle">{lng}</span>
        </DropdownToggle>
      </Dropdown>
    </React.Fragment>
  );
}

export default withTranslation()(LanguageDropdown);