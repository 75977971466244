import "../../../helpers/locale/locale_BR";
import { formatarFooterTituloLinhasDespesas, formatarFooterTituloLinhas, formatarFooterMultiplasLinhas, formatarFooterPercentualValorUnicaLinha, formatarColunaMultiplasLinhas, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValorUnicaLinha, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import {
  bizGridResponsive
} from "../../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { API } from "../../../config/index";
import { setasParaCimaOuParaBaixoComparacao, setasParaCimaOuParaBaixoComparacaoDespesas } from "../../../helpers/biz-padrao-visual";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento_liquido: 0,
    });
    this.set({
      faturamento_liquido: linha.faturamento_liquido
    })
  }
}


const gridConfig = (dados, grid, token) => {
  const formatarHeader = (valor) => {
    return valor.replaceAll(" ", "<br />");
  }

  let visualizacao = "";
  let u_negocios_id = "";
  let u_unidades_id = "";
  let r_redes_id = "";

  const larguraColunaComputador = 150;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      unidades: null,
      ano_inicial: null,
      ano_final: null,
      mes_inicial: null,
      mes_final: null,
      data_inicial: null,
      data_final: null,
      tipo_cmv: null,
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/varejo/ws_vendas_unidades`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.unidades = request.u_unidades_id;
            this.ano_inicial = request.ano_inicial;
            this.ano_final = request.ano_final;
            this.mes_inicial = request.mes_inicial;
            this.mes_final = request.mes_final;
            this.data_inicial = request.data_inicial;
            this.data_final = request.data_final;
            this.tipo_cmv = request.tipo_cmv;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }

        },
        exception(response) {
          console.log(response);
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual > 0) {
            u_negocios_id = this.getById(event.params.id).data.u_negocio_id || "";
            u_unidades_id = `${this.getById(event.params.id).data.u_id_empresa}|${this.getById(event.params.id).data.u_id_filial}|;`;
            r_redes_id = this.getById(event.params.id).data.r_rede_id || "";
            visualizacao = "";
            switch (nivel_atual) {
              case 1:
                visualizacao = "negocio";
                break;
              case 2:
                visualizacao = "rede";
                break;
              case 3:
                visualizacao = "cliente";
                break;
            }
            event.source.fetchOptions.body = JSON.stringify({
              u_negocios_id,
              u_unidades_id,
              r_redes_id,
              agrupamento: "total",
              visualizacao,
              arvore: 4,
              ano_inicial: this.ano_inicial,
              ano_final: this.ano_final,
              mes_inicial: this.mes_inicial,
              mes_final: this.mes_final,
              data_inicial: this.data_inicial,
              data_final: this.data_final,
              tipo_cmv: this.tipo_cmv,
              classeN1: "",
              retorna_desafio: 1,
              sob_demanda: 1,
              nivel_atual,
              retornaMC3: 1,
              base_calculo_percentual: "bruto"
            });
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.u_nome,
        field: "u_nome",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 120,
        responsiveLevels: hideMedium
      },
      {
        text: dados.header.u_abreviatura,
        field: "u_abreviatura",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 120,
        locked: true,
        responsiveLevels: showMedium
      },
      {
        align: "right",
        text: dados.header.faturamento,
        field: "faturamento",
        type: "number",
        sum: true,
        htmlEncode: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_bruto_percentual, 1), toMoney(dados.footer.desafio_bruto_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.faturamento_percentual, 1), toMoney(dados.footer.faturamento), "", false, true, true, false)
              ], false),
            dados.header.faturamento,
            dados.footer.desafio_bruto_variacao_percentual
          );
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacao(
            record.desafio_bruto_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_bruto_percentual, 1), toMoney(record.desafio_bruto_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.faturamento_percentual, 1), toMoney(record.faturamento), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.desconto_total_valor,
        field: "desconto_total_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_desconto_percentual, 1), toMoney(dados.footer.desafio_desconto_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desconto_total_percentual, 1), toMoney(dados.footer.desconto_total_valor), "", false, true, true, false)
              ], false)
            , dados.header.desconto_total_valor, dados.footer.desafio_desconto_variacao_percentual);
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_desconto_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_desconto_percentual, 1), toMoney(record.desafio_desconto_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desconto_total_percentual, 1), toMoney(record.desconto_total_valor), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },

      {
        align: "right",
        text: dados.header.faturamento_com_desconto,
        field: "faturamento_com_desconto",
        type: "number",
        sum: true,
        htmlEncode: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhas(
            formatarFooterMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(0, 1), toMoney(0), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.faturamento_com_desconto_percentual, 1), toMoney(dados.footer.faturamento_com_desconto), "", false, true, true, false)
              ], false),
            dados.header.faturamento_com_desconto,
            0
          );
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacao(
            0,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(0, 1), toMoney(0), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.faturamento_com_desconto_percentual, 1), toMoney(record.faturamento_com_desconto), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },

      {
        align: "right",
        text: dados.header.impostos_valor,
        field: "impostos_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_impostos_percentual, 1), toMoney(dados.footer.desafio_impostos_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.impostos_percentual, 1), toMoney(dados.footer.impostos_valor), "", false, true, true, false)
              ], false)
            ,
            dados.header.impostos_valor,
            dados.footer.desafio_impostos_variacao_percentual
          );
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_impostos_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_impostos_percentual, 1), toMoney(record.desafio_impostos_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.impostos_percentual, 1), toMoney(record.impostos_valor), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.devolucao_total_valor,
        field: "devolucao_total_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_devolucao_percentual, 1), toMoney(dados.footer.desafio_devolucao_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.devolucao_total_percentual, 1), toMoney(dados.footer.devolucao_total_valor), "", false, true, true, false)
              ], false)
            , dados.header.devolucao_total_valor, dados.footer.desafio_devolucao_variacao_percentual);
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_devolucao_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_devolucao_percentual, 1), toMoney(record.desafio_devolucao_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.devolucao_total_percentual, 1), toMoney(record.devolucao_total_valor), "", false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.faturamento_liquido,
        field: "faturamento_liquido",
        type: "number",
        sum: true,
        htmlEncode: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_percentual, 1), toMoney(dados.footer.desafio_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.faturamento_liquido_percentual, 1), toMoney(dados.footer.faturamento_liquido), dados.footer.desafio_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.faturamento_liquido, dados.footer.desafio_variacao_percentual);

        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacao(
            record.desafio_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_percentual, 1), toMoney(record.desafio_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.faturamento_liquido_percentual, 1), toMoney(record.faturamento_liquido), record.desafio_variacao_percentual, false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        autoHeight: true,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_cmv_percentual, 1), toMoney(dados.footer.desafio_cmv_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.cmv_percentual, 1), toMoney(dados.footer.cmv_valor), dados.footer.desafio_cmv_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.cmv_valor, dados.footer.desafio_cmv_variacao_percentual, "titulo_cmv");

        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_cmv_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_cmv_percentual, 1), toMoney(record.desafio_cmv_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.cmv_percentual, 1), toMoney(record.cmv_valor), record.desafio_cmv_variacao_percentual, false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem_contribuicao_valor,
        field: "margem_contribuicao_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_margem_i_percentual, 1), toMoney(dados.footer.desafio_margem_i_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.margem_contribuicao_percentual, 1), toMoney(dados.footer.margem_contribuicao_valor), dados.footer.desafio_margem_i_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.margem_contribuicao_valor, dados.footer.desafio_margem_i_variacao_percentual);
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacao(
            record.desafio_margem_i_variacao_percentual,
            0,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_margem_i_percentual, 1), toMoney(record.desafio_margem_i_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.margem_contribuicao_percentual, 1), toMoney(record.margem_contribuicao_valor), record.desafio_margem_i_variacao_percentual, false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.despesas_unidade,
        field: "despesas_unidade",
        type: "number",
        sum: true,
        htmlEncode: false,
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_despesa_percentual, 1), toMoney(dados.footer.desafio_despesa_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.despesas_unidade_percentual, 1), toMoney(dados.footer.despesas_unidade), dados.footer.desafio_despesa_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.despesas_unidade, dados.footer.desafio_despesa_variacao_percentual);
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_despesa_variacao_percentual,
            0,
            cellElement
          );
          return record.ca_dimensao1_id ? `<div style="cursor:pointer;"> 
          ${formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_despesa_percentual, 1), toMoney(record.desafio_despesa_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.despesas_unidade_percentual, 1), toMoney(record.despesas_unidade), record.desafio_despesa_variacao_percentual, false, true, true, false)
            ])}
            </div>` : formatarColunaMultiplasLinhas(
              [
                formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_despesa_percentual, 1), toMoney(record.desafio_despesa_valor), "", false, true, false, false),
                formatarColunaPercentualValorUnicaLinha(toMoney(record.despesas_unidade_percentual, 1), toMoney(record.despesas_unidade), record.desafio_despesa_variacao_percentual, false, true, true, false)
              ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.nao_vendas,
        field: "nao_vendas",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhasDespesas(
            formatarColunaMultiplasLinhas(
              [
                formatarColunaPercentualValorUnicaLinha(toMoney(dados.footer.desafio_nao_vendas_percentual, 1), toMoney(dados.footer.desafio_nao_vendas_valor), "", false, true, false, false),
                formatarColunaPercentualValorUnicaLinha(toMoney(dados.footer.nao_vendas_percentual, 1), toMoney(dados.footer.nao_vendas), dados.footer.desafio_nao_vendas_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.nao_vendas, dados.footer.desafio_nao_vendas_variacao_percentual);
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacaoDespesas(
            record.desafio_nao_vendas_variacao_percentual,
            0,
            cellElement
          );
          return record.ca_dimensao1_id ? `<div style="cursor:pointer;"> 
          ${formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_nao_vendas_percentual, 1), toMoney(record.desafio_nao_vendas_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.nao_vendas_percentual, 1), toMoney(record.nao_vendas), record.desafio_nao_vendas_variacao_percentual, false, true, true, false)
            ])}
            </div>` : formatarColunaMultiplasLinhas(
              [
                formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_nao_vendas_percentual, 1), toMoney(record.desafio_nao_vendas_valor), "", false, true, false, false),
                formatarColunaPercentualValorUnicaLinha(toMoney(record.nao_vendas_percentual, 1), toMoney(record.nao_vendas), record.desafio_nao_vendas_variacao_percentual, false, true, true, false)
              ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem_ii,
        field: "margem_ii",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTituloLinhas(
            formatarColunaMultiplasLinhas(
              [
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.desafio_margem_ii_percentual, 1), toMoney(dados.footer.desafio_margem_ii_valor), "", false, true, false, false),
                formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer.margem_ii_percentual, 1), toMoney(dados.footer.margem_ii), dados.footer.desafio_margem_ii_variacao_percentual, false, true, true, false)
              ], false)
            , dados.header.margem_ii, dados.footer.margem_ii - dados.footer.desafio_margem_ii_valor );
        },
        renderer: ({ value, cellElement, record }) => {
          setasParaCimaOuParaBaixoComparacao(
            record.margem_ii,
            record.desafio_margem_ii_valor,
            cellElement
          );
          return formatarColunaMultiplasLinhas(
            [
              formatarColunaPercentualValorUnicaLinha(toMoney(record.desafio_margem_ii_percentual, 1), toMoney(record.desafio_margem_ii_valor), "", false, true, false, false),
              formatarColunaPercentualValorUnicaLinha(toMoney(record.margem_ii_percentual, 1), toMoney(record.margem_ii), record.desafio_margem_ii_variacao_percentual, false, true, true, false)
            ]);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
    ]
  };
};

export { gridConfig };
