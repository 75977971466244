import * as types from "./types";

export const requisitarDados = (parametros) => {
  return {
    type: types.REQUISITAR_DADOS,
    payload: { parametros },
  };
};

export const requisicaoBemSucedida = (dados) => ({
  type: types.REQUISITAR_DADOS_SUCESSO,
  payload: dados,
});

export const requisicaoFalhou = (error) => ({
  type: types.REQUISITAR_DADOS_FALHOU,
  payload: error,
});

export const requisitarLimparDados = () => {};
