import React, { useEffect, useState } from "react";
import 'react-modern-drawer/dist/index.css';
import { FormGroup, Label } from 'reactstrap';
import Select from "react-select";
import { vw_camadas_negocio_json } from '../../../helpers/api';
const DropdownOpcoesCamadas = ({ idParceiro, idCliente, relacao, token, filtroCamadasOpcoes, setFiltroCamadasOpcoes }) => {
    const [opcoesCamada, setOpcoesCamada] = useState([]);
    useEffect(() => {
        if (!relacao) return;
        setOpcoesCamada([]);
        let isSubscribed = true;
        vw_camadas_negocio_json({
            idParceiro,
            idCliente,
            idAgenteHolograma: relacao.value,
            token
        })
            .then((resolve) => {
                if (isSubscribed)
                    setOpcoesCamada(resolve);
            })
            .catch((error) => {
                if (isSubscribed)
                    console.log(error);
            });

        return () => (isSubscribed = false);
    }, [relacao])
    return (
        <FormGroup className="select2-container">
            <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                <Label>
                    {relacao.label}
                </Label>
                <Select
                    placeholder="..."
                    className="basic-single"
                    isLoading={!opcoesCamada || !opcoesCamada.length}
                    options={opcoesCamada}
                    value={filtroCamadasOpcoes.length ? filtroCamadasOpcoes.filter(a => a?.value.split('|')[0] == relacao?.value) : []}
                    isMulti
                    isSearchable={false}
                    classNamePrefix="select2-selection"
                    onChange={(Object) => {
                        let filtros = [...filtroCamadasOpcoes];
                        let auxFiltros = [];
                        filtros.map((elemento, indice) => {
                            if (elemento.value.split('|')[0] != relacao.value) {
                                auxFiltros.push(elemento);
                            }
                        })
                        Object.map(elemento => auxFiltros.push(elemento));
                        setFiltroCamadasOpcoes([...new Set(auxFiltros)]);
                    }}
                />
            </div>
        </FormGroup>
    )
}
export default DropdownOpcoesCamadas