import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

const HeaderGrafico = ({ colunas, itens, marginLeft = 0, marginRight = 0, mostrar,  }) => {
    return (
        <>
            <div style={{ marginLeft: marginLeft, marginRight: marginRight }}>
                <Row className="totalizadores">

                                {itens.map((item, i) => {
                                    return (
                                        // <Col sm={6} md={4}  lg={colunas} key={`headerMaps_${i}`}>
                                        <Col key={`headerMaps_${i}`}>
                                            <Card>
                                                <CardBody>
                                                    <p className="text-muted mb-2 mb-lg-3 text-truncate">
                                                        <i className={`mdi mdi-circle font-size-10 mr-1 ${item.corClass || "text-light"} align-middle mb-0 me-3`}></i> 
                                                        {item.nome ? item.nome :" "} 
                                                    </p>
                                                    
                                                    <h5 className={`${item.valor ? "text-truncate" : "text-white"}`}>
                                                        {item.valor || ""}
                                                        {item.segundoValor ? <span className="text-muted ml-1 ml-xl-2 text-muted-span">{item.segundoValor}</span> : null}
                                                    </h5>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                
                            })}
                </Row>
            </div>
        </>
    )
}
export default HeaderGrafico