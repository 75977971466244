import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import * as types from "./types";
import { _handleError } from "../../errorHandler";
import * as actions from "./actions";
import { API } from "../../../config/index";

const dadosApi = (
  token,
  idEmpresa,
  idParceiro,
  idCliente,
  idPeriodoResposta,
  visualizacao
) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("idEmpresa", idEmpresa);
    urlencoded.append("idParceiro", idParceiro);
    urlencoded.append("idCliente", idCliente);
    urlencoded.append("idPeriodoResposta", idPeriodoResposta);
    urlencoded.append("visualizacao", visualizacao || " ");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${API}/api/pesquisas/retornarICRRelacoesCamadas`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => console.log("error", error));
  });
};

function carregarDadosAPI(action) {
  return new Promise((resolve, reject) => {
    dadosApi(
      action.token,
      action.idEmpresa,
      action.idParceiro,
      action.idCliente,
      action.idPeriodoResposta,
      action.visualizacao
    )
      .then((result) => {
        return resolve(result.ResultadoObjeto);
      })
      .catch((e) => reject(e));
  });
}

function* carregar(action) {
  try {
    const dados = yield call(carregarDadosAPI, action.payload);
    yield put(actions.requisicaoBemSucedida(dados));
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* assistindoRequisicao() {
  yield takeEvery(types.REQUISITAR_DADOS, carregar);
}

function* assistindoCarregamento() {
  yield all([
    fork(assistindoRequisicao),
    fork(assistindoLimparDados)
  ]);
}

function* limparDados() {
  yield put(actions.limparDados())
}

function* assistindoLimparDados() {
  yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}

export default assistindoCarregamento;
