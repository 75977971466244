import "../../helpers/locale/locale_BR";
import React from 'react';
import { formatarFooterTituloLinhas, formatarFooterMultiplasLinhas, formatarFooterPercentualValorUnicaLinha, formatarColunaMultiplasLinhas, formatarColunaPercentualValorUnicaLinha, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValorClicavel, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../helpers/utils";
import {
  bizGridResponsive
} from "../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { urlApiFinanceiro } from "../../helpers/api";
import { setasParaCimaOuParaBaixoComparacaoInvertida } from "../../helpers/biz-padrao-visual";

const larguraColuna = 130;

class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      valor_liquidado: 0,
    });
    this.set({
      valor_liquidado: linha.valor_liquidado
    })
  }
}

const retornarValorCelula = (value, cellElement, record, indice) => {
  if (value > 0 || record['valor_previsto' + indice] > 0) {

    setasParaCimaOuParaBaixoComparacaoInvertida(
      value,
      record['valor_previsto' + indice],
      cellElement
    );
    return `<div style="cursor:pointer;">
            ${formatarColunaMultiplasLinhas(
      [
        formatarColunaPercentualValorUnicaLinha(toMoney(record['variacao_previsto' + indice]), toMoney(record['valor_previsto' + indice]), "", false, true, false, false),
        formatarColunaPercentualValorUnicaLinha(toMoney(record['participacao' + indice]), toMoney(value), "", false, true, true, false)
      ]
    )
      }
           </div>`
  } else {
    return formatarColunaMultiplasLinhas(
      [
        formatarColunaPercentualValorUnicaLinha(toMoney(0), toMoney(0), "", false, true, false, false),
        formatarColunaPercentualValorUnicaLinha(toMoney(record['participacao' + indice]), toMoney(value), "", false, true, true, false)
      ]);
  }
}

const retornarValorCelulaFooter = (indice, dados) => {

  if (!dados.footer) return "";

  return formatarFooterTituloLinhas(
    formatarFooterMultiplasLinhas(
      [
        formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer['variacao_previsto' + indice]), toMoney(dados.footer['valor_previsto' + indice]), "", false, true, false, false),
        formatarFooterPercentualValorUnicaLinha(toMoney(dados.footer['participacao' + indice]), toMoney(dados.footer['valor_liquidado' + indice]), "", false, true, true, false)
      ], false),
    dados.header['coluna' + indice],
    dados.footer['valor_liquidado' + indice] - dados.footer['valor_previsto' + indice]
  );


}

const gridConfigBenassi = (dados, grid, token, setParametrosModal) => {
  let visualizacao = "familia";
  var u_unidades_negocio_id;
  let cf_nivel1_ids = "";
  let cf_nivel2_ids = "";
  let cf_nivel3_ids = "";
  let classeN1 = "";

  const larguraColunaComputador = 110;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      unidades: null,
      u_unidades_negocio_id: null,
      ano_inicial: null,
      mes_inicial: null,
      ano_final: null,
      mes_final: null,
      tree: true,
      cuidador_id: true,
      modelClass: GridLinha,
      readUrl: urlApiFinanceiro(),
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && event != "" && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.u_unidades_negocio_id = request.u_unidades_negocio_id;
            this.ano_inicial = request.ano_inicial;
            this.mes_inicial = request.mes_inicial;
            this.ano_final = request.ano_final;
            this.mes_final = request.mes_final;
            this.cuidador_id = request.cuidador_id || "";
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data && event.json.ResultadoObjeto.data.length) {
              dados.header = event.json.ResultadoObjeto.header;
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          var cr_visualizacao = event.params.id ? this.getById(event.params.id).data.cr_visualizacao : "";

          //if (nivel_atual == 1) {
          //  cr_visualizacao = "agrupador";
          //}

          var cr_agrupador_id = event.params.id ? this.getById(event.params.id).data.cr_agrupador_id : "";

          //u_unidades_negocio_id = "";//event.params.id ? this.getById(event.params.id).data.ca_dimensao1_id + "-,|;" : "";

          var dimensao1_filtro = event.params.id ? this.getById(event.params.id).data.ca_dimensao1_id : "";
          //"2-,|;"
          cf_nivel1_ids = event.params.id ? this.getById(event.params.id).data.cf_nivel1_id : "";
          cf_nivel2_ids = event.params.id ? this.getById(event.params.id).data.cf_nivel2_id : "";
          cf_nivel3_ids = event.params.id ? this.getById(event.params.id).data.cf_nivel3_id : "";

          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              ano_inicial: this.ano_inicial,
              mes_inicial: this.mes_inicial,
              ano_final: this.ano_final,
              mes_final: this.mes_final,
              arvore: 5,
              visualizacao: cr_visualizacao,
              cr_agrupador_id,
              agrupamento: "mensal",
              classeN1,
              u_unidades_negocio_id: this.u_unidades_negocio_id,
              cf_nivel1_ids,
              cf_nivel2_ids,
              cf_nivel3_ids,
              sob_demanda: 1,
              nivel_atual,
              dimensao1_filtro,
              resultado: "despesas",
              retorno: "coluna",
              titulos: "pagar",
              cuidador_id: this.cuidador_id
            })
          }
        }
      }
    },
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 200,
        responsiveLevels: hideMedium,
        filterable: false
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 200,
        locked: true,
        responsiveLevels: showMedium,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_01,
        field: "valor_liquidado_01",
        minWidth: larguraColuna,
        type: "number",
        sum: true,
        htmlEncode: false,
        autoHeight: true,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";

          return retornarValorCelulaFooter('_01', dados);
        },
        renderer: ({ value, cellElement, record }) => {
          //if (record.valor_liquidado_01 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "01|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_01, toMoney(value)) }}
          // ></div>;


          return retornarValorCelula(value, cellElement, record, '_01');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_02,
        field: "valor_liquidado_02",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_02', dados);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_02 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "02|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_02, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_02');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_03,
        field: "valor_liquidado_03",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_03', dados);
        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_03 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "03|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_03, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_03');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_04,
        field: "valor_liquidado_04",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_04', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_04 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "04|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_04, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_04');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_05,
        field: "valor_liquidado_05",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_05', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_05 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "05|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_05, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_05');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_06,
        field: "valor_liquidado_06",
        type: "number",
        sum: true,
        htmlEncode: false,
        minWidth: larguraColuna,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_06', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_06 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "06|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_06, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_06');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_07,
        field: "valor_liquidado_07",
        type: "number",
        sum: true,
        minWidth: larguraColuna,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_07', dados);


        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_07 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "07|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_07, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_07');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_08,
        field: "valor_liquidado_08",
        type: "number",
        sum: true,
        minWidth: larguraColuna,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_08', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_08 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "08|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_08, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_08');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_09,
        field: "valor_liquidado_09",
        type: "number",
        sum: true,
        minWidth: larguraColuna,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_09', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_09 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "09|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_09, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_09');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_10,
        field: "valor_liquidado_10",
        type: "number",
        sum: true,
        minWidth: larguraColuna,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_10', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_10 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "10|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_10, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_10');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_11,
        field: "valor_liquidado_11",
        type: "number",
        sum: true,
        minWidth: larguraColuna,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_11', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_11 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {
          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "11|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_11, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_11');

        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna_12,
        field: "valor_liquidado_12",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('_12', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          if (record.valor_liquidado_12 == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {

          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "12|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao_12, toMoney(value)) }}
          // ></div>;
          return retornarValorCelula(value, cellElement, record, '_12');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.coluna,
        field: "valor_liquidado",
        type: "number",
        minWidth: larguraColuna,
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return retornarValorCelulaFooter('', dados);

        },
        renderer: ({ value, cellElement, record }) => {
          // if (record.participacao == 0) return "-";

          // return <div style={{ cursor: "pointer" }}
          //   tabIndex="0"
          //   onDoubleClick={() => {

          //     const filtro = {
          //       cf_nivel1_id: record.cf_nivel1_id,
          //       cf_nivel2_id: record.cf_nivel2_id,
          //       cf_nivel3_id: record.cf_nivel3_id,
          //       meses: "01|;02|;03|;04|;05|;06|;07|;08|;09|;10|;11|;12|;",
          //       token
          //     };
          //     setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
          //   }}
          //   dangerouslySetInnerHTML={{ __html: formatarColunaPercentualValorClicavel(record.participacao, toMoney(value)) }}
          // ></div>;

          return retornarValorCelula(value, cellElement, record, '');
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
      /*
      {
        align: "right",
        text: dados.header.valor_saldo,
        field: "valor_saldo",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(0, dados.footer.valor_saldo), dados.header.valor_saldo);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValorClicavel(0, toMoney(value, 0))
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
      */
    ]
  };
};

export { gridConfigBenassi };
