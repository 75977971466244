import { retornarIdCliente } from "../../../../cliente/variaveis";
const clienteId = retornarIdCliente();

export const retornaExportacaoExcel = (tipo) => {
    switch (clienteId) {
        case "real":
        case "volo":
            return "batida_tambor";
        case "benassi":
            return `batida_tambor_${tipo}`;
    }
}

export const retornarVisualizacao = () => {
    switch (clienteId) {
        case "real":
        case "volo":
            return "negocio";
        case "benassi":
            return "unidade_negocio";
    }
}