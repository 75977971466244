import React from 'react';
import "../../helpers/locale/locale_BR";
import { zeroEsquerda, formatarColunaClassCss, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../helpers/utils";
import {
  bizGridResponsive
} from "../../helpers/biz-padrao-visual";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { API } from "../../config/index";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento: 0,
    });
    this.set({
      faturamento: linha.faturamento
    })
  }
}


const gridConfig = (dados, grid, setParametrosModal, token) => {
  let visualizacao = "familia";
  let agrupador_id = "";
  let cf_nivel1_id = "";
  let cf_nivel2_id = "";
  let classeN1 = "";
  const larguraColunaComputador = 100;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }

  let colunas = [
    {
      text: dados.header.descricao,
      field: "descricao",
      sum: true,
      type: "tree",
      minWidth: 350,
      locked: true,
      responsiveLevels: hideMedium,
      filterable: false
    },
    {
      text: dados.header.descricao,
      field: "descricao",
      sum: true,
      type: "tree",
      minWidth: 120,
      locked: true,
      responsiveLevels: showMedium,
      filterable: false
    }
  ];

  const abrirModal = (coluna, record, token) => {
    const filtro = {
      cf_nivel1_id: record.cf_nivel1_id,
      cf_nivel2_id: record.cf_nivel2_id,
      cf_nivel3_id: record.cf_nivel3_id,
      ca_id: dados.header[`ca_${coluna}`],
      token
    };
    setParametrosModal({ ...record.originalData, filtro, mostarModal: true });
  }

  for (var i = 1; i <= 5; i++) {
    let agrupador = dados.header[`agrupador_${zeroEsquerda(i, 2)}_colunas`];
    if (agrupador) {
      let listaColunas = agrupador.split(",");
      let children = [];
      if (listaColunas.length > 1) {
        listaColunas.forEach(coluna => {
          children.push(
            {
              align: "right",
              text: dados.header[`descricao_${zeroEsquerda(coluna, 2)}`],
              field: `cr_coluna_${zeroEsquerda(coluna, 2)}`,
              type: "number",
              htmlEncode: false,
              renderer: ({ value, cellElement, record }) => {
                let coluna = cellElement.dataset.column.split("_")[2];
                cellElement.classList.add(dados.header[`classe_${coluna}`]);
                return `<div ${record.possui_detalhes ? 'style="cursor:pointer;"' : ""}>${record.cr_tipo_dado == "$" ? formatarColunaPercentualValor(record[`cr_percentual_${coluna}`], toMoney(value)) : toMoney(value, 1) + "%"}</div>`;
                
                
                // if (record.possui_detalhes) {
                //   return <div style={{ cursor: "pointer" }}
                //     tabIndex="0"
                //     onDoubleClick={() => { abrirModal(coluna, record, token) }}
                //     dangerouslySetInnerHTML={{ __html: record.cr_tipo_dado == "$" ? formatarColunaPercentualValor(record[`cr_percentual_${coluna}`], toMoney(value)) : toMoney(value, 1) + "%" }}
                //   ></div>;
                // } else {
                //   return record.cr_tipo_dado == "$" ? formatarColunaPercentualValor(record[`cr_percentual_${coluna}`], toMoney(value)) : toMoney(value, 1) + "%";
                // }

              },
              responsiveLevels: configResponsiveLevels,
              filterable: false,
              hidden: !dados.header[`coluna_${zeroEsquerda(coluna, 2)}_visivel`],
              cls: dados.header[`classe_${zeroEsquerda(coluna, 2)}`]
            }
          )
        });
        colunas.push({
          text: dados.header[`agrupador_${zeroEsquerda(i, 2)}_descricao`],
          align: "center",
          children
        });
      } else {
        colunas.push({
          align: "right",
          text: dados.header[`descricao_${zeroEsquerda(agrupador, 2)}`],
          field: `cr_coluna_${zeroEsquerda(agrupador, 2)}`,
          type: "number",
          htmlEncode: false,
          renderer: ({ value, cellElement, record }) => {

            let coluna = cellElement.dataset.column.split("_")[2];
            cellElement.classList.add(dados.header[`classe_${coluna}`]);
            return record.cr_tipo_dado == "$" ? formatarColunaPercentualValor(record[`cr_percentual_${coluna}`], toMoney(value)) : toMoney(value, 1) + "%";
          },
          responsiveLevels: configResponsiveLevels,
          filterable: false,
          hidden: !dados.header[`coluna_${zeroEsquerda(agrupador, 2)}_visivel`],
          cls: dados.header[`classe_${zeroEsquerda(agrupador, 2)}`]
        });
      }
    }
  }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      ano: "",
      meses: "",
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/varejo/ws_cinema_resultado`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          if (nivel_atual == 0 && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.ano = request.ano;
            this.meses = request.meses;
          }

        },
        exception({ response }) {
          console.log(response || "");
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          classeN1 = "";
          cf_nivel1_id = "";
          cf_nivel2_id = "";
          switch (nivel_atual) {
            case 1:
              visualizacao = "gaveta";
              agrupador_id = this.getById(event.params.id).data.cr_agrupador_id;
              cf_nivel1_id = this.getById(event.params.id).data.cf_nivel1_id;
              break;
            case 2:
              visualizacao = "pasta";
              agrupador_id = this.getById(event.params.id).data.cr_agrupador_id;
              cf_nivel1_id = this.getById(event.params.id).data.cf_nivel1_id;
              cf_nivel2_id = this.getById(event.params.id).data.cf_nivel2_id;
              break;
          }
          if (nivel_atual > 0) {

            event.source.fetchOptions.body = JSON.stringify({
              visualizacao,
              filme_id: "01",
              agrupador_id,
              ano: this.ano,
              meses: this.meses,
              cf_nivel1_id,
              cf_nivel2_id,
              cf_nivel3_id: "",
              arvore: 3,
              nivelExpandido: 0,
              classeN1,
              sob_demanda: 1,
              nivel_atual
            })
          }
        }
      }
    },
    columns: colunas
  };
};

export { gridConfig };
