import React from "react";
import { Navigate } from "react-router-dom";  // Mudança aqui

// autenticacao related pages
import Login from "../pages/autenticacao/Login";
import AlterPassword from "../pages/autenticacao/alterPassword";
import Logout from "../pages/autenticacao/Logout";
import ForgetPwd from "../pages/autenticacao/ForgetPassword";
import lockedScreen from "../pages/autenticacao/lockedScreen";
import cliente from "../cliente";
import { rotas } from "../cliente/rotas";

// Modificando as rotas para o formato v6
const userRoutes = rotas[cliente.id].map(route => ({
  ...route,
  // Se a rota for um redirect, transformar em Navigate
  component: route.component === (() => <Navigate to="/dashboard" />) 
    ? () => <Navigate to="/dashboard" replace />
    : route.component
}));

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/locked", component: lockedScreen },
  { path: "/alter-password", component: AlterPassword },
  { path: "/forgot-password", component: ForgetPwd },
];

export { userRoutes, authRoutes };