import React from "react"
import {Modal} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"

import ModalTitulo from "./partes/modalTitulo"
import ModalFiltroFooter from "./partes/ModalFiltroFooter"


const  ModalFiltro = (props) =>{
    const {parametros} = props
    return(
        <Modal size="lg" centered
        isOpen={parametros.modalFiltro}
        toggle={() => {
            parametros.toggleModalFiltro()
        }}
      >
        <ModalTitulo 
          titulo={parametros.titulo} 
          toggleModalFiltro={parametros.toggleModalFiltro}
        />
          {props.children}
        <ModalFiltroFooter toggleModalFiltro={parametros.toggleModalFiltro} filtrar={parametros.filtrar} />
      </Modal>
    )
}

export default ModalFiltro