import React from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

// Actions
import { loginUser } from '../../store/actions';

// Images and Components
import zib from "../../assets/images/profile-img.png";
import LogoCliente from './partes/logoCliente';
import FooterMsg from "./footerMsg";
import HeaderAuth from './partes/headerAutenticacao';
import FooterLogin from "./partes/footer";
import cliente from '../../cliente';

const Locked = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error, loading } = useSelector((state) => state.Login);
    const user = useSelector((state) => state.User);

    if (!user.usuario) {
        navigate("/login");
        return null;
    }

    const { attributes } = user.usuario;

    function enviarDados(event, values) {
        values.email = attributes.email;
        dispatch(loginUser(values, navigate));
    }

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <HeaderAuth 
                                    titulo="Seja bem-vindo!" 
                                    subtitulo="Entre no sistema com as suas informações de acesso" 
                                    imagem={zib} 
                                />
                                <CardBody className="pt-0">
                                    <LogoCliente />
                                    <div className="p-2">
                                        <AvForm 
                                            className="form-horizontal" 
                                            onValidSubmit={(e, v) => { enviarDados(e, v) }}
                                        >
                                            {error && <Alert color="danger">{error}</Alert>}
                                            
                                            <div className="user-thumb text-center mb-4">
                                                <img 
                                                    src={attributes["custom:foto"] || cliente.logoLogIn} 
                                                    className="rounded-circle img-thumbnail avatar-md" 
                                                    alt="thumbnail" 
                                                />
                                                <h5 className="font-size-15 mt-3">
                                                    {attributes["custom:apelido"] || attributes.email}
                                                </h5>
                                            </div>

                                            <AvGroup>
                                                <Label for="senha">Senha</Label>
                                                <AvInput 
                                                    name="password" 
                                                    label="Senha" 
                                                    id="senha" 
                                                    errorMessage="Campo obrigatório" 
                                                    type="password" 
                                                    required 
                                                    placeholder="Senha" 
                                                />
                                            </AvGroup>

                                            <div className="mt-3">
                                                <button 
                                                    disabled={loading} 
                                                    className="btn btn-primary btn-block waves-effect waves-light" 
                                                    type="submit"
                                                >
                                                    {loading ? (
                                                        <>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Carregando
                                                        </>
                                                    ) : (
                                                        "Entrar"
                                                    )}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                Não é você? <Link to="/login">logar.</Link>
                            </div>
                            <FooterMsg />
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterLogin />
        </React.Fragment>
    );
}

export default Locked;