import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/icrRelacoesCamadas/actions"
import Header from "../../../components/HorizontalLayout/HeaderApp"
import Grafico from "./partes/grafico"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import HeaderGraficoSuplicas from '../../../components/headerGraficoSuplicas';
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";

const GraficoFormulaLucro = (props) => {
  const { dados, carregando } = useSelector(state => state.IcrRelacoesCamadas);
  const [carregandoPagina, setCarregandoPagina] = useState(false);
  const tela = useSelector((state) => state.Gerais);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const [cardsGrafico, setCardsGrafico] = useState([]);
  const [tituloGrafico, setTituloGrafico] = useState("");
  const [submitHabilitado, setSubmitHabilitado] = useState(false);

  //=====Filtros=====//
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  //=====Filtros=====//

  //===== Carregando Filtros=====//
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);

  //===== Carregando Filtros=====//


  const atualizarDados = async (
    idParceiro,
    idCliente,
    idPeriodoResposta,
    idAgenteHolograma
  ) => {
    setCarregandoPagina(true);
    await dispatch(requisitarDados({
      idParceiro,
      idCliente,
      idPeriodoResposta,
      idAgenteHolograma,
      visualizacao: "relacao",
      // idsAgentesHolograma: "1|;"
      // idsAgentesHolograma: "",
      // idCamadaOpcao: "",
      // idParceiro: "000002",
      // idCliente: "000004",
      // idPeriodoResposta: "00002",
      token
    }
    ));
    setTituloGrafico("Fiel da balança");
    setMostrarFiltros(false);
    if (dados) {
      setMostrarTotais(true);
    }
    setTimeout(() => {
      setCarregandoPagina(false);
      setSubmitHabilitado(true);
    }, 500)
  }
  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length) {
      setSubmitHabilitado(true);
    }

  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
  }, [cliente]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {
    setPeriodo([]);
    setCarregandoPeriodo(true);
    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
  };

  useEffect(() => {
    atualizarDadosAmbiente();
    return () => { dispatch(requisitarLimparDados()); }
  }, []);



  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])

  return (
    <React.Fragment>
      <PaginaLoader carregando={tela.carregando || carregandoPagina} />
      <>
        <Header
          parametros={
            {
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Período"
                      className="basic-single"
                      isLoading={carregandoPeriodo}
                      value={periodo}
                      options={tela.periodos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setPeriodo(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      setSubmitHabilitado(false);
                      atualizarDados(
                        parceiro.value,
                        cliente.value,
                        periodo.value,
                        0
                      );
                    }}
                    disabled={!submitHabilitado}
                    className="ml-2"
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>


              </Form>
            </FiltroBrytum>

          </div>
          <Container fluid className="fixWith graficoprincipal mt-3">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <div className={"text-center w-100"}>
                      {/* <h5>{tituloGrafico}</h5> */}
                    </div>
                    <Grafico dados={dados} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoFormulaLucro;