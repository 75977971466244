import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { dadosApiListaEvolucaoRelacoes } from "../../../helpers/api"

function carregarDadosAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApiListaEvolucaoRelacoes(action.parametros)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* carregarDados(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarDados)
}

function* limparDados() {
    yield put(actions.limparDados())
}


function* assistindoLimparDados() {
    yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}


function* listarEvolucaoRealacoes() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoLimparDados)
        ]
    )
}

export default listarEvolucaoRealacoes