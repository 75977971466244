import "../../../helpers/locale/locale_BR";
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import {
  bizGridResponsive
} from "../../../helpers/biz-padrao-visual";

const gridConfig = (dados) => {
  const larguraColunaComputador = 150;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      data: dados.data,
    },
    columns: [
      {
        text: dados.header.u_nome,
        field: "u_nome",
        type: "tree",
        sum: true,
        expanded: true,
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 120,
        responsiveLevels: hideMedium
      },
      {
        text: dados.header.u_nome,
        field: "u_abreviatura",
        type: "tree",
        sum: true,
        expanded: true,
        locked: true,
        summaryRenderer: () => {
          return "Total";
        },
        width: 120,
        responsiveLevels: showMedium
      },

      {
        align: "right",
        text: dados.header.faturamento,
        field: "faturamento",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.faturamento_percentual, dados.footer.faturamento), dados.header.faturamento);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.participacao, toMoney(value))
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.impostos_valor,
        field: "impostos_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.impostos_percentual, dados.footer.impostos_valor), dados.header.impostos_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.impostos_percentual, toMoney(value));
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.devolucao_total_valor,
        field: "devolucao_total_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.devolucao_total_percentual, dados.footer.devolucao_total_valor), dados.header.devolucao_total_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.devolucao_total_percentual, toMoney(value));
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return valorEmInteiro(dados.footer.cmv_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.cmv_percentual, toMoney(value));
        },
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.cmv_percentual, dados.footer.cmv_valor), dados.header.cmv_valor);
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.margem_contribuicao_valor,
        field: "margem_contribuicao_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          return valorEmInteiro(dados.footer.margem_contribuicao_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.margem_contribuicao_percentual, toMoney(value));
        },
        summaryRenderer: ({ sum }) => {
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.margem_contribuicao_percentual, dados.footer.margem_contribuicao_valor), dados.header.margem_contribuicao_valor);
        },
        responsiveLevels: configResponsiveLevels
      }
    ]
  };
};

export { gridConfig };
