import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import { API } from "../../../config/index"

const dadosApi = (parametros) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + parametros.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(parametros),
            redirect: 'follow'
        };
        fetch(`${API}/api/comercial/ws_pedidos_estagios_vendedor`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}

function carregarDadosAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApi(action.parametros)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* carregarVendedores(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarVendedores)
}


function* listarVendedoresVendaEstagios() {
    yield all(
        [
            fork(assistindoRequisicao)
        ]
    )
}

export default listarVendedoresVendaEstagios