import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/painelReposicaoProdutos/actions"
import { gridConfigBenassi } from './AppConfigBenassi';
import { FormGroup, Button, Row, Col, Card, CardBody, Input } from 'reactstrap';
import {  toDateSQL, Ontem, Mes, removerFocusNoBotao, primeiroDiaMes, ArrayToMultiSelect, retornarTipoCustos,
          retornarVisualizacaoPainelProdutos, concatenaDropdownToString } from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import Select from 'react-select';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Vazio from '../../components/vazio';
import FocarPaisagem from '../../components/forcarPaisagem';
import { requisitarDadosRede } from "../../store/benassi/rede/actions";
import { requisitarDadosLoja } from "../../store/benassi/loja/actions";
import { requisitarDadosGrupoProduto } from "../../store/benassi/grupoProduto/actions";
import { requisitarDadosFamiliaProduto } from "../../store/benassi/familiaProduto/actions";
const tiposCusto = retornarTipoCustos();
const tiposVisualizacao = retornarVisualizacaoPainelProdutos();

const PainelReposicaoProdutos = (props) => {
    toastr.options = {
        positionClass: "toast-bottom-full-width",
        timeOut: 5000
    }

    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.PainelReposicaoProdutos);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Ontem());
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[1])
    const [tipoVisualizacao, setTipoVisualizacao] = useState(tiposVisualizacao[0])
    const [produto, setProduto] = useState("");
    const redes = useSelector(state => state.Rede.dados);
    const lojas = useSelector(state => state.Loja.dados);
    const gruposProduto = useSelector(state => state.GrupoProduto.dados);
    const familiasProduto = useSelector(state => state.FamiliaProduto.dados);

    const [rede, setRede] = useState("");
    const [redeSelecionada, setRedeSelecionada] = useState([]);

    const [loja, setLoja] = useState("");
    const [carregandoLoja, setCarregandoLoja] = useState(false);

    const [grupoProduto, setGrupoProduto] = useState("");

    const [familiaProduto, setFamiliaProduto] = useState("");
    const [carregandoFamiliaProduto, setCarregandoFamiliaProduto] = useState(false);

    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);
    const token = useSelector(state => state.Login.user.tokenNOL);

    const atualizarDados = () => {
        dispatch(requisitarDadosRede(token, { u_unidades_id: "", u_negocios_id: "05|;" }));
        dispatch(requisitarDadosGrupoProduto(token));

        setLoading(false);
        setMostrarFiltros(true);
    }

    const atualizarDadosAmbiente = () => {
        dispatch(requisitarDadosAmbiente(token, "gerenciais"));

        dispatch(requisitarDados({
            rede_id: "",
            data_inicial: toDateSQL(dataInicial),
            data_final: toDateSQL(dataInicial),
            visualizacao: "grupo",
            origem: "produto",
            tipo_cmv: tipoCusto.value,
            token
        }));
    }

    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();

        return () => dispatch(requisitarLimparDados())
    }, []);

    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const atualizarDadosDemanda = () => {
        setmensagemDadosNaoEncontrados(false);
        setMostrarFiltros(false);
        setLoading(true);

        if (grid.current) {
            document.getElementById(grid.current.instance.id).style.display = "flex";

            let lojas_id = "", grupos_id = "", familias_id = "";

            if (loja?.length) {
                lojas_id = concatenaDropdownToString(loja);
            }

            if (grupoProduto?.length) {
                grupos_id = concatenaDropdownToString(grupoProduto);
            }

            if (familiaProduto?.length) {
                familias_id = concatenaDropdownToString(familiaProduto);
            }

            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                rede_id: rede,
                data_inicial: toDateSQL(dataInicial),
                data_final: toDateSQL(dataFinal),
                lojas_id: lojas_id,
                grupos_id: grupos_id,
                familias_id: familias_id,
                visualizacao: tipoVisualizacao.value,
                origem: "produto",
                inicializar: "1",
                produto: produto,
                tipo_cmv: tipoCusto.value
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    setLoading(false);

                    if (!resolve.data.length) {
                        document.getElementById(grid.current.instance.id).style.display = "none";
                        setMostrarFiltros(true);
                        if (rede != "") {
                            setmensagemDadosNaoEncontrados(true);
                        }
                    }
                    else {
                        document.getElementById(grid.current.instance.id).style.display = "flex";
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const trocarTipoCustos = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto([])
        }
    }

    const trocarTipoVisualizao = (valor) => {
        if (valor) {
            setTipoVisualizacao(valor)
        } else {
            setTipoVisualizacao([])
        }
    }

    const trocarRedes = (valor) => {
        if (valor) {
            setRedeSelecionada(valor || []);
            setRede(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
        } else {
            setRedeSelecionada([]);
            setRede("");
        }
    }

    const trocarLojas = (valor) => {
        if (valor) {
            setLoja(valor || null);
        } else {
            setLoja("");
        }
    }

    const trocarGrupoProduto = (valor) => {
        if (valor) {
            setGrupoProduto(valor || "");
        } else {
            setGrupoProduto("");
        }
    }

    const trocarFamiliaProduto = (valor) => {
        if (valor) {
            setFamiliaProduto(valor || "");
        } else {
            setFamiliaProduto("");
        }
    }

    const gridInfo = useMemo(() => {
        if (!dados) {
            return;
        }
        else {
            return gridConfigBenassi(dados, grid, token);
        }
    }, [dados])

    useEffect(() => {
        setCarregandoLoja(true);
        setLoja("");
        if (rede) {
            dispatch(requisitarDadosLoja(token, { u_unidades_id: "", u_negocios_id: "05|;", r_redes_id: rede }));
        }
        else {
            dispatch(requisitarDadosLoja(token, { u_unidades_id: "", u_negocios_id: "05|;", r_redes_id: "X" }));
        }
        setTimeout(() => {
            setCarregandoLoja(false);
        }, 1000);
    }, [rede])

    useEffect(() => {
        setCarregandoFamiliaProduto(true);
        setFamiliaProduto("");

        let grupos_id;

        if (grupoProduto?.length) {
            grupos_id = concatenaDropdownToString(grupoProduto);
        }

        dispatch(requisitarDadosFamiliaProduto(token, { grupos_id: grupos_id }));

        setTimeout(() => {
            setCarregandoFamiliaProduto(false);
        }, 1000);
    }, [grupoProduto])

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    }
                    position={true}
                    titulo="Painel de reposição dos produtos"
                />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder="Selecione a rede"
                                                className="basic-single"
                                                isClearable={true}
                                                options={redes}
                                                classNamePrefix="select2-selection"
                                                isSearchable={true}
                                                value={redeSelecionada || null}
                                                onChange={Object => {
                                                    trocarRedes(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder="Todas as lojas"
                                                isLoading={carregandoLoja}
                                                isClearable={true}
                                                className="basic-single"
                                                options={lojas}
                                                classNamePrefix="select2-selection"
                                                isSearchable={true}
                                                isMulti
                                                value={loja || null}
                                                onChange={Object => {
                                                    trocarLojas(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder="Todos os grupos"
                                                isClearable={true}
                                                className="basic-single"
                                                options={gruposProduto}
                                                classNamePrefix="select2-selection"
                                                isSearchable={true}
                                                isMulti
                                                value={grupoProduto || null}
                                                onChange={Object => {
                                                    trocarGrupoProduto(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder="Todos as famílias"
                                                isLoading={carregandoFamiliaProduto}
                                                isClearable={true}
                                                className="basic-single"
                                                options={familiasProduto}
                                                classNamePrefix="select2-selection"
                                                isSearchable={true}
                                                isMulti
                                                value={familiaProduto || null}
                                                onChange={Object => {
                                                    trocarFamiliaProduto(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder=""
                                                className="basic-single"
                                                options={tiposCusto}
                                                isSearchable={false}
                                                classNamePrefix="select2-selection"
                                                defaultValue={tiposCusto[1] || []}
                                                onChange={Object => {
                                                    trocarTipoCustos(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            placeholder="Produto"
                                            onChange={(e) => setProduto(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <div className="unidadesControler">
                                            <Select
                                                placeholder=""
                                                className="basic-single"
                                                options={tiposVisualizacao}
                                                isSearchable={false}
                                                classNamePrefix="select2-selection"
                                                defaultValue={tiposVisualizacao[0] || []}
                                                onChange={Object => {
                                                    trocarTipoVisualizao(Object || []);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <FormGroup>
                                        <Flatpickr
                                            className="form-control d-block w-100"
                                            placeholder="dd M,yyyy"
                                            locale="pt"
                                            options={{
                                                mode: "single",
                                                locale: Portuguese,
                                                dateFormat: "d/m/Y",
                                                maxDate: `today`,
                                                defaultDate: dataInicial,
                                                disableMobile: "true",
                                            }}
                                            onChange={(selectedDates, dateStr, instance) => {
                                                if (selectedDates.length > 0) {
                                                    setDataInicial(selectedDates[0]);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <FormGroup>
                                        <Flatpickr
                                            className="form-control d-block w-100"
                                            placeholder="dd M,yyyy"
                                            locale="pt"
                                            options={{
                                                mode: "single",
                                                locale: Portuguese,
                                                dateFormat: "d/m/Y",
                                                maxDate: `today`,
                                                defaultDate: dataFinal,
                                                disableMobile: "true",
                                            }}
                                            onChange={(selectedDates, dateStr, instance) => {
                                                if (selectedDates.length > 0) {
                                                    setDataFinal(selectedDates[0]);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <FormGroup>
                                        <Button color="light" onClick={(e) => {
                                            if (rede) {
                                                toastr.remove();
                                                atualizarDadosDemanda();
                                                removerFocusNoBotao(e.currentTarget);
                                            }
                                            else {
                                                toastr.error("Por favor, selecione a rede!");
                                            }
                                        }} className="ml-2">
                                            <i className="fal fa-hashtag"></i>
                                            boralá!
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </FiltroBrytum>
                {mensagemDadosNaoEncontrados && <Vazio />}
                {dados &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={true}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />}
            </>
        </React.Fragment >
    );
}
export default PainelReposicaoProdutos;