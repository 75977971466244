import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import PaginaLoader from "../../components/loader/pagina";
import cliente from "../../cliente";

// Actions
import { requisitarDados } from "../../store/menus/actions";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { carregando, atalhos, menuPrincipal } = useSelector(
    (state) => state.Menus
  );
  const token = useSelector((state) => state.Login.user?.tokenNOL);

  const atualizarDados = async (mes, ano) => {
    if (cliente.id != 'capitalRelacional') {
      await dispatch(requisitarDadosAmbiente(token));
    }
    await dispatch(requisitarDados(token, mes, ano));
  };

  useEffect(() => {
    atualizarDados();
  }, []);

  const inserirIcone = (icone, titulo, urlAmigavel) => {
    localStorage.setItem("iconeBizAtual", icone);
    localStorage.setItem("tituloBizAtual", titulo);
    navigate(urlAmigavel);
  };

  const mostrarLoader = carregando && atalhos?.length >= 0;

  return (
    <React.Fragment>
      <PaginaLoader carregando={mostrarLoader} />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="PÁGINA PRINCIPAL" breadcrumbItem="" />
          <Row className="mb-2">
            {atalhos?.map((atalho) => {
              return atalho?.subMenu?.map((sub) => {
                return (
                  <Col xs="6" sm="4" lg="2" key={`${atalho.id}_${sub.id}`}>
                    <Card
                      className="btnPaginaPrincipal"
                      onClick={() => {
                        if (sub.urlAmigavel.includes("https://")) {
                          const url = sub.urlAmigavel.replace("/", "");
                          window.open(url.substring(0, url.length - 1), "_blank");
                        } else {
                          inserirIcone(sub.icone, sub.nome, sub.urlAmigavel);
                        }
                      }}
                    >
                      <CardBody className="text-center text-secondary">
                        <i className={`${sub.icone} fa-4x mt-2`}></i>
                        <div className="boxtexto">
                          <p className="mb-0 mt-3">{sub.nome}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              });
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;