import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import * as types from "./types";
import { _handleError } from "../../errorHandler";
import * as actions from "./actions";
import { dadosApiIcrVisaoSuplicas } from "../../../helpers/api"

function carregarDadosAPI(action) {
  return new Promise((resolve, reject) => {
    dadosApiIcrVisaoSuplicas(action.parametros)
      .then(result => {
        return resolve(result.ResultadoObjeto);
      })
      .catch(e => reject(e))
  })
}

function* carregar(action) {
  try {
    const dados = yield call(carregarDadosAPI, action.payload);
    yield put(actions.requisicaoBemSucedida(dados));
  } catch (e) {
    yield put(actions.requisicaoFalhou(e));
  }
}

function* assistindoRequisicao() {
  yield takeEvery(types.REQUISITAR_DADOS, carregar);
}

function* limparDados() {
  yield put(actions.limparDados())
}

function* assistindoLimparDados() {
  yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}

function* assistindoCarregamento() {
  yield all(
    [
      fork(assistindoRequisicao),
      fork(assistindoLimparDados)
    ]

  );
}

export default assistindoCarregamento;
