import "../../../helpers/locale/locale_BR"
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium } from "../../../helpers/utils"
import {
  bizFrontEnd,
  setasParaCimaOuParaBaixo,
  tamanhos,
  setaSumario,
  numeroComas,
  destacarCelula
} from "../../../helpers/biz-padrao-visual";
import {
  NumberFormat
} from '@bryntum/grid/grid.umd';

const gridConfig = (dados) => {
  const moeda = new NumberFormat('9,999.99##');
  return {
    store: {
      tree: false,
      data: dados
    },
    columns: [
      {
        text: "Vendedor",
        field: "nomeVendedor",
        sum: true,
        summaryRenderer: ({ sum }) => {
          return "Total";
        },
        flex: 1,
        minWidth: 120,
        responsiveLevels: hideMedium
      },
      {
        text: "Vendedor",
        field: "nomeVendedor",
        sum: true,
        locked: true,
        summaryRenderer: ({ sum }) => {
          return "Total";
        },
        width: 120,
        responsiveLevels: showMedium
      },
      {
        text: "Floresta",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "florestaPedidos",
            type: "number",
            align: "right",
            sum: true,
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            width: tamanhos.cupons *  0.7
          },
          {
            text: "R$",
            field: "florestaValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },
      {
        text: "Ta quente",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "taQuentePedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "taQuenteValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },
      {
        text: "Em análise",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "analisePedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "analiseValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },
      {
        text: "Fechado",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "fechadoPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "fechadoValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },

      {
        text: "Entrega futura",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "remessaFuturaPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "remessaFuturaValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },

      {
        text: "Em separação",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "separacaoPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "separacaoValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      },


      {
        text: "Faturado",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "finalizadoPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "finalizadoValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            minWidth: tamanhos.ticketMedio

          }
        ]
      },
      {
        text: "Total",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "totalPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.cupons *  0.7

          },
          {
            text: "R$",
            field: "totalValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.ticketMedio

          }
        ]
      }
    ]
  };
};

export { gridConfig };
