import React from "react"
import { Link } from "react-router-dom"
import cliente from "../../../cliente"
const LogoCliente = () =>{
    return(
        <div>
            <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                        <img src={cliente.logoLogIn} alt="" height="42" />
                    </span>
                </div>
            </Link>
    </div>
    )
}

export default LogoCliente