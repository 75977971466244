import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/saldosContas/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { exportarExcel, retornarColunasExcel, removerFocusNoBotao, retornarMeses, retornarAnos } from "../../../helpers/utils";
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions"
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from 'react-select';
import Vazio from '../../../components/vazio';
import FocarPaisagem from '../../../components/forcarPaisagem';
const meses = retornarMeses();
const anos = retornarAnos(6);

const SaldosContasBTFlex = () => {
    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.SaldosContas);
    const grid = useRef(null);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const [mes, setMes] = useState(meses[new Date().getMonth()]);
    const [ano, setAno] = useState(anos[0]);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [lojas, setLojas] = useState([]);
    const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };

    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            ano_inicial: ano.value,
            mes_inicial: mes.value,
            ano_final: ano.value,
            mes_final: mes.value,
            u_filial_id: "",
            tipo_conta_id: "",
            visualizacao: "tipo-conta",
            agrupamento: "mensal",
            arvore: 3,
            nivelExpandido: 0,
            classeN1: "",
            sob_demanda: 1,
            nivel_atual: 0,
            token
        }));
        setMostrarFiltros(false);
    }
    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token));
    }
    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();
        return () => dispatch(requisitarLimparDados())
    }, []);


    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);


    const atualizarDadosDemanda = () => {
        setMostrarFiltros(false);
        setmensagemDadosNaoEncontrados(false);
        setLoading(true);

        if (grid.current) {
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                ano_inicial: ano.value,
                mes_inicial: mes.value,
                ano_final: ano.value,
                mes_final: mes.value,
                u_filial_id: "",
                tipo_conta_id: "",
                visualizacao: "tipo-conta",
                agrupamento: "mensal",
                arvore: 3,
                nivelExpandido: 0,
                classeN1: "",
                sob_demanda: 1,
                nivel_atual: 0
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    document.getElementById(grid.current.instance.id).style.display = "flex";
                    setLoading(false);
                    if (!resolve.data.length) {
                        document.getElementById(grid.current.instance.id).style.display = "none";
                        setMostrarFiltros(true);
                        setmensagemDadosNaoEncontrados(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, grid, token);
    }, [dados])


    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }
    const trocarMes = (valor) => {
        if (valor) {
            setMes(valor)
        } else {
            setMes([])
        }
    }
    const trocarAno = (valor) => {
        if (valor) {
            setAno(valor)
        } else {
            setAno([])
        }
    }
    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler anoControler">
                                <Select
                                    placeholder="Todas as unidades"
                                    className="basic-single"
                                    options={anos}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={anos[0] || []}
                                    onChange={Object => {
                                        trocarAno(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={meses || []}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={meses[new Date().getMonth()]}
                                    onChange={Object => {
                                        trocarMes(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {mensagemDadosNaoEncontrados && <Vazio />}
                {dados &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={true}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />
                }
            </>
        </React.Fragment>
    );
}
export default SaldosContasBTFlex;