import React from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"; // Mudança aqui
import { useTranslation } from 'react-i18next'; // Mudança aqui

//Simple bar
import SimpleBar from "simplebar-react";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
    const { t } = useTranslation(); // Adiciona hook de tradução
    const location = useLocation(); // Adiciona hook de localização

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent />
                        </SimpleBar>
                    ) : <SidebarContent />}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStatetoProps, {})(Sidebar);