import * as types from "./types"

export const requisitarDados = (parametros) => {
	return {
		type: types.REQUISITAR_DADOS,
		payload: { parametros }
	}
};

export const requisicaoBemSucedida = (dados) => ({
	type: types.REQUISITAR_DADOS_SUCESSO,
	payload: dados
});


export const requisicaoFalhou = (error) => ({
	type: types.REQUISITAR_DADOS_FALHOU,
	payload: error
});


export const requisitarrAtualizarFooter = (footer) =>{
	return {
		type: types.REQUISITAR_ATUALIZAR_FOOTER,
		payload: footer
	}
}

export const requiserAtualizarFooterSucesso = (footer) =>{
	return {
		type: types.REQUISITAR_ATUALIZAR_FOOTER_SUCESS,
		payload: footer
	}
}