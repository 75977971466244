import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container, Input, Label } from "reactstrap";

// Redux
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { Auth } from "aws-amplify";

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import AuthCode from "react-auth-code-input";

import HeaderAuth from './partes/headerAutenticacao';
import FooterLogin from "./partes/footer";
import FooterMsg from "./footerMsg";
import LogoCliente from "./partes/logoCliente";

// actions
import { userForgetPassword } from "../../store/actions";

// import images
import zib from "../../assets/images/profile-img.png";
import { retornarIdCliente } from '../../cliente/variaveis';

const idCliente = retornarIdCliente();

const EsqueciMinhaSenha = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [code, setarCode] = useState(false);
    const [codigoVerificacao, setarCodigiVerificacao] = useState("");
    const [username, setatrUser] = useState("");
    const [password, setarPassword] = useState("");
    const [confirmarcaoSenha, setConfirmarcaoSenha] = useState("");

    let _label, _placeHolder, _input, _message;

    if (idCliente == "volo") {
        _label = "Usuário";
        _placeHolder = "Seu login";
        _input = "input";
        _message = "Por favor informe seu login.";
    } else {
        _label = "E-mail";
        _placeHolder = "Seu e-mail";
        _input = "email";
        _message = "Campo inválido ou não é um e-mail.";
    }

    async function handleValidSubmit(event, values) {
        try {
            const data = await Auth.forgotPassword(values.email.toLowerCase().trim());
            dispatch({ type: "CLEAN_API_MESSAGE", payload: "" });
            setarCode(data.CodeDeliveryDetails);
            setatrUser(values.email.trim());
        } catch (err) {
            console.log(err);
            if (!err.message) {
                toastr.error("Usuário não encontrado!");
            } else if (err.message === "Attempt limit exceeded, please try after some time.") {
                toastr.error("Tente novamente em alguns minutos.", "Você tentou recuperar sua senha muitas vezes");
            } else {
                toastr.error("Usuário não encontrado!", "Nenhum usuário com essa combinação");
            }
        }
    }

    async function reenViar() {
        try {
            const data = await Auth.forgotPassword(username.toLowerCase().trim());
            toastr.success("Código reenviado!");
            setarCode(data.CodeDeliveryDetails);
        } catch (err) {
            console.log(err);
            if (err.message === "Attempt limit exceeded, please try after some time.") {
                toastr.error("Tente novamente em alguns minutos.", "Você tentou recuperar sua senha muitas vezes");
            } else {
                toastr.error("Usuário não encontrado!", "Nenhum usuário com essa combinação");
            }
        }
    }

    const forgotPasswordSubmit = async () => {
        if (!codigoVerificacao || !password) {
            return toastr.error("Por favor preencha os campos para continuar.", "Erro!");
        }

        if (password !== confirmarcaoSenha) {
            return toastr.error("Senha e sua confirmação estão diferentes", "Erro!");
        }

        try {
            await Auth.forgotPasswordSubmit(username.toLowerCase().trim(), codigoVerificacao, password);
            toastr.success("Nova senha configurada com sucesso.", "Senha alterada!");
            navigate('/login');
        } catch (err) {
            if (err.message === "Invalid verification code provided, please try again.") {
                toastr.error("Erro!", "Código de verificação incorreto.");
            } else {
                toastr.error("Erro!", "Erro ao confirmar nova senha");
            }
        }
    };

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <HeaderAuth 
                                    titulo="Esqueci minha senha." 
                                    subtitulo="Recupere sua senha." 
                                    imagem={zib} 
                                />
                                <CardBody className="pt-0">
                                    <LogoCliente />
                                    <div className="p-2">
                                        {props.forgetError && (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {props.forgetError}
                                            </Alert>
                                        )}
                                        {code && (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {`Por favor digite o código de verificação enviado para ${code.Destination || '****'}`}
                                            </Alert>
                                        )}

                                        {code ? (
                                            <>
                                                <div className="authcode mb-2">
                                                    <AuthCode
                                                        characters={6}
                                                        onChange={setarCodigiVerificacao}
                                                        className="form-control form-control-lg text-center d-flex"
                                                        allowedCharacters="^[0-9]"
                                                        inputStyle={{
                                                            width: "47px",
                                                            height: "42px",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                            border: "1px solid #ced4da",
                                                            textTransform: "uppercase",
                                                        }}
                                                    />
                                                </div>
                                                <hr />
                                                <div className="mb-3">
                                                    <Label>Nova senha</Label>
                                                    <Input
                                                        className="form-control"
                                                        placeholder="*******"
                                                        type="password"
                                                        autoComplete="off"
                                                        onChange={(e) => setarPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Confirmar senha</Label>
                                                    <Input
                                                        className="form-control"
                                                        placeholder="*******"
                                                        type="password"
                                                        autoComplete="off"
                                                        onChange={(e) => setConfirmarcaoSenha(e.target.value)}
                                                    />
                                                </div>
                                                <Row>
                                                    <Col className="text-right">
                                                        <button
                                                            onClick={reenViar}
                                                            className="btn btn-primary w-md waves-effect waves-light mr-2"
                                                        >
                                                            Reenviar código
                                                        </button>
                                                        <button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            onClick={forgotPasswordSubmit}
                                                        >
                                                            Enviar
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <AvForm
                                                className="form-horizontal mt-1"
                                                onValidSubmit={handleValidSubmit}
                                            >
                                                <div className="form-group">
                                                    <AvField
                                                        name="email"
                                                        label={_label}
                                                        className="form-control"
                                                        placeholder={_placeHolder}
                                                        type={_input}
                                                        errorMessage={_message}
                                                        required
                                                    />
                                                </div>
                                                <Row className="form-group">
                                                    <Col className="text-right">
                                                        <button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Alterar
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-2 text-center">
                                <p>
                                    Voltar para{" "}
                                    <Link to="login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>
                                </p>
                            </div>
                            <FooterMsg />
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterLogin />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg };
};

export default connect(mapStateToProps, { userForgetPassword })(EsqueciMinhaSenha);