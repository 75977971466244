import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/nuvemPalavras/actions";
import { requisitarDados as requisitarDadosIcrRelacoesCamadas, requisitarLimparDados as requisitarLimparDadosIcrRelacoesCamadas } from "../../../store/capitalRelacional/icrRelacoesCamadas/actions";
import Header from "../../../components/HorizontalLayout/HeaderApp"
import Grafico from "./partes/grafico"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody, Progress, Collapse } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, retornarCorSuplicas } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import { retornarGraficoCapitalRelacionalDinamico } from "../../../helpers/utils-amcharts";
import ModalOpcoesCamadas from '../../../components/CapitalRelacional/ModalOpcoesCamadas';

import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";

const GraficoPainelRelacional = (props) => {
  const { dados, carregando } = useSelector(state => state.NuvemPalavras);
  const dadosRelacao = useSelector(state => state.IcrRelacoesCamadas.dados);
  const carregandoRelacao = useSelector(state => state.IcrRelacoesCamadas.carregando);
  const tela = useSelector((state) => state.Gerais);
  const [mostrarModalOpcoesCamadas, setMostrarModalOpcoesCamadas] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [carregandoConsulta, setCarregandoConsulta] = useState(false);
  const [agentesFiltrados, setAgentesFiltrados] = useState([]);
  const [submitHabilitado, setSubmitHabilitado] = useState(false);
  const [botoesExtrasHeader, setBotoesExtrasHeader] = useState([]);
  const [tituloGrafico, setTituloGrafico] = useState("");

  const suplicas = [
    "suplica_1_",
    "suplica_2_",
    "suplica_3_",
    "suplica_4_",
    "suplica_5_",
    "suplica_6_",
    "suplica_7_",
    "suplica_8_"
  ];

  const engajamentos = [
    { titulo: "Convidados:", propriedade: "pesquisas_enviadas" },
    { titulo: "Respondentes:", propriedade: "pesquisas_feitas" },
    { titulo: "Em andamento:", propriedade: "pesquisas_andamento" },
    { titulo: "Pendentes:", propriedade: "pesquisas_nao_respondidas" }
  ]

  //=====Filtros=====//
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [agente, setAgente] = useState(null);
  //=====Filtros=====//

  //===== Carregando Filtros=====//
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);
  const [filtroCamadasOpcoes, setFiltroCamadasOpcoes] = useState('');

  //===== Carregando Filtros=====//

  const retornarValorProgressBar = (valor) => {
    const valorInicial = valor + 100;
    const escalaPercentual = valorInicial / 200;
    return escalaPercentual * 100;
  }

  useLayoutEffect(() => {
    if (mostrarFiltros) {

      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarFiltros(!mostrarFiltros)
            },
            icone: "bx bx bx-filter-alt"
          }
        ]
      })
    } else {
      setBotoesExtrasHeader(
        {
          botoesExtras: [
            {
              funcao: () => {
                setMostrarFiltros(!mostrarFiltros)
              },
              icone: "bx bx bx-filter-alt"
            },
            {
              funcao: () => {
                setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
              },
              icone: "fal fa-layer-group"
            }
          ]
        })
    }


  }, [mostrarFiltros])

  useEffect(() => {
    if (mostrarModalOpcoesCamadas) {
      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
            },
            icone: "fal fa-layer-group"
          }
        ]
      })
    } else {
      if (dados) {
        setBotoesExtrasHeader(
          {
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros)
                },
                icone: "bx bx bx-filter-alt"
              },
              {
                funcao: () => {
                  setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
                },
                icone: "fal fa-layer-group"
              }
            ]
          })
      }
    }
  }, [mostrarModalOpcoesCamadas]);

  const atualizarDados = async (idsAgentesHolograma, idsRelacoesCamadasOpcoes) => {
    setCarregandoConsulta(true);

    await dispatch(requisitarDados({
      token,
      idParceiro: parceiro?.value,
      idCliente: cliente?.value,
      idPeriodoResposta: periodo?.value,
      idsAgentesHolograma,
      idsRelacoesCamadasOpcoes,
      //idAgenteHolograma: agente?.value,

      // idCamada: camadas?.value,
      //visualizacao: "opcao",

      //idParceiro: "000002",
      //idCliente: "000004",
      //idPeriodoResposta: "00002",
      //idsAgentesHolograma: "5|;",
      //idCamadaOpcao: "",
      idTipo: "P"
    }
    ));
    await dispatch(requisitarDadosIcrRelacoesCamadas({
      token,
      idParceiro: parceiro?.value,
      idCliente: cliente?.value,
      idPeriodoResposta: periodo?.value,
      idsAgentesHolograma,
      idsRelacoesCamadasOpcoes,
      visualizacao: "relacao"
      // idCamada: camadas?.value,
      //visualizacao: "opcao",

      //idParceiro: "000002",
      //idCliente: "000004",
      //idPeriodoResposta: "00002",
      //idsAgentesHolograma: "5|;",
      //idCamadaOpcao: "",
      //idTipo: "P"
    }
    ));
    setMostrarFiltros(false);
    setAgentesFiltrados(agente);
    setTimeout(() => {
      setCarregandoConsulta(false);
      setSubmitHabilitado(true);
    }, 2000)

  }

  useEffect(() => {
    if (!dadosRelacao?.data) return;

    dadosRelacao.data.map((item, i) => {
      suplicas.map((suplica, i_suplica) => {
        setTimeout(() => {
          retornarGraficoCapitalRelacionalDinamico(item[`${suplica}resultado`], `painelGraficoSuplica_${i}_${i_suplica}`, { id: `graficoSuplica_${i}_${i_suplica}` });
        }, 1000);
      })
    })

  }, [dadosRelacao?.data]);

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {

  }, [carregando]);

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length && tela.agentes?.length) {
      setSubmitHabilitado(true);
    }

  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    //setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, cliente.value));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value));
  }, [cliente]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setAgente([]);
      setFiltroCamadasOpcoes([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);
      setCarregandoAgente(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente?.value || ""));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente?.value || ""));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setPeriodo([]);
    setAgente([]);
    setFiltroCamadasOpcoes([]);
    setCarregandoPeriodo(true);
    setCarregandoAgente(true);

    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));

  };

  useEffect(() => {
    atualizarDadosAmbiente();

    return () => { dispatch(requisitarLimparDados()); dispatch(requisitarLimparDadosIcrRelacoesCamadas()) }
  }, []);

  const executarConsulta = () => {

    setSubmitHabilitado(false);
    const agentesFiltro =
      (agente && agente.length) > 0
        ? agente.map((item) => item.value).join("|;") + "|;"
        : "";

    const camadasOpcoes =
      (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
        ? filtroCamadasOpcoes.map((item) => item.value).join("|;") + "|;"
        : "";

    atualizarDados(agentesFiltro, camadasOpcoes);
  }

  const formatarTituloOpcoesCamadas = (idRelacao) => {

    const camadasOpcoes =
      (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
        ? filtroCamadasOpcoes?.filter(a => a.value.split('|')[0] == idRelacao).map((item) => item.label).join(", ")
        : "";

    return camadasOpcoes ? `${camadasOpcoes}` : ''
  }

  return (
    <React.Fragment>
      <PaginaLoader carregando={tela.carregando || carregandoConsulta} />
      <>
        <Header parametros={botoesExtrasHeader} />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Período"
                      className="basic-single"
                      isLoading={carregandoPeriodo}
                      value={periodo}
                      options={tela.periodos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setPeriodo(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Todas as relações"
                      className="basic-single"
                      isMulti
                      isLoading={carregandoAgente}
                      options={tela.agentes}
                      value={agente}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setAgente(Object || []);
                        //atualizarDependenciasAgente(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      executarConsulta();
                    }}
                    className="ml-2"
                    disabled={!submitHabilitado}
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>


              </Form>
            </FiltroBrytum>

          </div>
          {(!carregando && !carregandoRelacao && dados && dadosRelacao)
            &&
            dadosRelacao?.data?.map((item, i) => {
              if ((!agentesFiltrados?.length || agentesFiltrados?.filter(a => a.value == item.icr_id_agente_holograma).length) && item.relacao_icr)
                return (
                  <Container className="mt-3" key={`painel_relacao_${i}`}>
                    <Row>

                      <Col sm={12} className={"pr-0"}>
                        <Row>
                          <Col sm={2}>
                            <Card style={{ marginBottom: "12px" }} color={!item.relacao_icr ? "info" : item.relacao_icr[0] == "A" ? "success" : item.relacao_icr[0] == "B" ? "warning" : "danger"}>
                              <CardBody className='text-white'>
                                <h6 className='text-white'>
                                  <strong>
                                    {item?.icr_descricao}
                                  </strong>
                                </h6>
                                <div className={"d-flex"} style={{ height: 119 }}>
                                  <div className={`align-self-center flex-grow-1 text-center`} style={{ fontWeight: "bold", fontSize: "79px", paddingRight: "10px", paddingTop: "15px", paddingBottom: "15px", lineHeight: "45px" }}>
                                    {item.relacao_icr}
                                  </div>

                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col sm={2} style={{ height: "100%" }}>
                            <Card outline className={`mb-0 border border-${item?.pesquisas_feitas_indicador == "verde" ? "success" : item?.pesquisas_feitas_indicador == "amarelo" ? "warning" : "danger"}`} color={item?.pesquisas_feitas_indicador == "verde" ? "success" : item?.pesquisas_feitas_indicador == "amarelo" ? "warning" : "danger"} >
                              <CardBody>
                                <div style={{ fontSize: "12px", paddingLeft: "26px" }}>
                                  Engajamento
                                </div>
                                <div style={{ position: "absolute", top: "14px", left: "18px", fontSize: "20px" }}>
                                  {item?.pesquisas_feitas_indicador === "vermelho" && <i className="fad fa-circle text-danger"></i>}
                                  {item?.pesquisas_feitas_indicador === "verde" && <i className="fad fa-circle text-success"></i>}
                                  {item?.pesquisas_feitas_indicador === "amarelo" && <i className="fad fa-circle text-warning"></i>}

                                </div>
                                <div className={"d-flex w-100"}>
                                  <ul className={"w-100"} style={{ color: "#9092a1", fontSize: "11px", paddingLeft: "20px", marginBottom: "14px", marginTop: "14px" }}>
                                    {
                                      engajamentos?.map((engajamento, engajamento_i) => {
                                        return (
                                          <li className={"mt-2"} key={`engajamento${engajamento_i}`}>
                                            <div className={"d-flex"}>
                                              <div className={"flex-grow-1"}>{engajamento.titulo}</div>
                                              <div>
                                                <strong>
                                                  {item[engajamento.propriedade]}
                                                </strong>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })
                                    }
                                  </ul>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col sm={8} style={{ height: "100%" }}>
                            <Row>
                              {suplicas.map((suplica, i_suplica) => {
                                return (
                                  <Col sm={3} className='' key={`idSuplica_${i_suplica}`}>
                                    <Card outline className={`border border-${retornarCorSuplicas(item[`${suplica}resultado`])}`} style={{ height: 80 }}>
                                      <CardBody className={'text-center p-2'} style={{ heigth: 70 }}>
                                        <div style={{ fontSize: 9 }}>
                                          {item[`${suplica}nome`]}
                                        </div>
                                        <div id={`painelGraficoSuplica_${i}_${i_suplica}`} className={'w-100'} style={{ position: "absolute", top: 20, left: 0 }}>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                )
                              })
                              }
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                      {/* <Col sm={12} className='pr-0'>
                        <Card >
                          <CardBody>
                            <div className={" w-100"}>
                              {formatarTituloOpcoesCamadas(item.icr_id_agente_holograma)}
                            </div>
                          </CardBody>
                        </Card>
                      </Col> */}
                      <Col sm={12} className='pr-0'>
                        <Card >
                          <CardBody style={{ height: "76px", overflowY: "hidden", animationDuration: "1s", animationIterationCount: 1, animationFillMode: "forwards" }} id={`collapse_${i}`}>
                            <div className="w-100" style={{ height: "calc(100vh - 373px)" }}>
                              {/* <h6>Nuvem de oportunidades</h6> */}
                              <Button outline color="secondary" id={`btn_collapse_${i}`} onClick={() => {

                                if (!document.getElementById(`collapse_${i}`).style.animationName || document.getElementById(`collapse_${i}`).style.animationName == "nuvemPalavrasFechada") {
                                  document.getElementById(`collapse_${i}`).style.animationName = "nuvemPalavrasAberta";
                                  document.getElementById(`btn_collapse_${i}`).classList.add("btn-outline-secondary-hover");
                                  document.getElementById(`chartDiv_${i}`).style.opacity = 1;
                                }
                                else {
                                  document.getElementById(`collapse_${i}`).style.animationName = "nuvemPalavrasFechada";
                                  document.getElementById(`btn_collapse_${i}`).classList.remove("btn-outline-secondary-hover");
                                  setTimeout(() => {
                                    document.getElementById(`chartDiv_${i}`).style.opacity = 0;
                                  }, 1000);
                                }
                              }}>
                                Oportunidades <span className="badge bg-info ms-1 text-white">{dados?.filter(a => a.icr_id_agente_holograma == item.icr_id_agente_holograma).length || ""}</span>
                              </Button>
                              <Grafico dados={dados} idAgenteHolograma={item.icr_id_agente_holograma} id={`chartDiv_${i}`} />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm={12} className=''>
                        <hr className='mt-0 mb-2' />
                      </Col>
                    </Row>

                  </Container>
                )
            })
          }
        </div>
      </>
      <ModalOpcoesCamadas submitHabilitado={submitHabilitado} executarConsulta={executarConsulta} filtroCamadasOpcoes={filtroCamadasOpcoes} setFiltroCamadasOpcoes={setFiltroCamadasOpcoes} token={token} idParceiro={parceiro?.value} idCliente={cliente?.value} aberto={mostrarModalOpcoesCamadas} setAberto={setMostrarModalOpcoesCamadas} relacoes={agente?.length ? agente : tela.agentes || []}></ModalOpcoesCamadas>
    </React.Fragment >
  );
}
export default GraficoPainelRelacional;