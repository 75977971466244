import * as types from "./types";

const inicialState = {
  parceiros: [],
  loadingParceiro: false,

  clientes: [],
  loadingCliente: false,

  periodos: [],
  loadingPeriodos: false,

  agentes: [],
  loadingAgentes: false,

  camadas: [],
  carregando: false,
  falha: "",
};

const Gerais = function (state = inicialState, action) {
  switch (action.type) {
    case types.REQUISITAR_DADOS:
      return { ...state, carregando: true };
    case types.REQUISITAR_DADOS_SUCESSO:
      return {
        ...state,
        carregando: false,
        parceiros: action.payload.parceiros,
        clientes: action.payload.clientes,
        periodos: action.payload.periodos,
        agentes: action.payload.agentes,
        camadas: action.payload.camadas,
      };
    case types.ATUALIZAR_LISTA_CLIENTES_SUCCESS:
      return {
        ...state,
        clientes: action.payload.clientes,
      };
    case types.ATUALIZAR_LISTA_PERIODO_SUCCESS:
      return {
        ...state,
        periodos: action.payload.periodos,
      };
    case types.ATUALIZAR_LISTA_AGENTES_SUCCESS:
      return {
        ...state,
        agentes: action.payload.agentes,
      };
    case types.ATUALIZAR_LISTA_CAMADAS_SUCCESS:
      return {
        ...state,
        camadas: action.payload.camadas,
      };
    case types.REQUISITAR_DADOS_FALHOU:
      return { ...state, carregando: false, falha: action.payload };
    default:
      return state;
  }
};

export default Gerais;
