import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados } from "../../store/topFamilias/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { exportarExcel, Hoje, Mes, concatenaLojas, removerFocusNoBotao, primeiroDiaMes, toDateSQL } from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Flatpickr from "react-flatpickr";
import Select from 'react-select';
import { Portuguese } from "../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import makeAnimated from 'react-select/animated';
import FocarPaisagem from '../../components/forcarPaisagem';

const animatedComponents = makeAnimated();

const TopFamiliasOnDemand = (props) => {
    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.TopFamilias);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const [lojas, setLojas] = useState([]);
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);

    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };


    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            data_inicial: toDateSQL(dataInicial),
            data_final: toDateSQL(dataFinal),
            arvore: 1,
            visualizacao: "familia",
            agrupamento: "total",
            classeN1: "",
            u_unidades_id: concatenaLojas(lojasRef.current.lojas),
            token,
            exportacao: "matrix"
        }));
        setMostrarFiltros(false);
    }

    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token));
    }

    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();
    }, []);


    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const atualizarDadosDemanda = () => {
        setMostrarFiltros(false);
        setLoading(true);
        if (grid.current) {
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                data_inicial: toDateSQL(dataInicial),
                data_final: toDateSQL(dataFinal),
                arvore: 5,
                visualizacao: "familia",
                agrupamento: "total",
                classeN1: "",
                u_unidades_id: concatenaLojas(lojasRef.current.lojas),
                sob_demanda: 1,
                nivel_atual: 0,
                token,
                exportacao: "matrix"
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    const gridInfo = useMemo(() => {
        if (!dados && !dados?.data) return
        return gridConfig(dados, grid, token, toDateSQL(dataInicial), toDateSQL(dataFinal), concatenaLojas(lojasRef.current.lojas));
    }, [dados, grid])



    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="select2-container"  >
                            <div className="unidadesControler">
                                <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                />
                            </div>

                        </FormGroup>
                        <FormGroup className="ml-2" style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: dataInicial,
                                    disableMobile: "true"
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataInicial(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <div className={"ml-1 mr-1"} >a</div>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: "today",
                                    disableMobile: "true"
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataFinal(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {dados && <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    key={carregando}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />}
            </>
        </React.Fragment>
    );
}
export default TopFamiliasOnDemand;