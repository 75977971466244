export const REQUISITAR_DADOS = "gerais@REQUISITAR_DADOS";
export const REQUISITAR_DADOS_SUCESSO = "gerais@REQUISITAR_DADOS_SUCESSO";
export const REQUISITAR_DADOS_FALHOU = "gerais@REQUISITAR_DADOS_FALHOU";

export const ATUALIZAR_LISTA_CLIENTES = "gerais@ATUALIZAR_LISTA_CLIENTES";
export const ATUALIZAR_LISTA_CLIENTES_SUCCESS =
  "gerais@ATUALIZAR_LISTA_CLIENTES_SUCCESS";

export const ATUALIZAR_LISTA_PERIODO = "gerais@ATUALIZAR_LISTA_PERIODO";
export const ATUALIZAR_LISTA_PERIODO_SUCCESS =
  "gerais@ATUALIZAR_LISTA_PERIODO_SUCCESS";

export const ATUALIZAR_LISTA_AGENTES = "gerais@ATUALIZAR_LISTA_AGENTES";
export const ATUALIZAR_LISTA_AGENTES_SUCCESS =
  "gerais@ATUALIZAR_LISTA_AGENTES_SUCCESS";

export const ATUALIZAR_LISTA_CAMADAS = "gerais@ATUALIZAR_LISTA_CAMADAS";
export const ATUALIZAR_LISTA_CAMADAS_SUCCESS =
  "gerais@ATUALIZAR_LISTA_CAMADAS_SUCCESS";
