import React, { useEffect, useRef, useMemo, useState } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../../store/raioXLoja/actions";
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form, Input } from 'reactstrap';
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from '@bryntum/grid-react';
import { exportarExcel, retornarTipoCustosBTFlex, removerFocusNoBotao } from '../../../helpers/utils';
import FocarPaisagem from '../../../components/forcarPaisagem';
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const RaioXLojaBTFlex = () => {
    const tiposCusto = retornarTipoCustosBTFlex();
    const { dados, carregando } = useSelector(state => state.RaioXLoja);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const token = useSelector(state => state.Login.user.tokenNOL);
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
    const [mostrarFiltros, setMostrarFiltros] = useState(false);

    const atualizarDados = async () => {
        await dispatch(requisitarDados(token, "", tipoCusto.value));
        setMostrarFiltros(false);
    }

    useEffect(() => {
        if (!dados) atualizarDados();
        return () => {
            if (grid.current) {
                grid.current.gridInstance.store.stopAutoUpdate();
                grid.current.gridInstance.destroy();
                dispatch(requisitarLimparDados());
            }
        }
    }, []);

    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, token);
    }, [dados, grid])


    const trocarTipoCusto = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto([])
        }
    }

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={carregando} />
            <>
                <Header
                    parametros={
                        {
                            exportarExcel: () => exportarExcel(dados),
                            titulo: "BiZoiada",
                            icone: "fad fa-telescope",
                            botoesExtras: [
                                {
                                    funcao: () => {
                                        setMostrarFiltros(!mostrarFiltros)
                                    },
                                    icone: "bx bx bx-filter-alt"
                                }
                            ]
                        }
                    } />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={tiposCusto}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposCusto[0] || []}
                                    onChange={Object => {
                                        trocarTipoCusto(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { removerFocusNoBotao(e.currentTarget); atualizarDados() }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    key={carregando}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />
            </>
        </React.Fragment>
    );
}
export default RaioXLojaBTFlex;