import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/graficoReviravoltaAia/actions"
import Header from "../../../components/HorizontalLayout/HeaderApp"
import Grafico from "./partes/grafico"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import ModalOpcoesCamadas from '../../../components/CapitalRelacional/ModalOpcoesCamadas';
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";

const GraficoReviravoltaAia = (props) => {
  const { dados, carregando } = useSelector(state => state.GraficoReviravoltaAia);
  const tela = useSelector((state) => state.Gerais);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [agentesSelecionados, setAgentesSelecionados] = useState([]);
  const [mostrarModalOpcoesCamadas, setMostrarModalOpcoesCamadas] = useState(false);



  //=====Filtros=====//
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [agente, setAgente] = useState(null);
  const [camadas, setCamadas] = useState(null);
  const [filtroCamadasOpcoes, setFiltroCamadasOpcoes] = useState('');

  //=====Filtros=====//

  //===== Carregando Filtros=====//
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);
  const [carregandoCamadas, setCarregandoCamadas] = useState(false);
  const [botoesExtrasHeader, setBotoesExtrasHeader] = useState([]);
  //===== Carregando Filtros=====//

  const [submitHabilitado, setSubmitHabilitado] = useState(false);


  const atualizarDados = async (
    idParceiro,
    idCliente,
    idPeriodoResposta,
    idsAgentesHolograma,
    idsRelacoesCamadasOpcoes
  ) => {
    await dispatch(requisitarDados({
      idParceiro,
      idCliente,
      idPeriodoResposta,
      idsAgentesHolograma,
      idCamadaOpcao: "",
      idsRelacoesCamadasOpcoes,
      // idParceiro: "000002",
      // idCliente: "000004",
      // idPeriodoResposta: "00002",
      // idsAgentesHolograma: "1|;2|;",
      // idCamadaOpcao: "",
      token,
      ordenarPelaRelacao: 1
    }
    ));
    setAgentesSelecionados(tela.agentes);
    setSubmitHabilitado(true);
  }
  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);
    // if (!agente) setAgente(tela.agentes[0]);
    // if (!camadas) setAgente(tela.camadas[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length && tela.camadas?.length) {
      setSubmitHabilitado(true);
    }
  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    // setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    setCamadas([]);
    setCarregandoCamadas(false);
  }, [tela.camadas]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
    dispatch(
      requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value)
    );
  }, [cliente]);



  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setAgente([]);
      setFiltroCamadasOpcoes([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);
      setCarregandoAgente(true);
    
      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente.value));
    
    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setPeriodo([]);
    setAgente([]);
    setCarregandoPeriodo(true);
    setCarregandoAgente(true);
    setFiltroCamadasOpcoes([]);

    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));
  };


  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados("", "", "", "", "");
    return () => { dispatch(requisitarLimparDados()) }
  }, []);


  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);

    if (mostrarFiltros) {

      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarFiltros(!mostrarFiltros)
            },
            icone: "bx bx bx-filter-alt"
          }
        ]
      })
    } else {
      setBotoesExtrasHeader(
        {
          botoesExtras: [
            {
              funcao: () => {
                setMostrarFiltros(!mostrarFiltros)
              },
              icone: "bx bx bx-filter-alt"
            },
            {
              funcao: () => {
                setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
              },
              icone: "fal fa-layer-group"
            }
          ]
        })
    }

  }, [mostrarFiltros])

  useEffect(() => {
    if (mostrarModalOpcoesCamadas) {
      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
            },
            icone: "fal fa-layer-group"
          }
        ]
      })
    } else {
      if (dados) {
        setBotoesExtrasHeader(
          {
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros)
                },
                icone: "bx bx bx-filter-alt"
              },
              {
                funcao: () => {
                  setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
                },
                icone: "fal fa-layer-group"
              }
            ]
          })
      }
    }
  }, [mostrarModalOpcoesCamadas]);

  const executarConsulta = () => {
    setSubmitHabilitado(false);

    const agentesFiltro =
      (agente && agente.length) > 0
        ? agente.map((item) => item.value).join("|;") + "|;"
        : "";


    const camadasOpcoes =
      (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
        ? filtroCamadasOpcoes.map((item) => item.value).join("|;") + "|;"
        : "";

    atualizarDados(
      parceiro.value,
      cliente.value,
      periodo.value,
      agentesFiltro,
      camadasOpcoes
    );
    setMostrarFiltros(false);

  }

  return (
    <React.Fragment>
      <PaginaLoader carregando={tela.carregando || carregando} />
      <>
        <Header
          parametros={botoesExtrasHeader} />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Período"
                      className="basic-single"
                      isLoading={carregandoPeriodo}
                      value={periodo}
                      options={tela.periodos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setPeriodo(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Todas as relações"
                      className="basic-single"
                      isMulti
                      isLoading={carregandoAgente}
                      options={tela.agentes}
                      value={agente}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setAgente(Object || []);
                        setFiltroCamadasOpcoes([]);
                        //atualizarDependenciasAgente(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                {/* <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "230px" }}>
                    <Select
                      placeholder="Camada de negócio"
                      className="basic-single"
                      options={tela.camadas}
                      isLoading={carregandoCamadas}
                      value={camadas}
                      isSearchable={false}
                      isMulti
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setCamadas(Object || []);
                      }}
                    />
                  </div>
                </FormGroup> */}
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      executarConsulta();
                    }}
                    className="ml-2"
                    disabled={!submitHabilitado}
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>


              </Form>
            </FiltroBrytum>

          </div>
          <Container fluid className="fixWith graficoprincipal mt-3">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <Grafico dados={dados} agentes={agentesSelecionados} filtroCamadasOpcoes={filtroCamadasOpcoes} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      <ModalOpcoesCamadas submitHabilitado={submitHabilitado} executarConsulta={executarConsulta} filtroCamadasOpcoes={filtroCamadasOpcoes} setFiltroCamadasOpcoes={setFiltroCamadasOpcoes} token={token} idParceiro={parceiro?.value} idCliente={cliente?.value} aberto={mostrarModalOpcoesCamadas} setAberto={setMostrarModalOpcoesCamadas} relacoes={agente?.length ? agente : tela.agentes || []}></ModalOpcoesCamadas>
    </React.Fragment>
  );
}
export default GraficoReviravoltaAia;