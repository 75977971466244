import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { retornarTipoCustosBTFlex, retornarTipoCustos, exportarExcel, Hoje, toMoney, primeiroDiaMes, toDateSQL, Mes, valorEmInteiro, removerFocusNoBotao } from "../../../helpers/utils";
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../../store/graficoUnidades/actions";
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { FormGroup, Button, Form, Container, } from 'reactstrap';
import Grafico from "./partes/grafico";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../../helpers/locale/portuguese";
import { pegarCores, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from '../../../helpers/utils';
import HeaderGrafico from '../../../components/headerGrafico';
import FiltroCasca from '../../../components/filtroGrafico/filtroCasca';
import GraficoAltura from '../../../components/graficoAltura';
import FocarPaisagem from '../../../components/forcarPaisagem';
import { retornarIdCliente } from '../../../cliente/variaveis';
import Select from 'react-select';
import FiltroBrytum from "../../../components/filtroBrytum";
import { retornaExportacaoExcel } from './partes/variaveis';

import makeAnimated from 'react-select/animated';
const idCliente = retornarIdCliente();
const tiposCusto = retornarTipoCustos();
const GraficoUnidadesBTFlex = () => {
  const tiposCusto = retornarTipoCustosBTFlex();
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const carregandoMesAtual = useSelector(state => state.MapaNegociosUnidades.carregando);
  const { dados, carregando } = useSelector(state => state.GraficoUnidades);
  const [cores, seTcores] = useState(pegarCores());
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const dispatch = useDispatch();
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
  const [propTipoCusto, setPropTipoCusto] = useState(tiposCusto[1].value == "02" ? "02_" : "");
  const dataInicialRef = useRef(null);

  dataInicialRef.current = { dataInicial, setDataInicial };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };


  const atualizarDados = async () => {
    if (!dataInicialRef.current.dataInicial || !dataFinalRef.current.dataFinal) return

    await dispatch(requisitarDados({
      data_inicial: toDateSQL(dataInicialRef.current.dataInicial),
      data_final: toDateSQL(dataFinalRef.current.dataFinal),
      arvore: 1,
      visualizacao: "zoom",
      agrupamento: "total",
      exportacao: retornaExportacaoExcel(tipoCusto.value),
      token,
      retornaMC3: "",
      retorna_desafio: "",
      base_calculo_percentual: "",
      tipo_cmv: tipoCusto.value
    }
    ));
    setMostrarFiltros(false);
  }

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  useEffect(() => {
    setMostrarTotais(!carregando && (dados && dados.data && dados.data.length));
  }, [carregando, dados])

  useEffect(() => {
    if (dados) {
      setPropTipoCusto(tipoCusto.value == "02" ? "02_" : "");
    }
  }, [dados]);

  useLayoutEffect(() => {
    var objeto = document.querySelector("#margem");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])



  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando || carregandoMesAtual} />
      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: dataInicial,
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataInicial(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <div className={"ml-1 mr-1"} >a</div>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: "today",
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataFinal(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="ml-2">
                  <div className="unidadesControler">
                    <Select
                      placeholder=""
                      className="basic-single"
                      options={tiposCusto}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      defaultValue={tiposCusto[0] || []}
                      onChange={Object => {
                        trocarTipoCusto(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>
            <Container className="fixWith" fluid>
              <div className={"headers mt-4"}>
                {
                  <HeaderGrafico mostrar={mostrarTotais} colunas={3} itens={[
                    {
                      nome: dados?.header?.faturamento,
                      valor: `R$ ${toMoney(dados?.footer?.faturamento, 0)}`,
                      marginRight: 30,
                      corClass: "text-success"
                    },
                    {
                      nome: dados?.header?.impostos_valor,
                      valor: "R$ " + toMoney(dados?.footer?.impostos_valor, 0),
                      corClass: "text-warning"
                    },
                    {
                      nome: dados?.header?.devolucao_total_valor,
                      valor: "R$ " + toMoney(dados?.footer?.devolucao_total_valor, 0),
                      corClass: "text-info"
                    },

                    {
                      nome: dados?.header?.cmv_valor,
                      valor: `${toMoney(dados?.footer?.cmv_percentual, 1)}%`,
                      segundoValor: `${valorEmInteiro(dados?.footer?.cmv_valor, false)}`,
                      corClass: "text-danger"
                    },
                    {
                      nome: dados?.header?.margem_contribuicao_valor,
                      valor: `${toMoney(dados?.footer?.margem_contribuicao_percentual, 1)}%`,
                      segundoValor: `${valorEmInteiro(dados?.footer?.margem_contribuicao_valor, false)}`,
                      corClass: "text-dark"
                    }
                  ]} />}
              </div>
            </Container>
          </div>



          <GraficoAltura>
            <Grafico index={"margem"} dados={dados} cores={cores} />
          </GraficoAltura>


        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoUnidadesBTFlex;