
import { 
  bizFrontEnd, 
  setasParaCimaOuParaBaixo, 
  tamanhos, 
  setaSumario,
  numeroComas,
  destacarCelula } from "../../helpers/biz-padrao-visual";
import {
  NumberFormat
} from '@bryntum/grid/grid.umd';

import "../../helpers/locale/locale_BR"

const gridConfig = (gridZib) => {
  const moeda = new NumberFormat('9,999.99##');
  return {
    
    store: {
      tree: true,
      data: gridZib.dados,
    },
    columns: [
      {
        text: "Negócio/Unidades",
        field: "nome",
        type: "tree",
        expanded: true,
        flex: 1,
        sum : ()=>{
          return "Total"
        },
        tooltipRenderer: false,
        headerRenderer: ()=>{
          return(
            `Negócio <br />
            <div class="small-text">Unidades</div>
            `
          )
        }
      },
      {
        text: gridZib.header.mes,
        align: "center",
        children: [
          {
            text: "Ticket Médio",
            field: "ticketMedio",
            type: "number",
            icon: bizFrontEnd("ticketMedio").coluna.icone,
            align: "right",
            width: tamanhos.ticketMedio,
            sum: ()=>{
              return {valor:`${gridZib.footer.ticketMedio}`, valorPerc: `${gridZib.footer.ticketMedioPerc}`}
            },
            summaryRenderer: ({sum})=> {
              return  setaSumario(moeda.format(sum.valor), sum.valorPerc);
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorTicketMedio, cellElement);
              setasParaCimaOuParaBaixo(value, cellElement, record.ticketMedioPerc);
              return moeda.format(value)
            },
            headerRenderer : ({column, headerElement}) => {
              return "Ticket Médio"
            }
          },
          {
            text: "Cupons",
            field: "cupons",
            type: "number",
            icon: bizFrontEnd("cupons").coluna.icone,
            align: "right",
            width: tamanhos.cupons,
            sum: ()=>{
              return {valor:`${numeroComas(gridZib.footer.cupons)}`, valorPerc: `${gridZib.footer.cuponsPerc}`}
            },
            summaryRenderer: ({sum})=> {
              return  setaSumario(sum.valor,sum.valorPerc ) 
            },
            renderer :({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorCupons, cellElement);
              setasParaCimaOuParaBaixo(value, cellElement, record.cuponsPerc);
              return numeroComas(value)
            },
            headerRenderer: ({headerElement}) => {
              return "Cupons"
            }
          },
          {
            text: "Faturamento",
            field: "faturamento",
            type: "number",
            icon: bizFrontEnd("faturamento").coluna.icone,
            align: "right",
            width: tamanhos.faturamento,
            sum: ()=>{
              return {valor:`${moeda.format(gridZib.footer.faturamento)}`, valorPerc: gridZib.footer.faturamentoPerc}
            },
            summaryRenderer: ({sum})=> {
              return  setaSumario(sum.valor, sum.valorPerc) 
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value,record.indicadorFaturamento, cellElement);
              setasParaCimaOuParaBaixo(value, cellElement, record.faturamentoPerc);
              return moeda.format(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Faturamento"
            }
          },
        ],
      },
      {
        text: gridZib.header.mesAnterior,
        align: "center",
        children: [
          {
            text: "Ticket Médio",
            field: "ticketMedioAnterior",
            type: "number",
            icon: bizFrontEnd("ticketMedio").coluna.icone,
            align: "right",
            width: tamanhos.ticketMedio,
            sum: ()=>{
              return `${moeda.format(gridZib.footer.ticketMedioAnterior)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorTicketMedioAnterior, cellElement);
              return moeda.format(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Ticket Médio"
            },
            tooltipRenderer : false,
          },
          {
            text: "Cupons",
            field: "cuponsAnterior",
            type: "number",
            icon: bizFrontEnd("cupons").coluna.icone,
            align: "right",
            width: tamanhos.cupons,
            sum: ()=>{
              return `${numeroComas(gridZib.footer.cuponsAnterior)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorCuponsAnterior, cellElement);
              return numeroComas(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Cupons"
            }
          },
          {
            text: "Faturamento",
            field: "faturamentoAnterior",
            type: "number",
            icon: bizFrontEnd("faturamento").coluna.icone,
            align: "right",
            width: tamanhos.faturamento,
            sum: ()=>{
              return `${moeda.format(gridZib.footer.faturamentoAnterior)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorFaturamentoAnterior, cellElement);
              return moeda.format(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Faturamento"
            }
          }
        ],
      },
      {
        text: gridZib.header.ano,
        align: "center",
        children: [
          {
            text: "Ticket Médio",
            field: "ticketMedioAno",
            type: "number",
            icon: bizFrontEnd("ticketMedio").coluna.icone,
            align: "right",
            width: tamanhos.ticketMedio,
            sum: ()=>{
              return `${moeda.format(gridZib.footer.ticketMedioAno)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorTicketMedioAno, cellElement);
              return moeda.format(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Ticket Médio"
            },
            tooltipRenderer: false     
          },
          {
            text: "Cupons",
            field: "cuponsAno",
            type: "number",
            icon: bizFrontEnd("cupons").coluna.icone,
            align: "right",
            width: tamanhos.cupons,
            sum: ()=>{
              return `${numeroComas(gridZib.footer.cuponsAno)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorCuponsAno, cellElement);
              return numeroComas(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Cupons"
            }
          },
          {
            text: "Faturamento",
            field: "faturamentoAno",
            type: "number",
            icon: bizFrontEnd("faturamento").coluna.icone,
            align: "right",
            width: tamanhos.faturamento,
            sum: ()=>{
              return `${moeda.format(gridZib.footer.faturamentoAno)}`
            },
            renderer : ({value, cellElement, record}) => {
              destacarCelula(value, record.indicadorFaturamentoAno, cellElement);
              return moeda.format(value)
            },
            headerRenderer : ({headerElement}) => {
              return "Faturamento"
            }
          }
        ]
      }
    ]
  };
};


export { gridConfig };
