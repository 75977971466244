import "../../../helpers/locale/locale_BR";
import { toDate, valorEmMoeda } from "../../../helpers/utils";
import { bizGridResponsive } from "../../../helpers/biz-padrao-visual";

const gridConfig = (dados, grid, colunasVisiveis) => {
  const larguraColunaComputador = 100;
  const configResponsiveLevels = {
    "*": { width: larguraColunaComputador },
    small: { width: 100 },
  };
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      data: dados.data,
    },
    columns: [
      {
        text: dados.header.fornecedor,
        field: "fornecedor",
        minWidth: 250,
        flex: 1,
        align: "left",
        sum: true,
        summaryRenderer: ({ sum }) => {
          return "Total";
        },
      },
      {
        text: dados.header.codigo,
        field: "codigo",
        width: 120,
      },

      {
        text: dados.header.descricao,
        field: "descricao",
        width: 380,
      },

      {
        text: dados.header.dataEntrega,
        field: "dataEntrega",
        width: 120,
        filterable: false,
        renderer: ({ value }) => {
          return toDate(value);
        },
      },
      {
        text: dados.header.dataPedido,
        field: "dataPedido",
        width: 120,
        filterable: false,
        renderer: ({ value }) => {
          return toDate(value);
        },
      },
      {
        text: dados.header.diasAtraso,
        field: "diasAtraso",
        width: 120,
        filterable: false,
      },
      {
        text: dados.header.saldo,
        field: "saldo",
        align: "right",
        width: 120,
        filterable: false,
        renderer: ({ value, record }) => {
          if (record.casasDecimais > 4) {
          }
          return value.toFixed(record.casasDecimais || 0);
        },
      },

      {
        text: dados.header.unidadeMedida,
        field: "unidadeMedida",
        width: 90,
        filterable: false,
      },
      {
        text: dados.header.valorTotal,
        field: "valorTotal",
        align: "right",
        width: 130,
        sum: true,
        filterable: false,
        summaryRenderer: ({ sum }) => {
          return valorEmMoeda(sum, true);
        },
        renderer: ({ value }) => {
          return valorEmMoeda(value, true);
        },
      },
    ],
  };
};

export { gridConfig };
