import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { pegarCores, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, toMoney } from "../../../../helpers/utils"
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts, formatarDatasZoomAmCharts, retornarQtdeCategoriasZoomAmCharts } from "../../../../helpers/utils-amcharts"

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;
const GraficoRelacao = ({ dados }) => {

    const dadosGrafico = dados || [];
    const chartReferencia = useRef(null);
    useLayoutEffect(() => {


        if (dadosGrafico.length > 0) {

            const coresRelacao = {
                "success": "#34c38f",
                "warning": "#f1b44c",
                "danger": "#f46a6a"
            }


            const cores = [
                "#233d4d",
                "#fe7f2d",
                "#a1c181",
                "#540d6e",


                "#e9d8a6",

                "#ee9b00",

                "#005f73",

                "#7f4f24"
            ]

            let maxValor = 0;

            dadosGrafico.map(arr => {
                maxValor = arr.relacao_resultado > maxValor ? arr.relacao_resultado : maxValor;
            });


            let data = [];

            let series = [...new Set(dadosGrafico.map(item => item.icr_id_agente_holograma))];
            let periodos = [...new Set(dadosGrafico.map(item => item.icr_nome_periodo_resposta))];

            periodos.map(periodo => {
                data.push({
                    periodo
                })
                var atual = data[data.length - 1];
                series.map(serie => {
                    let valor = dadosGrafico.filter(a => a.icr_nome_periodo_resposta == periodo && a.icr_id_agente_holograma == serie)
                    atual[`valor_${serie}`] = valor[0]?.relacao_resultado || 0
                    atual[`icr_${serie}`] = valor[0]?.relacao_icr || ""
                    atual[`relacao_${serie}_cor`] = coresRelacao[valor[0]?.relacao_cor] || ""
                });
            })


            var chart = am4core.create("chartdiv", am4charts.XYChart);

            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = data;


            const eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValor, "");
            eixoY.renderer.labels.template.disabled = true;
            eixoY.renderer.grid.template.strokeOpacity = 0;


            const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.CategoryAxis()), { categoria: "periodo" });
            dateAxis.renderer.grid.template.strokeOpacity = 0;
            chart.legend = new am4charts.Legend();
            chart.legend.position = 'top';
            chart.legend.itemContainers.template.paddingTop = 0;



            series.map((item, i) => {
                let nome = dadosGrafico.filter(a => a.icr_id_agente_holograma == item)[0].icr_descricao;
                var serie = chart.series.push(new am4charts.LineSeries());
                serie.dataFields.valueY = `valor_` + item;
                serie.dataFields.categoryX = "periodo";
                serie.name = nome;
                serie.stroke = am4core.color(cores[i]);
                serie.fill = am4core.color(cores[i]);
                serie.strokeWidth = 2;

                const bullet = new am4charts.CircleBullet();
                bullet.circle.fillOpacity = 1;
                bullet.circle.radius = 3;
                bullet.circle.fill = am4core.color(cores[i]);
                bullet.circle.stroke = am4core.color(cores[i]);
                bullet.circle.strokeWidth = 2;
              //  bullet.circle.tooltipText = "Value: [bold]{value}[/]";
                serie.bullets.push(bullet);

                var valueLabel = serie.bullets.push(new am4charts.LabelBullet());
                valueLabel.label.fontSize = 15;
                //valueLabel.label.fill = am4core.color("#495057");
                valueLabel.label.propertyFields.fill = `relacao_${item}_cor`;
                valueLabel.label.background.fillOpacity = 0;
                valueLabel.label.paddingRight = 5;
                valueLabel.label.paddingLeft = 5;
                valueLabel.label.paddingTop = 3;
                valueLabel.label.dy = -18;

                valueLabel.label.text = `[bold font-size: 15px]{${`icr_` + item}}[/]`

            });

            return () => {
                chart.dispose();
            };
        }
    }, [dados])
    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}
export default memo(GraficoRelacao)