import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { retornarTipoCustosBTFlex, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, retornarTipoCustos, exportarExcel, Hoje, primeiroDiaMes, toDateSQL, Mes, valorEmMoeda, valorEmInteiro, removerFocusNoBotao, toMoney } from "../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/reaLina/actions";
import HeaderGrafico from '../../../components/headerGrafico';
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import Grafico from "./partes/grafico";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../../helpers/locale/portuguese";
import FocarPaisagem from '../../../components/forcarPaisagem';
import { retornaExportacaoExcel } from './partes/variaveis';
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from 'react-select';


const formatacaoAmCharts = retornarFormatacaoAmCharts();
const tipoGrafico = "faturamento";
const formatoNumeroGrafico = formatacaoAmCharts.formatoValores.decimal0Casas;
const tiposCusto = retornarTipoCustosBTFlex();
const ReaLinaBTFlex = () => {
  const [cor, setCor] = useState("");
  const [simboloGrafico, setSimboloGrafico] = useState(" R$");
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const { dados, carregando } = useSelector(state => state.ReaLina);
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const [lojas, setLojas] = useState([]);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };
  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const atualizarDados = async () => {
    if (!dataFinalRef.current.dataFinal) return

    await dispatch(requisitarDados({
      token,
      data_inicial: toDateSQL(dataInicial),
      data_final: toDateSQL(dataFinal),
      arvore: 2,
      visualizacao: "zoom",
      agrupamento: "total",
      exportacao: retornaExportacaoExcel(tipoCusto.value),
      retornaMC3: "",
      retorna_desafio: "",
      base_calculo_percentual: "",
      tipo_cmv: tipoCusto?.value || "",
    }
    ));
    setMostrarFiltros(false);
  }

  useEffect(() => {
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  const atualizarLojas = () => {
    atualizarDados();
  }

  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }

  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />

      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />

        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: dataInicial,
                      disableMobile: "true"

                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataInicial(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <div className={"ml-1 mr-1"} >a</div>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: "today",
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataFinal(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="ml-2">
                  <div className="unidadesControler">
                    <Select
                      placeholder=""
                      className="basic-single"
                      options={tiposCusto}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      defaultValue={tiposCusto[0] || []}
                      onChange={Object => {
                        trocarTipoCusto(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarLojas(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>

            <Container fluid className="fixWith">
              <div className={"headers mt-4"}>
                <HeaderGrafico mostrar={mostrarTotais} colunas={2} itens={[
                  {
                    nome: "Período",
                    valor: dados?.header?.periodo_data,
                    corClass: ""
                  },
                  {
                    nome: dados?.header?.faturamento,
                    valor: `R$ ${valorEmInteiro(dados?.footer?.faturamento, true)}`,
                    corClass: "text-success"

                  },
                  {
                    nome: dados?.header?.impostos_valor,
                    valor: "R$ " + toMoney(dados?.footer?.impostos_valor, 0),
                    corClass: "text-warning"
                  },
                  {
                    nome: dados?.header?.devolucao_total_valor,
                    valor: "R$ " + toMoney(dados?.footer?.devolucao_total_valor, 0),
                    corClass: "text-info"
                  },
                  {
                    nome: dados?.header?.cmv_valor,
                    valor: `${toMoney(dados?.footer?.cmv_percentual, 1)}%`,
                    segundoValor: `${valorEmInteiro(dados?.footer?.cmv_valor, false)}`,
                    corClass: "text-danger"
                  },
                  {
                    nome: dados?.header?.margem_contribuicao_valor,
                    valor: `${toMoney(dados?.footer?.margem_contribuicao_percentual, 1)}%`,
                    segundoValor: `${valorEmInteiro(dados?.footer?.margem_contribuicao_valor, false)}`,
                    corClass: "text-darker"
                  },
                ]} />

              </div>
            </Container>
          </div>
          <Container fluid className="fixWith graficoprincipal">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <Row>
                      <Col>
                        <Grafico cor={cor?.trim()} formatoNumeroGrafico={formatoNumeroGrafico} tipoGrafico={tipoGrafico} dados={dados} simbolo={simboloGrafico} id={"chartdiv"} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>

    </React.Fragment>
  );
}
export default ReaLinaBTFlex;