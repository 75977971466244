export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_REQUESTED = 'SET_USER_REQUESTED';
export const CLEAN_API_MESSAGE = 'CLEAN_API_MESSAGE';

