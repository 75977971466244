import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/nuvemPalavras/actions";
import { requisitarDados as requisitarDadosIcrRelacoesCamadas, requisitarLimparDados as requisitarLimparDadosIcrRelacoesCamadas } from "../../../store/capitalRelacional/icrRelacoesCamadas/actions";
import Header from "../../../components/HorizontalLayout/HeaderApp"
import Grafico from "./partes/grafico"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody, Progress } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";
import Knob from "../../../components/knob/knob";

const BoaConversa = ({ respondente, setRespondenteSelecionado }) => {
  // const respondente = props?.location?.state?.respondente;
  
  const { dados, carregando } = useSelector(state => state.NuvemPalavras);
  const dadosRelacao = useSelector(state => state.IcrRelacoesCamadas.dados);
  const carregandoRelacao = useSelector(state => state.IcrRelacoesCamadas.carregando);
  const tela = useSelector((state) => state.Gerais);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [carregandoConsulta, setCarregandoConsulta] = useState(false);

  const cores = {
    "success": "#34c38f",
    "warning": "#f1b44c",
    "danger": "#f46a6a"
  }



  // useEffect(() => {
  //   if (!props.location.state) return props.history.push("/painel-das-suplicas");
  // }, []);

  const formatarCamadas = (camadas) => {
    const camadasLista = camadas.trim().split("|");

    return `
      ${camadasLista
        .map((valor, i) => {
          if (!valor) return "";
          return `
          <span class="badge bg-light mr-2 p-1">
              ${valor}
            </span>`;
        })
        .join(" ")}
    `;
  }

  return (
    <React.Fragment>
      <>
        <Header
          tituloPagina={"Boa conversa"}
          funcaoFechar={() => { setRespondenteSelecionado(null); }}
          parametros={
            {
              abrirFiltro: false
            }
          } />
        <div id="layout-wrapper">
          <Container className={"mt-4"}>
            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody style={{ color: "black" }}>
                    <h1 className={"mb-0 font-weight-bolder"} style={{ marginTop: "-11px" }}>
                      {respondente.icr_nome_respondente}
                    </h1>
                    <h5 className={"text-uppercase"} style={{ fontWeight: 300 }}>
                      {respondente.icr_empresa_respondente}
                    </h5>
                    <div className={"w-100"} style={{ fontSize: "11px" }} >
                      {(respondente.icr_nome_agente_holograma + "|" + respondente.icr_camadas).split("|").map((camada, camada_id) => {
                        return (
                          <span className={"py-1 px-2 mr-2"} style={{ backgroundColor: "#d9d9d9" }} key={`camada_${camada_id}`}>
                            {camada}
                          </span>
                        )
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {
                [1, 2, 3, 4, 5, 6, 7, 8].map(idSuplica => {
                  let coresFrases = respondente[`icr_cor_${idSuplica}`].split('|;');
                  
                  return (
                    <Col sm={6} className={"mb-4"} key={`suplica_${idSuplica}`}>
                      <Card style={{ height: "100%" }}>
                        <CardBody className={"pt-3 px-3 pb-0"}>
                          <div className={"font-weight-bold"}>{respondente[`nome_suplica_${idSuplica}`]}</div>
                          <div className={"w-100 d-flex"}>
                            <div className={"ml-3 mt-3 align-self-center"} style={{ maxHeight: "120px" }}>
                              <Knob
                                value={respondente[`suplica_retorno_${idSuplica}`]}
                                width={115}
                                // fgColor="#7a6fbe"
                                fgColor={cores[respondente[`badge_${idSuplica}`]]}
                                displayCustom={() => {
                                  return false
                                }}
                                onChange={() => { }}
                              />
                            </div>
                            <div className={"mt-3 align-self-center"} style={{ color: "#545454", fontSize: "11px" }}>
                              {respondente[`icr_frases_${idSuplica}`] &&
                                <ul className={"mb-0"}>
                                  {
                                    respondente[`icr_frases_${idSuplica}`].split("|;").map((frase, idFrase) => {
                                      if (frase) {
                                        return (
                                          <li className={`text-${coresFrases[idFrase]}`} key={`suplica_${idSuplica}_${idFrase}`}>
                                            {frase}
                                          </li>
                                        )
                                      }
                                      return ("")
                                    })
                                  }
                                </ul>}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
              }

            </Row>
          </Container>

        </div>
      </>
    </React.Fragment>
  );
}
export default BoaConversa;