import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { dadosApiProdutos } from "../../helpers/api"

function carregarDadosAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApiProdutos(action.parametros)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* carregarDadosTopFamilias(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* carregarDadosTopFamiliasFooter(action) {
    try {
        yield put(actions.requiserAtualizarFooterSucesso(action.payload))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosTopFamilias)
}

function* assistindoRequisicaoFooter() {
    yield takeEvery(types.REQUISITAR_ATUALIZAR_FOOTER, carregarDadosTopFamiliasFooter)
}


function* listarTopFamilias() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoRequisicaoFooter)
        ]
    )
}

export default listarTopFamilias