import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados } from "../../../store/topFamilias/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { retornarTipoCustosBTFlex, exportarExcel, Hoje, Mes, concatenaLojas, removerFocusNoBotao, primeiroDiaMes, toDateSQL } from "../../../helpers/utils";
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions"
import Flatpickr from "react-flatpickr";
import Select from 'react-select';
import { Portuguese } from "../../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../../components/filtroBrytum";
import makeAnimated from 'react-select/animated';
import FocarPaisagem from '../../../components/forcarPaisagem';
import { dadosApiDdlGrade01, dadosApiEstruturaCusto } from '../../../helpers/api';

const animatedComponents = makeAnimated();

const CustoProducaoBTFlex = (props) => {
    const tiposCusto = retornarTipoCustosBTFlex();
    const [loading, setLoading] = useState(true);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const [lojas, setLojas] = useState([]);
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const grid = useRef(null);
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[0]);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(true);
    const [grade01, setGrade01] = useState([]);
    const [grade01Selecionado, setGradeSelecionado01] = useState();
    const [dados, setDados] = useState();
    const [carregando, setCarregando] = useState();

    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };


    const atualizarDados = async () => {
        setCarregando(true);
        const auxDados = await dadosApiEstruturaCusto({
            p_grade_01: grade01Selecionado?.value || "",
            tipo_cmv: tipoCusto?.value || "",
            p_referencia: "",
            p_codigo: "",
            arvore: 99,
            nivel_atual: 0,
            token
        })
        setDados(auxDados.ResultadoObjeto);
        setCarregando(false);
        setMostrarFiltros(false);
    }


    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token));
    }

    useEffect(() => {
        atualizarDadosAmbiente();

        async function consultarDdlGrade01() {
            const auxDdlGrade01 = await dadosApiDdlGrade01({ token });
            setGrade01(auxDdlGrade01.ResultadoObjeto);
        }
        consultarDdlGrade01();

        atualizarDados();
    }, []);


    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const atualizarDadosDemanda = () => {
        setMostrarFiltros(false);
        setLoading(true);
        if (grid.current) {
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                p_grade_01: grade01Selecionado?.value || "",
                tipo_cmv: tipoCusto?.value || "",
                p_referencia: "",
                p_codigo: "",
                arvore: 99,
                nivel_atual: 0,
                token
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    const gridInfo = useMemo(() => {
        if (!dados && !dados?.data) return
        return gridConfig(dados, grid, token, toDateSQL(dataInicial), toDateSQL(dataFinal), concatenaLojas(lojasRef.current.lojas));
    }, [dados, grid])



    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }

    const trocarTipoCusto = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto([])
        }
    }

    const trocarGrade01 = (valor) => {
        if (valor) {
            setGradeSelecionado01(valor)
        } else {
            setGradeSelecionado01([])
        }
    }

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        forcaExportaExcel: true,
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    name="meses"
                                    options={grade01}
                                    placeholder="Todos os produtos"
                                    isClearable
                                    classNamePrefix="select2-selection"
                                    isSearchable={true}
                                    onChange={Object => {
                                        trocarGrade01(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={tiposCusto}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposCusto[0] || []}
                                    onChange={Object => {
                                        trocarTipoCusto(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {dados && <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    key={carregando}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />}
            </>
        </React.Fragment>
    );
}
export default CustoProducaoBTFlex;