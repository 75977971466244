import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import { requisitarDadosParceiros, requisitarLimparDados } from "../../../store/capitalRelacional/relacao/actions";
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form, Input } from "reactstrap";
import "@bryntum/grid/grid.stockholm.css";
import "../../../App.scss";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from "react-select";
import FocarPaisagem from "../../../components/forcarPaisagem";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import './partes/app.css';
const GridRelacao = () => {


  //Funcionalidades da tela
  const dispatch = useDispatch();
  const grid = useRef(null);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [loading, setLoading] = useState(false);
  const [parceiro, setParceiro] = useState(null);
  const [mostrarGrid, setMostrarGrid] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [loadingCliente, setLoadingCliente] = useState(false);
  const [periodo, setPeriodo] = useState(null);
  const [loadingPeriodo, setLoadingPeriodo] = useState(false);
  const [agente, setAgente] = useState(null);
  const [camadas, setCamadas] = useState(null);
  const token = useSelector((state) => state.Login.user.tokenNOL);
  const { dados, carregando } = useSelector((state) => state.Relacao);
  const tela = useSelector((state) => state.Gerais);
  const [submitHabilitado, setSubmitHabilitado] = useState(false);

  const atualizarDados = async (
    idEmpresa,
    idParceiro,
    idCliente,
    idPeriodoResposta
  ) => {
    dispatch(
      requisitarDadosParceiros(
        token,
        idEmpresa,
        idParceiro,
        idCliente,
        idPeriodoResposta,
        "relacao"
      )
    );
  };

  const atualizarDadosAmbiente = async () => {
    dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);
    if (!agente) setAgente(tela.agentes[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length) {
      setSubmitHabilitado(true);
    }
  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setLoadingCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setLoadingPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
    dispatch(
      requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value)
    );
  }, [cliente]);

  useEffect(() => {
    if (!agente || agente.length <= 0) return;
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        agente.value
      )
    );
  }, [agente]);

  useEffect(() => {
    setCamadas([]);
  }, [tela.camadas]);


  const atualizarDadosDemanda = () => {
    setMostrarFiltros(false);
    if (!parceiro?.value || !cliente?.value || !periodo?.value) return;
    setLoading(true);
    if (grid.current) {
      grid.current.instance.store.fetchOptions.body = JSON.stringify({
        token,
        idParceiro: parceiro.value,
        idCliente: cliente.value,
        idPeriodoResposta: periodo.value,
        visualizacao: "relacao",
        classeN1: "xpto",
      });
      grid.current.instance.store.removeAll();
      grid.current.instance.store.loadData();
      grid.current.instance.store
        .load()
        .then((resolve) => {
          setMostrarGrid(true);

          setTimeout(() => {
            setLoading(false);
            setSubmitHabilitado(true);
          }, 500);

          grid.current.instance.on('cellMouseOver', (event) => {
            document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
              if (indice)
                item.style.setProperty("background-color", "#12956c0d", "important");

            });
          });


          grid.current.instance.on('cellMouseOut', (event) => {
            document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
              if (indice)
                item.style.removeProperty("background-color");
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
  }, [tela.agentes]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    setCliente([]);
    setPeriodo([]);
    setLoadingCliente(true);
    setLoadingPeriodo(true);
    if (idEmpresa) {
      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaCamadas(token, idEmpresa, cliente.value, agente.value));
    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {
    setPeriodo([]);
    setLoadingPeriodo(true);
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, idCliente)
    );
  };

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => dispatch(requisitarLimparDados())
  }, []);


  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(dados, grid, token);
  }, [dados]);
  return (
    <React.Fragment>
      <FocarPaisagem />
      <div className="capitalRelacional painelRelacoes">
        <PaginaLoader carregando={loading} />
        <>
          <Header
            parametros={{
              //exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros);
                  },
                  icone: "bx bx bx-filter-alt",
                },
              ],
            }}
            position={true}
            titulo="Mapa de negócios"
          />
          {/* <FiltroBrytum aberto={mostrarFiltros}> */}
          <FiltroBrytum aberto={mostrarFiltros}>
            <Form inline>
              <FormGroup className="select2-container">
                <div className="unidadesControler">
                  <Select
                    placeholder="Parceiro"
                    value={parceiro}
                    options={tela.parceiros || []}
                    classNamePrefix="select2-selection"
                    defaultValue={parceiro || []}
                    onChange={(Object) => {
                      setParceiro(Object || []);
                      atualizarFiltroDependenciaParceiros(Object.value);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2">
                  <Select
                    placeholder="Cliente"
                    className="basic-single"
                    value={cliente}
                    options={tela.clientes}
                    isSearchable={true}
                    isLoading={loadingCliente}
                    classNamePrefix="select2-selection"
                    onChange={(result) => {
                      setCliente(result || []);
                      atualizarDependenciaCliente(result.value);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2">
                  <Select
                    placeholder="Período"
                    className="basic-single"
                    value={periodo}
                    options={tela.periodos}
                    isSearchable={false}
                    isLoading={loadingPeriodo}
                    classNamePrefix="select2-selection"
                    onChange={(Object) => {
                      setPeriodo(Object || []);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  color="light"
                  onClick={(e) => {
                    setSubmitHabilitado(false);
                    atualizarDadosDemanda();
                  }}
                  className="ml-2"
                  disabled={!submitHabilitado}
                >
                  <i className="fal fa-hashtag"></i>
                  boralá!
                </Button>
              </FormGroup>
            </Form>
          </FiltroBrytum>
          {dados && (
            <div style={{ display: mostrarGrid ? "block" : "none", flex: 1 }}>
              <BryntumGrid
                ref={grid}
                {...gridInfo}
                treeFeature={false}
                columnReorderFeature={false}
                cellEditFeature={false}
                summaryFeature={false}
                sortFeature={false}
                key={carregando}
                cellMenuFeature={{
                  disabled: true,
                }}
                headerMenuFeature={{
                  items: {
                    hideColumn: false,
                  },
                }}
              />
            </div>
          )}
        </>
      </div>
    </React.Fragment>
  );
};
export default GridRelacao;
