import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

const NonAuthLayout = ({ children }) => {
    const location = useLocation();

    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    useEffect(() => {
        let currentage = capitalizeFirstLetter(location.pathname);
        document.title = currentage + " | :)BiZ - Central do Cliente";
    }, [location.pathname]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

export default NonAuthLayout;