import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Input, Button } from "reactstrap";
import { retornarTipoCustos, Mes, retornarMeses, retornarAnos, retornarTiposPeriodo, concatenaLojas, Hoje, primeiroDiaMes, toDateSQL, ArrayToMultiSelect } from "../../../helpers/utils"

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Link } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { requisitarDados } from "../../../store/menus/actions";
import { requisitarDadosFilial } from "../../../store/benassi/filial/actions";
import { requisitarDadosNegocio } from "../../../store/benassi/negocio/actions";
import { requisitarDadosRede } from "../../../store/benassi/rede/actions";
import { requisitarDadosLoja } from "../../../store/benassi/loja/actions";
import { requisitarDadosComprador } from "../../../store/benassi/comprador/actions";
import { requisitarDadosGrupoProduto } from "../../../store/benassi/grupoProduto/actions";
import { requisitarDadosVendedor } from "../../../store/benassi/vendedor/actions";
import PaginaLoader from '../../../components/loader/pagina';
import { useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BtnLoader from '../../../components/forms/btnLoader';
import makeAnimated from 'react-select/animated';
import Flatpickr from "react-flatpickr"
import Select from 'react-select'
import { Portuguese } from "../../../helpers/locale/portuguese";
const animatedComponents = makeAnimated();
const anos = retornarAnos(6);
const meses = retornarMeses();
const tiposPeriodo = retornarTiposPeriodo();
const mesAutal = meses.filter(a => a.value == Mes(Hoje()))[0];
const tiposCusto = retornarTipoCustos();
const PreFiltroProdutos = (props) => {
    const token = useSelector(state => state.Login.user.tokenNOL);
    const { carregando, atalhos, menuPrincipal } = useSelector(state => state.Menus)
    const { history, match } = props;
    const filiais = useSelector(state => state.Filial.dados);
    const negocios = useSelector(state => state.Negocio.dados);
    const redes = useSelector(state => state.Rede.dados);
    const lojas = useSelector(state => state.Loja.dados);
    const compradores = useSelector(state => state.Comprador.dados);
    const vendedores = useSelector(state => state.Vendedor.dados);
    const gruposProduto = useSelector(state => state.GrupoProduto.dados);

    const [filial, setFilial] = useState("");
    const [filialSelecionado, setFilialSelecionado] = useState("");
    const [negocio, setNegocio] = useState("");
    const [negocioSelecionado, setNegocioSelecionado] = useState("");
    const [redeSelecionado, setRedeSelecionado] = useState("");
    const [rede, setRede] = useState("");
    const [loja, setLoja] = useState("");
    const [lojaSelecionado, setLojaSelecionado] = useState("");
    const [comprador, setComprador] = useState("");
    const [compradorSelecionado, setCompradorSelecionado] = useState("");
    const [vendedor, setVendedor] = useState("");
    const [grupoProduto, setGrupoProduto] = useState("");
    const [tipoPeriodo, setTipoPeriodo] = useState(tiposPeriodo[0].value);
    const [ano, setAno] = useState(anos[0]);
    const [mesSelecionado, setMesSelecionado] = useState(mesAutal);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes());
    const [dataFinal, setDataFinal] = useState(Hoje());
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[1])




    const [carregandoNegocio, setcarregandoNegocio] = useState(false);
    const [carregandoRede, setCarregandoRede] = useState(false);
    const [carregandoLoja, setCarregandoLoja] = useState(false);
    const [carregandoComprador, setCarregandoComprador] = useState(false);



    const dispatch = useDispatch();
    const [config, setConfig] = useState({});

    const atualizarDados = async () => {
        await dispatch(requisitarDadosFilial(token));
        await dispatch(requisitarDadosNegocio(token, { u_unidades_id: filial }));
        await dispatch(requisitarDadosRede(token, { u_unidades_id: filial, u_negocios_id: negocio }));
        await dispatch(requisitarDadosLoja(token, { u_unidades_id: filial, u_negocios_id: negocio, r_redes_id: rede }));
        await dispatch(requisitarDadosComprador(token, { u_unidades_id: filial }));
        await dispatch(requisitarDadosVendedor(token));
        await dispatch(requisitarDadosGrupoProduto(token));
        await dispatch(requisitarDados(token));
    }

    useEffect(() => {
        setConfig({
            icone: localStorage.getItem("iconeBizAtual"),
            titulo: localStorage.getItem("tituloBizAtual")
        })
        atualizarDados();
    }, [])

    useEffect(() => {
        setcarregandoNegocio(true);
        setCarregandoRede(true);
        setCarregandoLoja(true);
        setCarregandoComprador(true);

        setNegocio("");
        setNegocioSelecionado("");
        setRede("");
        setRedeSelecionado("");
        setLoja("");
        setLojaSelecionado("");
        setComprador("");
        setCompradorSelecionado("");
        dispatch(requisitarDadosNegocio(token, { u_unidades_id: filial.trim() }));
        dispatch(requisitarDadosRede(token, { u_unidades_id: filial.trim(), u_negocios_id: negocio.trim() }));
        dispatch(requisitarDadosLoja(token, { u_unidades_id: filial.trim(), u_negocios_id: negocio.trim(), r_redes_id: rede.trim(), }));
        dispatch(requisitarDadosComprador(token, { u_unidades_id: filial.trim() }));

        setTimeout(() => {
            setcarregandoNegocio(false);
            setCarregandoRede(false);
            setCarregandoLoja(false);
            setCarregandoComprador(false);

        }, 1000);
    }, [filial])

    useEffect(() => {
        setCarregandoRede(true);
        setCarregandoLoja(true);

        setLoja("");
        setLojaSelecionado("");

        dispatch(requisitarDadosRede(token, { u_unidades_id: filial.trim(), u_negocios_id: negocio.trim() }));
        dispatch(requisitarDadosLoja(token, { u_unidades_id: filial.trim(), u_negocios_id: negocio.trim(), r_redes_id: rede.trim(), }));
        setTimeout(() => {
            setCarregandoRede(false);
            setCarregandoLoja(false);
        }, 1000);
    }, [negocio])

    useEffect(() => {
        setCarregandoLoja(true);
        
        setLoja("");
        setLojaSelecionado("");
        dispatch(requisitarDadosLoja(token, { u_unidades_id: filial.trim(), u_negocios_id: negocio.trim(), r_redes_id: rede.trim(), }));
        setTimeout(() => {
            setCarregandoLoja(false);
        }, 1000);
    }, [rede])

    const filtrar = () => {
        history.push({
            pathname: `/${match.params.pagina}`,
            state: {
                filtros: {
                    filial: filial?.value || filial,
                    negocio: negocio?.value || negocio,
                    rede: rede?.value || rede,
                    loja: loja?.value || loja,
                    comprador: comprador?.value || comprador,
                    vendedor: vendedor?.value || vendedor,
                    produto: document.querySelector("#produto").value,
                    grupoProduto: grupoProduto?.value || grupoProduto,
                    tipoPeriodo: tipoPeriodo?.value || tipoPeriodo,
                    ano: ano?.value || ano,
                    mes: mesSelecionado?.value ? ArrayToMultiSelect([mesSelecionado?.value]) : ArrayToMultiSelect([mesSelecionado]),
                    dataInicial,
                    dataFinal,
                    tipoCusto: tipoCusto?.value || tipoCusto
                }
            }
        })
    }
    //======controle dos filtros==========

    const trocarFilial = (valor) => {
        if (valor) {
            setFilialSelecionado(valor);
            setFilial(valor?.value ? valor?.value.replace("--,", "").replace("-", "|") : " ");
        } else {
            setFilial(" ");
            setFilialSelecionado("");
        }


    }
    const trocarNegocios = (valor) => {
        if (valor) {
            setNegocio(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
            setNegocioSelecionado(valor)
        } else {
            setNegocio("");
            setNegocioSelecionado("")
        }
    }
    const trocarRedes = (valor) => {
        if (valor) {
            setRede(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
            setRedeSelecionado(valor);
        } else {
            setRede("");
            setRedeSelecionado("");
        }
    }
    const trocarLojas = (valor) => {
        if (valor) {
            setLoja(valor?.value || "");
            setLojaSelecionado(valor);
        } else {
            setLoja("");
            setLojaSelecionado("");
        }
    }
    const trocarCompradores = (valor) => {
        if (valor) {
            setComprador(valor?.value || "");
            setCompradorSelecionado(valor);
        } else {
            setComprador("");
            setCompradorSelecionado("");
        }
    }
    const trocarVendedores = (valor) => {
        if (valor) {
            setVendedor(valor?.value || "");
        } else {
            setVendedor("");
        }
    }
    const trocarGrupoProduto = (valor) => {
        if (valor) {
            setGrupoProduto(valor?.value || "");
        } else {
            setGrupoProduto("");
        }
    }
    const trocarTiposPeriodo = (valor) => {
        if (valor) {
            setTipoPeriodo(valor?.value);
        } else {
            setTipoPeriodo("");
        }
    }
    const trocarAno = (valor) => {
        if (valor) {
            setAno(valor?.value)
        } else {
            setAno("")
        }
    }
    const trocarMes = (valor) => {
        if (valor) {
            setMesSelecionado(valor?.value)
        } else {
            setMesSelecionado("")
        }
    }
    const trocarTipoCustos = (valor) => {
        if (valor) {
            setTipoCusto(valor?.value)
        } else {
            setTipoCusto("")
        }
    }

    const mostrarLoader = carregando && (atalhos.length >= 0) && (menuPrincipal.length <= 0);
    return (
        <React.Fragment>
            <PaginaLoader carregando={mostrarLoader} />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={config.titulo} links={[
                        { nome: "Pagina principal", url: "/", ativa: false },
                        { nome: config.titulo, url: "#", ativa: true },
                    ]} />
                    <Card>
                        <CardBody>
                            <AvForm onSubmit={(event, errors, values) => { filtrar(errors, values) }}>
                                <Row>
                                    <Col sm={3}>
                                        {
                                            filiais &&
                                            <FormGroup>
                                                <Label>Filial</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isClearable={true}
                                                        components={animatedComponents}
                                                        options={filiais}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={filialSelecionado || null}
                                                        onChange={Object => {
                                                            trocarFilial(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }

                                    </Col>
                                    <Col sm={3}>
                                        {
                                            negocios &&
                                            <FormGroup>
                                                <Label>Negócios</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoNegocio}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={negocios}
                                                        classNamePrefix="select2-selection"
                                                        value={negocioSelecionado || null}
                                                        isSearchable={true}
                                                        onChange={Object => {
                                                            trocarNegocios(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={3}>
                                        {
                                            redes &&
                                            <FormGroup>
                                                <Label>Redes</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoRede}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={redes}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={redeSelecionado || null}
                                                        onChange={Object => {
                                                            trocarRedes(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={3}>
                                        {
                                            lojas &&
                                            <FormGroup>
                                                <Label>Lojas</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoLoja}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={lojas}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={lojaSelecionado || null}
                                                        onChange={Object => {
                                                            trocarLojas(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        {
                                            compradores &&
                                            <FormGroup>
                                                <Label>Compradores</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoComprador}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={compradores}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={compradorSelecionado || ""}
                                                        onChange={Object => {
                                                            trocarCompradores(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={3}>
                                        {
                                            vendedores &&
                                            <FormGroup>
                                                <Label>Vendedores</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={vendedores}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        onChange={Object => {
                                                            trocarVendedores(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={3}>
                                        {
                                            gruposProduto &&
                                            <FormGroup>
                                                <Label>Grupo de produto</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={gruposProduto}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        onChange={Object => {
                                                            trocarGrupoProduto(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <AvField name="produto" label="Produto" className="form-control" placeholder="" type="text" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Tipo de pesquisa</Label>
                                            <div className="unidadesControler">
                                                <Select
                                                    placeholder=""
                                                    className="basic-single"
                                                    options={tiposPeriodo}
                                                    isSearchable={false}
                                                    classNamePrefix="select2-selection"
                                                    defaultValue={tiposPeriodo[0] || []}
                                                    onChange={Object => {
                                                        trocarTiposPeriodo(Object || []);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        {tipoPeriodo == "1" &&
                                            <FormGroup inline>
                                                <Label>Ano</Label>
                                                <div className="unidadesControler anoControler">
                                                    <Select
                                                        placeholder="Todas as unidades"
                                                        className="basic-single"
                                                        options={anos}
                                                        isSearchable={false}
                                                        classNamePrefix="select2-selection"
                                                        defaultValue={anos[0] || []}
                                                        onChange={Object => {
                                                            trocarAno(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                        {tipoPeriodo == "2" &&
                                            <FormGroup>
                                                <Label>De</Label>
                                                <Flatpickr
                                                    className="form-control d-block w-100"
                                                    placeholder="dd M,yyyy"
                                                    locale="pt"
                                                    options={{
                                                        mode: "single",
                                                        locale: Portuguese,
                                                        dateFormat: "d/m/Y",
                                                        maxDate: `today`,
                                                        defaultDate: dataInicial,
                                                        disableMobile: "true"

                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        if (selectedDates.length > 0) {
                                                            setDataInicial(selectedDates[0]);
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        }


                                    </Col>
                                    <Col sm={3}>
                                        {tipoPeriodo == "1" &&
                                            <FormGroup className="select2-container" inline >
                                                <Label>Mês</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todas as unidades"
                                                        className="basic-single"
                                                        options={meses}
                                                        isSearchable={false}
                                                        classNamePrefix="select2-selection"
                                                        defaultValue={mesAutal}
                                                        onChange={Object => {
                                                            trocarMes(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }
                                        {tipoPeriodo == "2" &&
                                            <FormGroup>
                                                <Label>Até</Label>
                                                <Flatpickr
                                                    className="form-control d-block w-100"
                                                    placeholder="dd M,yyyy"
                                                    locale="pt"
                                                    options={{
                                                        mode: "single",
                                                        locale: Portuguese,
                                                        dateFormat: "d/m/Y",
                                                        maxDate: `today`,
                                                        defaultDate: "today",
                                                        disableMobile: "true"
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        if (selectedDates.length > 0) {
                                                            setDataFinal(selectedDates[0]);
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col>
                                        <div className="unidadesControler">
                                            <Label>Tipo de custo</Label>
                                            <Select
                                                placeholder=""
                                                className="basic-single"
                                                options={tiposCusto}
                                                isSearchable={false}
                                                classNamePrefix="select2-selection"
                                                defaultValue={tiposCusto[1] || []}
                                                onChange={Object => {
                                                    trocarTipoCustos(Object || []);
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className={"d-grid gap-2 d-md-flex justify-content-md-end"}>
                                    <Button color="light" type="submit" >
                                        <i className="fal fa-hashtag"></i>
                                        boralá!
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PreFiltroProdutos;