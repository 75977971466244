import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/agora/actions"
import Header from "../../../components/HorizontalLayout/HeaderApp"
import Grafico from "./partes/grafico"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";

const GraficoAgora = (props) => {
  const { dados, carregando } = useSelector(state => state.Agora);
  const [carregandoPagina, setCarregandoPagina] = useState(false);
  const tela = useSelector((state) => state.Gerais);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [titulo, setTitulo] = useState("");
  const [submitHabilitado, setSubmitHabilitado] = useState(false);





  //=====Filtros=====//
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [agente, setAgente] = useState(null);
  const [camadas, setCamadas] = useState(null);
  //=====Filtros=====//

  //===== Carregando Filtros=====//
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);
  const [carregandoCamadas, setCarregandoCamadas] = useState(false);
  //===== Carregando Filtros=====//


  const atualizarDados = async () => {
    await dispatch(requisitarDados({
      idParceiro: parceiro.value || "",
      idCliente: cliente.value || "",
      idPeriodoResposta: periodo.value || "",
      idAgenteHolograma: agente.value || "",
      idCamada: camadas.value || "",

      // idParceiro,
      // idCliente,
      // idPeriodoResposta,
      // idsAgentesHolograma,
      // idsAgentesHolograma: "1|;"
      // idsAgentesHolograma: "",
      // idCamadaOpcao: "",
      // idParceiro: "000002",
      // idCliente: "000004",
      // idPeriodoResposta: "00002",
      token
    }
    ));

    setTitulo(`${agente?.label + " - " || ""}${camadas?.label || ""}`);

    setMostrarFiltros(false);
    setSubmitHabilitado(true);
  }
  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);
    if (!agente) setAgente(tela.agentes[0]);
    if (!camadas) setAgente(tela.camadas[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length && tela.agentes?.length && tela.camadas?.length) {
      setSubmitHabilitado(true);
    }
  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    setCamadas(tela.camadas?.length > 0 ? tela.camadas[0] : []);
    setCarregandoCamadas(false);
  }, [tela.camadas]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
    dispatch(
      requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value)
    );
  }, [cliente]);

  useEffect(() => {
    if (!agente || agente.length <= 0) return;
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        agente.value
      )
    );
  }, [agente]);


  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setAgente([]);
      setCamadas([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);
      setCarregandoAgente(true);
      setCarregandoCamadas(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaCamadas(token, idEmpresa, cliente.value, agente.value));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setPeriodo([]);
    setAgente([]);
    setCamadas([]);
    setCarregandoPeriodo(true);
    setCarregandoAgente(true);
    setCarregandoCamadas(true);

    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        idCliente,
        agente.value
      )
    );
  };

  const atualizarDependenciasAgente = (idAgente) => {
    setCamadas([]);
    setCarregandoCamadas(true);
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        idAgente
      )
    );
  };

  useEffect(() => {
    atualizarDadosAmbiente();
    //atualizarDados("", "", "", "", "");
    return () => { dispatch(requisitarLimparDados()); }
  }, []);



  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])

  return (
    <React.Fragment>
      <PaginaLoader carregando={tela.carregando || carregando} />
      <>
        <Header
          parametros={
            {
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Período"
                      className="basic-single"
                      isLoading={carregandoPeriodo}
                      value={periodo}
                      options={tela.periodos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setPeriodo(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Agente do Holograma"
                      className="basic-single"
                      isLoading={carregandoAgente}
                      options={tela.agentes}
                      value={agente}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setAgente(Object || []);
                        atualizarDependenciasAgente(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "230px" }}>
                    <Select
                      placeholder="Camada de negócio"
                      className="basic-single"
                      options={tela.camadas}
                      isLoading={carregandoCamadas}
                      value={camadas}
                      isSearchable={false}
                      //isMulti
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setCamadas(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      setSubmitHabilitado(false);
                      atualizarDados();
                    }}
                    className="ml-2"
                    disabled={!submitHabilitado}
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>

          </div>
          <Container fluid className="fixWith graficoprincipal mt-3">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <h5 className='w-100 text-center'>{titulo}</h5>
                    <Grafico dados={dados} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoAgora;