import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { dadosApi, dadosApiProdutos } from "../../helpers/api"
import { retornarIdCliente } from "../../cliente/variaveis"

function carregarDadosAPI(action) {
    const idCliente = retornarIdCliente();
    if (idCliente == "benassi") {
        return new Promise((resolve, reject) => {
            dadosApi(action.parametros)
                .then(result => {
                    return resolve(result.ResultadoObjeto);
                })
                .catch(e => reject(e))
        })
    } else {
        return new Promise((resolve, reject) => {
            dadosApi(action.parametros)
                .then(result => {
                    return resolve(result.ResultadoObjeto);
                })
                .catch(e => reject(e))
        })
    }
}

function* carregarDadosGraficoUnidades(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosGraficoUnidades)
}

function* limparDados() {
    yield put(actions.limparDados())
}


function* assistindoLimparDados() {
    yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}


function* listarGraficoUnidades() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoLimparDados)
        ]
    )
}

export default listarGraficoUnidades