import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import MetisMenu from "metismenujs";

const SidebarContent = (props) => {
    const location = useLocation();

    useEffect(() => {
        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [location.pathname]);

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menu')}  </li>
                    <li>
                        <Link to="/bannerslist" className="has-arrow waves-effect">
                            <i className="bx bx-message-square-detail"></i>
                            <span>{props.t('Banners')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/banners">{props.t('Lista de banners')}</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/educadores" className="waves-effect">
                            <i className="bx bx-user-circle"></i>
                            <span>{props.t('Educadores')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/depoimentos" className="waves-effect">
                            <i className="bx bx-message-rounded-dots"></i>
                            <span>{props.t('Depoimentos')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="programas" className="has-arrow waves-effect">
                            <i className="bx bx-movie"></i>
                            <span>{props.t('Programas')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/programas">{props.t('Lista de programas')}</Link></li>
                            <li><Link to="/criar-programa">{props.t('Criar novo')}</Link></li>
                        </ul>
                    </li>
                    <li className="menu-title">{props.t('Conteúdo')}  </li>
                    <li>
                        <Link to="/artigoslist" className="has-arrow waves-effect">
                            <i className="bx bx-message-square-detail"></i>
                            <span>{props.t('Artigos')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/artigos">{props.t('Listar artigos')}</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/categorias" className="waves-effect">
                            <i className="bx bx-sort-down"></i>
                            <span>{props.t('Categorias')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/autores" className="waves-effect">
                            <i className="bx bxs-user-rectangle"></i>
                            <span>{props.t('Autores')}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(SidebarContent);