import React, { useEffect, useState, useRef } from "react"
import lottie from "lottie-web"
import gabiz from "../../assets/animacoes/girar.json"
const FocarPaisagem = () => {
    const [mostrar, setMostrar] = useState(true);
    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            autoplay: true,
            animationData: gabiz
          })
        let timer = setTimeout(() => {
            setMostrar(false);
        }, 2500)
        return () => {
            clearTimeout(timer);
        }
    }, []);




    return (
        mostrar &&
        <div className="forcarPaisagem" style={{zIndex:99999999}}>
            <div className="text-center">
                <div ref={container} style={{margin:"auto", width: 200}}></div>
                <p className="p-3 pt-0 pb-0">Para melhor visualização recomendamos o uso no modo paisagem</p>
            </div>
        </div>
    )
}




export default FocarPaisagem
