import React, { useLayoutEffect, useRef } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { pegarCores, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../../helpers/utils"
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts, formatarDatasZoomAmCharts, retornarQtdeCategoriasZoomAmCharts } from "../../../../helpers/utils-amcharts"
const formatacaoAmCharts = retornarFormatacaoAmCharts();


am4core.options.commercialLicense = true;
const GraficoJojasAoVivo = ({ nomeGrafico, tipoGrafico, formatoNumeroGrafico, dados }) => {
    const dadosGrafico = dados?.data || [];
    const chartReferencia = useRef(null);
    let cores = pegarCores();
    let corSeleciona = cores.danger;
    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            const data = dadosGrafico.map(arr => {
                return { ...arr, date: toDateJS(arr.ano, arr.mes) }
            });

            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.scrollbarX = new am4core.Scrollbar();

            chart.scrollbarX.parent = chart.bottomAxesContainer;
            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = data;

            const eixoY = chart.yAxes.push(new am4charts.ValueAxis());
            eixoY.renderer.grid.template.strokeOpacity = 0.1;
            eixoY.renderer.grid.template.stroke = am4core.color("gray");
            eixoY.renderer.labels.template.disabled = true;
            eixoY.strictMinMax = true;
            eixoY.min = 0;

            var eixoYLinha = chart.yAxes.push(new am4charts.ValueAxis());
            eixoYLinha.renderer.grid.template.disabled = true;
            eixoYLinha.renderer.labels.template.disabled = true;
            eixoYLinha.strictMinMax = true;
            eixoYLinha.min = 0;
            eixoYLinha.max = 100;
            eixoYLinha.renderer.opposite = true;

            const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.DateAxis()));

            var series = chart.series.push(new am4charts.ColumnSeries());
            series.name = nomeGrafico;
            series.dataFields.valueY = tipoGrafico;
            series.dataFields.dateX = "date";
            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 0.8;
            //series.columns.template.width = am4core.percent(40);
            series.stroke = am4core.color(corSeleciona);
            series.fill = am4core.color(corSeleciona);
            //series.clustered = false;


            var columnTemplate = series.columns.template;
            columnTemplate.strokeOpacity = 0;
            columnTemplate.propertyFields.fill = "color"
            var label = columnTemplate.createChild(am4core.Label);
            label.text = `R$ {valueY.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            label.align = "center";
            label.valign = "top";
            label.dy = 15;
            label.fontSize = 15;
            label.fill = am4core.color("#fff");
            label.background.fill = am4core.color("gray");
            label.background.fillOpacity = 0.5;
            label.paddingRight = 5;
            label.paddingLeft = 5;
            label.paddingTop = 3;
            label.truncate = false;
            label.hideOversized = false;

            // create pie chart as a column child
            var pieChart = series.columns.template.createChild(am4charts.PieChart);
            pieChart.width = am4core.percent(80);
            pieChart.height = am4core.percent(80);
            pieChart.align = "center";
            pieChart.valign = "middle";
            pieChart.dataFields.data = "children";

            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "valor_liquidado";
            pieSeries.dataFields.category = "descricao";
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            pieSeries.slices.template.stroke = am4core.color("#ffffff");
            pieSeries.slices.template.strokeWidth = 1;
            pieSeries.slices.template.strokeOpacity = 0;
            pieSeries.slices.template.tooltipText = `[bold]{category}[/] : \n {value.percent.formatNumber('#.#')}% \n R$ {value.formatNumber('${formatoNumeroGrafico}')}`

            pieSeries.slices.template.adapter.add("fill", function (fill, target) {
                return am4core.color("#ffffff")
            })
            pieSeries.slices.template.adapter.add("fillOpacity", function (fillOpacity, target) {
                return (target.dataItem.index + 1) * 0.2;
            });


            return () => {
                chart.dispose();
            };
        }
    }, [dados, tipoGrafico])
    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}
export default GraficoJojasAoVivo