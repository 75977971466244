import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from '../../store/actions';

const Logout = ({ logoutUser }) => {
    const navigate = useNavigate();

    useEffect(() => {
        logoutUser(navigate);
    }, []); // Adicionado array de dependências

    return <></>;
}

const mapDispatchToProps = {
    logoutUser
};

export default connect(null, mapDispatchToProps)(Logout);