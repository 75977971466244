import "../../../helpers/locale/locale_BR";
import {
  formatarColunaTresValoresNegativoBTFlex,
  toMoney,
  valorEmMoeda,
  valorEmInteiro,
} from "../../../helpers/utils";
import {
  setasParaCimaOuParaBaixoComparacao,
  bizGridResponsive,
  setaSumarioComparacao,
} from "../../../helpers/biz-padrao-visual";
import { dadosApi } from "../../../store/raioXLoja/saga";
import { GridRowModel } from "@bryntum/grid/grid.umd.js";
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      nome: linha.nome,
      abreviatura: linha.abreviatura,
      cmvHoje: linha.cmvHoje,
      cmvSemana: linha.cmvSemana,
      cmvOntem: linha.cmvOntem,
      margemHoje: linha.margemHoje,
      margemSemana: linha.margemSemana,
      margemOntem: linha.margemOntem,
      faturamentoHoje: linha.faturamentoHoje,
      faturamentoSemana: linha.faturamentoSemana,
      faturamentoOntem: linha.faturamentoOntem,
    });
  }
}
//Largura da coluna da visualização no computador
const larguraColunaComputador = 300;
//Largura de todas as colunas
const configResponsiveLevels = {
  "*": { width: larguraColunaComputador },
  small: { width: 65 },
};
const configResponsiveLevelsComparacao = {
  "*": { width: larguraColunaComputador },
  small: { width: 75 },
};
//Colunas faturamento
const configResponsiveLevelsFaturamento = {
  "*": { width: larguraColunaComputador },
  small: { width: 63 },
};
const configResponsiveLevelsFaturamentoComparacao = {
  "*": { width: larguraColunaComputador },
  small: { width: 100 },
};
const showMedium = {
  large: { hidden: true },
  "*": {
    hidden: false,
  },
},
  hideMedium = {
    large: { hidden: false },
    "*": {
      hidden: true,
    },
  };
const gridConfig = (dados, token) => {
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      modelClass: GridLinha,
      tree: false,
      data: dados.dados,
      autoLoad: true,
      updateInterval: 10000,
      updatePriceIntervalId: null,
      listeners: {
        refresh({ action, records }) {
          if (action === "dataset") {
            this.startAutoUpdate();
          }
        },
      },
      startAutoUpdate() {
        // this.updatePriceIntervalId = setInterval(async () => {
        //   const novosDados = await dadosApi(token, "", true);
        //   if (novosDados.ResultadoObjeto && novosDados.ResultadoObjeto.data) {
        //     dados.footer = novosDados.ResultadoObjeto.footer;
        //     novosDados.ResultadoObjeto.data.forEach((linha, indice) => {
        //       this.getById(linha.id).updateLinha(linha);
        //       if (linha.children) {
        //         linha.children.forEach((filho, indice) => {
        //           this.getById(filho.id).updateLinha(filho);
        //         });
        //       }
        //     });
        //   }
        // }, this.updateInterval);
      },
      stopAutoUpdate() {
        // clearInterval(this.updatePriceIntervalId);
      },
    },
    columns: [
      {
        text: "PRODUTOS",
        field: "nome",
        sum: () => {
          return "Total";
        },
        flex: 1,
        minWidth: 300,
        responsiveLevels: hideMedium
      },
      {
        text: "PRODUTOS",
        field: "abreviatura",
        sum: () => {
          return "Total";
        },
        locked: true,
        width: 163,
        minWidth: 163,
        responsiveLevels: showMedium
      },
      {
        align: "right",
        text: dados.header.tituloHoje,
        field: "faturamentoHoje",
        type: "number",
        htmlEncode: false,
        autoHeight: true,
        sum: true,
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(dados.footer.faturamentoHoje, 0),
            "R$ " + toMoney(dados.footer.cmvHoje, 0),
            "R$ " + toMoney(dados.footer.margemHoje, 0)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(record.faturamentoHoje, 0),
            "R$ " + toMoney(record.cmvHoje, 0),
            "R$ " + toMoney(record.margemHoje, 0)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
      {
        align: "right",
        text: dados.header.tituloOntem,
        field: "faturamentoOntem",
        type: "number",
        htmlEncode: false,
        // sum: () => {
        //   return {
        //     faturamentoHoje: dados.footer.faturamentoHoje,
        //     faturamentoSemana: dados.footer.faturamentoSemana
        //   };
        // },
        // summaryRenderer: ({ sum }) => {
        //   return `${valorEmInteiro(sum.faturamentoHoje)} ${setaSumarioComparacao(sum.faturamentoHoje, sum.faturamentoSemana)}`;
        // },
        sum: true,
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(dados.footer.faturamentoOntem, 0),
            "R$ " + toMoney(dados.footer.cmvOntem, 0),
            "R$ " + toMoney(dados.footer.margemOntem, 0)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(record.faturamentoOntem, 0),
            "R$ " + toMoney(record.cmvOntem, 0),
            "R$ " + toMoney(record.margemOntem, 0)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
      {
        align: "right",
        text: dados.header.tituloSemana,
        field: "faturamentoSemana",
        type: "number",
        htmlEncode: false,
        sum: true,
        // sum: () => {
        //   return {
        //     faturamentoHoje: dados.footer.faturamentoHoje,
        //     faturamentoSemana: dados.footer.faturamentoSemana
        //   };
        // },
        // summaryRenderer: ({ sum }) => {
        //   return `${valorEmInteiro(sum.faturamentoHoje)} ${setaSumarioComparacao(sum.faturamentoHoje, sum.faturamentoSemana)}`;
        // },
        summaryRenderer: ({ sum }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(dados.footer.faturamentoSemana, 0),
            "R$ " + toMoney(dados.footer.cmvSemana, 0),
            "R$ " + toMoney(dados.footer.margemSemana, 0)
          );
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaTresValoresNegativoBTFlex(
            "R$ " + toMoney(record.faturamentoSemana, 0),
            "R$ " + toMoney(record.cmvSemana, 0),
            "R$ " + toMoney(record.margemSemana, 0)
          );
        },
        responsiveLevels: configResponsiveLevelsFaturamentoComparacao,
      },
    ],
  };
};
export { gridConfig };
