import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { valorEmInteiro, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, exportarExcel, concatenaLojas, Hoje, Mes, Ano, removerFocusNoBotao, RetornaPeriodoAnterior, toMoney } from "../../helpers/utils"
import { retornarFormatacaoAmCharts } from "../../helpers/utils-amcharts"
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados, requisitarDadosTotais } from "../../store/graficoEvolucaoDespesas/actions"
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import HeaderGrafico from '../../components/headerGrafico';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { FormGroup, Button, Form, Container } from 'reactstrap';
import GraficoLojasAoVivo from "./partes/grafico";
import GraficoLojasAoVivoBenassi from "./partes/graficoBenassi";
import Select from 'react-select'
import GraficoAltura from '../../components/graficoAltura';
import makeAnimated from 'react-select/animated';
import FocarPaisagem from '../../components/forcarPaisagem';
import { retornarIdCliente } from '../../cliente/variaveis';
import FiltroBrytum from "../../components/filtroBrytum";
import HeaderGraficoBenassi from '../../components/headerGraficoBenassi';

const animatedComponents = makeAnimated();
const formatacaoAmCharts = retornarFormatacaoAmCharts();
const mesAnterior = RetornaPeriodoAnterior(Mes(Hoje()), Ano(Hoje()));
const mesAtual = { mes: Mes(Hoje()), ano: Ano(Hoje()) };
const tipoGrafico = "valor_liquidado";
const idCliente = retornarIdCliente();
const GraficoEvolucaoDespesas = () => {
  const [nomeGrafico, setNomeGrafico] = useState("Faturamento");
  const [formatoNumeroGrafico, setFormatoNumeroGrafico] = useState(formatacaoAmCharts.formatoValores.decimal0Casas);
  const { dados, carregando, totais } = useSelector(state => state.GraficoEvolucaoDespesas);
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const [lojasFiltradas, setLojasFiltradas] = useState([]);
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const dispatch = useDispatch();
  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };

  const lojasFiltradasRef = useRef(null);
  lojasFiltradasRef.current = { lojasFiltradas, setLojasFiltradas };

  const atualizarDados = async () => {
    await dispatch(requisitarDados({
      token,
      u_unidades_negocio_id: concatenaLojas(lojasRef.current.lojas),
      mes_inicial: 1,
      ano_inicial: 2015,
      mes_final: mesAnterior.mes,
      ano_final: mesAnterior.ano,
      resultado: "despesas",
      titulos: "pagar",
      agrupamento: "mensal",
      visualizacao: "despesa_gaveta",
      arvore: "1",
      sob_demanda: 0,
      despesas_historicas: 1,
      exportacao: "evolucao_despesas",
      retorna_desafio: 1
    }));
    if (dados) {
      setMostrarTotais(true);
    }
  }

  const atualizarDadosMesAtual = async () => {
    await dispatch(requisitarDadosTotais({
      token,
      u_unidades_negocio_id: concatenaLojas(lojasRef.current.lojas),
      mes_inicial: mesAtual.mes,
      ano_inicial: mesAtual.ano,
      mes_final: mesAtual.mes,
      ano_final: mesAtual.ano,
      resultado: "despesas",
      titulos: "pagar",
      agrupamento: "mensal",
      visualizacao: "despesa_gaveta",
      arvore: "1",
      sob_demanda: 0,
      despesas_historicas: 1,
      exportacao: "evolucao_despesas",
      retorna_desafio: 1
    }));
  }

  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])


  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token, "gerenciais"));
  }

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    atualizarDadosMesAtual();
    const timer = setInterval(() => {
      if (!carregando) {
        atualizarDadosMesAtual();
      }
    }, 2000)
    return () => {
      clearInterval(timer)
      dispatch(requisitarLimparDados())
    }
  }, []);


  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }


  const atualizarLojas = () => {
    atualizarDados();
    setLojasFiltradas(lojasRef.current.lojas);
    atualizarDadosMesAtual();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }
  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />
      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ],
              titulo: "Batida do tambor",
              icone: "fad fa-drum"
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container"  >
                  <div style={{ minWidth: 200 }}>
                    <Select
                      placeholder="Todas as unidades"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      options={lojasDoUsuario}
                      isSearchable={false}
                      isMulti
                      classNamePrefix="select2-selection"
                      defaultValue={lojas || []}
                      onChange={Object => {
                        trocarLojas(Object || []);
                      }}
                    />
                  </div>

                </FormGroup>
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarLojas(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>

            {<Container className="fixWith" fluid>
              {idCliente == "benassi" &&
                <div className={"headers mt-4"}>
                  <HeaderGrafico mostrar={mostrarTotais} colunas={2} itens={[
                    { nome: "Previsão para o mês de:", valor: totais?.header?.periodo_ano },
                    {
                      nome: totais?.header?.valor_original,
                      valor: `R$ ${toMoney(totais?.footer?.valor_original, 0)}`,
                      corClass: "text-warning"
                    },
                    {
                      nome: totais?.header?.valor_acrescimos,
                      valor: `R$ ${toMoney(totais?.footer?.valor_acrescimos, 0)}`,
                      corClass: "text-info"
                    },
                    {
                      nome: totais?.header?.valor_descontos,
                      valor: `R$ ${toMoney(totais?.footer?.valor_descontos, 0)}`,
                      corClass: "text-success"
                    },
                    {
                      nome: totais?.header?.valor_liquidado,
                      valor: `R$ ${toMoney(totais?.footer?.valor_liquidado, 0)}`,
                      corClass: "text-danger"
                    },
                    {
                      nome: totais?.header?.valor_saldo,
                      valor: `R$ ${toMoney(totais?.footer?.valor_saldo, 0)}`,
                      corClass: "text-danger"
                    }
                  ]} />
                </div>
              }
              {(idCliente == "real" || idCliente == "volo") &&
                <div className={"headers mt-4"}>
                  <HeaderGrafico mostrar={mostrarTotais} colunas={2} itens={[
                    { nome: "Previsão para o mês de:", valor: totais?.header?.periodo_ano },
                    {
                      nome: totais?.header?.valor_original,
                      valor: `R$ ${toMoney(totais?.footer?.valor_original, 0)}`,
                      corClass: "text-warning"
                    },
                    {
                      nome: totais?.header?.valor_acrescimos,
                      valor: `R$ ${toMoney(totais?.footer?.valor_acrescimos, 0)}`,
                      corClass: "text-info"
                    },
                    {
                      nome: totais?.header?.valor_descontos,
                      valor: `R$ ${toMoney(totais?.footer?.valor_descontos, 0)}`,
                      corClass: "text-success"
                    },
                    {
                      nome: totais?.header?.valor_liquidado,
                      valor: `R$ ${toMoney(totais?.footer?.valor_liquidado, 0)}`,
                      corClass: "text-danger"
                    },
                    {
                      nome: totais?.header?.valor_saldo,
                      valor: `R$ ${toMoney(totais?.footer?.valor_saldo, 0)}`,
                      corClass: "text-danger"
                    }
                  ]} />
                </div>
              }

            </Container>}
          </div>


          <GraficoAltura>
            {(idCliente == "real" || idCliente == "volo") && <GraficoLojasAoVivo nomeGrafico={nomeGrafico} tipoGrafico={tipoGrafico} formatoNumeroGrafico={formatoNumeroGrafico} dados={dados} />}
            {idCliente == "benassi" && <GraficoLojasAoVivoBenassi nomeGrafico={nomeGrafico} tipoGrafico={tipoGrafico} formatoNumeroGrafico={formatoNumeroGrafico} dados={dados} />}
          </GraficoAltura>

        </div>
      </>
    </React.Fragment>
  );
}

export default GraficoEvolucaoDespesas;