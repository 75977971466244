import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";
import { insertIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;

const GraficoBenassi = ({ dados, cores, index }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        const success = cores.success.trim();
        const info = cores.info.trim();
        const danger = cores.danger.trim();
        const opacidadeNecessario = .5;

        let maxValores = {
            faturamento: 0,
            cupons: 0,
            cmv: 0,
            margem: 0
        }
        if (dadosGrafico.length > 0) {
            const data = dadosGrafico.map(arr => {
                maxValores.faturamento = arr.faturamento > maxValores.faturamento ? arr.faturamento : maxValores.faturamento
                maxValores.cupons = arr.cupons > maxValores.cupons ? arr.cupons : maxValores.cupons
                maxValores.cmv_percentual = arr.cmv_percentual > maxValores.cmv_percentual ? arr.cmv_percentual : maxValores.cmv_percentual
                maxValores.margem_contribuicao_percentual = arr.margem_contribuicao_percentual > maxValores.margem_contribuicao_percentual ? arr.margem_contribuicao_percentual : maxValores.margem_contribuicao_percentual
                
                arr.cor_faturamento_liquido = arr.faturamento_liquido >= 0 ? info : danger
                arr.cor_desafio_faturamento_liquido = arr.desafio_valor >= 0 ? info : danger
                
                arr.cor_margem_i = arr.margem_contribuicao_percentual >= 0 ? success : danger
                arr.cor_desafio_margem_i = arr.desafio_margem_i_percentual >= 0 ? success : danger
                
                arr.cor_margem_ii = arr.margem_ii_percentual >= 0 ? success : danger
                arr.cor_desafio_margem_ii = arr.desafio_margem_ii_percentual >= 0 ? success : danger
                return { ...arr, date: toDateJS(arr.ano, arr.mes) }
            });

            var interfaceColors = new am4core.InterfaceColorSet();
            var chart = am4core.create(index, am4charts.XYChart);

            definirLocalizacaoAmCharts(chart);
            chart.data = data;
            chart.bottomAxesContainer.layout = "horizontal";
            chart.bottomAxesContainer.reverseOrder = true;





            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "u_nome";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.strokeOpacity = 1;
            categoryAxis.renderer.minGridDistance = 30;
            // categoryAxis.renderer.cellStartLocation = 0.2;
            // categoryAxis.renderer.cellEndLocation = 0.8;
            categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
            categoryAxis.renderer.inversed = true;


            //+++++++ faturamento liquido ++++++++++++
            var valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis1.tooltip.disabled = true;
            valueAxis1.renderer.baseGrid.disabled = true;
            valueAxis1.marginRight = 30;
            valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis1.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis1.renderer.grid.template.strokeOpacity = 1;
            valueAxis1.title.text = dados?.header?.faturamento_liquido || "";
            valueAxis1.renderer.labels.template.disabled = true;
            valueAxis1.title.dy = 10;


            var series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.categoryY = "u_nome";
            series1.dataFields.valueX = "faturamento_liquido";
            series1.xAxis = valueAxis1;
            series1.name = dados?.header?.faturamento_liquido || "";
            series1.clustered = false;
            series1.opacity = .5;
            series1.columns.template.height = 40;
            series1.columns.template.dy = 20;


            var columnTemplate1 = series1.columns.template;
            columnTemplate1.strokeOpacity = 0;
            columnTemplate1.propertyFields.fill = "cor_faturamento_liquido";

            var label1 = columnTemplate1.createChild(am4core.Label);
            label1.text = `[bold font-size: 15px]{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
            label1.align = "left";
            label1.valign = "middle";
            label1.dx = 5;
            label1.fontSize = 15;
            label1.fill = am4core.color("#fff")
            label1.background.fill = am4core.color("gray");
            label1.background.fillOpacity = 0.5;
            label1.paddingRight = 5;
            label1.paddingLeft = 5;
            label1.paddingTop = 3;
            label1.truncate = false;
            label1.hideOversized = false;


            var series11 = chart.series.push(new am4charts.ColumnSeries());
            series11.dataFields.categoryY = "u_nome";
            series11.dataFields.valueX = "desafio_valor";
            series11.xAxis = valueAxis1;
            series11.name = dados?.header?.desafio_valor || "";
            series11.clustered = false;
            series11.opacity = .5;
            series11.columns.template.height = 40;
            series11.columns.template.dy = -20;

            var columnTemplate11 = series11.columns.template;
            columnTemplate11.strokeOpacity = 0;
            columnTemplate11.propertyFields.fill = "cor_desafio_faturamento_liquido";
            columnTemplate11.column.fillOpacity = opacidadeNecessario;


            var label11 = columnTemplate11.createChild(am4core.Label);
            label11.text = `[bold font-size: 15px]{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
            label11.align = "left";
            label11.valign = "middle";
            label11.dx = 5;
            label11.fontSize = 15;
            label11.fill = am4core.color("#fff")
            label11.background.fill = am4core.color("gray");
            label11.background.fillOpacity = 0.5;
            label11.paddingRight = 5;
            label11.paddingLeft = 5;
            label11.paddingTop = 3;
            label11.truncate = false;
            label11.hideOversized = false;



            //+++++++ faturamento liquido ++++++++++++

            //+++++++ CMV ++++++++++++
            // var valueAxis3 = chart.xAxes.push(new am4charts.ValueAxis());
            // valueAxis3.tooltip.disabled = true;
            // valueAxis3.renderer.baseGrid.disabled = true;
            // valueAxis3.marginRight = 30;
            // valueAxis3.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            // valueAxis3.renderer.gridContainer.background.fillOpacity = 0.03;
            // valueAxis3.renderer.grid.template.stroke = interfaceColors.getFor("background");
            // valueAxis3.renderer.grid.template.strokeOpacity = 1;
            // valueAxis3.renderer.grid.template.align = "left";
            // valueAxis3.renderer.labels.template.disabled = true;

            // valueAxis3.title.text = dados?.header?.cmv_valor || "";
            // valueAxis3.max = 100;
            // valueAxis3.title.dy = 10;

            // var series3 = chart.series.push(new am4charts.ColumnSeries());
            // series3.dataFields.categoryY = "u_nome";
            // series3.dataFields.valueX = "cmv_percentual";
            // series3.xAxis = valueAxis3;
            // series3.name = dados?.header?.cmv_percentual || "";
            // series3.stroke = am4core.color(corCMV);
            // series3.fill = am4core.color(corCMV);
            // series3.columns.template.height = 40;
            // series3.columns.template.dy = -20;
            // series3.clustered = false;

            // var columnTemplate3 = series3.columns.template;
            // columnTemplate3.strokeOpacity = 0;
            // columnTemplate3.propertyFields.fill = "color";

            // var label3 = columnTemplate3.createChild(am4core.Label);
            // label3.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            // label3.align = "left";
            // label3.valign = "middle";
            // label3.dx = 5;
            // label3.fontSize = 15;
            // label3.fill = am4core.color("#fff")
            // label3.background.fill = am4core.color("gray");
            // label3.background.fillOpacity = 0.5;
            // label3.paddingRight = 5;
            // label3.paddingLeft = 5;
            // label3.paddingTop = 3;
            // label3.truncate = false;
            // label3.hideOversized = false;

            // var series31 = chart.series.push(new am4charts.ColumnSeries());
            // series31.dataFields.categoryY = "u_nome";
            // series31.dataFields.valueX = "desafio_cmv_percentual";
            // series31.xAxis = valueAxis3;
            // series31.name = dados?.header?.desafio_cmv_percentual || "";
            // series31.columns.template.height = 40;
            // series31.columns.template.dy = 20;
            // series31.stroke = am4core.color(corCMVDesafio);
            // series31.fill = am4core.color(corCMVDesafio);
            // series31.clustered = false;

            // var columnTemplate31 = series31.columns.template;
            // columnTemplate31.strokeOpacity = 0;
            // columnTemplate31.propertyFields.fill = "color";

            // var label31 = columnTemplate31.createChild(am4core.Label);
            // label31.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            // label31.align = "left";
            // label31.valign = "middle";
            // label31.dx = 5;
            // label31.fontSize = 15;
            // label31.fill = am4core.color("#fff")
            // label31.background.fill = am4core.color("gray");
            // label31.background.fillOpacity = 0.5;
            // label31.paddingRight = 5;
            // label31.paddingLeft = 5;
            // label31.paddingTop = 3;
            // label31.truncate = false;
            // label31.hideOversized = false;
            //+++++++ CMV ++++++++++++

            //+++++++ Margem I ++++++++++++
            var valueAxis4 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis4.tooltip.disabled = true;
            valueAxis4.renderer.baseGrid.disabled = true;
            valueAxis4.marginRight = 30;
            valueAxis4.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis4.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis4.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis4.renderer.grid.template.strokeOpacity = 1;
            valueAxis4.renderer.grid.template.align = "left";
            valueAxis4.renderer.labels.template.disabled = true;
            valueAxis4.title.text = dados?.header?.margem_contribuicao_valor || "";
            valueAxis4.max = 100;
            valueAxis4.title.dy = 10;
            valueAxis4.min = -100;
            valueAxis4.max = 100;

            var series4 = chart.series.push(new am4charts.ColumnSeries());
            series4.dataFields.categoryY = "u_nome";
            series4.dataFields.valueX = "margem_contribuicao_percentual";
            series4.xAxis = valueAxis4;
            series4.name = dados?.header?.margem_contribuicao_valor || "";
            // series4.stroke = am4core.color(success);
            // series4.fill = am4core.color(success);
            // series4.propertyFields.stroke = "cor_margem_i";
            // series4.propertyFields.fill = "cor_margem_i";
            series4.columns.template.height = 40;
            series4.columns.template.dy = 20;
            series4.clustered = false;

            var columnTemplate4 = series4.columns.template;
            columnTemplate4.strokeOpacity = 0;
            columnTemplate4.propertyFields.fill = "cor_margem_i";

            var label4 = columnTemplate4.createChild(am4core.Label);
            label4.text = `[bold font-size: 15px]{margem_contribuicao_percentual.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%[/]    [opacity: .9]{margem_contribuicao_valor.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
            label4.align = "left";
            label4.valign = "middle";
            label4.dx = 5;
            label4.fontSize = 15;
            label4.fill = am4core.color("#fff")
            label4.background.fill = am4core.color("gray");
            label4.background.fillOpacity = 0.5;
            label4.paddingRight = 5;
            label4.paddingLeft = 5;
            label4.paddingTop = 4;
            label4.truncate = false;
            label4.hideOversized = false;


            var series41 = chart.series.push(new am4charts.ColumnSeries());
            series41.dataFields.categoryY = "u_nome";
            series41.dataFields.valueX = "desafio_margem_i_percentual";
            series41.xAxis = valueAxis4;
            series41.name = dados?.header?.desafio_margem_i_valor || "";

            series41.columns.template.height = 40;
            series41.columns.template.dy = -20;
            series41.clustered = false;

            var columnTemplate41 = series41.columns.template;
            columnTemplate41.strokeOpacity = 0;
            columnTemplate41.propertyFields.fill = "cor_desafio_margem_i";
            columnTemplate41.column.fillOpacity = opacidadeNecessario;


            var label41 = columnTemplate41.createChild(am4core.Label);
            label41.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            label41.text = `[bold font-size: 15px]{desafio_margem_i_percentual.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%[/]    [opacity: .9]{desafio_margem_i_valor.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;

            label41.align = "left";
            label41.valign = "middle";
            label41.dx = 5;
            label41.fontSize = 15;
            label41.fill = am4core.color("#fff")
            label41.background.fill = am4core.color("gray");
            label41.background.fillOpacity = 0.5;
            label41.paddingRight = 5;
            label41.paddingLeft = 5;
            label41.paddingTop = 4;
            label41.truncate = false;
            label41.hideOversized = false;


            //+++++++ Margem I ++++++++++++

            //+++++++ Despesas ++++++++++++
            // var valueAxis5 = chart.xAxes.push(new am4charts.ValueAxis());
            // valueAxis5.tooltip.disabled = true;
            // valueAxis5.renderer.baseGrid.disabled = true;
            // valueAxis5.marginRight = 30;
            // valueAxis5.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            // valueAxis5.renderer.gridContainer.background.fillOpacity = 0.03;
            // valueAxis5.renderer.grid.template.stroke = interfaceColors.getFor("background");
            // valueAxis5.renderer.grid.template.strokeOpacity = 1;
            // valueAxis5.title.text = dados?.header?.despesas_unidade || "";
            // valueAxis5.renderer.labels.template.disabled = true;
            // valueAxis5.title.dy = 10;


            // var series5 = chart.series.push(new am4charts.ColumnSeries());
            // series5.dataFields.categoryY = "u_nome";
            // series5.dataFields.valueX = "despesas_unidade";
            // series5.xAxis = valueAxis5;
            // series5.name = dados?.header?.despesas_unidade || "";;
            // series5.stroke = am4core.color(corDespesasUniades);
            // series5.fill = am4core.color(corDespesasUniades);
            // series5.columns.template.height = 40;
            // series5.columns.template.dy = -20;
            // series5.clustered = false;
            // series5.opacity = .5;

            // var columnTemplate5 = series5.columns.template;
            // columnTemplate5.strokeOpacity = 0;
            // columnTemplate5.propertyFields.fill = "color";

            // var label5 = columnTemplate5.createChild(am4core.Label);
            // label5.text = `R$ {valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            // label5.align = "left";
            // label5.valign = "middle";
            // label5.dx = 5;
            // label5.fontSize = 15;
            // label5.fill = am4core.color("#fff")
            // label5.background.fill = am4core.color("gray");
            // label5.background.fillOpacity = 0.5;
            // label5.paddingRight = 5;
            // label5.paddingLeft = 5;
            // label5.paddingTop = 3;
            // label5.truncate = false;
            // label5.hideOversized = false;

            // var series51 = chart.series.push(new am4charts.ColumnSeries());
            // series51.dataFields.categoryY = "u_nome";
            // series51.dataFields.valueX = "desafio_despesa_valor";
            // series51.xAxis = valueAxis5;
            // series51.name = dados?.header?.desafio_despesa_valor || "";
            // series51.stroke = am4core.color(corDespesasUniadesDesafio);
            // series51.fill = am4core.color(corDespesasUniadesDesafio);
            // series51.columns.template.height = 40;
            // series51.columns.template.dy = 20;
            // series51.clustered = false;
            // series51.opacity = .5;

            // var columnTemplate51 = series51.columns.template;
            // columnTemplate51.strokeOpacity = 0;
            // columnTemplate51.propertyFields.fill = "color";

            // var label51 = columnTemplate51.createChild(am4core.Label);
            // label51.text = `R$ {valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            // label51.align = "left";
            // label51.valign = "middle";
            // label51.dx = 5;
            // label51.fontSize = 15;
            // label51.fill = am4core.color("#fff")
            // label51.background.fill = am4core.color("gray");
            // label51.background.fillOpacity = 0.5;
            // label51.paddingRight = 5;
            // label51.paddingLeft = 5;
            // label51.paddingTop = 3;
            // label51.truncate = false;
            // label51.hideOversized = false;
            //+++++++ Despesas ++++++++++++

            //+++++++ Margem II ++++++++++++
            var valueAxis6 = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis6.tooltip.disabled = true;
            valueAxis6.renderer.baseGrid.disabled = true;
            valueAxis6.marginRight = 30;
            valueAxis6.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
            valueAxis6.renderer.gridContainer.background.fillOpacity = 0.03;
            valueAxis6.renderer.grid.template.stroke = interfaceColors.getFor("background");
            valueAxis6.renderer.grid.template.strokeOpacity = 1;
            valueAxis6.renderer.grid.template.align = "left";
            valueAxis6.renderer.labels.template.disabled = true;
            valueAxis6.title.text = dados?.header?.margem_ii || "";
            valueAxis6.max = 100;
            valueAxis6.title.dy = 10;
            valueAxis6.min = -100;
            valueAxis6.max = 100;

            var series6 = chart.series.push(new am4charts.ColumnSeries());
            series6.dataFields.categoryY = "u_nome";
            series6.dataFields.valueX = "margem_ii_percentual";
            series6.xAxis = valueAxis6;
            series6.name = dados?.header?.margem_ii || "";
            // series6.stroke = am4core.color(success);
            // series6.fill = am4core.color(success);
            series6.columns.template.height = 40;
            series6.columns.template.dy = 20;
            series6.clustered = false;

            var columnTemplate6 = series6.columns.template;
            columnTemplate6.strokeOpacity = 0;
            columnTemplate6.propertyFields.fill = "cor_margem_ii";

            var label6 = columnTemplate6.createChild(am4core.Label);
            label6.text = `[bold font-size: 15px]{margem_ii_percentual.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%[/]    [opacity: .9]{margem_ii.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
            label6.align = "left";
            label6.valign = "middle";
            label6.dx = 5;
            label6.fontSize = 15;
            label6.fill = am4core.color("#fff")
            label6.background.fill = am4core.color("gray");
            label6.background.fillOpacity = 0.5;
            label6.paddingRight = 5;
            label6.paddingLeft = 5;
            label6.paddingTop = 4;
            label6.truncate = false;
            label6.hideOversized = false;


            var series61 = chart.series.push(new am4charts.ColumnSeries());
            series61.dataFields.categoryY = "u_nome";
            series61.dataFields.valueX = "desafio_margem_ii_percentual";
            series61.xAxis = valueAxis6;
            series61.name = dados?.header?.margem_ii || "";
            // series61.stroke = am4core.color(success);
            // series61.fill = am4core.color(success);
            series61.columns.template.height = 40;
            series61.columns.template.dy = -20;
            series61.clustered = false;

            var columnTemplate61 = series61.columns.template;
            columnTemplate61.strokeOpacity = 0;
            columnTemplate61.propertyFields.fill = "cor_desafio_margem_ii";
            columnTemplate61.column.fillOpacity = opacidadeNecessario;

            var label61 = columnTemplate61.createChild(am4core.Label);
            label61.text = `{valueX.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%`;
            label61.text = `[bold font-size: 15px]{desafio_margem_ii_percentual.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%[/]    [opacity: .9]{desafio_margem_ii_valor.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;

            label61.align = "left";
            label61.valign = "middle";
            label61.dx = 5;
            label61.fontSize = 15;
            label61.fill = am4core.color("#fff")
            label61.background.fill = am4core.color("gray");
            label61.background.fillOpacity = 0.5;
            label61.paddingRight = 5;
            label61.paddingLeft = 5;
            label61.paddingTop = 4;
            label61.truncate = false;
            label61.hideOversized = false;


            //+++++++ Margem II ++++++++++++

            chart.responsive.useDefault = true;
            chart.responsive.enabled = true;

            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                    { "type": "jpg", "label": "JPG" },
                    { "type": "png", "label": "PNG" },
                    { "type": "svg", "label": "SVG" }
                ]
            }];

            return () => {
                chart.dispose();
            };
        }

    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        if (alturaGrafico < 300) {
            alturaGrafico = 350
        }
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
    }

    return (
        <div id={index} style={{ width: "100%", minHeight: alturaFinal, maxHeight: alturaFinal, height: alturaFinal }}></div>
    )
}

export default memo(GraficoBenassi)