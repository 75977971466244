import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins from "@amcharts/amcharts4/plugins/sunburst";
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toMoney, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { pegarCores } from '../../../helpers/utils';
am4core.options.commercialLicense = true;
const formatacaoAmCharts = retornarFormatacaoAmCharts();



const GraficoBenassi = ({ tipoGrafico, tipoGraficoPercentual, dados, comparativo, id, formatoNumeroGrafico, tipoCusto, mostrarFiltros }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        let cores = pegarCores();
        const lista_cores = [
            am4core.color(cores.success.trim()), //success
            am4core.color(cores.info.trim()), //info
            am4core.color(cores.warning.trim()),//warnning
        ];
        let maxValor = 0;
        let dadosFormatados = [];
        if (!dados) return
        if (dadosGrafico.length > 0) {
            let propTipoCusto = "";
            if (tipoCusto == "02") {
                propTipoCusto = "02_"
            }
            const dadosFormatados = [];
            dadosGrafico.map((arr, indice) => {
                dadosFormatados.push(
                    {
                        faturamento_mostrar_nome: dados.header[tipoGrafico],
                        faturamento_mostrar: "R$ " + toMoney(arr[tipoGrafico], 0),
                        faturamento_percentual_mostrar: toMoney(arr[tipoGraficoPercentual] || 0, 1) + "%",
                        desafio_mostrar_nome: dados.header.desafio_valor || "Desafio",
                        desafio_mostrar: "R$ " + toMoney(arr.desafio_valor, 0),
                        desafio_percentual_mostrar: toMoney(arr.desafio_percentual || 0, 1) + "%",
                        desafio_variacao_mostrar_nome: dados.header.desafio_variacao || "Variação",
                        desafio_variacao_mostrar: "R$ " + toMoney(arr.desafio_variacao, 0),
                        desafio_variacao_percentual_mostrar: toMoney(arr.desafio_variacao_percentual || 0, 1) + "%",

                        cmv_mostrar_nome: dados.header[`cmv_valor`],
                        cmv_mostrar: "R$ " + toMoney(arr[`cmv_valor`], 0),
                        cmv_percentual_mostrar: toMoney(arr[`cmv_percentual`], 1) + "%",

                        desafio_cmv_percentual_mostrar: toMoney(arr[`desafio_cmv_percentual`] || 0, 1) + "%",
                        desafio_margem_i_percentual_mostrar: toMoney(arr[`desafio_margem_i_percentual`] || 0, 1) + "%",
                        desafio_margem_ii_percentual_mostrar: toMoney(arr[`desafio_margem_ii_percentual`] || 0, 1) + "%",
                        desafio_despesa_percentual_mostrar: toMoney(arr[`desafio_despesa_percentual`] || 0, 1) + "%",
                        desafio_margem_ii_mostrar: "R$ " + toMoney(arr[`desafio_margem_ii_valor`], 0),


                        desafio_cmv_mostrar: "R$ " + toMoney(arr[`desafio_cmv_valor`], 0),

                        desafio_cmv_variacao_mostrar: "R$ " + toMoney(arr[`desafio_cmv_variacao`], 0),
                        desafio_cmv_variacao_percentual_mostrar: toMoney(arr[`desafio_cmv_variacao_percentual`], 1) + "%",
                        desafio_margem_i_mostrar: "R$ " + toMoney(arr[`desafio_margem_i_valor`], 0),
                        desafio_margem_i_variacao_mostrar: "R$ " + toMoney(arr[`desafio_margem_i_variacao`], 0),
                        desafio_margem_i_variacao_percentual_mostrar: toMoney(arr[`desafio_margem_i_variacao_percentual`], 1) + "%",

                        desafio_despesa_mostrar: "R$ " + toMoney(arr[`desafio_despesa_valor`], 0),
                        desafio_despesa_variacao_mostrar: "R$ " + toMoney(arr[`desafio_despesa_variacao`], 0),
                        desafio_despesa_variacao_percentual_mostrar: toMoney(arr[`desafio_despesa_variacao_percentual`], 1) + "%",

                        nao_vendas_nome: dados.header[`nao_vendas`],
                        nao_vendas_mostrar: "R$ " + toMoney(arr[`nao_vendas`], 0),
                        nao_vendas_percentual_mostrar: toMoney(arr[`nao_vendas_percentual`], 1) + "%",

                        desafio_nao_vendas_valor_mostrar: "R$ " + toMoney(arr[`desafio_nao_vendas_valor`], 0),
                        desafio_nao_vendas_percentual_mostrar: toMoney(arr[`desafio_nao_vendas_percentual`], 1) + "%",
                        desafio_nao_vendas_variacao_mostrar: "R$ " + toMoney(arr[`desafio_nao_vendas_variacao`], 0),
                        desafio_nao_vendas_variacao_percentual_mostrar: toMoney(arr[`desafio_nao_vendas_variacao_percentual`], 1) + "%",


                        desafio_resultado_mostrar: "R$ " + toMoney(arr[`desafio_resultado_valor`], 0),
                        desafio_resultado_variacao_mostrar: "R$ " + toMoney(arr[`desafio_resultado_variacao`], 0),
                        desafio_resultado_variacao_percentual_mostrar: toMoney(arr[`desafio_resultado_variacao_percentual`], 1) + "%",

                        margem_i_nome: dados.header[`margem_contribuicao_valor`],
                        margem_i_mostrar: "R$ " + toMoney(arr[`margem_contribuicao_valor`], 0),
                        margem_i_percentual_mostrar: toMoney(arr[`margem_contribuicao_percentual`], 1) + "%",

                        margem_ii_nome: dados.header[`margem_ii`],
                        margem_ii_mostrar: "R$ " + toMoney(arr[`margem_ii`], 0),
                        margem_ii_percentual_mostrar: toMoney(arr[`margem_ii_percentual`], 1) + "%",
                        despesas_unidade_nome: dados.header[`despesas_unidade`],
                        despesas_unidade_mostrar: "R$ " + toMoney(arr[`despesas_unidade`], 0),
                        despesas_unidade_percentual_mostrar: toMoney(arr[`despesas_unidade_percentual`], 1) + "%",

                        color: lista_cores[indice],
                        name: arr.u_nome
                    }
                )
                let dadosFormatadosUltimo = dadosFormatados[dadosFormatados.length - 1];
                if (arr.children) {
                    dadosFormatadosUltimo.children = [];
                    arr.children.forEach((children) => {
                        dadosFormatadosUltimo.children.push({
                            name: children.u_nome,
                            value: children[tipoGrafico],
                            faturamento_mostrar_nome: dados.header[tipoGrafico],
                            faturamento_mostrar: "R$ " + toMoney(children[tipoGrafico], 0),
                            faturamento_percentual_mostrar: toMoney(children[tipoGraficoPercentual] || 0, 1) + "%",
                            cmv_mostrar_nome: dados.header[`cmv_valor`],
                            cmv_mostrar: "R$ " + toMoney(children[`cmv_valor`], 0),
                            cmv_percentual_mostrar: toMoney(children[`cmv_percentual`], 1) + "%",


                            margem_i_nome: dados.header[`margem_contribuicao_valor`],
                            margem_i_mostrar: "R$ " + toMoney(children[`margem_contribuicao_valor`], 0),
                            margem_i_percentual_mostrar: toMoney(children[`margem_contribuicao_percentual`], 1) + "%",
                            desafio_mostrar_nome: dados.header.desafio_valor || "Desafio",
                            desafio_mostrar: "R$ " + toMoney(children.desafio_valor, 0),
                            desafio_percentual_mostrar: toMoney(children.desafio_percentual || 0, 1) + "%",
                            desafio_variacao_mostrar_nome: dados.header.desafio_variacao || "Variação",
                            desafio_variacao_mostrar: "R$ " + toMoney(children.desafio_variacao, 0),
                            desafio_variacao_percentual_mostrar: toMoney(children.desafio_variacao_percentual || 0, 1) + "%",

                            desafio_cmv_mostrar: "R$ " + toMoney(children[`desafio_cmv_valor`], 0),
                            desafio_cmv_variacao_mostrar: "R$ " + toMoney(children[`desafio_cmv_variacao`], 0),
                            desafio_cmv_variacao_percentual_mostrar: toMoney(children[`desafio_cmv_variacao_percentual`], 1) + "%",
                            desafio_margem_i_mostrar: "R$ " + toMoney(children[`desafio_margem_i_valor`], 0),
                            desafio_margem_i_variacao_mostrar: "R$ " + toMoney(children[`desafio_margem_i_variacao`], 0),
                            desafio_margem_i_variacao_percentual_mostrar: toMoney(children[`desafio_margem_i_variacao_percentual`], 1) + "%",
                            desafio_despesa_mostrar: "R$ " + toMoney(children[`desafio_despesa_valor`], 0),
                            desafio_despesa_variacao_mostrar: "R$ " + toMoney(children[`desafio_despesa_variacao`], 0),
                            desafio_despesa_variacao_percentual_mostrar: toMoney(children[`desafio_despesa_variacao_percentual`], 1) + "%",

                            desafio_nao_vendas_valor_mostrar: "R$ " + toMoney(children[`desafio_nao_vendas_valor`], 0),
                            desafio_nao_vendas_percentual_mostrar: toMoney(children[`desafio_nao_vendas_percentual`], 1) + "%",
                            desafio_nao_vendas_variacao_mostrar: "R$ " + toMoney(children[`desafio_nao_vendas_variacao`], 0),
                            desafio_nao_vendas_variacao_percentual_mostrar: toMoney(children[`desafio_nao_vendas_variacao_percentual`], 1) + "%",
                            nao_vendas_nome: dados.header[`nao_vendas`],
                            nao_vendas_mostrar: "R$ " + toMoney(children[`nao_vendas`], 0),
                            nao_vendas_percentual_mostrar: toMoney(children[`nao_vendas_percentual`], 1) + "%",

                            desafio_resultado_mostrar: "R$ " + toMoney(children[`desafio_resultado_valor`], 0),
                            desafio_resultado_variacao_mostrar: "R$ " + toMoney(children[`desafio_resultado_variacao`], 0),
                            desafio_resultado_variacao_percentual_mostrar: toMoney(children[`desafio_resultado_variacao_percentual`], 1) + "%",
                            desafio_margem_ii_mostrar: "R$ " + toMoney(children[`desafio_margem_ii_valor`], 0),

                            margem_ii_nome: dados.header[`margem_ii`],
                            margem_ii_mostrar: "R$ " + toMoney(children[`margem_ii`], 0),
                            margem_ii_percentual_mostrar: toMoney(children[`margem_ii_percentual`], 1) + "%",
                            despesas_unidade_nome: dados.header[`despesas_unidade`],
                            despesas_unidade_mostrar: "R$ " + toMoney(children[`despesas_unidade`], 0),
                            despesas_unidade_percentual_mostrar: toMoney(children[`despesas_unidade_percentual`], 1) + "%",

                            desafio_cmv_percentual_mostrar: toMoney(children[`desafio_cmv_percentual`] || 0, 1) + "%",
                            desafio_margem_i_percentual_mostrar: toMoney(children[`desafio_margem_i_percentual`] || 0, 1) + "%",
                            desafio_margem_ii_percentual_mostrar: toMoney(children[`desafio_margem_ii_percentual`] || 0, 1) + "%",
                            desafio_despesa_percentual_mostrar: toMoney(children[`desafio_despesa_percentual`] || 0, 1) + "%"
                        })
                        let ultimoChildren = dadosFormatadosUltimo.children[dadosFormatadosUltimo.children.length - 1];
                        if (children.children) {
                            ultimoChildren.children = [];
                            children.children.forEach((children2) => {
                                ultimoChildren.children.push({
                                    name: children2.u_nome,
                                    value: children2[tipoGrafico],
                                    faturamento_mostrar_nome: dados.header[tipoGrafico],
                                    faturamento_mostrar: "R$ " + toMoney(children2[tipoGrafico], 0),
                                    faturamento_percentual_mostrar: toMoney(children2[tipoGraficoPercentual] || 0, 1) + "%",
                                    cmv_mostrar_nome: dados.header[`cmv_valor`],
                                    cmv_mostrar: "R$ " + toMoney(children2[`cmv_valor`], 0),
                                    cmv_percentual_mostrar: toMoney(children2[`cmv_percentual`], 1) + "%",
                                    margem_i_nome: dados.header[`margem_contribuicao_valor`],
                                    margem_i_mostrar: "R$ " + toMoney(children2[`margem_contribuicao_valor`], 0),
                                    margem_i_percentual_mostrar: toMoney(children2[`margem_contribuicao_percentual`], 1) + "%",
                                    desafio_mostrar_nome: dados.header.desafio_valor || "Desafio",
                                    desafio_mostrar: "R$ " + toMoney(children2.desafio_valor, 0),
                                    desafio_percentual_mostrar: toMoney(children2.desafio_percentual || 0, 1) + "%",
                                    desafio_variacao_mostrar_nome: dados.header.desafio_variacao || "Variação",
                                    desafio_variacao_mostrar: "R$ " + toMoney(children2.desafio_variacao, 0),
                                    desafio_variacao_percentual_mostrar: toMoney(children2.desafio_variacao_percentual || 0, 1) + "%",
                                    desafio_margem_ii_mostrar: "R$ " + toMoney(children2[`desafio_margem_ii_valor`], 0),

                                    desafio_cmv_mostrar: "R$ " + toMoney(children2[`desafio_cmv_valor`], 0),
                                    desafio_cmv_variacao_mostrar: "R$ " + toMoney(children2[`desafio_cmv_variacao`], 0),
                                    desafio_cmv_variacao_percentual_mostrar: toMoney(children2[`desafio_cmv_variacao_percentual`], 1) + "%",
                                    desafio_margem_i_mostrar: "R$ " + toMoney(children2[`desafio_margem_i_valor`], 0),
                                    desafio_margem_i_variacao_mostrar: "R$ " + toMoney(children2[`desafio_margem_i_variacao`], 0),
                                    desafio_margem_i_variacao_percentual_mostrar: toMoney(children2[`desafio_margem_i_variacao_percentual`], 1) + "%",
                                    desafio_despesa_mostrar: "R$ " + toMoney(children2[`desafio_despesa_valor`], 0),
                                    desafio_despesa_variacao_mostrar: "R$ " + toMoney(children2[`desafio_despesa_variacao`], 0),
                                    desafio_despesa_variacao_percentual_mostrar: toMoney(children2[`desafio_despesa_variacao_percentual`], 1) + "%",

                                    desafio_nao_vendas_valor_mostrar: "R$ " + toMoney(children2[`desafio_nao_vendas_valor`], 0),
                                    desafio_nao_vendas_percentual_mostrar: toMoney(children2[`desafio_nao_vendas_percentual`], 1) + "%",
                                    desafio_nao_vendas_variacao_mostrar: "R$ " + toMoney(children2[`desafio_nao_vendas_variacao`], 0),
                                    desafio_nao_vendas_variacao_percentual_mostrar: toMoney(children2[`desafio_nao_vendas_variacao_percentual`], 1) + "%",
                                    nao_vendas_nome: dados.header[`nao_vendas`],
                                    nao_vendas_mostrar: "R$ " + toMoney(children2[`nao_vendas`], 0),
                                    nao_vendas_percentual_mostrar: toMoney(children2[`nao_vendas_percentual`], 1) + "%",

                                    desafio_resultado_mostrar: "R$ " + toMoney(children2[`desafio_resultado_valor`], 0),
                                    desafio_resultado_variacao_mostrar: "R$ " + toMoney(children2[`desafio_resultado_variacao`], 0),
                                    desafio_resultado_variacao_percentual_mostrar: toMoney(children2[`desafio_resultado_variacao_percentual`], 1) + "%",
                                    margem_ii_nome: dados.header[`margem_ii`],
                                    margem_ii_mostrar: "R$ " + toMoney(children2[`margem_ii`], 0),
                                    margem_ii_percentual_mostrar: toMoney(children2[`margem_ii_percentual`], 1) + "%",
                                    despesas_unidade_nome: dados.header[`despesas_unidade`],
                                    despesas_unidade_mostrar: "R$ " + toMoney(children2[`despesas_unidade`], 0),
                                    despesas_unidade_percentual_mostrar: toMoney(children2[`despesas_unidade_percentual`], 1) + "%",

                                    desafio_cmv_percentual_mostrar: toMoney(children2[`desafio_cmv_percentual`] || 0, 1) + "%",
                                    desafio_margem_i_percentual_mostrar: toMoney(children2[`desafio_margem_i_percentual`] || 0, 1) + "%",
                                    desafio_margem_ii_percentual_mostrar: toMoney(children2[`desafio_margem_ii_percentual`] || 0, 1) + "%",
                                    desafio_despesa_percentual_mostrar: toMoney(children2[`desafio_despesa_percentual`] || 0, 1) + "%"

                                });
                            });
                        }
                    });
                }
            });

            // create chart
            var chart = am4core.create(id, am4plugins.Sunburst);
            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.padding(20, 20, 20, 20);
            chart.radius = am4core.percent(98);
            chart.data = dadosFormatados;
            chart.align = "left";




            chart.fontSize = 11;
            chart.innerRadius = am4core.percent(20);

            // define data fields
            chart.dataFields.value = "value";
            chart.dataFields.name = "name";
            chart.dataFields.children = "children";
            chart.dataFields.color = "color";


            var level0SeriesTemplate = new am4plugins.SunburstSeries();
            chart.seriesTemplates.setKey("0", level0SeriesTemplate)

            // this makes labels to be hidden if they don't fit
            level0SeriesTemplate.labels.template.truncate = true;
            level0SeriesTemplate.labels.template.hideOversized = true;
            level0SeriesTemplate.showOnInit = false;
            level0SeriesTemplate.usePercentHack = false;

            level0SeriesTemplate.radius = am4core.percent(100);
            level0SeriesTemplate.innerRadius = am4core.percent(0);
            level0SeriesTemplate.tooltip.disabled = true;

            let selectedState = level0SeriesTemplate.states.create("selected");
            selectedState.properties.opacity = 0.7;
            level0SeriesTemplate.defaultState.properties.radius = am4core.percent(100);

            var currentlySelected;

            level0SeriesTemplate.slices.template.events.on("over", function (event) {

                atualizarPainel(event.target.dataItem.dataContext._dataContext, event.target.dataItem.dataContext.color._value);
                atualizarPainelNecessario(event.target.dataItem.dataContext._dataContext, event.target.dataItem.dataContext.color._value);

                if (event.target.dataItem.sunburstDataItem.children) {
                    event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }
            })

            level0SeriesTemplate.slices.template.events.on("out", function (event) {
                chartReferencia.current.chartContainer.children._values.filter(a => a.id == "painelInformacoesNecessario")[0].disabled = true;
                chartReferencia.current.chartContainer.children._values.filter(a => a.id == "painelInformacoes")[0].disabled = true;
            })

            level0SeriesTemplate.slices.template.events.on("hit", function (event) {

                zoomOutButton.show();
                label.hide();
                var hitSlice = event.target;

                if (hitSlice.dataItem.sunburstDataItem.children) {

                    var series = event.target.dataItem.component;

                    if (!series.dummyData) {
                        //series.tooltip.disabled = true;
                        hitSlice.dataItem.label.radius = (hitSlice.radius - hitSlice.pixelInnerRadius) - 7;
                        hitSlice.dataItem.label.bent = true;
                        hitSlice.dataItem.label.rotation = -180;

                        currentlySelected = hitSlice;
                        series.dummyData = true;
                        series.setState("selected");
                        hitSlice.dataItem.sunburstDataItem.series.show();
                        series.slices.each(function (slice) {
                            if (slice != event.target) {
                                slice.dataItem.hide();
                            }
                        })
                    }
                    else {
                        drillUp(hitSlice);
                    }
                }
            })


            level0SeriesTemplate.labels.template.adapter.add("rotation", function (rotation, target) {
                target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
                target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);
                return rotation;
            })

            var level1SeriesTemplate = level0SeriesTemplate.clone();
            level1SeriesTemplate.hidden = true;
            level1SeriesTemplate.innerRadius = am4core.percent(10);
            chart.seriesTemplates.setKey("1", level1SeriesTemplate)
            level1SeriesTemplate.fillOpacity = 0.75;


            var level2SeriesTemplate = level0SeriesTemplate.clone();
            level2SeriesTemplate.hidden = true;
            level2SeriesTemplate.innerRadius = am4core.percent(20);
            chart.seriesTemplates.setKey("2", level2SeriesTemplate)

            var zoomOutButton = chart.seriesContainer.createChild(am4core.ZoomOutButton);
            zoomOutButton.visible = false;
            zoomOutButton.horizontalCenter = "middle";
            zoomOutButton.verticalCenter = "middle";
            zoomOutButton.events.on("hit", function () {
                drillUp(currentlySelected)
            })


            function drillUp(slice) {
                collapse(slice);
                var series = slice.dataItem.component;
                //series.tooltip.disabled = true;
                series.dummyData = false;
                series.setState("default");

                series.slices.each(function (slice) {
                    //if (slice != event.target) {
                    slice.dataItem.show();
                    //}
                })

                if (series.parentDataItem.seriesDataItem) {
                    currentlySelected = series.parentDataItem.seriesDataItem.slice;

                }
                else {
                    label.show();
                    zoomOutButton.hide();
                }
            }


            function collapse(slice) {

                slice.dataItem.label.bent = false;
                slice.dataItem.label.radius = 10;


                if (slice.dataItem.sunburstDataItem.children) {
                    slice.dataItem.sunburstDataItem.children.each(function (child) {
                        child.seriesDataItem.component.setState("hidden");
                        collapse(child.seriesDataItem.slice);
                    })
                }
            }

            var label = chart.seriesContainer.createChild(am4core.Label);
            label.textAlign = "middle";
            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            //label.fontSize = 17;
            label.adapter.add("text", function (text, target) {
                return comparativo;
            })



            //level0SeriesTemplate.tooltipText = `{faturamento}`;
            //level0SeriesTemplate.tooltip.text = `{category}`;
            let formatacao_faturamento = "";
            let formatacao_cmv = "";
            let formatacao_margem_1 = "";
            let formatacao_desafio = "";
            formatacao_desafio = `\n\n[font-size: 10px]{desafio_mostrar_nome}:[/] 
                                  \n[bold font-size: 20px]{desafio_mostrar}[/]
                                `;

            formatacao_faturamento = `\n\n[font-size: 10px]{faturamento_mostrar_nome}:[/] 
                                  \n[bold font-size: 20px]{faturamento_mostrar}[/]    [opacity: .7]{desafio_variacao_percentual_mostrar}[/]
                                `;


            formatacao_cmv = `\n\n[font-size: 10px]{cmv_mostrar_nome}:[/] 
                                       \n[bold font-size: 20px]{cmv_percentual_mostrar}[/]    [opacity: .7]{cmv_mostrar}[/]
                                        `;


            formatacao_margem_1 = ` \n\n[font-size: 10px]{margem_i_nome}:[/] 
                                        \n[bold font-size: 20px]{margem_i_percentual_mostrar}[/]    [opacity: .7]{margem_i_mostrar}[/]
                                      `;



            level0SeriesTemplate.slices.template.tooltipText = `[bold text-decoration: underline  ]{name}[/] 
                                                                 ${formatacao_desafio}
                                                                 ${formatacao_faturamento}
                                                                 ${formatacao_cmv}
                                                                 ${formatacao_margem_1}
                                                                 `;


            level0SeriesTemplate.labels.template.text = "[font-size: 10px]{category}:[/][bold font-size: 15px] {faturamento_percentual_mostrar}[/]    [opacity: .7]{faturamento_mostrar}[/]";
            level0SeriesTemplate.tooltip.getFillFromObject = false;
            level0SeriesTemplate.tooltip.background.stroke = am4core.color("gray");
            level0SeriesTemplate.tooltip.background.fill = am4core.color("gray");
            level0SeriesTemplate.tooltip.fontSize = 15;
            level0SeriesTemplate.tooltip.background.paddingRight = 5;
            level0SeriesTemplate.tooltip.background.paddingLeft = 5;
            level0SeriesTemplate.tooltip.background.paddingTop = 3;



            // level1SeriesTemplate.tooltip.getFillFromObject = false;
            // level1SeriesTemplate.tooltip.background.stroke = am4core.color("gray");
            // level1SeriesTemplate.tooltip.background.fill = am4core.color("gray");
            // level1SeriesTemplate.tooltip.fontSize = 15;
            // level1SeriesTemplate.tooltip.background.paddingRight = 5;
            // level1SeriesTemplate.tooltip.background.paddingLeft = 5;
            // level1SeriesTemplate.tooltip.background.paddingTop = 3;
            level1SeriesTemplate.hiddenInLegend = true;

            // level2SeriesTemplate.tooltip.getFillFromObject = false;
            // level2SeriesTemplate.tooltip.background.stroke = am4core.color("gray");
            // level2SeriesTemplate.tooltip.background.fill = am4core.color("gray");
            // level2SeriesTemplate.tooltip.fontSize = 15;
            // level2SeriesTemplate.tooltip.background.paddingRight = 5;
            // level2SeriesTemplate.tooltip.background.paddingLeft = 5;
            // level2SeriesTemplate.tooltip.background.paddingTop = 3;
            level2SeriesTemplate.hiddenInLegend = true;

            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                    { "type": "jpg", "label": "JPG" },
                    { "type": "png", "label": "PNG" },
                    { "type": "svg", "label": "SVG" }
                ]
            }];

            let info = chart.chartContainer.createChild(am4core.Container);
            info.width = 300;
            info.padding(10, 10, 10, 10);
            info.id = "painelInformacoes";
            info.disabled = true;

            let titleLabel = info.createChild(am4core.Label);
            titleLabel.width = am4core.percent(100);
            //titleLabel.wrap = true;

            titleLabel.fixedWidthGrid = true;

            titleLabel.html = ``;

            function atualizarPainel(dado, cor) {
                titleLabel.html = ` 
                                    <div class="container" style="width:280px;opacity:${!document.querySelector("#cardRealizado") ? "0" : "1"};" id="cardRealizado">
                                        <div class="card text-white" style="background:rgb(${cor.r},${cor.g},${cor.b})">
                                            <div class="card-header">
                                                <p class="h4 text-white text-center">Realizado</p>
                                            </div>
                                            <div class="card-body">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12 text-left mb-4">  
                                                            <span class="text-left font-size-15"><strong>${dado.name}</strong></span>
                                                        </div>
                                                    </div>
                                                    ${retornarHtmlMetasFaturamento(dado.faturamento_mostrar_nome, dado.faturamento_mostrar)}
                                                    ${retornarHtmlMetas(dado.cmv_mostrar_nome, dado.cmv_mostrar, dado.cmv_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.margem_i_nome, dado.margem_i_mostrar, dado.margem_i_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.despesas_unidade_nome, dado.despesas_unidade_mostrar, dado.despesas_unidade_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.nao_vendas_nome, dado.nao_vendas_mostrar, dado.nao_vendas_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.margem_ii_nome, dado.margem_ii_mostrar, dado.margem_ii_percentual_mostrar, "")}                                        
                                                </div>  
                                            </div>    
                                        </div>
                                    </div>
                                      `;
                chartReferencia.current.chartContainer.children._values.filter(a => a.id == "painelInformacoes")[0].disabled = false;

                const container = chartReferencia.current.chartContainer.children._values.filter(a => a.id == "painelInformacoes")[0];
                container.disabled = false;
                container.x = ((document.querySelector("#chartdiv_realizado").clientWidth / 2) + (chartReferencia.current.chartContainer.children._values[0].contentWidth / 2) - 30) + 100;

                setTimeout(() => {
                    container.y = (document.querySelector("#chartdiv_realizado").clientHeight / 2) - (document.querySelector("#cardRealizado").clientHeight / 2) - 20;
                    document.querySelector("#cardRealizado").style.opacity = 1;
                }, 100)
            }




            //+++++++++++Necessário+++++++++++++++///

            let infoNecessario = chart.chartContainer.createChild(am4core.Container);
            infoNecessario.width = 300;
            infoNecessario.padding(10, 10, 10, 10);
            infoNecessario.id = "painelInformacoesNecessario";
            infoNecessario.disabled = true;

            let titleLabelNecessario = infoNecessario.createChild(am4core.Label);
            titleLabelNecessario.width = am4core.percent(100);
            //titleLabel.wrap = true;

            titleLabelNecessario.fixedWidthGrid = true;

            titleLabelNecessario.html = ``;

            function atualizarPainelNecessario(dado, cor) {
                titleLabelNecessario.html = ` 
                                    <div class="container" style="width:280px;opacity:${!document.querySelector("#cardNecessario") ? "0" : "1"};" id="cardNecessario">
                                        <div class="card text-white" style="background:rgba(128,128,128,.8)">
                                            <div class="card-header">
                                                <p class="h4 text-white text-center">Necessário</p>
                                            </div>
                                            <div class="card-body">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12 text-left mb-4">  
                                                            <span class="text-left font-size-15"><strong>${dado.name}</strong></span>
                                                        </div>
                                                    </div>
                                                    ${retornarHtmlMetasFaturamento(dado.faturamento_mostrar_nome, dado.desafio_mostrar)}
                                                    ${retornarHtmlMetas(dado.cmv_mostrar_nome, dado.desafio_cmv_mostrar, dado.desafio_cmv_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.margem_i_nome, dado.desafio_margem_i_mostrar, dado.desafio_margem_i_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.despesas_unidade_nome, dado.desafio_despesa_mostrar, dado.desafio_despesa_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.nao_vendas_nome, dado.desafio_nao_vendas_valor_mostrar, dado.desafio_nao_vendas_percentual_mostrar, "")}
                                                    ${retornarHtmlMetas(dado.margem_ii_nome, dado.desafio_margem_ii_mostrar, dado.desafio_margem_ii_percentual_mostrar, "")}
                                                </div>  
                                            </div>    
                                        </div>
                                    </div>
                                      `;
                const container = chartReferencia.current.chartContainer.children._values.filter(a => a.id == "painelInformacoesNecessario")[0];
                container.disabled = false;
                container.x = ((document.querySelector("#chartdiv_realizado").clientWidth / 2) - (chartReferencia.current.chartContainer.children._values[0].contentWidth / 2) - 310) - 100;

                setTimeout(() => {
                    container.y = (document.querySelector("#chartdiv_realizado").clientHeight / 2) - (document.querySelector("#cardNecessario").clientHeight / 2) - 20;
                    document.querySelector("#cardNecessario").style.opacity = 1;
                }, 100)


            }

            function retornarHtmlMetas(mostrar_nome, desafio_mostrar, desafio_mostrar_percentual, variacao_mostrar_percentual) {
                return `
                        <div class="row mt-4">
                            <div class="col-12 font-size-15 text-left">  
                                ${mostrar_nome}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-left border-secondary">  
                                    <span class="text-left font-size-18 mr-2"><strong>${desafio_mostrar_percentual}</strong></span><span>${desafio_mostrar} ${variacao_mostrar_percentual}</span>
                            </div>
                        </div>
                `
            }

            function retornarHtmlMetasFaturamento(mostrar_nome, desafio_mostrar) {
                return `
                        <div class="row">
                            <div class="col-12 font-size-15 text-left">  
                                ${mostrar_nome}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-left">  
                                <span class="text-left font-size-18 mr-2"><strong>${desafio_mostrar}</strong></span>
                            </div>
                        </div>
                `
            }


            return () => {
                //chart.dispose();
                //chart2.dispose();
            };
        }

    }, [dados, tipoGrafico])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card .totalizadores", ".headers"]) + 10;

    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico}px)`;
    }
    return (
        <div id={id} className="graficoRealina" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(GraficoBenassi)