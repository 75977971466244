import "../../../helpers/locale/locale_BR";
import { toDateJS, retornarDiaSemanaAbreviada, toDate, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import {
  bizGridResponsive
} from "../../../helpers/biz-padrao-visual";

const gridConfig = (dados, grid, colunasVisiveis) => {
  const larguraColunaComputador = 100;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      data: dados.data
    },
    columns: [
      {
        text: dados.header.previsao,
        field: "previsao",
        minWidth: 150,
        responsiveLevels: hideMedium,
        align: "center",
        renderer: ({ value, cellElement, record }) => {
          if (colunasVisiveis && value) {
            let arrayData = value.split("-");
            return toDate(value) + " " + retornarDiaSemanaAbreviada(toDateJS(arrayData[0], arrayData[1], arrayData[2]));
          } else {
            return value;
          }
        }
      },
      {
        text: dados.header.previsao,
        field: "previsao",
        locked: true,
        width: 120,
        responsiveLevels: showMedium,
        renderer: ({ value, cellElement, record }) => {
          if (colunasVisiveis && value) {
            let arrayData = value.split("-");
            return toDate(value) + " " + retornarDiaSemanaAbreviada(toDateJS(arrayData[0], arrayData[1], arrayData[2]));
          } else {
            return value;
          }
        }
      },
      {
        text: dados.header.gavetaPasta,
        field: "gavetaPasta",
        flex: 2
      },
      {
        text: dados.header.terceiro,
        field: "terceiro",
        flex: 2,
        hidden: !colunasVisiveis
      },
      {
        align: "center",
        text: dados.header.vencimento,
        field: "vencimento",
        responsiveLevels: configResponsiveLevels,
        renderer: ({ value, cellElement, record }) => {
          return toDate(value)
        },
        hidden: !colunasVisiveis
      },
      {
        text: dados.header.moeda,
        field: "moeda",
        responsiveLevels: configResponsiveLevels,
        hidden: true
      },
      {
        align: "right",
        text: dados.header.entradas,
        field: "entradas",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.saidas,
        field: "saidas",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        align: "right",
        text: dados.header.saldoPrevisto,
        field: "saldoPrevisto",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(0, toMoney(value, 2))
        },
        responsiveLevels: configResponsiveLevels
      },
      {
        text: dados.header.especieDocto,
        field: "especieDocto",
        responsiveLevels: configResponsiveLevels,
        hidden: !colunasVisiveis
      },
      {
        text: dados.header.documento,
        field: "documento",
        responsiveLevels: configResponsiveLevels,
        hidden: !colunasVisiveis
      },
      {
        text: dados.header.observacoes,
        field: "observacoes",
        hidden: !colunasVisiveis,
        flex: 1,
        minWidth: 120
      },
      {
        text: dados.header.situacao,
        field: "situacao",
        responsiveLevels: configResponsiveLevels,
        hidden: !colunasVisiveis
      }
    ]
  };
};

export { gridConfig };
