import React from "react"

const ModalFiltroFooter = ({toggleModalFiltro, filtrar}) =>{
    return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggleModalFiltro()
            }}
            className="btn btn-light"
            data-dismiss="modal"
          >
            Fechar
          </button>
          <button
            type="button"
            onClick={() => {
              filtrar()
            }}
            className="btn btn-primary"
          >
            Filtrar
          </button>
        </div>
    )
}

export default ModalFiltroFooter