import React, { useLayoutEffect, useRef } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
am4core.options.commercialLicense = true;

const Grafico = ({ cor, nomeGrafico, tipoGrafico, formatoNumeroGrafico, dados, data }) => {
    const chartReferencia = useRef(null);

    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        let maxValor = 0;

        if (dadosGrafico.length > 0) {
            dadosGrafico.map(arr => {
                maxValor = arr[tipoGrafico] > maxValor ? arr[tipoGrafico] : maxValor;
                arr.dataHora = new Date(data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate(), arr.hora, 0);
            });

            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = dadosGrafico;
            let eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValor, cor);
            eixoY.renderer.labels.template.disabled = true;

            formatarEixoXAmCharts(chart.xAxes.push(new am4charts.DateAxis()), { categoria: "dataHora", minGridDistance: 60 });
            var series = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                valorY: tipoGrafico,
                valorX: "dataHora",
                nome: nomeGrafico,
                cor: cor,
                chart,
                tipo: "linha"
            });
            formatarValoresAmCharts(series.bullets.push(new am4charts.LabelBullet()), formatoNumeroGrafico, cor);

            return () => {
                chart.dispose();
            };
        }

    }, [dados, tipoGrafico, cor])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura", "#tituloGrafico"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default Grafico