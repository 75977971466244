import React, {useEffect, useCallback, useState, useRef} from 'react';

import {concatenaLojas, Hoje, primeiroDiaMes, toDateSQL} from "../../helpers/utils"
import PaginaLoader from '../../components/loader/pagina';
import gerarToken from '../../helpers/gerarToken'
import { useDispatch, useSelector } from 'react-redux'
import {requisitarDados, limparDadosReq} from "../../store/rankingProdutos/actions"
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import {FormGroup, Label, InputGroup, Button} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Select from 'react-select'
import { Portuguese } from "../../helpers/locale/portuguese"

import { gridConfig } from './AppConfig'; 
import {AvField, AvForm} from "availity-reactstrap-validation"
import Filtros from "./partes/filtros"
import Header from "../../components/HorizontalLayout/HeaderApp"
import {
    BryntumGrid, BryntumToolbar
} from '@bryntum/grid-react';

import FiltroBrytum from "../../components/filtroBrytum"

import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
const RankingProdutos  = (props) => {
    //store
    const {filtros} = props.location.state
    const {dados, carregando} = useSelector(state => state.RankingProdutos);
    const {lojasDoUsuario} = useSelector(state => state.Ambiente);
    
    const [modalFiltro, setModalFiltro] = useState(true);
    const toggleModalFiltro = ()=> setModalFiltro(!modalFiltro)

    const grid = useRef(null);
    const [lojas, setLojas] = useState(filtros.lojas || []);
    const [dataInicial, setDataInicial] = useState( filtros?.dataInicial || primeiroDiaMes());
    const [dataFinal, setDataFinal] = useState(filtros?.dataFinal || Hoje());
    const dispatch = useDispatch();

    const atualizarDados = async(lojas, dataInicial, dataFinal)=>{
        const token = await gerarToken();
        await dispatch(requisitarDadosAmbiente(token));
        await dispatch(requisitarDados(token, lojas, dataInicial, dataFinal));
    }
    useEffect(()=>{
        if(!filtros) {
            return props.history.goBack();
        }
        atualizarDados(concatenaLojas(lojas), toDateSQL(dataInicial), toDateSQL(dataFinal));
        return ()=>{
            grid.current.gridInstance.destroy()
            dispatch(limparDadosReq())
        }
    }, [])
    
    const trocarLojas = (valor) => {
        setLojas(valor)
    }

    const trocarPeriodo = (valores) => {
        if (valores.length > 0){
            setDataInicial(valores[0]);
        }
        else{
            setDataInicial(null);
        }

        if (valores.length > 1){
            setDataFinal(valores[1]);
        }
        else{
            setDataFinal(null);
        }
    }

    const exportarExcel = ()=>{

    }

    const exportarPdf = ()=>{
      
      grid.current.instance.features.pdfExport.export({
        // Required, set list of column ids to export
        columns : grid.current.instance.columns.map(c => c.id),
        exporterType: "multipage",
        fileName: "biz"

      }).then(result => {
          // Response instance and response content in JSON
          let { response } = result;
      });

    }

    const filtrar = useCallback(() => {
        toggleModalFiltro();
        atualizarDados(concatenaLojas(lojas), toDateSQL(dataInicial), dataFinal ? toDateSQL(dataFinal): "");
    }, [lojas, dataInicial, dataFinal]);

    if(!dados) return <PaginaLoader carregando={true} />

    //confi grid
    const gridInfo = gridConfig(dados)

    return (
            <React.Fragment>
                <PaginaLoader carregando={carregando} />
                <>
                    <Header
                        url={"/ranking-sujeitos/"}
                        parametros={
                            {
                              //abrirFiltro: toggleModalFiltro,
                              titulo:"Ranking de sujeitos",
                              icone:"fad fa-transporter-3",
                              //imprimirPdf: ()=> exportarPdf()
                              botoesExtras: [
                                {
                                    funcao: () => {
                                        toggleModalFiltro()
                                    },
                                    icone: "bx bx-filter"
                                }
                            ]
                            }
                        } />
                    

                    {/* 
                    <FiltroBrytum aberto={modalFiltro}>
                        <AvForm inline onSubmit={(event, errors, values) => { filtrar( values) }}> 
                            <FormGroup className="select2-container altoZIndex"  >
                                <div style={{ minWidth: 200 }}>
                                    <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup style={{minWidth: 220}}>
                                    <Flatpickr
                                        className="form-control d-block ml-2"
                                        placeholder="dd M,yyyy"
                                        locale="pt"
                                        options={{
                                        mode: "range",
                                        locale: Portuguese,
                                        dateFormat: "d/m/Y",
                                        style: {width: 100},
                                        defaultDate:[dataInicial, dataFinal]
                                        }}
                                        onChange={(selectedDates, dateStr, instance) => {
                                        trocarPeriodo(selectedDates);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                            <Button type="submit" color="light" className="ml-2">
                                <i className={`fal fa-rabbit-fast mr-1`}></i>
                                vai...
                            </Button>
                            </FormGroup>
                        </AvForm>
                    </FiltroBrytum>*/}
                    <BryntumGrid
                        ref={grid}
                        key={carregando}
                        {...gridInfo} 
                        treeFeature={true}
                        cellEditFeature={false}
                        summaryFeature={true}
                        headerMenuFeature={
                            {
                                items:{
                                    hideColumn: false
                                }
                            }
                        }
                        filterBarFeature={{
                            compactMode: modalFiltro
                        }}
                        quickFindFeature={true}
                        />
                </>
            </React.Fragment>
          );
    }
        
export default RankingProdutos;