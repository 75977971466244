import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../../store/capitalRelacional/evolucaoRelacoes/actions";
import { requisitarDados as requisitarDadosEvolucaoSuplicas, requisitarLimparDados as requisitarLimparDadosEvolucaoSuplicas } from "../../../store/capitalRelacional/evolucaoSuplicas/actions";
import Header from "../../../components/HorizontalLayout/HeaderApp"
import GraficoRelacao from "./partes/graficoRelacao"
import GraficoSuplicas from "./partes/graficoSuplicas"
import GraficoAltura from '../../../components/graficoAltura';
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody, Progress } from 'reactstrap';
import { removerFocusNoBotao, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils"
import FiltroBrytum from "../../../components/filtroBrytum";
import ModalOpcoesCamadas from '../../../components/CapitalRelacional/ModalOpcoesCamadas';
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaAgentes,
} from "../../../store/capitalRelacional/gerais/actions";
import Select from "react-select";

const GraficoEvolucaoRelacinal = (props) => {
  const { dados, carregando } = useSelector(state => state.EvolucaoRelacoes);
  const dadosSuplicas = useSelector(state => state.EvolucaoSuplicas.dados);
  const carregandoSuplicas = useSelector(state => state.EvolucaoSuplicas.carregando);
  const tela = useSelector((state) => state.Gerais);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [carregandoConsulta, setCarregandoConsulta] = useState(false);
  const [mostrarModalOpcoesCamadas, setMostrarModalOpcoesCamadas] = useState(false);
  const [submitHabilitado, setSubmitHabilitado] = useState(false);
  const [botoesExtrasHeader, setBotoesExtrasHeader] = useState([]);
  const [tituloGrafico, setTituloGrafico] = useState("");




  //=====Filtros=====//
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [agente, setAgente] = useState(null);
  const [tipoPesquisa, setTipoPesquisa] = useState({ label: "Relação", value: "R" });
  const [tipoPesquisaUltima, setTipoPesquisaUltima] = useState({ label: "Relação", value: "R" });
  const [filtroCamadasOpcoes, setFiltroCamadasOpcoes] = useState('');
  //=====Filtros=====//

  //===== Carregando Filtros=====//
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);
  //===== Carregando Filtros=====//

  const retornarValorProgressBar = (valor) => {
    const valorInicial = valor + 100;
    const escalaPercentual = valorInicial / 200;
    return escalaPercentual * 100;
  }

  const atualizarDados = async (idsAgentesHolograma, idsRelacoesCamadasOpcoes) => {
    setCarregandoConsulta(true);
    setTipoPesquisaUltima(tipoPesquisa);
    if (tipoPesquisa.value == "R") {
      await dispatch(requisitarDados({
        token,
        idParceiro: parceiro?.value,
        idCliente: cliente?.value,
        idsAgentesHolograma,
        idsRelacoesCamadasOpcoes
        //idAgenteHolograma: agente?.value,
        // idCamada: camadas?.value,
        //visualizacao: "opcao",
        //idParceiro: "000002",
        //idCliente: "000004",
        //idPeriodoResposta: "00002",
        //idsAgentesHolograma: "5|;",
        //idCamadaOpcao: "",
        // idTipo: "P"
      }
      ));
    }
    if (tipoPesquisa.value == "S") {
      await dispatch(requisitarDadosEvolucaoSuplicas({
        token,
        idParceiro: parceiro?.value,
        idCliente: cliente?.value,
        idsAgentesHolograma,
        idsRelacoesCamadasOpcoes
        // visualizacao: "relacao"
        // idCamada: camadas?.value,
        //visualizacao: "opcao",
        //idParceiro: "000002",
        //idCliente: "000004",
        //idPeriodoResposta: "00002",
        //idsAgentesHolograma: "5|;",
        //idCamadaOpcao: "",
        //idTipo: "P"
      }
      ));

      setTituloGrafico("");
      if (agente?.length) {
        setTituloGrafico(
          agente.map((item, key) => {
            return (
              <span key={`titulo_${key}`}>
                {item?.label + " "} <span>{formatarTituloOpcoesCamadas(item.value)}</span> {(key < agente.length - 1 ? " - " : "")}
              </span>
            )
          })
        )
      } else {
        setTituloGrafico(tela.agentes?.length &&
          tela.agentes.map((item, key) => {
            return (
              <span key={`titulo_${key}`}>
                {item?.label + " "} <span>{formatarTituloOpcoesCamadas(item.value)}</span> {(key < tela.agentes.length - 1 ? " - " : "")}
              </span>
            )
          })
        )
      }
    }

    setMostrarFiltros(false);
    setTimeout(() => {

      setCarregandoConsulta(false);
      setSubmitHabilitado(true);
    }, 2000)

  }
  const formatarTituloOpcoesCamadas = (idRelacao) => {

    const camadasOpcoes =
      (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
        ? filtroCamadasOpcoes?.filter(a => a.value.split('|')[0] == idRelacao).map((item) => item.label).join(", ")
        : "";

    return camadasOpcoes ? `(${camadasOpcoes})` : ''
  }
  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);

    if (mostrarFiltros) {

      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarFiltros(!mostrarFiltros)
            },
            icone: "bx bx bx-filter-alt"
          }
        ]
      })
    } else {
      if (tipoPesquisa.value == "S") {
        setBotoesExtrasHeader(
          {
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros)
                },
                icone: "bx bx bx-filter-alt"
              },
              {
                funcao: () => {
                  setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
                },
                icone: "fal fa-layer-group"
              }
            ]
          })
      } else {
        setBotoesExtrasHeader(
          {
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros)
                },
                icone: "bx bx bx-filter-alt"
              }
            ]
          })
      }

    }

  }, [mostrarFiltros])

  useEffect(() => {
    if (mostrarModalOpcoesCamadas) {
      setBotoesExtrasHeader({
        botoesExtras: [
          {
            funcao: () => {
              setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
            },
            icone: "fal fa-layer-group"
          }
        ]
      })
    } else {
      if (dados) {
        setBotoesExtrasHeader(
          {
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros)
                },
                icone: "bx bx bx-filter-alt"
              },
              {
                funcao: () => {
                  setMostrarModalOpcoesCamadas(!mostrarModalOpcoesCamadas)
                },
                icone: "fal fa-layer-group"
              }
            ]
          })
      }
    }
  }, [mostrarModalOpcoesCamadas]);

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);

    if (tela.parceiros?.length && tela.clientes?.length) {
      setSubmitHabilitado(true);
    }
  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setAgente([]);
    setFiltroCamadasOpcoes([]);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value));
  }, [cliente]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setAgente([]);
      setFiltroCamadasOpcoes([]);
      setCarregandoCliente(true);
      setCarregandoAgente(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente?.value || ""));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setAgente([]);
    setFiltroCamadasOpcoes([]);
    setFiltroCamadasOpcoes([]);
    setCarregandoAgente(true);

    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));

  };

  useEffect(() => {
    atualizarDadosAmbiente();

    return () => { dispatch(requisitarLimparDados()); dispatch(requisitarLimparDadosEvolucaoSuplicas()) }
  }, []);

  const executarConsulta = () => {
    setSubmitHabilitado(false);
    setMostrarFiltros(false);
    const agentesFiltro =
      (agente && agente.length) > 0
        ? agente.map((item) => item.value).join("|;") + "|;"
        : "";

    const camadasOpcoes =
      (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
        ? filtroCamadasOpcoes.map((item) => item.value).join("|;") + "|;"
        : "";

    atualizarDados(agentesFiltro, camadasOpcoes);
  }

  return (
    <React.Fragment>
      <PaginaLoader carregando={tela.carregando || carregandoConsulta} />
      <>
        <Header parametros={botoesExtrasHeader} />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container">
                  <div className="unidadesControler">
                    <Select
                      placeholder="Parceiro"
                      value={parceiro}
                      options={tela.parceiros || []}
                      classNamePrefix="select2-selection"
                      defaultValue={parceiro || []}
                      onChange={(Object) => {
                        setParceiro(Object || []);
                        atualizarFiltroDependenciaParceiros(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2">
                    <Select
                      placeholder="Cliente"
                      className="basic-single"
                      isLoading={carregandoCliente}
                      value={cliente}
                      options={tela.clientes}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={(result) => {
                        setCliente(result || []);
                        atualizarDependenciaCliente(result.value);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Tipo de pesquisa"
                      className="basic-single"
                      options={[
                        { label: "Relação", value: "R" },
                        { label: "Suplicas", value: "S" }
                      ]}
                      value={tipoPesquisa}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      onChange={(Object) => {
                        setTipoPesquisa(Object || []);
                        //atualizarDependenciasAgente(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>
                {tipoPesquisa?.value == "S" && <FormGroup>
                  <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                    <Select
                      placeholder="Todas as relações"
                      className="basic-single"
                      isLoading={carregandoAgente}
                      options={tela.agentes}
                      value={agente}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      isMulti
                      onChange={(Object) => {
                        setAgente(Object || []);
                        setFiltroCamadasOpcoes([]);
                        //atualizarDependenciasAgente(Object.value);
                      }}
                    />
                  </div>
                </FormGroup>}
                <FormGroup>
                  <Button
                    color="light"
                    onClick={(e) => {
                      executarConsulta();
                    }}
                    className="ml-2"
                    disabled={!submitHabilitado}
                  >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>


              </Form>
            </FiltroBrytum>

          </div>
          <Container fluid className="fixWith graficoprincipal mt-3">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    <div className={"text-center w-100"}>
                      <h5>{tituloGrafico}
                      </h5>
                    </div>
                    {tipoPesquisaUltima.value == "R" && <GraficoRelacao dados={dados} />}
                    {tipoPesquisaUltima.value == "S" && <GraficoSuplicas dados={dadosSuplicas} />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      <ModalOpcoesCamadas submitHabilitado={submitHabilitado} executarConsulta={executarConsulta} filtroCamadasOpcoes={filtroCamadasOpcoes} setFiltroCamadasOpcoes={setFiltroCamadasOpcoes} token={token} idParceiro={parceiro?.value} idCliente={cliente?.value} aberto={mostrarModalOpcoesCamadas} setAberto={setMostrarModalOpcoesCamadas} relacoes={agente?.length ? agente : tela.agentes || []}></ModalOpcoesCamadas>
    </React.Fragment>
  );
}
export default GraficoEvolucaoRelacinal;