import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { pegarCores } from '../../../../helpers/utils';
import { definirLocalizacaoAmCharts5 } from "../../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../../helpers/utils-amcharts";

am5.addLicense("AM5C303720496");

const formatacaoAmCharts = retornarFormatacaoAmCharts();
//am4core.options.commercialLicense = true;

const Grafico = ({ dados, id, idAgenteHolograma }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados || [];

    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            document.querySelector(`#${id}`).innerHTML = "";
            var root = am5.Root.new(id);

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Add series
            // https://www.amcharts.com/docs/v5/charts/word-cloud/
            var series = root.container.children.push(am5wc.WordCloud.new(root, {
                categoryField: "icr_frases_palavras",
                valueField: "icr_quantidade",
                maxFontSize: am5.percent(50)
            }));

            // Configure labels
            series.labels.template.setAll({
                fontFamily: "Courier New",
                fill: am5.color(0xf57c45)
            });
            series.data.setAll(dadosGrafico.filter(a => a.icr_id_agente_holograma == idAgenteHolograma));
        }

    }, [dados])


    return (
        <div id={id} style={{ width: "100%", minHeight: "100%" }}></div>
    )
}

export default memo(Grafico)