import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toMoney, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";
import { pegarCores } from "../../../helpers/utils";
import { insertIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;

const GraficoBenassi = ({ dados, index, opcaoLB }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        if (dadosGrafico.length > 0) {
            const cores = pegarCores();
            let success = cores.success.trim();
            let danger = cores.danger.trim();
            let info = cores.info.trim();

            var opacidade_padrao = .8

            am4core.useTheme(am4themes_animated);

            var chart = am4core.create("chartdiv", am4charts.XYChart);
            chartReferencia.current = chart;

            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
            definirLocalizacaoAmCharts(chart);
            chart.data = [];
            
            if (opcaoLB == "R") {
                chart.data.push(
                    {
                        category: dados.header.faturamento,
                        value: dados.footer.faturamento,
                        open: 0,
                        stepValue: dados.footer.faturamento,
                        color: "#2771D9",
                        displayValue: dados.footer.faturamento,
                        percentual: "",
                        opacidade: opacidade_padrao,
                        toolTip: ""
                    },
                    {
                        category: dados.header.desconto_total_valor,
                        value: dados.footer.faturamento - dados.footer.desconto_total_valor,
                        open: dados.footer.faturamento,
                        stepValue: dados.footer.faturamento - dados.footer.desconto_total_valor,
                        color: "#AB160E",
                        displayValue: dados.footer.desconto_total_valor,
                        percentual: toMoney(dados.footer.desconto_total_percentual, 1) + "%",
                        opacidade: opacidade_padrao,
                        toolTip: (dados.footer.desconto_01_valor > 0 ?   
                                  "<strong>" + dados.header.desconto_01_valor + "&nbsp;</strong><br\>" + 
                                  toMoney((dados.footer.desconto_01_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                  "R$&nbsp;" + toMoney(dados.footer.desconto_01_valor, 0) + "<br\>" + "<br\>" : "") + 
                                  (dados.footer.desconto_02_valor > 0 ?   
                                    "<strong>" + dados.header.desconto_02_valor + "&nbsp;</strong><br\>" + 
                                    toMoney((dados.footer.desconto_02_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                    "R$&nbsp;" + toMoney(dados.footer.desconto_02_valor, 0) + "<br\>" + "<br\>" : "") + 
                                    (dados.footer.desconto_03_valor > 0 ?   
                                        "<strong>" + dados.header.desconto_03_valor + "&nbsp;</strong><br\>" + 
                                        toMoney((dados.footer.desconto_03_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                        "R$&nbsp;" + toMoney(dados.footer.desconto_03_valor, 0) + "<br\>" + "<br\>" : "") + 
                                        (dados.footer.desconto_04_valor > 0 ?   
                                            "<strong>" + dados.header.desconto_04_valor + "&nbsp;</strong><br\>" + 
                                            toMoney((dados.footer.desconto_04_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                            "R$&nbsp;" + toMoney(dados.footer.desconto_04_valor, 0) + "<br\>" + "<br\>" : "") + 
                                            (dados.footer.desconto_05_valor > 0 ?   
                                                "<strong>" + dados.header.desconto_05_valor + "&nbsp;</strong><br\>" + 
                                                toMoney((dados.footer.desconto_05_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                                "R$&nbsp;" + toMoney(dados.footer.desconto_05_valor, 0) + "<br\>" + "<br\>" : "") + 
                                                (dados.footer.desconto_06_valor > 0 ?   
                                                    "<strong>" + dados.header.desconto_06_valor + "&nbsp;</strong><br\>" + 
                                                    toMoney((dados.footer.desconto_06_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                                    "R$&nbsp;" + toMoney(dados.footer.desconto_06_valor, 0) + "<br\>" + "<br\>" : "") + 
                                                    (dados.footer.desconto_07_valor > 0 ?   
                                                        "<strong>" + dados.header.desconto_07_valor + "&nbsp;</strong><br\>" + 
                                                        toMoney((dados.footer.desconto_07_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                                        "R$&nbsp;" + toMoney(dados.footer.desconto_07_valor, 0) + "<br\>" + "<br\>" : "") + 
                                                        (dados.footer.desconto_08_valor > 0 ?   
                                                            "<strong>" + dados.header.desconto_08_valor + "&nbsp;</strong><br\>" + 
                                                            toMoney((dados.footer.desconto_08_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                                            "R$&nbsp;" + toMoney(dados.footer.desconto_08_valor, 0) + "<br\>" + "<br\>" : "") + 
                                                            (dados.footer.desconto_09_valor > 0 ?   
                                                                "<strong>" + dados.header.desconto_09_valor + "&nbsp;</strong><br\>" + 
                                                                toMoney((dados.footer.desconto_09_valor / dados.footer.faturamento) * 100, 1) + "%" + " - " + 
                                                                "R$&nbsp;" + toMoney(dados.footer.desconto_09_valor, 0) + "<br\>" + "<br\>" : "") +
                                                                    "<strong>TOTAL DE DESCONTOS&nbsp;</strong><br\>" + 
                                                                    toMoney(dados.footer.desconto_total_percentual, 1) + "%" + " - " + 
                                                                    "R$&nbsp;" + toMoney(dados.footer.desconto_total_valor, 0) + "<br\>" + "<br\>"
                                                                    

                    }
                );
            }

            if ((opcaoLB == "R") || (opcaoLB == "B")) {
                chart.data.push(
                    {
                        category: dados.header.faturamento_com_desconto,
                        value: dados.footer.faturamento_com_desconto,
                        open: 0,
                        stepValue: dados.footer.faturamento_com_desconto - dados.footer.desconto_total_valor,
                        color: "#2771D9",
                        displayValue: dados.footer.faturamento_com_desconto,
                        percentual:  toMoney(dados.footer.faturamento_com_desconto_percentual, 1) + "%",
                        opacidade: opacidade_padrao,
                        toolTip: ""
                    },
                    {
                        category: dados.header.impostos_valor,
                        value: dados.footer.faturamento_com_desconto - dados.footer.impostos_valor,
                        open: dados.footer.faturamento_com_desconto,
                        stepValue: dados.footer.faturamento_com_desconto - dados.footer.impostos_valor,
                        color: "#AB160E",
                        displayValue: dados.footer.impostos_valor,
                        percentual: toMoney(dados.footer.impostos_percentual, 1) + "%",
                        opacidade: opacidade_padrao,
                        toolTip: ""
                    },
                    {
                        category: dados.header.devolucao_total_valor,
                        value: dados.footer.faturamento_com_desconto - dados.footer.impostos_valor - dados.footer.devolucao_total_valor,
                        open: dados.footer.faturamento_com_desconto - dados.footer.impostos_valor,
                        stepValue: dados.footer.faturamento_com_desconto - dados.footer.impostos_valor - dados.footer.devolucao_total_valor,
                        color: "#96130C",
                        displayValue: dados.footer.devolucao_total_valor,
                        percentual: toMoney(dados.footer.devolucao_total_percentual, 1) + "%",
                        opacidade: opacidade_padrao,
                        toolTip: ""
                    }
                );
            }
            
            chart.data.push(
                {
                    category: dados.header.faturamento_liquido,
                    value: dados.footer.faturamento_liquido,
                    open: 0,
                    stepValue: dados.footer.faturamento_liquido,
                    color: info,
                    displayValue: dados.footer.faturamento_liquido,
                    percentual: toMoney(dados.footer.faturamento_liquido_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.cmv_valor,
                    value: dados.footer.faturamento_liquido - dados.footer.cmv_valor,
                    open: dados.footer.faturamento_liquido,
                    stepValue: dados.footer.faturamento_liquido - dados.footer.cmv_valor,
                    color: "#BF180F",
                    displayValue: dados.footer.cmv_valor,
                    percentual: toMoney(dados.footer.cmv_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.margem_contribuicao_valor,
                    value: dados.footer.margem_contribuicao_valor,
                    open: 0,
                    stepValue: dados.footer.margem_contribuicao_valor,
                    color: "#2E8071",
                    displayValue: dados.footer.margem_contribuicao_valor,
                    percentual: toMoney(dados.footer.margem_contribuicao_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""

                },
                {
                    category: dados.header.despesas_operacionais,
                    value: dados.footer.margem_contribuicao_valor - dados.footer.despesas_unidade - dados.footer.nao_vendas,
                    open: dados.footer.margem_contribuicao_valor,
                    stepValue: dados.footer.margem_contribuicao_valor - dados.footer.despesas_unidade - dados.footer.nao_vendas,
                    color: "#D41B11",
                    displayValue: dados.footer.despesas_unidade + dados.footer.nao_vendas,
                    percentual: toMoney(dados.footer.despesas_unidade_percentual + dados.footer.nao_vendas_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: "<strong>" + dados.header.despesas_unidade + "&nbsp;</strong><br\>" + 
                             toMoney(dados.footer.despesas_unidade_percentual, 1) + "%" + " - " + 
                             "R$&nbsp;" + toMoney(dados.footer.despesas_unidade, 0) + "<br\>" + "<br\>" + 
                             "<strong>" + dados.header.nao_vendas + "&nbsp;</strong><br\>" + 
                             toMoney(dados.footer.nao_vendas_percentual, 1) + "%" + " - " + 
                             "R$&nbsp;" + toMoney(dados.footer.nao_vendas, 0) + "<br\>" + "<br\>" + 
                             "<strong>" + dados.header.despesas_operacionais + "&nbsp;</strong><br\>" + 
                             toMoney(dados.footer.despesas_unidade_percentual + dados.footer.nao_vendas_percentual, 1) + "%" + " - " + 
                             "R$&nbsp;" + toMoney(dados.footer.despesas_unidade + dados.footer.nao_vendas, 0)
                },
                {
                    category: dados.header.margem_ii,
                    value: dados.footer.margem_ii,
                    open: 0,
                    stepValue: dados.footer.margem_ii,
                    color: "#379987",
                    displayValue: dados.footer.margem_ii,
                    percentual: toMoney(dados.footer.margem_ii_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.despesas_apoio,
                    value: dados.footer.margem_ii - dados.footer.despesas_apoio,
                    open: dados.footer.margem_ii,
                    stepValue: dados.footer.margem_ii - dados.footer.despesas_apoio,
                    color: "#C4514B",
                    displayValue: dados.footer.despesas_apoio,
                    percentual: toMoney(dados.footer.despesas_apoio_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.resultado,
                    value: dados.footer.resultado,
                    open: 0,
                    stepValue: dados.footer.resultado,
                    color: "#0DB898",
                    displayValue: dados.footer.resultado,
                    percentual: toMoney(dados.footer.resultado_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.valor_ir,
                    value: dados.footer.resultado - dados.footer.valor_ir,
                    open: dados.footer.resultado,
                    stepValue: dados.footer.resultado - dados.footer.valor_ir,
                    color: danger,
                    displayValue: dados.footer.valor_ir,
                    percentual: toMoney(dados.footer.valor_ir_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                },
                {
                    category: dados.header.resultado_apos_ir,
                    value: dados.footer.resultado_apos_ir,
                    open: 0,
                    stepValue: dados.footer.resultado_apos_ir,
                    color: "#53E1CC",
                    displayValue: dados.footer.resultado_apos_ir,
                    percentual: toMoney(dados.footer.resultado_apos_ir_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    toolTip: ""
                }
            );

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.grid.template.stroke = am4core.color("gray");

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.renderer.grid.template.stroke = am4core.color("gray");
            valueAxis.strictMinMax = true;


            var columnSeries = chart.series.push(new am4charts.ColumnSeries());
            columnSeries.dataFields.categoryX = "category";
            columnSeries.dataFields.valueY = "value";
            columnSeries.dataFields.openValueY = "open";
            columnSeries.sequencedInterpolation = true;
            columnSeries.interpolationDuration = 1500;
            // columnSeries.columns.template.column.cornerRadiusTopLeft = 10;
            // columnSeries.columns.template.column.cornerRadiusTopRight = 10;

            columnSeries.columns.template.tooltipHTML = "{toolTip}";
             
            var columnTemplate = columnSeries.columns.template;
            columnTemplate.strokeOpacity = 0;
            columnTemplate.propertyFields.fill = "color";
            columnSeries.columns.template.column.propertyFields.fillOpacity = "opacidade";


            var label = columnTemplate.createChild(am4core.Label);
            label.text = `[bold][font-size: 19px]{percentual}[/][/] \n R$ {displayValue.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}`;
            label.align = "center";
            label.valign = "top";
            label.dy = 1;
            label.fontSize = 14;
            label.fill = am4core.color("#fff");
            label.background.fill = am4core.color("gray");
            label.background.fillOpacity = 0.5;
            label.paddingRight = 5;
            label.paddingLeft = 5;
            label.paddingTop = 0;
            label.truncate = false;
            label.hideOversized = false;


            var stepSeries = chart.series.push(new am4charts.StepLineSeries());
            stepSeries.dataFields.categoryX = "category";
            stepSeries.dataFields.valueY = "stepValue";
            stepSeries.noRisers = true;
            stepSeries.stroke = am4core.color("gray").lighten(.3);

            stepSeries.strokeWidth = 0.5;
            stepSeries.interpolationDuration = 2000;
            stepSeries.sequencedInterpolation = true;

            stepSeries.startLocation = 0.1;
            stepSeries.endLocation = 1.1;

            return () => {
                chart.dispose();
            };
        }

    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id={index} style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(GraficoBenassi)