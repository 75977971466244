import React, { useLayoutEffect, useRef,memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { pegarCores, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, toMoney } from "../../../helpers/utils"
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts, formatarDatasZoomAmCharts, retornarQtdeCategoriasZoomAmCharts } from "../../../helpers/utils-amcharts"

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;
const GraficoJojasAoVivo = ({ sinalGraficoDepoisValor, cor, nomeGrafico, tipoGrafico, formatoNumeroGrafico, dados }) => {

    const dadosGrafico = dados?.data || [];
    const chartReferencia = useRef(null);
    useLayoutEffect(() => {
        let maxValores = {
            faturamento: 0,
            clientes: 0,
            ticket_medio: 0,
            cmv_percentual: 0,
            margem_contribuicao_percentual: 0
        }
        if (dadosGrafico.length > 0) {
            let cores = pegarCores();
            let success = cores.success.trim();
            let info = cores.info.trim();
            let warning = cores.warning.trim();
            let danger = cores.danger.trim();
            let dark = cores.dark.trim();

            const data = dadosGrafico.map(arr => {
                maxValores.faturamento = arr.faturamento > maxValores.faturamento ? arr.faturamento : maxValores.faturamento
                maxValores.clientes = arr.clientes > maxValores.clientes ? arr.clientes : maxValores.clientes
                maxValores.ticket_medio = arr.ticket_medio > maxValores.ticket_medio ? arr.ticket_medio : maxValores.ticket_medio
                maxValores.cmv_percentual = arr.cmv_percentual > maxValores.cmv_percentual ? arr.cmv_percentual : maxValores.cmv_percentual
                maxValores.margem_contribuicao_percentual = arr.margem_contribuicao_percentual > maxValores.margem_contribuicao_percentual ? arr.margem_contribuicao_percentual : maxValores.margem_contribuicao_percentual

                arr.margem_contribuicao_percentual_mostrar = toMoney(arr.margem_contribuicao_percentual, 1) + "%";
                arr.margem_ii_percentual_mostrar = toMoney(arr.margem_ii_percentual, 1) + "%";
                arr.margem_iii_percentual_mostrar = toMoney(arr.margem_iii_percentual, 1) + "%";

                arr.margem_contribuicao_percentual_nome = dados.header.margem_contribuicao_valor;
                arr.margem_ii_percentual_nome = dados.header.margem_ii;
                arr.margem_iii_percentual_nome = dados.header.margem_iii;

                arr.cmv_percentual_mostrar = toMoney(arr.cmv_percentual, 1) + "%";
                arr.nao_vendas_percentual_mostrar = toMoney(arr.nao_vendas_percentual, 1) + "%";
                arr.despesas_unidade_percentual_mostrar = toMoney(arr.despesas_unidade_percentual, 1) + "%";

                arr.cmv_percentual_nome = dados.header.cmv_valor;
                arr.nao_vendas_percentual_nome = dados.header.nao_vendas;
                arr.despesas_unidade_nome = dados.header.despesas_unidade;


                arr.grafico_nome = dados.header[tipoGrafico];


                return { ...arr, date: toDateJS(arr.ano, arr.mes) }
            });
            var chart = am4core.create("chartdiv", am4charts.XYChart);

            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = data;
            const eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValores[tipoGrafico], cor);
            eixoY.renderer.labels.template.disabled = true;

            // if (tipoGrafico.includes("percentual")) {
            //     eixoY.min = 0;
            //     eixoY.max = 100;
            // }


            const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.DateAxis()));
            chart.legend = new am4charts.Legend();
            chart.legend.position = 'top';
            chart.legend.itemContainers.template.paddingTop = 0;

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dateAxis;
            chart.cursor.yAxis = eixoY;
            chart.cursor.xAxis.cursorTooltipEnabled = false;
            chart.cursor.yAxis.cursorTooltipEnabled = false;
            chart.cursor.lineY.disabled = true;

            if (tipoGrafico == "margens") {


                var series = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "margem_contribuicao_percentual",
                    valorX: "date",
                    nome: dados.header.margem_contribuicao_valor,
                    cor: success,
                    chart,
                    tipo: "linha"
                });
                var series2 = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "margem_ii_percentual",
                    valorX: "date",
                    nome: dados.header.margem_ii,
                    cor: info,
                    chart,
                    tipo: "linha"
                });
                var series3 = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "margem_iii_percentual",
                    valorX: "date",
                    nome: dados.header.margem_iii,
                    cor: warning,
                    chart,
                    tipo: "linha"
                });
                chart.legend.itemContainers.template.events.on("hit", function (ev) {

                    chart.series.values.forEach(series => {
                        series.tooltip.disabled = true;
                    });

                    setTimeout(() => {
                        if (chart.series.values.filter(a => a.visible)[0])
                            chart.series.values.filter(a => a.visible)[0].tooltip.disabled = false;
                        formatarTooltipSeries(chart.series.values.filter(a => a.visible)[0]);
                    }, 1000)

                });

                series.bullets.removeIndex(0);
                series2.bullets.removeIndex(0);
                series3.bullets.removeIndex(0);

                series2.strokeWidth = 2;
                series3.strokeWidth = 2;

                series.fillOpacity = 0.1;
                series2.fillOpacity = 0.1;
                series3.fillOpacity = 0.1;

                series.fill = am4core.color(success);
                series2.fill = am4core.color(info);
                series3.fill = am4core.color(warning);

                series.id = "margem_1";
                series2.id = "margem_2";
                series3.id = "margem_3";


                var bullet = series.bullets.push(new am4charts.CircleBullet());
                bullet.fillOpacity = 0;
                bullet.strokeOpacity = 0;
                bullet.circle.fill = am4core.color(success);
                bullet.circle.radius = 8;
                bullet.circle.stroke = am4core.color("#fff");
                bullet.circle.strokeWidth = 2;

                var bulletState = bullet.states.create("hover");
                bulletState.properties.fillOpacity = 1;
                bulletState.properties.strokeOpacity = 1;

                var shadow = bullet.filters.push(new am4core.DropShadowFilter);
                shadow.color = am4core.color(success);
                shadow.dx = 0;
                shadow.dy = 0;
                shadow.opacity = 1;
                shadow.blur = 5;
                shadow.height = 300;
                shadow.width = 300;


                var bullet2 = series2.bullets.push(new am4charts.CircleBullet());
                bullet2.fillOpacity = 0;
                bullet2.strokeOpacity = 0;
                bullet2.circle.fill = am4core.color(info);
                bullet2.circle.radius = 8;
                bullet2.circle.stroke = am4core.color("#fff");
                bullet2.circle.strokeWidth = 2;

                var bulletState2 = bullet2.states.create("hover");
                bulletState2.properties.fillOpacity = 1;
                bulletState2.properties.strokeOpacity = 1;

                var shadow2 = bullet2.filters.push(new am4core.DropShadowFilter);
                shadow2.color = am4core.color(info);
                shadow2.dx = 0;
                shadow2.dy = 0;
                shadow2.opacity = 1;
                shadow2.blur = 5;
                shadow2.height = 300;
                shadow2.width = 300;

                var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
                bullet3.fillOpacity = 0;
                bullet3.strokeOpacity = 0;
                bullet3.circle.fill = am4core.color(warning);
                bullet3.circle.radius = 8;
                bullet3.circle.stroke = am4core.color("#fff");
                bullet3.circle.strokeWidth = 2;

                var bulletState3 = bullet3.states.create("hover");
                bulletState3.properties.fillOpacity = 1;
                bulletState3.properties.strokeOpacity = 1;

                var shadow3 = bullet3.filters.push(new am4core.DropShadowFilter);
                shadow3.color = am4core.color(warning);
                shadow3.dx = 0;
                shadow3.dy = 0;
                shadow3.opacity = 1;
                shadow3.blur = 5;
                shadow3.height = 300;
                shadow3.width = 300;



                chart.cursor = new am4charts.XYCursor();
                chart.cursor.xAxis = dateAxis;
                chart.cursor.yAxis = eixoY;
                chart.cursor.xAxis.cursorTooltipEnabled = false;
                chart.cursor.yAxis.cursorTooltipEnabled = false;
                chart.cursor.lineY.disabled = false;



                eixoY.renderer.labels.template.disabled = false;
                eixoY.renderer.grid.template.strokeOpacity = 0;
                eixoY.renderer.minGridDistance = 200;
                eixoY.renderer.labels.template.disabled = true;


                dateAxis.renderer.grid.template.strokeOpacity = 0;
                dateAxis.renderer.labels.template.disabled = true;

                formatarTooltipSeries(series);
                formatarTooltipSeries(series2);
                formatarTooltipSeries(series3);
                series2.tooltip.disabled = true;
                series3.tooltip.disabled = true;

            } else if (tipoGrafico == "custos") {

                var series = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "cmv_percentual",
                    valorX: "date",
                    nome: dados.header.cmv_valor,
                    cor: danger,
                    chart,
                    tipo: "linha"
                });
                var series2 = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "nao_vendas_percentual",
                    valorX: "date",
                    nome: dados.header.nao_vendas,
                    cor: dark,
                    chart,
                    tipo: "linha"
                });
                var series3 = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: "despesas_unidade_percentual",
                    valorX: "date",
                    nome: dados.header.despesas_unidade,
                    cor: warning,
                    chart,
                    tipo: "linha"
                });



                chart.legend.itemContainers.template.events.on("hit", function (ev) {

                    chart.series.values.forEach(series => {
                        series.tooltip.disabled = true;
                    });

                    setTimeout(() => {
                        if (chart.series.values.filter(a => a.visible)[0]) {
                            chart.series.values.filter(a => a.visible)[0].tooltip.disabled = false;
                            formatarTooltipSeriesCustos(chart.series.values.filter(a => a.visible)[0]);
                        }
                    }, 1000)

                });

                series.fillOpacity = 0.1;
                series2.fillOpacity = 0.1;
                series3.fillOpacity = 0.1;

                series.id = "cmv";
                series2.id = "naoVendas";
                series3.id = "despesas";

                series.fill = am4core.color(danger);
                series2.fill = am4core.color(dark);
                series3.fill = am4core.color(warning);


                series2.strokeWidth = 2;
                series3.strokeWidth = 2;

                series.bullets.removeIndex(0);
                series2.bullets.removeIndex(0);
                series3.bullets.removeIndex(0);

                series.tensionX = .95;
                series2.tensionX = .95;
                series3.tensionX = .95;

                var bullet = series.bullets.push(new am4charts.CircleBullet());
                bullet.fillOpacity = 0;
                bullet.strokeOpacity = 0;
                bullet.circle.fill = am4core.color(danger);
                bullet.circle.radius = 8;
                bullet.circle.stroke = am4core.color("#fff");
                bullet.circle.strokeWidth = 2;


                var bulletState = bullet.states.create("hover");
                bulletState.properties.fillOpacity = 1;
                bulletState.properties.strokeOpacity = 1;

                var shadow = bullet.filters.push(new am4core.DropShadowFilter);
                shadow.color = am4core.color(danger);
                shadow.dx = 0;
                shadow.dy = 0;
                shadow.opacity = 1;
                shadow.blur = 5;
                shadow.height = 300;
                shadow.width = 300;


                var bullet2 = series2.bullets.push(new am4charts.CircleBullet());
                bullet2.fillOpacity = 0;
                bullet2.strokeOpacity = 0;
                bullet2.circle.fill = am4core.color(dark);
                bullet2.circle.radius = 8;
                bullet2.circle.stroke = am4core.color("#fff");
                bullet2.circle.strokeWidth = 2;


                var bulletState2 = bullet2.states.create("hover");
                bulletState2.properties.fillOpacity = 1;
                bulletState2.properties.strokeOpacity = 1;

                var shadow2 = bullet2.filters.push(new am4core.DropShadowFilter);
                shadow2.color = am4core.color(dark);
                shadow2.dx = 0;
                shadow2.dy = 0;
                shadow2.opacity = 1;
                shadow2.blur = 5;
                shadow2.height = 300;
                shadow2.width = 300;

                var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
                bullet3.fillOpacity = 0;
                bullet3.strokeOpacity = 0;
                bullet3.circle.fill = am4core.color(warning);
                bullet3.circle.radius = 8;
                bullet3.circle.stroke = am4core.color("#fff");
                bullet3.circle.strokeWidth = 2;

                var bulletState3 = bullet3.states.create("hover");
                bulletState3.properties.fillOpacity = 1;
                bulletState3.properties.strokeOpacity = 1;

                var shadow3 = bullet3.filters.push(new am4core.DropShadowFilter);
                shadow3.color = am4core.color(warning);
                shadow3.dx = 0;
                shadow3.dy = 0;
                shadow3.opacity = 1;
                shadow3.blur = 5;
                shadow3.height = 300;
                shadow3.width = 300;




                formatarTooltipSeriesCustos(series);
                formatarTooltipSeriesCustos(series2);
                formatarTooltipSeriesCustos(series3);
                series2.tooltip.disabled = true;
                series3.tooltip.disabled = true;


            } else {
                var series4 = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: tipoGrafico,
                    valorX: "date",
                    nome: nomeGrafico,
                    cor: cor,
                    chart,
                    tipo: "linha"
                });
                // chart.scrollbarX = new am4charts.XYChartScrollbar();
                // chart.scrollbarX.minHeight = 40;
                // chart.scrollbarX.parent = chart.bottomAxesContainer;
                // chart.scrollbarX.series.push(series);

                //formatarValoresAmCharts(series.bullets.push(new am4charts.LabelBullet()), formatoNumeroGrafico, cor, sinalGraficoDepoisValor);
                series4.fillOpacity = 0.1;
                series4.fill = am4core.color(cor);
                series4.bullets.removeIndex(0);

                var bullet = series4.bullets.push(new am4charts.CircleBullet());
                bullet.fillOpacity = 0;
                bullet.strokeOpacity = 0;
                bullet.circle.fill = am4core.color(cor);
                bullet.circle.radius = 8;
                bullet.circle.stroke = am4core.color("#fff");
                bullet.circle.strokeWidth = 2;


                var bulletState = bullet.states.create("hover");
                bulletState.properties.fillOpacity = 1;
                bulletState.properties.strokeOpacity = 1;

                var shadow = bullet.filters.push(new am4core.DropShadowFilter);
                shadow.color = am4core.color(cor);
                shadow.dx = 0;
                shadow.dy = 0;
                shadow.opacity = 1;
                shadow.blur = 5;
                shadow.height = 300;
                shadow.width = 300;



                //series4.contents.stroke = am4core.color("gray");
                series4.strokeWidth = 2;
                series4.tooltipText = `[bold text-decoration: underline  ]{date.formatDate('MMMM yy')}[/] 
                                    \n\n[font-size: 10px]{grafico_nome}:[/] 
                                    \n[bold font-size: 20px]${sinalGraficoDepoisValor} {valueY.formatNumber('${formatoNumeroGrafico}')}[/]
                                     `;
                //series.tooltip.pointerOrientation = "vertical";
                series4.tooltip.getFillFromObject = false;
                series4.tooltip.background.stroke = am4core.color("gray");
                series4.tooltip.background.fill = am4core.color("gray");
                series4.tooltip.fontSize = 15;
                series4.tooltip.background.paddingRight = 5;
                series4.tooltip.background.paddingLeft = 5;
                series4.tooltip.background.paddingTop = 3;



                // if (data.length > 14) {
                //     chart.events.on("ready", function () {
                //         const dataFinal = data[data.length - 1];
                //         const dataInicial = data[data.length - retornarQtdeCategoriasZoomAmCharts()];
                //         formatarDatasZoomAmCharts(dateAxis, new Date(dataInicial.ano, dataInicial.mes, 1), new Date(dataFinal.ano, dataFinal.mes, 1));
                //     });
                // }

            }







            eixoY.renderer.labels.template.disabled = false;
            eixoY.renderer.grid.template.strokeOpacity = 0;
            eixoY.renderer.minGridDistance = 200;
            eixoY.renderer.labels.template.disabled = true;


            dateAxis.renderer.grid.template.strokeOpacity = 0;
            dateAxis.renderer.labels.template.disabled = true;


            function formatarTooltipSeries(series) {

                let formatacao_margem_1 = "";
                let formatacao_margem_2 = "";
                let formatacao_margem_3 = "";
                if (chart.series.values.filter(a => a.id == "margem_1")[0].visible)
                    formatacao_margem_1 = `\n\n[font-size: 10px]{margem_contribuicao_percentual_nome}:[/] 
                                      \n[bold font-size: 20px]{margem_contribuicao_percentual_mostrar}[/]    [opacity: .7]{margem_contribuicao_valor.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                                    `;
                if (chart.series.values.filter(a => a.id == "margem_2")[0].visible)
                    formatacao_margem_2 = `\n\n[font-size: 10px]{margem_ii_percentual_nome}:[/] 
                                           \n[bold font-size: 20px]{margem_ii_percentual_mostrar}[/]    [opacity: .7]{margem_ii.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                                            `;

                if (chart.series.values.filter(a => a.id == "margem_3")[0].visible)
                    formatacao_margem_3 = ` \n\n[font-size: 10px]{margem_iii_percentual_nome}:[/] 
                                            \n[bold font-size: 20px]{margem_iii_percentual_mostrar}[/]    [opacity: .7]{margem_iii.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                                          `;




                series.strokeWidth = 2;
                series.tooltipText = `[bold text-decoration: underline  ]{date.formatDate('MMMM yy')}[/] 
                                       ${formatacao_margem_1}
                                       ${formatacao_margem_2}
                                       ${formatacao_margem_3}
                                        `;
                //series.tooltip.pointerOrientation = "vertical";
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.stroke = am4core.color("gray");
                series.tooltip.background.fill = am4core.color("gray");
                series.tooltip.fontSize = 15;
                series.tooltip.background.paddingRight = 5;
                series.tooltip.background.paddingLeft = 5;
                series.tooltip.background.paddingTop = 3;
            }

            function formatarTooltipSeriesCustos(series) {

                let formatacao_cmv = "";
                let formatacao_nao_vendas = "";
                let formatacao_despesas = "";

                if (chart.series.values.filter(a => a.id == "cmv")[0].visible)
                    formatacao_cmv = ` \n\n[font-size: 10px]{cmv_percentual_nome}:[/] 
                                       \n[bold font-size: 20px]{cmv_percentual_mostrar}[/]    [opacity: .7]{cmv_valor.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                                    `;


                if (chart.series.values.filter(a => a.id == "naoVendas")[0].visible)
                    formatacao_nao_vendas = `\n\n[font-size: 10px]{nao_vendas_percentual_nome}:[/] 
                                             \n[bold font-size: 20px]{nao_vendas_percentual_mostrar}[/]    [opacity: .7]{nao_vendas.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                                             `;

                if (chart.series.values.filter(a => a.id == "despesas")[0].visible)
                    formatacao_despesas = `\n\n[font-size: 10px]{despesas_unidade_nome}:[/] 
                                           \n[bold font-size: 20px]{despesas_unidade_percentual_mostrar}[/]    [opacity: .7]{despesas_unidade.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]
                `;

                series.strokeWidth = 2;


                series.tooltipText = `[bold text-decoration: underline  ]{date.formatDate('MMMM yy')}[/] 
                                    ${formatacao_cmv}
                                    ${formatacao_nao_vendas}
                                    ${formatacao_despesas}
                                    `;
                //series.tooltip.pointerOrientation = "vertical";
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.stroke = am4core.color("gray");
                series.tooltip.background.fill = am4core.color("gray");
                series.tooltip.fontSize = 15;
                series.tooltip.background.paddingRight = 5;
                series.tooltip.background.paddingLeft = 5;
                series.tooltip.background.paddingTop = 3;
            }



            return () => {
                chart.dispose();
            };
        }
    }, [dados, tipoGrafico, cor])
    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        if (alturaGrafico < 300) {
            alturaGrafico = 350
        }
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}
export default memo(GraficoJojasAoVivo)