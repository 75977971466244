import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

const Layout = (props) => {
  const location = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    // Scrollto 0,0
    window.scrollTo(0, 0);

    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage + " | :)BiZ - Central do Cliente";

    props.changeLayout('horizontal');
    
    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    }
    
    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    }
  }, [
    location.pathname,
    props.topbarTheme,
    props.layoutWidth,
    props.changeLayout,
    props.changeTopbarTheme,
    props.changeLayoutWidth
  ]);

  /**
   * Opens the menu - mobile
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header 
          theme={props.topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu} 
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content">
          {props.children}
        </div>
        <Footer />
      </div>

      {props.showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
};

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};

export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth
})(Layout);