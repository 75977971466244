import {takeEvery, fork, all, call, put} from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { API } from "../../config/index"

export const dadosApi = (token, lojas, tipoGrafico, dataVenda) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                lojas: lojas,
                dataVenda,
                tipoGrafico
            }),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/graficoHoraLojas`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => reject(error));
    })
}
function carregarDadosAPI(action){
    return new Promise((resolve, reject)=>{
        dadosApi(action.token, action.lojas, action.tipoGrafico, action.dataVenda)
        .then(result => {
            return resolve(result.ResultadoObjeto);
    })
    .catch(e => reject(e))
})
}

function* carregarDadosLojasAoVivo(action){
    try{
        const dados = yield call( carregarDadosAPI , action.payload)
        yield put( actions.requisicaoBemSucedida(dados) )
    }catch (e){
        yield put (actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao(){
    yield takeEvery(types.REQUISITAR_DADOS, carregarDadosLojasAoVivo)
}


function* listarLojasAoVivo(){
    yield all(
        [
            fork(assistindoRequisicao)
        ]
    )
}

export default listarLojasAoVivo