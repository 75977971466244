import React, { useLayoutEffect, useRef, memo } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { pegarCores } from '../../../../helpers/utils';
import { definirLocalizacaoAmCharts5 } from "../../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, replaceAll } from "../../../../helpers/utils";
import { retornarFormatacaoAmCharts } from "../../../../helpers/utils-amcharts";

am5.addLicense("AM5C303720496");

const formatacaoAmCharts = retornarFormatacaoAmCharts();
//am4core.options.commercialLicense = true;

const Grafico = ({ dados, agentes, filtroCamadasOpcoes }) => {
    const chartReferencia = useRef(null);
    const dadosGrafico = dados || [];
    const formatarTituloOpcoesCamadas = (idRelacao) => {

        const camadasOpcoes =
            (filtroCamadasOpcoes && filtroCamadasOpcoes.length) > 0
                ? filtroCamadasOpcoes?.filter(a => a.value.split('|')[0] == idRelacao).map((item) => item.label).join(", ")
                : "";

        return camadasOpcoes ? `(${camadasOpcoes})` : ''
    }
    useLayoutEffect(() => {

        if (dadosGrafico.length > 0) {
            let cores = pegarCores();
            const tamanhoMinimo = 5;
            const tamanhoMaximo = 5;
            const tamanhoTitulos = "1.7em";
            const tamanhoTitulosInternos = "1.3em";
            let agentesHolograma = [];

            var root = am5.Root.new("chartdiv");


            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                paddingTop: 100,
                paddingBottom: 40,
                paddingLeft: 40,
                paddingRight: 40
            }));

            chart.get("colors").set("colors", [
                am5.color(0x5bc0eb),
                am5.color(0x9bc53d),
                am5.color(0xe55934),
                am5.color(0xfa7921)
            ]);


            definirLocalizacaoAmCharts5(root);

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            var xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 100
            });
            xRenderer.grid.template.set("strokeOpacity", 0);
            xRenderer.labels.template.setAll({
                visible: false
            });
            var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: -1,
                max: 9,
            }));



            var yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 20
            });
            yRenderer.grid.template.set("strokeOpacity", 0);
            yRenderer.labels.template.setAll({
                visible: false
            });
            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: yRenderer,
                min: -120,
                max: 120
            }));



            var serieDivisaoHorizontal = chart.series.push(am5xy.LineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "ax",
                valueYField: "ay",
                fill: am5.color("#fff"),
                stroke: am5.color(0xbfbfbf)
            }));
            serieDivisaoHorizontal.fills.template.setAll({ fillOpacity: 0, visible: true });
            serieDivisaoHorizontal.data.setAll([
                { ax: -1, ay: 0 },
                { ax: 9, ay: 0 }
            ]);
            serieDivisaoHorizontal.strokes.template.setAll({
                strokeWidth: 4,
                strokeOpacity: 0.4
            });


            var serieDivisaoVertical = chart.series.push(am5xy.LineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "ax",
                valueYField: "ay",
                fill: am5.color("#fff"),
                stroke: am5.color(0xbfbfbf)
            }));
            serieDivisaoVertical.fills.template.setAll({ fillOpacity: 0, visible: true });
            serieDivisaoVertical.data.setAll([
                { ax: 4, ay: 120 },
                { ax: 4, ay: -120 }
            ]);
            serieDivisaoVertical.strokes.template.setAll({
                strokeWidth: 4,
                strokeOpacity: 0.4
            });





            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/


            let todosAgentesHolograma = [...new Set(agentes.map(item => item.label))]
            let seriesAutomaticas = [];
            let tooltipsAutomaticos = [];


            todosAgentesHolograma.forEach((elemento, index) => {
                let idRespondente = agentes.filter(a => a.label == elemento)[0].value;
                let camadas = formatarTituloOpcoesCamadas(idRespondente);
                tooltipsAutomaticos[index] = am5.Tooltip.new(root, {
                    autoTextColor: false,
                    getFillFromSprite: false,
                    getLabelFillFromSprite: false,
                    visible: false
                })
                tooltipsAutomaticos[index].get("background").setAll({
                    fill: am5.color(0x646464),
                    fillOpacity: 0.8,
                    stroke: am5.color(0x646464),
                    paddingRight: 5,
                    paddingLeft: 5,
                    paddingTop: 5
                });

                tooltipsAutomaticos[index].label.setAll({
                    text: "[fontSize: 14px]{nomeRepondente}[/]\n[fontSize: 12px]{camadasRepondente}[/]",
                    //fill: am5.color("#fff")
                });


                seriesAutomaticas[index] = chart.series.push(am5xy.LineSeries.new(root, {
                    calculateAggregates: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    valueField: "value",
                    name: `[bold fontSize: 16px]${elemento} ${camadas}[/] [fontSize: 16px] (${dadosGrafico.filter(a => a.icr_nome_agente_holograma == elemento).length}${dadosGrafico.filter(a => a.icr_nome_agente_holograma == elemento).length > 1 ? " resp." : " resp."})[/]`,
                    //tooltip: tooltipsAutomaticos[index]
                    // tooltip: am5.Tooltip.new(root, {})
                }))
                // Add bullet
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
                var circleTemplate = am5.Template.new({});

                seriesAutomaticas[index].bullets.push(function () {
                    var graphics = am5.Circle.new(root, {
                        fill: seriesAutomaticas[index].get("fill"),
                        fillOpacity: 0.8,
                        tooltipText: `[fontSize: 14px]{nomeRepondente}[/]\n[fontSize: 12px]{camadasRepondente}[/]`,
                        tooltip: tooltipsAutomaticos[index]
                    }, circleTemplate);



                    return am5.Bullet.new(root, {
                        sprite: graphics
                    });
                });

                seriesAutomaticas[index].set("heatRules", [{
                    target: circleTemplate,
                    min: tamanhoMinimo,
                    max: tamanhoMaximo,
                    dataField: "value",
                    key: "radius"
                }]);

                seriesAutomaticas[index].strokes.template.set("strokeOpacity", 0);

            });

            chart.set("cursor", am5xy.XYCursor.new(root, {
                // xAxis: xAxis,
                // yAxis: yAxis,
                //snapToSeries: [series0, series1, series2]
                arrangeTooltips: false,
                snapToSeries: seriesAutomaticas
            }));

            let cursor = chart.get("cursor");

            cursor.lineX.setAll({
                visible: false
            });

            cursor.lineY.setAll({
                visible: false
            });

            var data = [];



            dadosGrafico.forEach(function (elemento, indice) {

                let dado = {
                    "x": elemento.icr_suplicas_grafico,
                    "y": elemento.icr_faixa_grafico,
                    "value": 1,
                    "value2": elemento.icr_suplicas_atender,
                    "serie": elemento.icr_serie,
                    "nomeAgenteHolograma": elemento.icr_nome_agente_holograma,
                    "nomeRepondente": elemento.icr_nome_respondente,
                    "camadasRepondente": replaceAll((elemento.icr_nome_agente_holograma + "|" + elemento.icr_camadas), "|", " - ")
                };
                data.push(dado);
            });


            let legend = chart.children.push(am5.Legend.new(root, {
                nameField: "name",
                fillField: "color",
                strokeField: "color",
                centerX: am5.percent(100),
                x: am5.percent(100),
                y: 15
            }));


            legend.data.setAll(seriesAutomaticas);


            seriesAutomaticas.forEach((elemento, index) => {
                elemento.data.setAll(data.filter(a => a.nomeAgenteHolograma == todosAgentesHolograma[index]));
                elemento.appear(1000);
            });

            chart.appear(1000, 100);


            var labelNegligencia = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "negligência",
                fontSize: tamanhoTitulosInternos,
                x: 0,
                y: 0,
                width: am5.p50,
                height: am5.p50,
                textAlign: "center",
                fill: am5.color(0xbfbfbf),
            }));

            labelNegligencia.setAll({
                paddingTop: labelNegligencia.height() * .4
            });

            var labelDesastre = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "desastre",
                fontSize: tamanhoTitulosInternos,
                x: 0,
                y: am5.p50,
                width: am5.p50,
                height: am5.p50,
                textAlign: "center",
                fill: am5.color(0xbfbfbf)
            }));

            labelDesastre.setAll({
                paddingTop: labelNegligencia.height() * .4
            });


            var labelAmor = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "amor",
                fontSize: tamanhoTitulosInternos,
                x: am5.p50,
                y: 0,
                width: am5.p50,
                height: am5.p50,
                textAlign: "center",
                fill: am5.color(0xbfbfbf)
            }));

            labelAmor.setAll({
                paddingTop: labelNegligencia.height() * .4
            });


            var labelOportunismo = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "oportunismo",
                fontSize: tamanhoTitulosInternos,
                x: am5.p50,
                y: am5.p50,
                width: am5.p50,
                height: am5.p50,
                textAlign: "center",
                fill: am5.color(0xbfbfbf)
            }));

            labelOportunismo.setAll({
                paddingTop: labelNegligencia.height() * .4
            });

            var labelAtencao = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "ATENÇÃO",
                fontWeight: "900",
                fontSize: tamanhoTitulos,
                x: 0,
                y: -40,
                marginTop: 0,
                paddingTop: 0,
                width: am5.p100,
                textAlign: "center",
                fill: am5.color(0x000000)
            }));

            var labelDesatencao = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "DESATENÇÃO",
                fontWeight: "900",
                fontSize: tamanhoTitulos,
                x: 0,
                y: am5.p100,
                marginTop: 0,
                paddingTop: 0,
                width: am5.p100,
                textAlign: "center",
                fill: am5.color(0x000000)
            }));

            labelDesatencao.setAll({
                y: labelDesatencao.y() + 5
            });


            var labelDesinteresse = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "DESINTERESSE",
                fontWeight: "900",
                fontSize: tamanhoTitulos,
                x: -25,
                y: am5.p50,
                centerY: am5.p50,
                rotation: -90,
                fill: am5.color(0x000000),
                textAlign: "center"
            }));

            var labelInteresse = chart.plotContainer.children.push(am5.Label.new(root, {
                text: "INTERESSE",
                fontWeight: "900",
                fontSize: tamanhoTitulos,
                x: am5.p100,
                y: am5.p50,
                centerY: am5.p50,
                rotation: -90,
                fill: am5.color(0x000000),
                textAlign: "center"
            }));

            labelInteresse.setAll({
                x: labelInteresse.x() + 20
            });


            return () => {
                chart.dispose();
            };
        }

    }, [dados])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}

export default memo(Grafico)