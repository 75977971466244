
import * as types from "./types"

const inicialState = {
    atalhos: [],
    menuPrincipal: [],
    carregando: false,
    falha: ""
}

const mapaDeNegociosUnidades = function(state = inicialState, action) {
    switch (action.type) {
        case types.REQUISITAR_DADOS:
            return {...state, carregando: true}
        case types.REQUISITAR_DADOS_SUCESSO:
            return {...state, carregando: false, menuPrincipal: action.payload}
        case types.REQUISITAR_DADOS_SUCESSO_ATALHO:
            return {...state, carregando: false, atalhos: action.payload}
        case types.ATUALIZAR_MENU_PRINCIPAL:
                return {...state, carregando: false, menuPrincipal: action.payload}
        case types.REQUISITAR_DADOS_FALHOU:
            return {...state, carregando: false, falha: action.payload}
        default:
            return state
    }
}

export default mapaDeNegociosUnidades