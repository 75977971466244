import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import gerarToken from '../../helpers/gerarToken'
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados,requisitarLimparDados } from "../../store/topProdutos/actions"
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form } from 'reactstrap';
import { Hoje, Mes, Ano, removerFocusNoBotao, primeiroDiaMes, toDateSQL, concatenaLojas } from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const TopProdutos = (props) => {
    const { dados, carregando } = useSelector(state => state.TopProdutos);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [lojas, setLojas] = useState([]);
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);

    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };

    const atualizarDados = async () => {
        const token = await gerarToken();
        await dispatch(requisitarDados({
            "data_inicial": toDateSQL(dataInicial),
            "data_final": toDateSQL(dataFinal),
            "arvore": "1",
            "visualizacao": "produto",
            "agrupamento": "total",
            u_unidades_id: concatenaLojas(lojasRef.current.lojas),
            token
        }));
        setMostrarFiltros(false);
    }
    const atualizarDadosAmbiente = async () => {
        const token = await gerarToken();
        await dispatch(requisitarDadosAmbiente(token));
    }
    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();
        return ()=> dispatch(requisitarLimparDados())
    }, []);
    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, grid);
    }, [dados, grid])

    if (!dados) return <PaginaLoader carregando={true} />
    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }
    return (
        <React.Fragment>
            <PaginaLoader carregando={carregando} />
            <>
                <Header
                    parametros={{
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            },
                            {
                                funcao: () => {
                                    setHabilitarFiltroColunas(!habilitarFiltroColunas)
                                },
                                icone: "bx bx-filter"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="select2-container"  >
                            <div style={{ minWidth: 200 }}>
                                <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block ml-2 w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: dataInicial
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataInicial(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <div className={"ml-2"} >a</div>
                        <FormGroup style={{ width: 110 }}>
                            <Flatpickr
                                className="form-control d-block ml-2 w-100"
                                placeholder="dd M,yyyy"
                                locale="pt"
                                options={{
                                    mode: "single",
                                    locale: Portuguese,
                                    dateFormat: "d/m/Y",
                                    maxDate: `today`,
                                    defaultDate: "today"
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                    if (selectedDates.length > 0) {
                                        setDataFinal(selectedDates[0]);
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-rabbit-fast mr-1"></i>
                                vai...
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={true}
                    key={carregando}
                    filterBarFeature={{
                        compactMode: habilitarFiltroColunas
                    }}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />
            </>
        </React.Fragment>
    );
}
export default TopProdutos;