import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import { _handleError } from "../../errorHandler"
import * as actions from "./actions"
import { API } from "../../../config/index"

const dadosApi = (token, tipo) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(tipo),
            redirect: 'follow'
        };
        fetch(`${API}/api/varejo/ddl_redes_por_negocio`, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => console.log('error', error));
    })
}

function carregarDadosAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApi(action.token, action.tipo)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* carregar(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, carregar)
}

function* carregarRede() {
    yield all(
        [
            fork(assistindoRequisicao)
        ]
    )
}

export default carregarRede