import React, { useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom"; // Mudança aqui
import classname from "classnames";
import { useTranslation } from 'react-i18next'; // Mudança aqui
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { requisitarAtualizarMenuPrincipal } from "../../store/menus/actions";

const criarSubmenu = (subMenu, nivel, abrirMenu, menuAberto) => {
  if (!subMenu) return null;
  let filhos = null;
  const inserirIcone = (icone, titulo) => {
    localStorage.setItem("iconeBizAtual", icone);
    localStorage.setItem("tituloBizAtual", titulo);
  };

  return (
    <div className={classname("dropdown-menu ", { show: menuAberto })}>
      {subMenu.map((subItem, indice) => {
        let nivelatual = [...nivel];
        nivelatual.push(indice);
        filhos = subItem.subMenu;
        if (filhos) {
          return (
            <div key={subItem.id} className="dropdown dropdown-menu-right">
              <Link
                key={subItem.id}
                to="#"
                className={"dropdown-item dropdown-toggle arrow-none"}
                onClick={(e) => {
                  e.preventDefault();
                  abrirMenu(indice, nivel);
                }}
              >
                {subItem.nome}
                <div className="arrow-down"></div>
              </Link>
              {criarSubmenu(filhos, nivelatual, abrirMenu, subItem.filhoAberto)}
            </div>
          );
        }
        return (
          <Link
            key={subItem.id}
            to={subItem.urlAmigavel}
            onClick={() => inserirIcone(subItem.icone, subItem.nome)}
            className={"dropdown-item"}
          >
            <i className={`fal fa-angle-right mr-2 text-muted`}></i>
            {subItem.nome}
          </Link>
        );
      })}
    </div>
  );
};

const Navbar = (props) => {
  const { t } = useTranslation(); // Adicionado hook de tradução
  const location = useLocation(); // Adicionado hook de localização
  const { menuPrincipal } = useSelector((state) => state.Menus);
  const dispatch = useDispatch();

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) { // Mudança aqui: props.location -> location
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const abrirMenu = (indice, nivel) => {
    dispatch(requisitarAtualizarMenuPrincipal(nivel, indice, menuPrincipal));
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {menuPrincipal?.map((item, i) => {
                  return (
                    <li className="nav-item dropdown" key={item.id + i}>
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          abrirMenu(i, 0);
                        }}
                        to="#"
                      >
                        <i className={`${item.icone} mr-2`}></i>
                        {item.nome} {props.menuOpen}
                        <div className="arrow-down"></div>
                      </Link>
                      {item.subMenu
                        ? criarSubmenu(
                            item.subMenu,
                            [i],
                            abrirMenu,
                            item.filhoAberto
                          )
                        : null}
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStatetoProps, {})(Navbar);