import "../../../helpers/locale/locale_BR";
import { API } from "../../../config/index";
import { showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, formatarFooterColunaPercentualValor } from "../../../helpers/utils";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento: 0,
    });
    this.set({
      faturamento: linha.faturamento
    })
  }
}


const gridConfig = (dados, grid, token, data_inicial, data_final, u_unidades_id) => {
  let visualizacao = "familia";
  let p_grade_01_id = "";
  let p_grade_02_id = "";
  let p_grade_03_id = "";
  let p_grade_v01_id = "";

  let classeN1 = "";
  const larguraColunaComputador = 150;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    readOnly: true,
    columns: [
      {
        text: dados.header.p_grade_01_descricao,
        field: "descricao",
        sum: true,
        summaryRenderer: () => {
          return "Total";
        },
        flex: 1,
        type: "tree",
        minWidth: 120,
        responsiveLevels: hideMedium
      },
      {
        text: dados.header.p_grade_01_descricao,
        field: "descricao",
        sum: true,
        type: "tree",
        summaryRenderer: () => {
          return "Total";
        },
        minWidth: 120,
        locked: true,
        responsiveLevels: showMedium
      },
      {
        align: "right",
        text: dados.header.quantidade,
        field: "quantidade",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          var qtde = "";
          if (record.nivel_atual == 2) {
            qtde = `<div class="text-right">${toMoney(record.quantidade, 0)} ${record.unidade_medida_id}<br />
                    <small>R$ ${toMoney(record.preco_medio_venda)}</small></div>`;
          } else {
            qtde = `-`;
          }
          return `${qtde}`
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.faturamento,
        field: "faturamento",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.faturamento_percentual, dados.footer.faturamento), dados.header.faturamento);
        },
        renderer: ({ value, cellElement, record }) => {
          return `${formatarColunaPercentualValor(record.participacao, toMoney(value))}`
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.impostos_valor,
        field: "impostos_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.impostos_percentual, dados.footer.impostos_valor), dados.header.impostos_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.impostos_percentual, toMoney(value));
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.devolucao_total_valor,
        field: "devolucao_total_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.devolucao_total_percentual, dados.footer.devolucao_total_valor), dados.header.devolucao_total_valor);
        },
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.devolucao_total_percentual, toMoney(value));
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      // {
      //   align: "right",
      //   text: dados.header.taxas_financeiras_valor,
      //   field: "taxas_financeiras_valor",
      //   type: "number",
      //   sum: true,
      //   htmlEncode: false,
      //   renderer: ({ value, cellElement, record }) => {
      //     return formatarColunaPercentualValor(record.taxas_financeiras_percentual, toMoney(value));
      //   },
      //   summaryRenderer: ({ sum }) => {
      //     if (!dados.footer) return "";
      //     return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.taxas_financeiras_percentual, dados.footer.taxas_financeiras_valor), dados.header.taxas_financeiras_valor);
      //   },
      //   responsiveLevels: configResponsiveLevels,
      //   filterable: false
      // },
      {
        align: "right",
        text: dados.header.cmv_valor,
        field: "cmv_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.cmv_percentual, toMoney(value));
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.cmv_percentual, dados.footer.cmv_valor), dados.header.cmv_valor);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.margem_contribuicao_valor,
        field: "margem_contribuicao_valor",
        type: "number",
        sum: true,
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return formatarColunaPercentualValor(record.margem_contribuicao_percentual, toMoney(value));
        },
        summaryRenderer: ({ sum }) => {
          if (!dados.footer) return "";
          return formatarFooterTitulo(formatarFooterColunaPercentualValor(dados.footer.margem_contribuicao_percentual, dados.footer.margem_contribuicao_valor), dados.header.margem_contribuicao_valor);
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      }
    ],
    store: {
      unidades: null,
      dataInicial: null,
      dataFinal: null,
      tree: true,
      tipo_cmv: null,
      modelClass: GridLinha,
      fields: ['descricao', 'faturamento'],
      readUrl: `${API}/api/varejo/ws_vendas_produtos`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;

          if (nivel_atual == 0 && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.unidades = request.u_unidades_id;
            this.dataInicial = request.data_inicial;
            this.dataFinal = request.data_final;
            this.tipo_cmv = request.tipo_cmv;
            if (event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
              dados.footer = event.json.ResultadoObjeto.footer;
              this.getById(event.json.ResultadoObjeto.data[0].id).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }

        },
        exception(response) {
          console.log(response);
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;
          switch (nivel_atual) {
            case 1:
              visualizacao = "produto";
              p_grade_01_id = this.getById(event.params.id).data.p_grade_01_id;
              p_grade_02_id = this.getById(event.params.id).data.p_grade_02_id;
              p_grade_03_id = this.getById(event.params.id).data.p_grade_03_id;
              p_grade_v01_id = this.getById(event.params.id).data.p_grade_v01_id;
              classeN1 = "";
              break;
            // case 1:
            //   visualizacao = "secao";
            //   p_grade_01_id = this.getById(event.params.id).data.p_grade_01_id;
            //   p_grade_02_id = "";
            //   p_grade_v01_id = "";
            //   p_grade_03_id = "";
            //   classeN1 = "";
            //   break;
            // case 2:
            //   visualizacao = "grupo";
            //   p_grade_01_id = this.getById(event.params.id).data.p_grade_01_id;
            //   p_grade_02_id = this.getById(event.params.id).data.p_grade_02_id;
            //   p_grade_v01_id = "";
            //   p_grade_03_id = "";
            //   classeN1 = "";
            //   break;
            // case 3:
            //   visualizacao = "sujeito";
            //   p_grade_01_id = this.getById(event.params.id).data.p_grade_01_id;
            //   p_grade_02_id = this.getById(event.params.id).data.p_grade_02_id;
            //   p_grade_03_id = this.getById(event.params.id).data.p_grade_03_id;
            //   p_grade_v01_id = "";
            //   classeN1 = "";
            //   break;
            // case 4:
            //   visualizacao = "produto";
            //   p_grade_01_id = this.getById(event.params.id).data.p_grade_01_id;
            //   p_grade_02_id = this.getById(event.params.id).data.p_grade_02_id;
            //   p_grade_03_id = this.getById(event.params.id).data.p_grade_03_id;
            //   p_grade_v01_id = this.getById(event.params.id).data.p_grade_v01_id;
            //   classeN1 = "";
            //   break;
          }
          if (nivel_atual > 0) {
            event.source.fetchOptions.body = JSON.stringify({
              data_inicial: this.dataInicial,
              data_final: this.dataFinal,
              arvore: 2,
              visualizacao,
              agrupamento: "total",
              classeN1,
              u_unidades_id: this.unidades,
              p_grade_01: p_grade_01_id,
              p_grade_02: p_grade_02_id,
              p_grade_03: p_grade_03_id,
              p_grade_v01: p_grade_v01_id,
              sob_demanda: 1,
              nivel_atual,
              tipo_cmv: this.tipo_cmv
            })
          }
        }
      }
    }
  };
};

export { gridConfig };
