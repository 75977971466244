import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { Amplify } from 'aws-amplify';
import config from "./aws-exports";

// Configurar o Amplify
Amplify.configure(config);

// Criar o aplicativo com todos os providers
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

// Renderizar o aplicativo
const root = createRoot(document.getElementById('root'));
root.render(app); // Correção aqui - estava renderizando apenas <App /> ao invés do app completo com providers

serviceWorker.unregister();