import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import {
  requisitarDados,
  requisitarLimparDados,
} from "../../../store/btflex/creditoDeImposto/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form } from "reactstrap";
import {
  exportarExcel,
  retornarTipoFluxoCaixa,
  Hoje,
  removerFocusNoBotao,
  Mes,
  retornarMeses,
  retornarAnos,
} from "../../../helpers/utils";

import "@bryntum/grid/grid.stockholm.css";
import "../../../App.scss";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from "react-select";

import { requisitarDadosAmbiente } from "../../../store/ambiente/actions";
import FocarPaisagem from "../../../components/forcarPaisagem";

const tiposFluxoCaixa = retornarTipoFluxoCaixa();

const meses = retornarMeses();
const anos = retornarAnos(4);

const CreditoDeImposto = () => {
  const token = useSelector((state) => state.Login.user.tokenNOL);
  const { dados, carregando } = useSelector((state) => state.CreditoDeImposto);

  const [dataFinal, setDataFinal] = useState(Hoje());
  const [mes, setMes] = useState(Mes(Hoje()));
  const [ano, setAno] = useState(anos[0]);
  const grid = useRef(null);
  const dispatch = useDispatch();

  const [mostrarFiltros, setMostrarFiltros] = useState(false);

  const [fluxoCaixa, setFluxoCaixa] = useState(tiposFluxoCaixa[0]);

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };

  const fluxoCaixaRef = useRef(null);
  fluxoCaixaRef.current = { fluxoCaixa, setFluxoCaixa };

  const atualizarDados = async () => {
    await dispatch(
      requisitarDados({
        token,
        ano: typeof ano === "object" ? parseInt(ano.value) : parseInt(ano),
        mes: typeof mes === "object" ? parseInt(mes.value) : parseInt(mes),
      })
    );
    setMostrarFiltros(false);
  };

  const destruirDados = () => {
    dispatch(requisitarLimparDados());
  };
  useEffect(() => {
    atualizarDados();
    return () => {
      destruirDados();
    };
  }, []);

  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(
      dados,
      grid,
      fluxoCaixaRef.current.fluxoCaixa.value == "D"
    );
  }, [dados, grid]);

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando} />

      <>
        <Header
          parametros={{
            exportarExcel: () =>
              exportarExcel({
                data: grid.current.gridInstance.store.data,
                exportacao: dados.exportacao,
              }),
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros);
                },
                icone: "bx bx bx-filter-alt",
              },
            ],
          }}
          position={true}
          titulo="Mapa de negócios"
        />
        <FiltroBrytum aberto={mostrarFiltros}>
          <Form inline>
            <FormGroup>
              <div className="unidadesControler anoControler">
                <Select
                  placeholder="Ano"
                  className="basic-single"
                  options={anos}
                  isSearchable={false}
                  classNamePrefix="select2-selection"
                  defaultValue={anos[0] || []}
                  onChange={(Object) => {
                    setAno(Object || []);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="select2-container ml-2">
              <div className="unidadesControler mesControler">
                <Select
                  defaultValue={meses.filter((a) => a.value == Mes(Hoje()))}
                  name="meses"
                  options={meses}
                  placeholder="Todos os meses"
                  closeMenuOnSelect={true}
                  classNamePrefix="select2-selection"
                  isSearchable={false}
                  onChange={(Object) => {
                    setMes(Object || []);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="ml-2">
              <Button
                color="light"
                onClick={(e) => {
                  atualizarDados();
                  removerFocusNoBotao(e.currentTarget);
                }}
              >
                <i className="fal fa-hashtag"></i>
                boralá!
              </Button>
            </FormGroup>
          </Form>
        </FiltroBrytum>
        {dados && (
          <BryntumGrid
            ref={grid}
            {...gridInfo}
            treeFeature={true}
            columnReordFeature={false}
            cellEditFeature={false}
            summaryFeature={true}
            sortFeature={false}
            key={carregando}
            cellMenuFeature={{
              disabled: true,
            }}
            headerMenuFeature={{
              items: {
                hideColumn: false,
              },
            }}
          />
        )}
      </>
    </React.Fragment>
  );
};
export default CreditoDeImposto;
