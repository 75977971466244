import {
    LocaleManager
  } from '@bryntum/grid/grid.umd';
/**
 * The following technique, although workable, is not recommended if the IE compatibility
 * is not needed. Importing LocaleManager and registering locale is the recommended way
 * for modern browsers that support modules.
 *
 * We want our examples to work also in IE 11 therefore we use for this locale ES5 style
 * script that assigns this German locale to the global namespace where it is recognized
 * by the Locale Manager. When the Locale Manager runs first time the locales found in
 * `window.bryntum.locales` are automatically registered.
 */
const Br = {
    localeName : 'Br',
    localeDesc : 'Português Brasileiro',
    App : {
        'Localization demo' : ':)BiZ'
    },
    Object : {
        Yes    : 'Sim',
        No     : 'Não',
        Cancel : 'Cancelar'
    },
    Combo : {
        noResults : 'Nenhum resultado',
        Theme     : 'Temas',
        Language  : 'Linguagem',
        Size      : 'Tamanho'
    },
    FilePicker : {
        file : 'Arquivo'
    },

    //endregion

    //region Features

    ColumnPicker : {
        column          : 'Coluna',
        columnsMenu     : 'Colunas',
        hideColumn      : 'Esconder coluna',
        hideColumnShort : 'Esconder',
        newColumns      : 'Novas colunas'
    },

    Filter : {
        applyFilter  : 'Aplicar filtro',
        filter       : 'Filtro',
        editFilter   : 'Editar filtro',
        on           : 'ligado',
        before       : 'Antes',
        after        : 'Depois',
        equals       : 'igual',
        lessThan     : 'menor que',
        moreThan     : 'maior que',
        removeFilter : 'Remover filtro'
    },

    FilterBar : {
        enableFilterBar  : 'Habilitar filtro',
        disableFilterBar : 'Desabilitar filtro'
    },

    Group : {
        group                : 'Grupo',
        groupAscending       : 'Grupo em ordem crescente',
        groupDescending      : 'Grupo em ordem decrescente',
        groupAscendingShort  : 'Crescente',
        groupDescendingShort : 'Decrescente',
        stopGrouping         : 'Parar de agrupar',
        stopGroupingShort    : 'Parar'
    },

    Search : {
        searchForValue : 'Procurar por valor'
    },

    Sort : {
        sort                   : 'Ordenar',
        sortAscending          : 'Ordernar ascendente',
        sortDescending         : 'Ordernar descendente',
        multiSort              : 'Classificar vários',
        removeSorter           : 'Remover classificação',
        addSortAscending       : 'Adicionar classificação crescente',
        addSortDescending      : 'Adicionar classificação decrescente',
        toggleSortAscending    : 'Mudar para ascendente',
        toggleSortDescending   : 'Mudar para decrescente',
        sortAscendingShort     : 'Ascendente',
        sortDescendingShort    : 'Descendente',
        removeSorterShort      : 'Remover',
        addSortAscendingShort  : '+ Ascendente',
        addSortDescendingShort : '+ Descendente'
    },

    //endregion

    //region Grid

    GridBase : {
        loadFailedMessage  : 'Carregando, por favor tente novamente!',
        syncFailedMessage  : 'A sincronização de dados falhou!',
        unspecifiedFailure : 'Erro não especificado',
        networkFailure     : 'Erro de rede',
        parseFailure       : 'A resposta do servidor não pôde ser analisada',
        loadMask           : 'carregando...',
        syncMask           : 'Salvando as alterações, aguarde ...',
        noRows             : 'Não há dados para exibir',
        removeRow          : 'Remover linha',
        removeRows         : 'Remover linha',
        moveColumnLeft     : 'Mover para o painel esquerdo',
        moveColumnRight    : 'Mova para a direita',
        moveColumnTo       : function(region) {
            return 'Mover coluna para ' + region;
        }
    },

    //endregion

    //region Export

    PdfExport : {
        'Waiting for response from server' : 'Esperando por uma resposta do servidor...',
        'Export failed'                    : 'A exportação falhou',
        'Server error'                     : 'Falha do servidor',
        'Generating pages'                 : 'Gerar páginas...'
    },

    CellMenu : {
        removeRow  : 'Apagar linha',
        removeRows : 'Apagar linhas'
    },

    ExportDialog : {
        width          : '40em',
        labelWidth     : '12em',
        exportSettings : 'Exportar configurações',
        export         : 'Exportar',
        exporterType   : 'Verifique a paginação',
        cancel         : 'Cancelar',
        fileFormat     : 'Formato do arquivo',
        rows           : 'Linhas',
        alignRows      : 'Alinhamento das linhas',
        columns        : 'Colunas',
        paperFormat    : 'Formatos do papel',
        orientation    : 'Orientação',
        repeatHeader   : 'Repetir cabeçalho'
    },

    ExportRowsCombo : {
        all     : 'Todos',
        visible : 'Visivel'
    },

    ExportOrientationCombo : {
        portrait  : 'Retrato',
        landscape : 'Paisagem'
    },

    SinglePageExporter : {
        singlepage : 'Página única'
    },

    MultiPageExporter : {
        multipage     : 'Múltiplas páginas',
        exportingPage : function(data) {
            return 'Exportar páginas ' + data.currentPage + '/' + data.totalPages;
        }
    },

    MultiPageVerticalExporter : {
        multipagevertical : 'Várias páginas (vertical)',
        exportingPage     : function(data) {
            return 'Exportar páginas ' + data.currentPage + '/' + data.totalPages;
        }
    },

    //endregion

    //region Widgets

    DateField : {
        invalidDate   : 'Data inválida',
        'Date:'       : 'Data:',
        'Select date' : 'Selecione a data'
    },

    Field : {
        // native input ValidityState statuses
        badInput        : 'Valor de campo inválido',
        patternMismatch : 'O valor deve corresponder a um certo padrão',
        rangeOverflow   : function(value) {
            return 'Der Wert muss größer als oder gleich ' + value.max + ' sein';
        },
        rangeUnderflow : function(value) {
            return 'Der Wert muss größer als oder gleich ' + value.min + ' sein';
        },
        stepMismatch : 'O valor deve corresponder ao passo',
        tooLong      : 'Der Wert sollte kürzer sein',
        tooShort     : 'Der Wert sollte länger sein',
        typeMismatch : 'O valor deve estar em um formato especial',
        valueMissing : 'Este campo é obrigatório',

        invalidValue          : 'Valor de campo inválido.',
        minimumValueViolation : 'Violação de valor mínimo',
        maximumValueViolation : 'Violação de valor máximo',
        fieldRequired         : 'Este campo é obrigatório',
        validateFilter        : 'O valor deve ser selecionado da lista'
    },

    List : {
        loading : 'Carregando...'
    },

    PagingToolbar : {
        firstPage         : 'Primeira página',
        prevPage          : 'Página anterior',
        page              : 'Página',
        nextPage          : 'Próxima página',
        lastPage          : 'Última página',
        reload            : 'Recarregar a página atual',
        noRecords         : 'Sem registros para exibir',
        pageCountTemplate : function(data) {
            return 'de ' + data.lastPage;
        },
        summaryTemplate : function(data) {
            return 'Resultados ' + data.start + ' - ' + data.end + ' de ' + data.allCount;
        }
    },

    NumberFormat : {
        locale   : 'pt-BR',
        currency : 'BRL'
    },

    DurationField : {
        invalidUnit : 'Unidade inválida'
    },

    TimeField : {
        invalidTime : 'Especificação de tempo inválida'
    },

    //endregion

    //region Dates

    DateHelper : {
        locale         : 'pt-br',
        weekStartDay   : 1,
        // Non-working days which match weekends by default, but can be changed according to schedule needs
        nonWorkingDays : {
            0 : true,
            6 : true
        },
        // Days considered as weekends by the selected country, but could be working days in the schedule
        weekends : {
            0 : true,
            6 : true
        },
        unitNames : [
            { single : 'Milissegundo', plural : 'Milissegundos', abbrev : 'ms' },
            { single : 'Segundo', plural : 'Segundos', abbrev : 's' },
            { single : 'Minuto', plural : 'Minutos', abbrev : 'min' },
            { single : 'Hora', plural : 'Horas', abbrev : 'hrs' },
            { single : 'Dia', plural : 'Dias', abbrev : 'd' },
            { single : 'Semana', plural : 'Semanas', abbrev : 's' },
            { single : 'Mes', plural : 'Meses', abbrev : 'm' },
            { single : 'Trimestre', plural : 'Trimestres', abbrev : 't' },
            { single : 'Ano', plural : 'Anos', abbrev : 'a' },
            { single : 'Década', plural : 'Décadas', abbrev : 'dec'}
        ],
        // Used to build a RegExp for parsing time units.
        // The full names from above are added into the generated Regexp.
        // So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
        // When generating its display value though, it uses the full localized names above.
        unitAbbreviations : [
            ['mil'],
            ['s', 'seg'],
            ['m', 'min'],
            ['h', 'hr'],
            ['d'],
            ['s', 'sem'],
            ['mes', 'mes', 'mes'],
            ['t', 'tri', 'tri'],
            ['a', 'ano'],
            ['dec']
        ],
        parsers : {
            L  : 'DD.MM.YYYY',
            LT : 'HH:mm'
        },
        ordinalSuffix : function(number) {
            return number;
        }
    },

    //endregion

    //region Examples

    Column : {
        Name             : 'Name',
        Age              : 'Alter',
        City             : 'Stadt',
        Food             : 'Essen',
        Color            : 'Farbe',
        'First name'     : 'Vorname',
        Surname          : 'Nachname',
        Team             : 'Team',
        Score            : 'Ergebnis',
        Rank             : 'Rang',
        Percent          : 'Prozent',
        Birthplace       : 'Geburstort',
        Start            : 'Anfang',
        Finish           : 'End',
        Template         : 'Vorlage (template)',
        Date             : 'Datum',
        Check            : 'Check',
        Contact          : 'Kontakt',
        Favorites        : 'Favoriten',
        'Customer#'      : 'Kunde#',
        When             : 'Wann',
        Brand            : 'Marke',
        Model            : 'Modell',
        'Personal best'  : 'Persönlicher rekord',
        'Current rank'   : 'Aktueller rang',
        Hometown         : 'Heimatstadt',
        Satisfaction     : 'Zufriedenheit',
        'Favorite color' : 'Lieblingsfarbe',
        Rating           : 'Wertung',
        Cooks            : 'Zuberaiten',
        Birthday         : 'Geburstag'
    },

    Shared : {
        'Locale changed' : 'Sprache geändert',
        'Full size'      : 'Volle Größe',
        'Phone size'     : 'Telefongröße'
    },

    //endregion

    Button : {
        'Show/Hide' : function(data) {
            return 'Anzeigen/Ausblenden (' + data + ')';
        },
        'Add column'    : 'Spalte hinzufügen',
        Apply           : 'Anwenden',
        'Display hints' : 'Tipps anzeigen',
        'Remove column' : 'Spalte entfernen'
    },

    Tooltip : {
        'Button with menu<br>(The number is random)' : 'Taste mit Tooltip<br>(Die Zahl ist zufällig)',

        'Width of name column in pixels' : 'Breite der Namensspalte in Pixel',

        longText : 'Deutsches Ipsum Dolor meliore persecuti Fußballweltmeisterschaft mel. ' +
            'Te oratio Nackenheim vix. Exerci eloquentiam Hochzeit per.<br>' +
            'Sie können diesen Text in den Einstellungen des geladenen Gebietsschemas ändern. <br> ' +
            'Die Lokalisierungseinstellungen befinden sich in der Datei <b>locales/custom.locale.De.js </b> dieses Beispiels.',
        'Click to show info and switch theme or locale' : 'Klicken Sie hier, um Informationen anzuzeigen und das Thema oder Gebietsschema zu wechseln',
        'Click to show the built in code editor'        : 'Klicken Sie hier, um den integrierten Code-Editor anzuzeigen',
        CheckAutoHints                                  : 'Aktivieren Sie diese Option, um beim Laden des Beispiels automatisch Hinweise anzuzeigen',
        Fullscreen                                      : 'Ganzer Bildschirm'

    },

    Checkbox : {
        'Sort by city' : 'Nach Stadt sortieren',
        'Auto apply'   : 'Automatisch anwenden',
        Automatically  : 'Automatisch'
    },

    MenuItem : {
        'Show <b>food</b> column'  : 'Spalte <b>Essen</b> anzeigen',
        'Show <b>color</b> column' : 'Spalte <b>Farbe</b> anzeigen'
    },

    Slider : {
        'Color width' : 'Farbbreite'
    },

    NumberField : {
        'Name width:' : 'Name Breite:',
        'Type width'  : 'Breite eingeben',

        width      : '14em',
        labelWidth : '7em',

        minValueError : function(value) {
            return 'Der Wert verletzt die mindestgrenze, die gleich ist ' + value;
        },
        maxValueError : function(value) {
            return 'Der Wert verletzt die maximalegrenze, die gleich ist ' + value;
        }
    },

    CodeEditor : {
        'Code editor'   : 'Code-Editor',
        'Download code' : 'Code herunterladen'
    }

};



LocaleManager.registerLocale('Br', {
    desc : 'Português', locale : {
        localeName : 'Br',
        localeDesc : 'Português - Brasileiro',
        ...Br
    }
});


LocaleManager.applyLocale('Br');