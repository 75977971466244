import "../../../helpers/locale/locale_BR"
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium } from "../../../helpers/utils"
import {
  bizFrontEnd,
  setasParaCimaOuParaBaixo,
  tamanhos,
  setaSumario,
  numeroComas,
  destacarCelula
} from "../../../helpers/biz-padrao-visual";
import {
  NumberFormat
} from '@bryntum/grid/grid.umd';
const gridConfig = (dados) => {
  const moeda = new NumberFormat('9,999.99##');

  return {
    store: {
      tree: false,
      data: dados
    },
    columns: [
      {
        text: "Vendedor",
        field: "nomeVendedor",
        sum: () => {
          return "Total"
        },
        flex:1,
        minWidth: 120,
        responsiveLevels: hideMedium
      },
      {
        text: "Vendedor",
        field: "nomeVendedor",
        sum: () => {
          return "Total"
        },
        locked: true,
        width: 120,
        responsiveLevels: showMedium

      },
      {
        text: "Vendido no mês",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "fechadoPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value)
            },
            width: tamanhos.ticketMedio * .6
          },
          {
            text: "R$",
            field: "fechadoValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.faturamento * .8

          }
        ]
      },
      {
        text: "Faturamento no mês",
        align: "center",
        children: [
          {
            text: "Pedidos",
            field: "finalizadoPedidos",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return valorEmInteiro(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return valorEmInteiro(value);
            },
            width: tamanhos.ticketMedio * .6

          },
          {
            text: "R$",
            field: "finalizadoValor",
            type: "number",
            align: "right",
            sum: true,
            summaryRenderer: ({ sum }) => {
              return moeda.format(sum);
            },
            renderer: ({ value, cellElement, record }) => {
              return moeda.format(value)
            },
            width: tamanhos.faturamento * .8

          }
        ]
      }
     
    ]
  };
};

export { gridConfig };
