import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { pegarCores, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, toMoney } from "../../../helpers/utils"
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts, formatarDatasZoomAmCharts, retornarQtdeCategoriasZoomAmCharts } from "../../../helpers/utils-amcharts"

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;
const GraficoJojasAoVivoBenassi = ({ sinalGraficoDepoisValor, cor, nomeGrafico, tipoGrafico, formatoNumeroGrafico, dados, tipoCusto }) => {

    const dadosGrafico = dados?.data || [];
    const chartReferencia = useRef(null);
    useLayoutEffect(() => {
        let maxValores = {
            faturamento: 0,
            faturamento_liquido: 0,
            clientes: 0,
            ticket_medio: 0,
            cmv_percentual: 0,
            margem_contribuicao_percentual: 0
        }
        if (dadosGrafico.length > 0) {
            let cores = pegarCores();
            let success = cores.success.trim();
            let info = cores.info.trim();
            let warning = cores.warning.trim();
            let danger = cores.danger.trim();
            let dark = cores.dark.trim();
            let gray = cores.gray.trim();
            let laranja = "#e7a747";

            const data = dadosGrafico.map(arr => {
                maxValores.faturamento = arr.faturamento > maxValores.faturamento ? arr.faturamento : maxValores.faturamento;

                maxValores.faturamento_liquido = arr.faturamento_liquido > maxValores.faturamento_liquido ? arr.faturamento_liquido : maxValores.faturamento_liquido
                maxValores.faturamento_liquido = arr.desafio_valor > maxValores.faturamento_liquido ? arr.desafio_valor : maxValores.faturamento_liquido

                maxValores.cmv_percentual = arr.cmv_percentual > maxValores.cmv_percentual ? arr.cmv_percentual : maxValores.cmv_percentual
                maxValores.cmv_percentual = arr.desafio_cmv_percentual > maxValores.cmv_percentual ? arr.desafio_cmv_percentual : maxValores.cmv_percentual


                maxValores.clientes = arr.clientes > maxValores.clientes ? arr.clientes : maxValores.clientes
                maxValores.ticket_medio = arr.ticket_medio > maxValores.ticket_medio ? arr.ticket_medio : maxValores.ticket_medio

                maxValores.margem_contribuicao_percentual = arr.margem_contribuicao_percentual > maxValores.margem_contribuicao_percentual ? arr.margem_contribuicao_percentual : maxValores.margem_contribuicao_percentual
                maxValores.margem_contribuicao_percentual = arr.desafio_margem_i_percentual > maxValores.margem_contribuicao_percentual ? arr.desafio_margem_i_percentual : maxValores.margem_contribuicao_percentual


                maxValores.despesas_unidade_percentual = arr.despesas_unidade_percentual > maxValores.despesas_unidade_percentual ? arr.despesas_unidade_percentual : maxValores.despesas_unidade_percentual
                maxValores.despesas_unidade_percentual = arr.desafio_despesa_percentual > maxValores.margem_contribuicao_percentual ? arr.desafio_despesa_percentual : maxValores.margem_contribuicao_percentual

                maxValores.margem_ii_percentual = arr.margem_ii_percentual > maxValores.margem_ii_percentual ? arr.margem_ii_percentual : maxValores.margem_ii_percentual
                maxValores.margem_ii_percentual = arr.desafio_margem_ii_percentual > maxValores.margem_ii_percentual ? arr.desafio_margem_ii_percentual : maxValores.margem_ii_percentual

                maxValores.nao_vendas_percentual = arr.nao_vendas_percentual > maxValores.nao_vendas_percentual ? arr.nao_vendas_percentual : maxValores.nao_vendas_percentual
                maxValores.nao_vendas_percentual = arr.desafio_nao_vendas_percentual > maxValores.desafio_nao_vendas_percentual ? arr.desafio_nao_vendas_percentual : maxValores.desafio_nao_vendas_percentual


                arr.margem_contribuicao_percentual_mostrar = toMoney(arr.margem_contribuicao_percentual, 1) + "%";
                arr.margem_ii_percentual_mostrar = toMoney(arr.margem_ii_percentual, 1) + "%";


                arr.margem_iii_percentual_mostrar = toMoney(arr.margem_iii_percentual, 1) + "%";

                arr.margem_contribuicao_percentual_nome = dados.header.margem_contribuicao_valor;
                arr.margem_ii_percentual_nome = dados.header.margem_ii;
                arr.margem_iii_percentual_nome = dados.header.margem_iii;

                arr.cmv_percentual_mostrar = toMoney(arr.cmv_percentual, 1) + "%";
                arr.nao_vendas_percentual_mostrar = toMoney(arr.nao_vendas_percentual, 1) + "%";

                arr.despesas_unidade_percentual_mostrar = toMoney(arr.despesas_unidade_percentual, 1) + "%";

                arr.cmv_percentual_nome = dados.header.cmv_valor;
                arr.nao_vendas_percentual_nome = dados.header.nao_vendas;
                arr.despesas_unidade_nome = dados.header.despesas_unidade;


                arr.grafico_nome = dados.header[tipoGrafico];


                return { ...arr, date: toDateJS(arr.ano, arr.mes) }
            });
            var chart = am4core.create("chartdiv", am4charts.XYChart);

            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = data;
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [{
                "label": "...",
                "menu": [
                    { "type": "jpg", "label": "JPG" },
                    { "type": "png", "label": "PNG" },
                    { "type": "svg", "label": "SVG" }
                ]
            }];
            const eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValores[tipoGrafico], cor);
            eixoY.renderer.labels.template.disabled = true;


            const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.DateAxis()));
            chart.legend = new am4charts.Legend();
            chart.legend.position = 'top';
            chart.legend.itemContainers.template.paddingTop = 0;

            if (tipoGrafico == "faturamento_liquido") {
                eixoY.max = maxValores.faturamento_liquido * 1.05;
                criarSeries("faturamento_liquido", "", "desafio_valor", "", "v", info, success);
            }
            if (tipoGrafico == "cmv_percentual") {
               // eixoY.min = 0;
                eixoY.max = maxValores.cmv_percentual * 1.05;
                criarSeries("cmv_valor", "cmv_percentual", "desafio_cmv_valor", "desafio_cmv_percentual", "p", laranja, danger);
            }
            if (tipoGrafico == "margem_contribuicao_percentual") {
                //eixoY.min = -100;
                eixoY.max = maxValores.margem_contribuicao_percentual * 1.05;
                criarSeries("margem_contribuicao_valor", "margem_contribuicao_percentual", "desafio_margem_i_valor", "desafio_margem_i_percentual", "p", info, success);
            }
            if (tipoGrafico == "despesas_unidade_percentual") {
                //eixoY.min = -100;
                eixoY.max = maxValores.despesas_unidade_percentual;
                criarSeries("despesas_unidade", "despesas_unidade_percentual", "desafio_despesa_valor", "desafio_despesa_percentual", "p", laranja, danger);
            }
            if (tipoGrafico == "nao_vendas_percentual") {
                //eixoY.min = 0;
                eixoY.max = maxValores.nao_vendas_percentual;
                criarSeries("nao_vendas", "nao_vendas_percentual", "desafio_nao_vendas_valor", "desafio_nao_vendas_percentual", "p", laranja, danger);
            }
            if (tipoGrafico == "margem_ii_percentual") {
                //eixoY.min = -100;
                eixoY.max = maxValores.margem_ii_percentual;
                criarSeries("margem_ii", "margem_ii_percentual", "desafio_margem_ii_valor", "desafio_margem_ii_percentual", "p", info, success);
            }
            function formatarValores(valueLabel, valor, percentual) {
                if (percentual == "")
                    valueLabel.label.text = `[bold font-size: 15px]{${valor}.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
                else
                    valueLabel.label.text = `[bold font-size: 15px]{${percentual}.formatNumber('${formatacaoAmCharts.formatoValores.decimal1Casa}')}%[/]    [opacity: .7]{${valor}.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}[/]`;
            }
            function criarSeries(valor, percentual, valor_necessario, percentual_necessario, tipo, cor_necessario, cor_realizado) {
                var serie_necessario = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: tipo == "v" ? valor_necessario : percentual_necessario,
                    valorX: "date",
                    nome: dados.header[valor_necessario],
                    cor: cor_necessario,
                    chart,
                    tipo: "linha"
                });
                var value_label_necessario = formatarValoresAmCharts(serie_necessario.bullets.push(new am4charts.LabelBullet()), formatoNumeroGrafico, cor_necessario);
                formatarValores(value_label_necessario, valor_necessario, percentual_necessario);


                var serie_realizado = formatarSeriesAmCharts(chart.series.push(new am4charts.LineSeries()), {
                    valorY: tipo == "v" ? valor : percentual,
                    valorX: "date",
                    nome: dados.header[valor],
                    cor: cor_realizado,
                    chart,
                    tipo: "linha"
                });
                var value_label_realizado = formatarValoresAmCharts(serie_realizado.bullets.push(new am4charts.LabelBullet()), formatoNumeroGrafico, cor_realizado);
                formatarValores(value_label_realizado, valor, percentual);

                chart.scrollbarX = new am4core.Scrollbar();
                chart.scrollbarX.parent = chart.bottomAxesContainer;


                //chart.scrollbarX = new am4charts.XYChartScrollbar();
                // chart.scrollbarX.minHeight = 40;
                //chart.scrollbarX.parent = chart.bottomAxesContainer;
                // chart.scrollbarX.series.push(serie_realizado);
                // chart.scrollbarX.scrollbarChart.xAxes.getIndex(0).renderer.labels.template.disabled = true;
            }

            if (data.length > 14) {
                chart.events.on("ready", function () {
                    const dataFinal = data[data.length - 1];
                    const dataInicial = data[data.length - retornarQtdeCategoriasZoomAmCharts()];
                    formatarDatasZoomAmCharts(dateAxis, new Date(dataInicial.ano, dataInicial.mes, 1), new Date(dataFinal.ano, dataFinal.mes, 1));
                });
            }

            return () => {
                chart.dispose();
            };
        }
    }, [dados, tipoGrafico, cor])
    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura", "#tituloGrafico"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerWidth < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = alturaGrafico;

    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}
export default memo(GraficoJojasAoVivoBenassi)