import "../../../helpers/locale/locale_BR"
import { valorEmMoeda, valorEmInteiro, showMedium, hideMedium } from "../../../helpers/utils"
const gridConfig = (dados, estagio) => {

  let columns = [
    {
      text: "Vendedor",
      field: "vendedor",
      sum: () => {
        return "Total"
      },
      width: 130,
      locked: true,
      responsiveLevels: showMedium

    },
    {
      text: "Vendedor",
      field: "vendedor",
      sum: () => {
        return "Total"
      },
      minWidth: 130,
      flex:1,
      responsiveLevels: hideMedium

    },
    {
      text: "Pedido",
      field: "pedido",
      width: 130
    },
    {
      text: "Cliente",
      field: "cliente",
      flex: 1,
      minWidth: 340
    },
    {
      text: "R$",
      field: "valorTotal",
      align: "right",
      width: 150,
      renderer: ({ value, cellElement, record }) => {
        return valorEmMoeda(value)
      },
      sum: true,
      summaryRenderer: ({ sum }) => {
        return valorEmMoeda(sum);
      },
    },
    {
      align: "right",
      text: "Dias de vida",
      field: "diasPedido",
      width: 100
    }
  ]
  
  if (estagio !== "Floresta") {
    columns.push({
      text: `Dias ${estagio}`,
      field: "diasProcesso",
      width: 120,
      align: "right",
      visible: false
    });
  }

  if (estagio == "Faturado") {
    columns.splice(4, 2);
  }

  return {
    store: {
      tree: false,
      data: dados
    },
    columns
  };
};

export { gridConfig };
