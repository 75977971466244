import React from "react"
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { removerFocusNoBotao } from "../../helpers/utils"



const BotoesCard = ({ botoes }) => {

    return (
        <div className="toolbar  button-items text-right ms-auto ml-auto">

            {botoes.map((item, i) => {
                if (item.tipo == 'dropdown')
                    return (
                        <ButtonDropdown
                            isOpen={item.drp_link}
                            toggle={() =>
                                item.set_drp_link(!item.drp_link)
                            }
                            direction="right"
                            className={"dropdown-menu-end"}
                        >
                            <Button id={`drop_${i}`} color={"light"}>{item.nome}</Button>
                            <DropdownToggle caret color={"light"} className="dropdown-toggle-split"><i className="mdi mdi-chevron-down"></i></DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                {
                                    item.links.map((link, i) => {
                                        return (<DropdownItem
                                            onClick={(e) => {
                                                link.funcao();
                                            }}
                                        >{link.nome}</DropdownItem>)
                                    })
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    )
                else
                    return (
                        <Button
                            color={"light"}
                            key={i}
                            className={item.ativo ? "active w-sm" : "w-sm"}
                            //size="sm"
                            type="button"
                            outline
                            onClick={(e) => {
                                item.funcao();
                                removerFocusNoBotao(e.currentTarget);
                            }}
                        >
                            <span className="d-none d-md-block">{item.nome}</span>
                            <span className="d-block d-md-none">{item.abr || item.nome}</span>
                        </Button>
                    )
            })}

        </div>
    )
}


export default BotoesCard
