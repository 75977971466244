import "../../helpers/locale/locale_BR"
import { valorEmMoeda, valorEmInteiro } from "../../helpers/utils"
const gridConfig = (dados) => {

  return {
    store: {
      tree: true,
      data: dados.dados
    },
    columns: [
      {
        text: "#",
        type: "rownumber",
        filterable: false
      },
      {
        text: "Código",
        field: "codigo",
        sum: () => {
          return "Total"
        },
        minWidth: 120
      },
      {
        text: "Descrição",
        field: "descricao",
        flex: 2,
        minWidth: 220
      },
      {
        text: "Sujeito",
        field: "gradeV01",
        flex: 1,
        minWidth: 120
      },
      {
        text: "Família",
        field: "grade01",
        flex: 1,
        minWidth: 120
      },
      {
        text: "Seção",
        field: "grade02",
        flex: 1,
        minWidth: 120

      },
      {
        text: "Grupo",
        field: "grade03",
        flex: 1
      },
      {
        text: "Quantidade",
        field: "quantidade",
        type: "number",
        align: "right",
        renderer: ({ value, cellElement, record }) => {
          if (record.unidade_medida == "UN") {
            return `${valorEmInteiro(value)} ${record.unidade_medida}`;
          } else {
            return `${valorEmMoeda(value)} ${record.unidade_medida}`;
          }
        },
        filterable: false,
        width: 130
      },
      {
        text: "Faturamento",
        field: "valor",
        type: "number",
        align: "right",
        renderer: ({ value }) => valorEmMoeda(value),
        sum: true,
        summaryRenderer: ({ sum }) => {
          return valorEmMoeda(sum);
        },
        filterable: false,
        width: 130
      },
    ]
  };
};

export { gridConfig };
