import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SET_USER_REQUESTED } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError, setUserSucess } from './actions';

function* setUser ({ payload }){
    yield put (setUserSucess(payload))
} 



export function* watchUserSetUser() {
    yield takeEvery(SET_USER_REQUESTED, setUser)
}


function* authSaga() {
    yield all([
        fork(watchUserSetUser),
    ]);
}

export default authSaga;