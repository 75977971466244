import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { pegarCores, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, toMoney } from "../../../../helpers/utils"
import { retornarFormatacaoAmCharts, definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts, formatarDatasZoomAmCharts, retornarQtdeCategoriasZoomAmCharts } from "../../../../helpers/utils-amcharts"

const formatacaoAmCharts = retornarFormatacaoAmCharts();
am4core.options.commercialLicense = true;
const GraficoRelacao = ({ dados }) => {

    const dadosGrafico = dados || [];
    const chartReferencia = useRef(null);
    useLayoutEffect(() => {

        if (dadosGrafico.length > 0) {

            const coresSuplicas = {
                "success": "#34c38f",
                "warning": "#f1b44c",
                "danger": "#f46a6a"
            }

            const cores = [
                "#233d4d",
                "#fe7f2d",
                "#a1c181",
                "#8ecae6",
                "#f5cb5c",
                "#7f7f7f",
                "#7f4f24",
                "#0077b6"
            ]

            let maxValor = 0;
            dadosGrafico.map(arr => {
                [1, 2, 3, 4, 5, 6, 7, 8].forEach(item => {
                    arr[`suplica_${item}_cor`] = coresSuplicas[arr[`suplica_${item}_cor`]];

                    maxValor = arr[`suplica_${item}_resultado`] > maxValor ? arr[`suplica_${item}_resultado`] : maxValor;
                });
            });


            var chart = am4core.create("chartdiv", am4charts.XYChart);

            chartReferencia.current = chart;
            definirLocalizacaoAmCharts(chart);
            chart.data = dadosGrafico;


            const eixoY = formatarEixoYAmCharts(chart.yAxes.push(new am4charts.ValueAxis()), maxValor, "");
            eixoY.renderer.labels.template.disabled = true;
            eixoY.renderer.grid.template.strokeOpacity = 0;

            const dateAxis = formatarEixoXAmCharts(chart.xAxes.push(new am4charts.CategoryAxis()), { categoria: "icr_nome_periodo_resposta" });
            dateAxis.renderer.grid.template.strokeOpacity = 0;

            chart.legend = new am4charts.Legend();
            chart.legend.position = 'top';
            chart.legend.itemContainers.template.paddingTop = 0;

            [1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => {

                let nome = dadosGrafico[0][`suplica_${item}_nome`];

                var serie = chart.series.push(new am4charts.LineSeries());
                serie.dataFields.valueY = `suplica_${item}_resultado`;
                serie.dataFields.categoryX = "icr_nome_periodo_resposta";
                serie.name = nome;
                serie.stroke = am4core.color(cores[i]);
                serie.fill = am4core.color(cores[i]);
                serie.strokeWidth = 2;
                const bullet = new am4charts.CircleBullet();
                bullet.circle.fillOpacity = 1;
                bullet.circle.radius = 3;
                bullet.circle.fill = am4core.color(cores[i]);
                bullet.circle.stroke = am4core.color(cores[i]);
                bullet.circle.strokeWidth = 2;
                serie.bullets.push(bullet);

                var valueLabel = serie.bullets.push(new am4charts.LabelBullet());
                valueLabel.label.fontSize = 15;
                //valueLabel.label.fill = am4core.color("#495057");
                valueLabel.label.propertyFields.fill = `suplica_${item}_cor`;
                valueLabel.label.background.fillOpacity = 0;
                valueLabel.label.paddingRight = 5;
                valueLabel.label.paddingLeft = 5;
                valueLabel.label.paddingTop = 3;
                valueLabel.label.dy = -18;

                valueLabel.label.text = `[bold font-size: 15px]{suplica_${item}_resultado.formatNumber('${formatacaoAmCharts.formatoValores.decimal0Casas}')}%[/]`

            });

            return () => {
                chart.dispose();
            };
        }
    }, [dados])
    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 10;
    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `calc(100vh - ${alturaGrafico + 26}px)`;
    }
    return (
        <div id="chartdiv" style={{ width: "100%", minHeight: alturaFinal }}></div>
    )
}
export default memo(GraficoRelacao)