import "../../../helpers/locale/locale_BR";
import { API } from "../../../config/index";
import { showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, formatarFooterColunaPercentualValor,toDate } from "../../../helpers/utils";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento: 0,
    });
    this.set({
      faturamento: linha.faturamento
    })
  }
}


const gridConfig = (dados, grid, token, data_inicial, data_final, u_unidades_id) => {
  let visualizacao = "familia";
  let p_referencia = "";
  let p_codigo = "";
  let p_grade_v01_id = "";

  let classeN1 = "";
  const larguraColunaComputador = 150;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }
  return {
    readOnly: true,
    columns: [
      {
        text: dados.header.descricao,
        field: "descricao",
        flex: 1,
        type: "tree",
        minWidth: 120,
        responsiveLevels: hideMedium,
        renderer: ({ value, cellElement, record }) => {
          return `${record.codigo} - ${value}`;
        }
      },
      {
        text: dados.header.descricao,
        field: "descricao",
        minWidth: 120,
        type: "tree",
        locked: true,
        responsiveLevels: showMedium,
        renderer: ({ value, cellElement, record }) => {
          return `${record.codigo} - ${value}`;
        },
      },
      {
        align: "right",
        text: dados.header.qtde,
        field: "qtde",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          var qtde = "";
          qtde = `<div class="text-right">${record.qtde > 0 ? toMoney(record.qtde, 4) : '-'}`;

          return qtde;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "left",
        text: dados.header.unidade,
        field: "unidade",
        htmlEncode: false,
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.custo_unitario,
        field: "custo_unitario",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return `<div class="text-right">${value > 0 ? "R$ " + toMoney(value, 6) : '-'}`;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "right",
        text: dados.header.custo_total,
        field: "custo_total",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return `<div class="text-right">${value > 0 ? "R$ " + toMoney(value, 6) : '-'}`;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "center",
        text: dados.header.dataUltimaCompra,
        field: "dataUltimaCompra",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return `<div class="text-right">${value ? toDate(value) : '-'}`;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "center",
        text: dados.header.NFUltimaCompra,
        field: "NFUltimaCompra",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return `<div class="text-right">${value ? value : '-'}`;
        },
        responsiveLevels: configResponsiveLevels,
        filterable: false
      },
      {
        align: "left",
        text: dados.header.fornecedorUltimaCompra,
        field: "fornecedorUltimaCompra",
        type: "number",
        htmlEncode: false,
        renderer: ({ value, cellElement, record }) => {
          return `<div class="text-right">${value ? value : '-'}`;
        },
        flex:1,
        filterable: false
      }
    ],
    store: {
      tree: true,
      tipo_cmv: null,
      p_grade_01: null,
      modelClass: GridLinha,
      fields: ['descricao', 'faturamento'],
      readUrl: `${API}/api/varejo/ws_estrutura_custo`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;

          if (nivel_atual == 0 && !event.json.Message) {
            var request = JSON.parse(event.source.fetchOptions.body);
            this.p_grade_01 = request.p_grade_01;
            this.tipo_cmv = request.tipo_cmv;
          }

        },
        exception(response) {
          console.log(response);
        },
        beforeRequest(event) {
          var nivel_atual = event.params.id ? this.getById(event.params.id).childLevel + 1 : 0;

          if (nivel_atual > 0) {
            p_referencia = this.getById(event.params.id).data.referencia;
            p_codigo = this.getById(event.params.id).data.codigo;
            event.source.fetchOptions.body = JSON.stringify({
              p_grade_01: this.p_grade_01,
              tipo_cmv: this.tipo_cmv,
              p_referencia: p_referencia,
              p_codigo: p_codigo,
              arvore: 99,
              nivel_atual,
              token
            })
          }
        }
      }
    }
  };
};

export { gridConfig };
