import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { definirLocalizacaoAmCharts, formatarEixoXAmCharts, formatarValoresTooltipTextAmCharts, formatarValoresAmCharts, formatarSeriesAmCharts, formatarEixoYAmCharts } from "../../../helpers/utils-amcharts"
import { toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { pegarCores } from '../../../helpers/utils';
import { retornarVisualizacao } from './variaveis';
am4core.options.commercialLicense = true;


const Grafico = ({ cor, nomeGrafico, tipoGrafico, formatoNumeroGrafico, dados, simbolo, id }) => {
    const chartReferencia = useRef(null);

    const dadosGrafico = dados?.data || [];
    useLayoutEffect(() => {
        let cores = pegarCores();
        let maxValor = 0;
        let dadosFormatados = [];
        if (!dados) return
        if (dadosGrafico.length > 0) {
            dadosGrafico.map(arr => {
                if (arr.children)
                    arr.subData = [...arr.children]
                maxValor = arr[tipoGrafico] > maxValor ? arr[tipoGrafico] : maxValor
            });

            var container = am4core.create("chartdiv", am4core.Container);
            container.width = am4core.percent(85);
            container.height = am4core.percent(100);
            container.layout = "horizontal";


            var chart = container.createChild(am4charts.PieChart);
            definirLocalizacaoAmCharts(chart);
            chart.data = dadosGrafico;


            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = tipoGrafico;
            pieSeries.dataFields.category = "u_nome";
            pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
            pieSeries.labels.template.text = `[bold]{category}[/] : {value.percent.formatNumber('#.#')}% \n ${simbolo} {value.formatNumber('${formatoNumeroGrafico}')}`;

            pieSeries.labels.template.fontSize = 15;
            pieSeries.labels.template.fill = am4core.color("#495057")
            pieSeries.labels.template.background.fill = am4core.color("gray");
            pieSeries.labels.template.background.fillOpacity = 0;
            pieSeries.labels.template.paddingRight = 5;
            pieSeries.labels.template.paddingLeft = 5;
            pieSeries.labels.template.paddingTop = 3;
            pieSeries.labels.template.dy = -18;
            /*
            valueLabel.label.text = `{valueY.formatNumber('${formatoNumeroGrafico}')}`;
            valueLabel.label.fontSize = 15;
            valueLabel.label.fill = am4core.color("#495057")
            valueLabel.label.background.fill = am4core.color("gray");
            valueLabel.label.background.fillOpacity = 0.1;
            valueLabel.label.paddingRight = 5;
            valueLabel.label.paddingLeft = 5;
            valueLabel.label.paddingTop = 3;
            valueLabel.label.dy = -18;
            */
            pieSeries.tooltip.disabled = true;
            pieSeries.slices.template.events.on("hit", function (event) {
                selectSlice(event.target.dataItem);
            })
            pieSeries.colors.list = [
                am4core.color(cores.success.trim()), //success
                am4core.color(cores.warning.trim()),//warnning
                am4core.color(cores.primary.trim()),//danger
                am4core.color(cores.info.trim()) //info
            ];

            var chart2 = container.createChild(am4charts.PieChart);
            chart2.width = am4core.percent(30);
            chart2.radius = am4core.percent(80);

            // Add and configure Series
            var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
            pieSeries2.dataFields.value = tipoGrafico;
            pieSeries2.dataFields.category = "u_nome";
            pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
            pieSeries2.labels.template.text = `[bold]{category}[/] : {value.percent.formatNumber('#.#')}% \n ${simbolo} {value.formatNumber('${formatoNumeroGrafico}')}`;

            pieSeries2.labels.template.fontSize = 15;
            pieSeries2.labels.template.fill = am4core.color("#495057")
            pieSeries2.labels.template.background.fill = am4core.color("gray");
            pieSeries2.labels.template.background.fillOpacity = 0;
            pieSeries2.labels.template.paddingRight = 5;
            pieSeries2.labels.template.paddingLeft = 5;
            pieSeries2.labels.template.paddingTop = 3;
            pieSeries2.labels.template.dy = -18;



            //pieSeries2.labels.template.radius = am4core.percent(50);
            //pieSeries2.labels.template.inside = true;
            //pieSeries2.labels.template.fill = am4core.color("#ffffff");
            //pieSeries2.labels.template.disabled = true;
            //pieSeries2.ticks.template.disabled = true;
            //pieSeries2.alignLabels = false;
            pieSeries2.tooltip.disabled = true;
            pieSeries2.events.on("positionchanged", updateLines);

            var interfaceColors = new am4core.InterfaceColorSet();

            var line1 = container.createChild(am4core.Line);
            line1.strokeDasharray = "2,2";
            line1.strokeOpacity = 0.5;
            line1.stroke = interfaceColors.getFor("alternativeBackground");
            line1.isMeasured = false;

            var line2 = container.createChild(am4core.Line);
            line2.strokeDasharray = "2,2";
            line2.strokeOpacity = 0.5;
            line2.stroke = interfaceColors.getFor("alternativeBackground");
            line2.isMeasured = false;

            var selectedSlice;

            function selectSlice(dataItem) {

                selectedSlice = dataItem.slice;

                var fill = selectedSlice.fill;

                var count = dataItem.dataContext.subData.length;
                pieSeries2.colors.list = [];
                for (var i = 0; i < count; i++) {
                    pieSeries2.colors.list.push(fill.brighten(i * 2 / count));
                }

                chart2.data = dataItem.dataContext.subData;
                pieSeries2.appear();

                var middleAngle = selectedSlice.middleAngle;

                var firstAngle = pieSeries?.slices.getIndex(0).startAngle;
                var animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
                animation.events.on("animationprogress", updateLines);

                selectedSlice.events.on("transformed", updateLines);

                //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
                //  animation.events.on("animationprogress", updateLines)
            }

            function updateLines() {
                if (selectedSlice) {
                    var p11 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle) };
                    var p12 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc) };

                    p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
                    p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

                    var p21 = { x: 0, y: -pieSeries2.pixelRadius };
                    var p22 = { x: 0, y: pieSeries2.pixelRadius };

                    p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
                    p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

                    line1.x1 = p11.x;
                    line1.x2 = p21.x;
                    line1.y1 = p11.y;
                    line1.y2 = p21.y;

                    line2.x1 = p12.x;
                    line2.x2 = p22.x;
                    line2.y1 = p12.y;
                    line2.y2 = p22.y;
                }
            }

            chart.events.on("datavalidated", function () {
                setTimeout(function () {
                    selectSlice(pieSeries.dataItems.getIndex(0));
                }, 1000);
            });
            return () => {
                //chart.dispose();
                //chart2.dispose();
            };
        }

    }, [dados, tipoGrafico, cor])

    let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

    let alturaFinal = ""
    if (window.outerHeight < 600) {
        alturaGrafico = alturaGrafico * 4;
        if (alturaGrafico < 300) {
            alturaGrafico = 350
        }
        alturaFinal = `calc(100vh)`;
    } else {
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
    }

    return (
        <div id={id} style={{ width: "100%", minHeight: alturaFinal, maxHeight: alturaFinal, height: alturaFinal }}></div>
    )
}

export default memo(Grafico)