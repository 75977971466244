import { takeEvery, fork, all, call, put } from "redux-saga/effects"
import * as types from "./types"
import * as actions from "./actions"
import { dadosApiFinanceiro } from "../../helpers/api"

function carregarDadosAPI(action) {
    return new Promise((resolve, reject) => {
        dadosApiFinanceiro(action.parametros)
            .then(result => {
                return resolve(result.ResultadoObjeto);
            })
            .catch(e => reject(e))
    })
}

function* graficoEvolucaDespesasAtendidos(action) {
    try {
        const dados = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedida(dados))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* carregarTotais(action) {
    try {
        const totais = yield call(carregarDadosAPI, action.payload)
        yield put(actions.requisicaoBemSucedidaTotais(totais))
    } catch (e) {
        yield put(actions.requisicaoFalhou(e))
    }
}

function* assistindoRequisicao() {
    yield takeEvery(types.REQUISITAR_DADOS, graficoEvolucaDespesasAtendidos)
}

function* assistindoRequisicaoTotais() {
    yield takeEvery(types.REQUISITAR_DADOS_TOTAIS, carregarTotais)
}
function* limparDados() {
    yield put(actions.limparDados())
}

function* assistindoLimparDados() {
    yield takeEvery(types.REQUISITAR_LIMPAR_DADOS, limparDados);
}

function* listarClientesAtendidos() {
    yield all(
        [
            fork(assistindoRequisicao),
            fork(assistindoRequisicaoTotais),
            fork(assistindoLimparDados)
        ]
    )
}

export default listarClientesAtendidos