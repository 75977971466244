import React, { useEffect, useState, useRef, useMemo } from "react";
import PaginaLoader from "../../../components/loader/pagina";
import { useDispatch, useSelector } from "react-redux";
import { requisitarDadosParceiros } from "../../../store/capitalRelacional/relacao/actions";
import {
  requisitarDadosGerais,
  requisitarAtualizarListaClientes,
  requisitarAtualizarListaPerido,
  requisitarAtualizarListaAgentes,
  requisitarAtualizarListaCamadas,
} from "../../../store/capitalRelacional/gerais/actions";
import { gridConfig } from "./AppConfig";
import { FormGroup, Button, Form, Input } from "reactstrap";
import "@bryntum/grid/grid.stockholm.css";
import "../../../App.scss";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from "@bryntum/grid-react";
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from "react-select";
import FocarPaisagem from "../../../components/forcarPaisagem";
const GridPainelCamadasNegocio = () => {


  //Funcionalidades da tela
  const dispatch = useDispatch();
  const grid = useRef(null);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [loading, setLoading] = useState(false);
  const [parceiro, setParceiro] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [agente, setAgente] = useState(null);
  const [camadas, setCamadas] = useState(null);
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [carregandoPeriodo, setCarregandoPeriodo] = useState(false);
  const [carregandoAgente, setCarregandoAgente] = useState(false);
  const [carregandoCamadas, setCarregandoCamadas] = useState(false);
  const [mostrarGrid, setMostrarGrid] = useState(false);
  const token = useSelector((state) => state.Login.user.tokenNOL);
  const { dados, carregando } = useSelector((state) => state.Relacao);
  const tela = useSelector((state) => state.Gerais);
  const [submitHabilitado, setSubmitHabilitado] = useState(false);

  const atualizarDados = async (
    idEmpresa,
    idParceiro,
    idCliente,
    idPeriodoResposta,
    idAgenteHolograma,
    idCamadaOpcao
  ) => {
    await dispatch(
      requisitarDadosParceiros(
        token,
        idEmpresa,
        idParceiro,
        idCliente,
        idPeriodoResposta,
        idAgenteHolograma,
        idCamadaOpcao
      )
    );
  };

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosGerais(token));
  };

  useEffect(() => {
    if (!parceiro) setParceiro(tela.parceiros[0]);
    if (!cliente) setCliente(tela.clientes[0]);
    if (!periodo) setPeriodo(tela.periodos[0]);
    if (!agente) setAgente(tela.agentes[0]);
    if (!camadas) setAgente(tela.camadas[0]);

    if (tela.parceiros?.length && tela.clientes?.length && tela.periodos?.length && tela.camadas?.length) {
      setSubmitHabilitado(true);
    }

  }, [tela]);

  useEffect(() => {
    setCliente(tela.clientes ? tela.clientes[0] : []);
    setCarregandoCliente(false);
  }, [tela.clientes]);

  useEffect(() => {
    setPeriodo(tela.periodos ? tela.periodos[0] : []);
    setCarregandoPeriodo(false);
  }, [tela.periodos]);

  useEffect(() => {
    setAgente(tela.agentes?.length > 0 ? tela.agentes[0] : []);
    setCarregandoAgente(false);
  }, [tela.agentes]);

  useEffect(() => {
    setCamadas(tela.camadas?.length > 0 ? tela.camadas[0] : []);
    setCarregandoCamadas(false);
  }, [tela.camadas]);

  useEffect(() => {
    if (!cliente) return;
    dispatch(
      requisitarAtualizarListaPerido(token, parceiro.value, cliente.value)
    );
    dispatch(
      requisitarAtualizarListaAgentes(token, parceiro.value, cliente.value)
    );
  }, [cliente]);

  useEffect(() => {
    if (!agente || agente.length <= 0) return;
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        agente.value
      )
    );
  }, [agente]);

  const atualizarFiltroDependenciaParceiros = async (idEmpresa) => {
    if (idEmpresa) {
      setCliente([]);
      setPeriodo([]);
      setAgente([]);
      setCamadas([]);
      setCarregandoCliente(true);
      setCarregandoPeriodo(true);
      setCarregandoAgente(true);
      setCarregandoCamadas(true);

      dispatch(requisitarAtualizarListaClientes(token, idEmpresa));
      dispatch(requisitarAtualizarListaPerido(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaAgentes(token, idEmpresa, cliente.value));
      dispatch(requisitarAtualizarListaCamadas(token, idEmpresa, cliente.value, agente.value));

    }
  };

  const atualizarDependenciaCliente = async (idCliente) => {

    setPeriodo([]);
    setAgente([]);
    setCamadas([]);
    setCarregandoPeriodo(true);
    setCarregandoAgente(true);
    setCarregandoCamadas(true);

    dispatch(requisitarAtualizarListaPerido(token, parceiro.value, idCliente));
    dispatch(requisitarAtualizarListaAgentes(token, parceiro.value, idCliente));
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        idCliente,
        agente.value
      )
    );
  };

  const atualizarDependenciasAgente = (idAgente) => {
    setCamadas([]);
    setCarregandoCamadas(true);
    dispatch(
      requisitarAtualizarListaCamadas(
        token,
        parceiro.value,
        cliente.value,
        idAgente
      )
    );
  };

  const atualizarDadosDemanda = () => {
    setMostrarFiltros(false);
    if (!parceiro?.value || !cliente?.value || !periodo?.value) return;
    setLoading(true);
    if (grid.current) {
      // const camadasSelecionadas =
      // camadas.length > 0
      //   ? camadas.map((item) => item.value).join("-,") + "-,"
      //   : "";
      grid.current.instance.store.fetchOptions.body = JSON.stringify({
        token,
        idParceiro: parceiro.value,
        idCliente: cliente.value,
        idPeriodoResposta: periodo.value,
        idAgenteHolograma: agente.value,
        idCamada: camadas.value,
        visualizacao: "opcao",
        classeN1: "xpto",
      });
      grid.current.instance.store.removeAll();
      grid.current.instance.store.loadData();
      grid.current.instance.store
        .load()
        .then((resolve) => {
          setMostrarGrid(true);


          setTimeout(() => {
            setLoading(false);
            setSubmitHabilitado(true);
          }, 500);



          grid.current.instance.on('cellMouseOver', (event) => {
            document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
              if (indice)
                item.style.setProperty("background-color", "#12956c0d", "important");

            });
          });


          grid.current.instance.on('cellMouseOut', (event) => {
            document.querySelectorAll(`[data-column-id="${event.column.id}"]`).forEach((item, indice) => {
              if (indice)
                item.style.removeProperty("background-color");
            });
          });

          document.getElementsByName("icr_descricao")[0].setAttribute("placeholder", resolve.json.ResultadoObjeto.header.icr_descricao)

          // const celulasGrafico = document.querySelectorAll(".grafico_suplica");

          // celulasGrafico.forEach((elemento) => {
          //   // if (!elemento.innerHTML) {
          //   elemento.style.height = "80px";
          //   elemento.style.width = "100%";
          //   gerarGrafico(elemento.id, elemento.getAttribute("valor"));
          //   //}
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
  }, []);

  const gridInfo = useMemo(() => {
    if (!dados) return;
    return gridConfig(dados, grid, token);
  }, [dados]);
  return (
    <React.Fragment>
      <FocarPaisagem />
      <div className="capitalRelacional">
        <PaginaLoader carregando={loading} />
        <>
          <Header
            parametros={{
              //exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros);
                  },
                  icone: "bx bx bx-filter-alt",
                },
              ],
            }}
            position={true}
            titulo="Mapa de negócios"
          />
          {/* <FiltroBrytum aberto={mostrarFiltros}> */}
          <FiltroBrytum aberto={mostrarFiltros}>
            <Form inline>
              <FormGroup className="select2-container">
                <div className="unidadesControler">
                  <Select
                    placeholder="Parceiro"
                    value={parceiro}
                    options={tela.parceiros || []}
                    classNamePrefix="select2-selection"
                    defaultValue={parceiro || []}
                    onChange={(Object) => {
                      setParceiro(Object || []);
                      atualizarFiltroDependenciaParceiros(Object.value);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2">
                  <Select
                    placeholder="Cliente"
                    className="basic-single"
                    isLoading={carregandoCliente}
                    value={cliente}
                    options={tela.clientes}
                    isSearchable={true}
                    classNamePrefix="select2-selection"
                    onChange={(result) => {
                      setCliente(result || []);
                      atualizarDependenciaCliente(result.value);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2">
                  <Select
                    placeholder="Período"
                    className="basic-single"
                    isLoading={carregandoPeriodo}
                    value={periodo}
                    options={tela.periodos}
                    isSearchable={false}
                    classNamePrefix="select2-selection"
                    onChange={(Object) => {
                      setPeriodo(Object || []);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2" style={{ minWidth: "240px" }}>
                  <Select
                    placeholder="Agente do Holograma"
                    className="basic-single"
                    isLoading={carregandoAgente}
                    options={tela.agentes}
                    value={agente}
                    isSearchable={false}
                    classNamePrefix="select2-selection"
                    onChange={(Object) => {
                      setAgente(Object || []);
                      atualizarDependenciasAgente(Object.value);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="unidadesControler ml-2" style={{ minWidth: "230px" }}>
                  <Select
                    placeholder="Camada de negócio"
                    className="basic-single"
                    options={tela.camadas}
                    isLoading={carregandoCamadas}
                    value={camadas}
                    isSearchable={false}
                    //isMulti
                    classNamePrefix="select2-selection"
                    onChange={(Object) => {
                      setCamadas(Object || []);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  color="light"
                  onClick={(e) => {
                    setSubmitHabilitado(false);
                    atualizarDadosDemanda();
                  }}
                  className="ml-2"
                  disabled={!submitHabilitado}
                >
                  <i className="fal fa-hashtag"></i>
                  boralá!
                </Button>
              </FormGroup>
            </Form>
          </FiltroBrytum>
          {dados && (
            <div style={{ display: mostrarGrid ? "block" : "none", flex: 1 }}>
              <BryntumGrid
                ref={grid}
                {...gridInfo}
                treeFeature={false}
                columnReorderFeature={false}
                cellEditFeature={false}
                summaryFeature={false}
                sortFeature={false}
                key={carregando}
                filterBarFeature={{ compactMode: true }}
                cellMenuFeature={{
                  disabled: true,
                }}
                headerMenuFeature={{
                  items: {
                    hideColumn: false,
                  },
                }}
              />
            </div>
          )}
        </>
      </div>
    </React.Fragment>
  );
};
export default GridPainelCamadasNegocio;
