import React from "react"
import { Collapse, Card, CardBody } from "reactstrap"

const FiltroBrytum = ({ aberto, children }) => {
    return (
        <Collapse isOpen={aberto}>
            <Card className={"mb-0"}>
                <CardBody className="altoZIndex barBrytum toolBarVard">
                    {children}
                </CardBody>
            </Card>
        </Collapse>
    )
}

export default FiltroBrytum