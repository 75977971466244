import React, { useLayoutEffect, useRef, memo } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { toMoney, toDateJS, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from "../../../helpers/utils";
import { pegarCores } from "../../../helpers/utils";
import { definirLocalizacaoAmCharts, retornarFormatacaoAmCharts } from "../../../helpers/utils-amcharts";

am4core.options.commercialLicense = true;
const formatacaoAmCharts = retornarFormatacaoAmCharts();

const GraficoBenassi = ({ dados }) => {

    const chartReferencia = useRef(null);
    const dadosGrafico = dados?.data || {};
    useLayoutEffect(() => {
        if (dadosGrafico?.faturamento) {
            const cores = pegarCores();
            let success = cores.success.trim();
            let danger = cores.danger.trim();
            let info = cores.info.trim();

            var opacidade_padrao = .8
            var vermelho = "#f67e7d";

            am4core.useTheme(am4themes_animated);

            var chart = am4core.create(dados.id, am4charts.XYChart);
            chartReferencia.current = chart;




            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
            definirLocalizacaoAmCharts(chart);

            chart.data = [
                {
                    category: "FAT",
                    value: dados.data.faturamento_liquido,
                    open: 0,
                    stepValue: dados.data.faturamento_liquido,
                    color: info,
                    displayValue: dados.data.faturamento_liquido,
                    percentual: " ",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "bold",
                    boldPercentual: "",
                    distancia: -25
                },
                {
                    category: "CMV",
                    value: dados.data.cmv_valor,
                    open: dados.data.faturamento_liquido,
                    stepValue: dados.data.faturamento_liquido - dados.data.cmv_valor,
                    color: danger,
                    displayValue: dados.data.cmv_valor,
                    percentual: toMoney(dados.data.cmv_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "",
                    boldPercentual: "bold",
                    distancia: -43
                },
                {
                    category: "MC",
                    value: dados.data.margem_contribuicao_valor,
                    open: 0,
                    stepValue: dados.data.margem_contribuicao_valor,
                    color: success,
                    displayValue: dados.data.margem_contribuicao_valor,
                    percentual: toMoney(dados.data.margem_contribuicao_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "",
                    boldPercentual: "bold",
                    distancia: -43

                },
                {
                    category: "EXEC",
                    value: dados.data.despesas_unidade,
                    open: dados.data.margem_contribuicao_valor,
                    stepValue: dados.data.margem_contribuicao_valor - dados.data.despesas_unidade,
                    color: danger,
                    displayValue: dados.data.despesas_unidade,
                    percentual: toMoney(dados.data.despesas_unidade_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "",
                    boldPercentual: "bold",
                    distancia: -43
                },
                {
                    category: "CD",
                    value: dados.data.nao_vendas,
                    open: dados.data.margem_contribuicao_valor - dados.data.despesas_unidade,
                    stepValue: dados.data.margem_contribuicao_valor - dados.data.despesas_unidade - dados.data.nao_vendas,
                    color: danger,
                    displayValue: dados.data.nao_vendas,
                    percentual: toMoney(dados.data.nao_vendas_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "",
                    boldPercentual: "bold",
                    distancia: -43
                },
                {
                    category: "MCII",
                    value: dados.data.margem_ii,
                    open: 0,
                    stepValue: dados.data.margem_ii,
                    color: success,
                    displayValue: dados.data.margem_ii,
                    percentual: toMoney(dados.data.margem_ii_percentual, 1) + "%",
                    opacidade: opacidade_padrao,
                    tamanhoValor: 12,
                    tamanhoPercentual: 12,
                    boldValor: "",
                    boldPercentual: "bold",
                    distancia: -43

                }
                // ,
                // {
                //     category: dados.header.despesas_apoio,
                //     value: dados.footer.margem_ii - dados.footer.despesas_apoio,
                //     open: dados.footer.margem_ii,
                //     stepValue: dados.footer.margem_ii - dados.footer.despesas_apoio,
                //     color: vermelho,
                //     displayValue: dados.footer.despesas_apoio,
                //     percentual: toMoney(dados.footer.despesas_apoio_percentual, 1) + "%",
                //     opacidade: opacidade_padrao

                // },
                // {
                //     category: dados.header.resultado,
                //     value: dados.footer.resultado,
                //     open: 0,
                //     stepValue: dados.footer.resultado,
                //     color: "#c4f5ed",
                //     displayValue: dados.footer.resultado,
                //     percentual: toMoney(dados.footer.resultado_percentual, 1) + "%",
                //     opacidade: opacidade_padrao

                // }
            ];

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.grid.template.stroke = am4core.color("gray");

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.renderer.grid.template.stroke = am4core.color("gray");
            valueAxis.strictMinMax = true;
            valueAxis.max = dados.data.faturamento_liquido * 1.3;


            var columnSeries = chart.series.push(new am4charts.ColumnSeries());
            columnSeries.dataFields.categoryX = "category";
            columnSeries.dataFields.valueY = "value";
            columnSeries.sequencedInterpolation = true;
            columnSeries.interpolationDuration = 1500;
            // columnSeries.columns.template.column.cornerRadiusTopLeft = 10;
            // columnSeries.columns.template.column.cornerRadiusTopRight = 10;

            var columnTemplate = columnSeries.columns.template;
            columnTemplate.strokeOpacity = 0;
            columnTemplate.propertyFields.fill = "color";
            columnSeries.columns.template.column.propertyFields.fillOpacity = "opacidade";

            var label = columnTemplate.createChild(am4core.Label);
            label.text = `[{boldPercentual} font-size: {tamanhoPercentual}px]{percentual}[/] \n [{boldValor} font-size: {tamanhoValor}px] {displayValue.formatNumber("#.0a")}[/]`;

            label.align = "center";
            label.valign = "top";
            label.propertyFields.dy = "distancia";
            label.fontSize = 15;
            label.fill = am4core.color("#fff");
            label.background.fill = am4core.color("gray");
            label.background.fillOpacity = 0.7;
            label.paddingRight = 5;
            label.paddingLeft = 5;
            label.paddingTop = 3;
            label.truncate = false;
            label.hideOversized = false;
            label.textAlign = "middle";




            // var stepSeries = chart.series.push(new am4charts.StepLineSeries());
            // stepSeries.dataFields.categoryX = "category";
            // stepSeries.dataFields.valueY = "stepValue";
            // stepSeries.noRisers = true;
            // stepSeries.stroke = am4core.color("gray").lighten(.3);

            // stepSeries.strokeWidth = 0.5;
            // stepSeries.interpolationDuration = 2000;
            // stepSeries.sequencedInterpolation = true;

            // stepSeries.startLocation = 0.1;
            // stepSeries.endLocation = 1.1;



            return () => {
                chart.dispose();
            };
        }

    }, [])

    return (
        <div id={dados?.id || "chartdiv"} style={{ width: "100%", minHeight: "250px" }}></div>
    )
}

export default memo(GraficoBenassi)