import React, { useEffect, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../store/raioXLoja/actions";
import { gridConfig } from './AppConfig';
import Header from "../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from '@bryntum/grid-react';
import { exportarExcel } from '../../helpers/utils';
import FocarPaisagem from '../../components/forcarPaisagem';

const RaioXLoja = () => {
    const { dados, carregando } = useSelector(state => state.RaioXLoja);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const token = useSelector(state => state.Login.user.tokenNOL);
    const atualizarDados = async () => {
        await dispatch(requisitarDados(token, ""));
    }
    
    useEffect(() => {
        if (!dados) atualizarDados();
        return () => {
            if (grid.current) {
                grid.current.gridInstance.store.stopAutoUpdate();
                grid.current.gridInstance.destroy();
                dispatch(requisitarLimparDados());
            }
        }
    }, []);

    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, token);
    }, [dados, grid])

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={carregando} />
            <>
                <Header
                    parametros={
                        {
                            exportarExcel: () => exportarExcel(dados),
                            titulo: "BiZoiada",
                            icone: "fad fa-telescope"
                        }
                    } />
                <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    key={carregando}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />
            </>
        </React.Fragment>
    );
}
export default RaioXLoja;